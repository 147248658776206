import React, {Fragment} from 'react';
import {Link} from "react-router-dom";

import TransactionsTable from '../Transactions/TransactionsTable';
import Table from '../Elements/Table/Table';
import Field from '../Elements/Field';

import {getDocumentDownloadUrl} from '../services';
import Can from "../Auth/Can";
import {Role} from "../Auth/BackOfficeUserRole";
import LinkToOverlay from "../Elements/Overlay/LinkToOverlay";
import {FormatAlignJustify, Launch} from "@material-ui/icons";

type Props = {
    id: string;
    senderId: number;
    senderName: string;
    userId: number;
    userName: string;
    title: string;
    documentType: string;
    customerId: string;
    dates: {
        key: string;
        value: string | null;
    }[];
    origin: string;
    amount: number;
    reason: string;
    traits: {
        key: string;
        val: string;
    }[];
    isDownloadable: boolean;
    channel: string;
    directPaylink: string,
    recipientEmail: string;
    emailFallbackStatuses: { [key: string]: string },
    paymentplan?: PaymentPlanType;
};

const Document = ({
                      id,
                      senderId,
                      senderName,
                      userId,
                      userName,
                      title,
                      documentType,
                      customerId,
                      dates,
                      origin,
                      amount,
                      reason,
                      traits,
                      directPaylink,
                      isDownloadable,
                      channel,
                      recipientEmail,
                      emailFallbackStatuses,
                      paymentplan,
                  }: Props) => {


    const traitsTableHeaders = {
        key: '',
        val: ''
    };

    const datesTableHeaders = {
        key: '',
        value: ''
    };

    let senderLink = null;
    if (senderId && senderName) {
        senderLink = <Link to={'/senders/' + senderId}>{senderName}</Link>;
    }

    let userLink = null;
    if (userId) {
        if (userName) {
            userLink = <Link to={'/users/' + userId}>{userName}</Link>;
        } else {
            userLink = <Link to={'/users/' + userId}>{userId}</Link>;
        }
    }

    const overlay: any = `/json-details/${id}`;
    function renderOverlay() {
        // @ts-ignore
        return <LinkToOverlay overlay={overlay}>
            <div className="mb-2 btn btn-success btn-sm"> Document json <FormatAlignJustify /></div>
        </LinkToOverlay>;
    }

    function renderDirectPaylinkButton() {
            return <button onClick={()=> window.open(directPaylink, "_blank")} className="mb-2 ml-3 btn btn-success btn-sm"> Direct paylink <Launch /> </button>
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col">
                    <h1 className="mb-5">Document: {title}</h1>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Field name='Document Id'>{id}</Field>
                    <Field name='Document title'>{title}</Field>
                    <Field name='Document type'>{documentType}</Field>
                    <Field name='Customer Id'>{customerId}</Field>
                    <Field name='Recipient Email'>{recipientEmail}</Field>
                    <Field name='Email Fallback Status'>{emailFallbackStatuses && Object.keys(emailFallbackStatuses).map(key => {
                        return <div>{key} - {emailFallbackStatuses[key]}</div>
                    })}</Field>
                    <Table columns={datesTableHeaders} items={dates} hideHeader name="Dates" extraClassName='table-sm'/>
                    <Field name='Origin'>{origin}</Field>
                    <Field name='Sender'>{senderLink}</Field>
                    <Field name='User'>{userLink}</Field>
                    {isDownloadable &&
                    <Field name='Download'>
                        <a
                            className="btn btn-success"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getDocumentDownloadUrl(id)}
                            children="Download"
                        />
                    </Field>
                    }
                </div>
                <div className="col">
                    <span>
                        <Can roles={[Role.READ]}>
                            {renderOverlay()}
                            { directPaylink &&
                                renderDirectPaylinkButton()
                            }
                        </Can>
                    </span>
                    <br/>
                    <Table
                        columns={traitsTableHeaders}
                        items={traits}
                        hideHeader
                        name="Traits"
                        extraClassName='table-sm'
                    />
                    <Field name='Amount' inline>{amount}</Field>
                    <Field name='Reason' inline>{reason}</Field>
                    <Field name='Channel' inline>{channel}</Field>
                    {paymentplan && (
                        <div className="mt-3">
                            <Table
                                name="Payment plan"
                                extraClassName="table-sm"
                                columns={{
                                    amount: 'Amount',
                                    dueDate: 'Due date',
                                    paid: 'Paid',
                                }}
                                items={paymentplan.brackets}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <TransactionsTable
                        name="Transactions"
                        itemsPerPage={10}
                        searchParams={{documentId: id}}
                        hideFilters
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default Document;



















