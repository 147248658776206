import React, {Fragment} from 'react';
import UsersTable from './UsersTable';

const UsersRoute = () => (
    <Fragment>
        <h1 className="mb-5">Users</h1>
        <UsersTable/>
    </Fragment>
);

export default UsersRoute;