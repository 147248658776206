import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import BillingReportMailForm from "./BillingReportMailForm";
import {fetchBillingReport} from "../../../services";
import Fetch from "../../../Elements/Fetch";

const BillingReportMailRoute = ({match, history, onSubmit}) => {
    if (!match || !match.params || !match.params.id) {
        return null; //Why is this necessary? When closing the overlay, we receive null here but this isn't the case in the (e.g.) user routes?!
    }

    return (
        <Fetch fetchFunction={fetchBillingReport} fetchFunctionParams={match.params.id}>
            {billingReport => {
                const handleSubmit = onSubmit || (() => history.push('/billing/reports'));

                return (
                    <div style={{maxWidth: 800}}>
                        <BillingReportMailForm billingReport={billingReport} onSubmit={handleSubmit}/>
                    </div>
                );
            }}
        </Fetch>
    );
};

BillingReportMailRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default withRouter(BillingReportMailRoute);