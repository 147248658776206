import React from 'react';
import PropTypes from 'prop-types';
import {fetchBillingRules} from "../../../services";
import Fetch from "../../../Elements/Fetch";
import BillingRuleEditForm from "./BillingRuleEditForm";

const BillingRuleEditRoute = ({match, history, onSubmit}) => (
    <Fetch fetchFunction={fetchBillingRules} fetchFunctionParams={{id: match.params.id}}>
        {billingRules => {

            const billingRule = billingRules.items[0];

            if (!billingRule) {
                return <div className="alert alert-danger">404: Not Found</div>;
            }

            const handleSubmit = onSubmit || (() => history.push('/billing/rules'));

            return (
                <div style={{maxWidth: 800}}>
                    <BillingRuleEditForm
                        billingRule={billingRule}
                        onSubmit={handleSubmit}
                    />
                </div>
            );
        }}
    </Fetch>
);

BillingRuleEditRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default BillingRuleEditRoute;
