import React from 'react';
import {fetchSender} from "../services";
import Fetch from "../Elements/Fetch";
import Sender from "./Sender";

const SenderRoute = ({match}) => (
    <Fetch fetchFunction={fetchSender} fetchFunctionParams={match.params.id} refreshKey={`sender:${match.params.id}`}>
        {sender => <Sender {...sender}/>}
    </Fetch>
);

export default SenderRoute;