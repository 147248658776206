import React from 'react';
import PropTypes from 'prop-types';
import SenderMerchantAddForm from "./SenderMerchantAddForm";

const SenderMerchantAddRoute = ({match, history, onSubmit}) => {

    const handleSubmit = onSubmit || (() => history.push('/senders/' + match.params.id));

    return (
        <div style={{maxWidth: 800}}>
            <SenderMerchantAddForm
                senderId={match.params.id}
                onSubmit={handleSubmit}
            />
        </div>
    );
};

SenderMerchantAddRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default SenderMerchantAddRoute;