import React, {useEffect, useState} from 'react';
import {fetchMerchantsForSender} from '../../../services';
import CreateSenderStepCounter from '../../wizard/CreateSenderStepCounter';
import CreateSenderStepButtons from '../../wizard/CreateSenderStepButtons';
import FormGroupRowText from "../../../Elements/FormGroupRowText";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import {ArrowDropDownOutlined} from "@material-ui/icons";
import {covertToLabel} from "../../CreateSenderHelper";
import {appleAttributes} from "./CreateSenderMerchantHelper";

const CreateSenderStepMerchant = (props: any) => {
    const [paymentFlows, setPaymentFlows] = useState<any[]>(props.data.merchant.paymentFlows || []);
    const [parentMerchantProviders, setParentMerchantProviders] = useState<any[]>([]);
    const [merchantProviders, setMerchantProviders] = useState<{ name: string, label: string, enabled: boolean, type: string, attributes: { key: string, value: string }[] }[]>(props.data.merchant.providers || []);

    const updateBody = () => {
        props.updateBody({
            merchant: {
                providers: merchantProviders,
                paymentFlows: paymentFlows,
            }
        });
    };

    const isCurrentStep = () => {
        return props.isActive;
    };

    useEffect(() => {
        const {senderParentId} = props.data.parent;
        if (senderParentId !== undefined && senderParentId !== '') {
            fetchMerchantsForSender(senderParentId).then(merchants => {
                setParentMerchantProviders(merchants);
            });
        }
    }, [props.data.parent]);

    function handleFormChange(rowIndex: number, attIndex: number, value: any) {
        setMerchantProviders(prevState => {
            const attributes = [...prevState[rowIndex].attributes];
            attributes[attIndex] = {...attributes[attIndex], value: value};
            const updatedMerchantProviders = [...prevState];
            updatedMerchantProviders[rowIndex] = {...prevState[rowIndex], attributes: attributes};
            return updatedMerchantProviders;
        });
    }

    function handlePaymentFlowsCheckBoxChange(paymentFlow: any, index: number, isSelected: boolean) {
        setPaymentFlows(prevState => {
            const updatedPaymentFlows = [...prevState];
            updatedPaymentFlows[index] = {
                ...updatedPaymentFlows[index],
                isSelected: isSelected
            };
            let clonedPaymentFlow = JSON.parse(JSON.stringify(updatedPaymentFlows[index]));
            prepareProviders(clonedPaymentFlow);
            return updatedPaymentFlows;
        });
    }

    function canPaymentFlowBeRemoved(paymentFlow: any) {
        let results = paymentFlows.map(pf => pf.type === paymentFlow.type && pf.isSelected && pf.label !== paymentFlow.label);
        return !results.includes(true);
    }

    function isFormValid() {
        const providerWithEmptyValue = merchantProviders.find(provider => provider.attributes.some(attr => attr.value === ''));
        const isAnyItemSelected = paymentFlows.some(item => item.isSelected);
        return isAnyItemSelected && !providerWithEmptyValue;
    }

    function prepareProviders(paymentFlow: any) {
        let updatedProviders = [...merchantProviders];
        if (paymentFlow.isSelected) {
            let providerExist = updatedProviders.find(provider => provider.type === paymentFlow.type);
            if (!providerExist) {
                updatedProviders.push(paymentFlow);
            }

        } else if (canPaymentFlowBeRemoved(paymentFlow)) {
            let providerIndex = updatedProviders.findIndex(provider => provider.type === paymentFlow.type);
            updatedProviders.splice(providerIndex, 1);
        }

        if (paymentFlow.method === 'applepay' && updatedProviders.length > 0) {
            addORDeleteExtraAttributes(paymentFlow, updatedProviders);
        }

        setMerchantProviders(updatedProviders);
    }

    function addORDeleteExtraAttributes(paymentFlow: any, updatedProviders: any[]) {
        let attributes = updatedProviders[0]?.attributes;
        if (paymentFlow.isSelected) {
            attributes = [...attributes, ...appleAttributes];
        } else {
            attributes.splice(2, 2)
        }
        updatedProviders[0].attributes = attributes;
    }

    function renderAddedMerchants(merchantProviders: any[], isForParent: boolean) {
        return (
            <Accordion key={isForParent.toString()} defaultExpanded={!isForParent}>
                <AccordionSummary expandIcon={<ArrowDropDownOutlined fontSize="large"/>}>
                    <div className='d-flex justify-content-between w-100'>
                        <h5>{isForParent ? 'Parent providers' : 'Partner providers'}</h5>
                    </div>
                </AccordionSummary>
                <div className='p-3'>
                    {!isForParent &&
                        <>
                            <h5>Payment flows</h5>
                            <div className='d-flex justify-content-md-start flex-wrap mt-3'>
                                {paymentFlows.map((flow, index) => (
                                    <div key={index+'input-wrapper'} className="form-check mr-5 mb-5 ">
                                        <input key={index+'input'} checked={flow.isSelected} className="form-check-input"
                                               type="checkbox"
                                               id={isForParent ? `parent-${index}` : `child-${index}`}
                                               onChange={event => handlePaymentFlowsCheckBoxChange(flow, index, event.target.checked)}/>
                                        <label className="form-check-label"  htmlFor={isForParent ? `parent-${index}` : `child-${index}`}>{flow.label}</label>
                                    </div>
                                ))}
                            </div>
                        </>
                    }
                </div>

                <AccordionDetails>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Type</th>
                            <th>Attributes</th>
                        </tr>
                        </thead>
                        <tbody>

                        {(merchantProviders === undefined || merchantProviders.length === 0) &&
                            <tr>
                                <td className='text-center' colSpan={4}>
                                    No providers found
                                </td>
                            </tr>
                        }
                        {merchantProviders?.map((mp: any, rowIndex: number) => {
                            return (
                                <tr key={rowIndex}>
                                    <td className="align-middle">{covertToLabel(mp.type)}</td>
                                    <td className="align-middle">
                                        {mp.attributes.map((a: any, attIndex: number) => {
                                            return <FormGroupRowText label={covertToLabel(a.key)}
                                                                     value={a.value}
                                                                     key={attIndex+isForParent.toString()}
                                                                     disabled={isForParent}
                                                                     type="string"
                                                                     cssClasses={['mt-3']}
                                                                     onChange={value => handleFormChange(rowIndex, attIndex, value)}/>
                                        })}
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>
        )
    }

    const renderBody = () => {
        if (!isCurrentStep()) {
            return (<></>);
        }

        return (
            <div className="card-body">
                <h4>Merchants</h4>

                <div className='mt-3 mb-3 p-3'>
                    {renderAddedMerchants(parentMerchantProviders, true)}
                    {renderAddedMerchants(merchantProviders, false)}
                </div>
                <CreateSenderStepButtons {...props}
                                         isValid={isFormValid()}
                                         onBack={updateBody}
                                         onNext={updateBody}> </CreateSenderStepButtons>
            </div>
        );
    };

    return (
        <div>
            <div className="card">
                <div className="card-header text-center">
                    <CreateSenderStepCounter {...props}></CreateSenderStepCounter>
                </div>
                {renderBody()}
            </div>
        </div>
    );
};
export default CreateSenderStepMerchant;