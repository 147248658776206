import React from 'react';
import PropTypes from 'prop-types';
import {fetchSenderOnboarding, sendBuckarooOnboardingMerchantRequest} from "../services";
import Fetch, {withRefresh} from "../Elements/Fetch";
import SenderOnboardingSendBuckarooOnboardingMerchantRequestForm
    from "./SenderOnboardingSendBuckarooOnboardingMerchantRequestForm";

const SenderOnboardingSendBuckarooOnboardingMerchantRequestRoute = ({match, history, onSubmit, triggerRefresh}) => {
    return (
        <Fetch fetchFunction={fetchSenderOnboarding} fetchFunctionParams={match.params.id}>
            {senderOnboarding => {
                const handleAction = async () => {
                    try {
                        await sendBuckarooOnboardingMerchantRequest(senderOnboarding.id);
                        onSubmit();
                    } finally {
                        triggerRefresh(`senderOnboarding:${senderOnboarding.id}`, true);
                    }
                };

                return (
                    <SenderOnboardingSendBuckarooOnboardingMerchantRequestForm
                        json={senderOnboarding.buckarooOnboardingAPIData}
                        onAction={handleAction}
                    />
                );
            }}
        </Fetch>
    );
};

SenderOnboardingSendBuckarooOnboardingMerchantRequestRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default withRefresh(SenderOnboardingSendBuckarooOnboardingMerchantRequestRoute);