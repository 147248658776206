import React, {useState} from 'react';
import {Col, Row} from "reactstrap";
import FormWithActions from "../Elements/FormWithActions/FormWithActions";
import FileUpload from "../FileUpload/FileUpload";

const SenderOnboardingBuckarooAddFileForm = ({onAction}) => {
    const [files, setFiles] = useState([]);

    const handleAction = () => {
        return onAction(files);
    };

    return (
        <>
            <Row>
                <Col>
                    <h1 className="mb-5">
                        Buckaroo add documents
                    </h1>
                </Col>
            </Row>
            <FormWithActions onAction={handleAction} disableSubmitReason={files.length > 0 ? '' : 'Select a file to continue.'}>
                <FileUpload
                    files={files}
                    setFiles={setFiles}
                    dropzoneOptions={{
                        multiple: true,
                        maxSize: 1024 * 1024 * 4,
                        accept: {
                            'image/png': ['.png'],
                            'image/jpg': ['.jpg', '.jpeg'],
                            'image/gif': ['.gif'],
                            'image/tiff': ['.tiff'],
                            'application/pdf': ['.pdf'],
                            'application/msword': ['.doc'],
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                        },
                    }}
                />
            </FormWithActions>
        </>
    );
};

export default SenderOnboardingBuckarooAddFileForm;