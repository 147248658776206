import React, {Component} from 'react';
import {fetchStats, formatDate} from '../services';
import googleChartLoader from '../GoogleChartLoader';
import Loader from '../Elements/Loader';
import {Button, ButtonGroup, Row, Col} from 'reactstrap';
import Select from 'react-virtualized-select';
import FetchFromContext from "../Elements/FetchFromContext";

class PaymentSpeedStats extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isCommunicatingWithServer: true,
            showError: false,
            rows: [],
            date: formatDate(new Date()),
            nbPayments: 0,
            nbMax: 0,
            groupBy: 'month',
            billingSenderIds: [],
            channels: [],
            height: this.props.height ? parseInt(this.props.height, 10) : 400,
        };

        this.drawDashboard = this.drawDashboard.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleChangeSenders = this.handleChangeSenders.bind(this);
        this.handleChangeChannels = this.handleChangeChannels.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);

        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        googleChartLoader.init(['corechart']);
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.groupBy !== this.state.groupBy) {
            this.fetchData();
            return;
        }

        if (prevState.date !== this.state.date) {
            this.fetchData();
            return;
        }

        if (JSON.stringify(prevState.billingSenderIds) !== JSON.stringify(this.state.billingSenderIds)) {
            this.fetchData();
            return;
        }

        if (JSON.stringify(prevState.channels) !== JSON.stringify(this.state.channels)) {
            this.fetchData();
            return;
        }

        if (prevState.rows.length !== this.state.rows.length) {
            googleChartLoader.init().then(() => {
                this.drawDashboard();
            });
        }
    }

    fetchData() {
        this.setState({
            isCommunicatingWithServer: true,
            showError: false,
            rows: [],
        });

        fetchStats('paymentSpeed', {
            groupBy: this.state.groupBy,
            billingSenderIds: this.state.billingSenderIds,
            channels: this.state.channels,
            date: this.state.date || formatDate(new Date()),
        })
            .then(data => {
                const processedData = [
                    ['< 5m', data[0]], //0: Binnen 5 minuten
                    ['< 1u', data[1]], //1: Binnen een uur
                    ['< 1d', data[2]], //2: Binnen een dag
                    ['< 1w', data[3]], //3: Binnen een week
                    ['< 1M', data[4]], //4: Binnen een maand
                    ['> 1M', data[5]], //5: Langer
                ];

                const nbPayments = data.reduce((prev, curr) => prev + curr, 0);

                processedData[0][2] = `${processedData[0][1]}`;
                processedData[0][3] = `< ${Math.round(processedData[0][1] / nbPayments * 100)}%`;
                processedData[0][4] = `${Math.round(processedData[0][1] / nbPayments * 100)}%`;

                processedData[1][2] = `${processedData[1][1]}`;
                processedData[1][3] = `< ${Math.round((processedData[0][1] + processedData[1][1]) / nbPayments * 100)}%`;
                processedData[1][4] = `${Math.round(processedData[1][1] / nbPayments * 100)}%`;

                processedData[2][2] = `${processedData[2][1]}`;
                processedData[2][3] = `< ${Math.round((processedData[0][1] + processedData[1][1] + processedData[2][1]) / nbPayments * 100)}%`;
                processedData[2][4] = `${Math.round(processedData[2][1] / nbPayments * 100)}%`;

                processedData[3][2] = `${processedData[3][1]}`;
                processedData[3][3] = `< ${Math.round((processedData[0][1] + processedData[1][1] + processedData[2][1] + processedData[3][1]) / nbPayments * 100)}%`;
                processedData[3][4] = `${Math.round(processedData[3][1] / nbPayments * 100)}%`;

                processedData[4][2] = `${processedData[4][1]}`;
                processedData[4][3] = `< ${Math.round((processedData[0][1] + processedData[1][1] + processedData[2][1] + processedData[3][1] + processedData[4][1]) / nbPayments * 100)}%`;
                processedData[4][4] = `${Math.round(processedData[4][1] / nbPayments * 100)}%`;

                processedData[5][2] = `${processedData[5][1]}`;
                processedData[5][3] = `< ${Math.round((processedData[0][1] + processedData[1][1] + processedData[2][1] + processedData[3][1] + processedData[4][1] + processedData[5][1]) / nbPayments * 100)}%`;
                processedData[5][4] = `${Math.round(processedData[5][1] / nbPayments * 100)}%`;

                this.setState({
                    isCommunicatingWithServer: false,
                    rows: processedData,
                    nbPayments: nbPayments,
                    nbMax: Math.max(processedData[0][1], processedData[1][1], processedData[2][1], processedData[3][1], processedData[4][1], processedData[5][1]),
                });
            })
            .catch(() => {
                this.setState({
                    isCommunicatingWithServer: false,
                    showError: true,
                });
            });
    }

    drawDashboard() {
        const data = new window.google.visualization.DataTable();
        data.addColumn("string", 'Time');
        data.addColumn("number", 'Payments');
        data.addColumn({type:'string', role:'annotation'});
        data.addColumn({type:'string', role:'annotation'});
        data.addColumn({type:'string', role:'annotation'});
        data.addRows(this.state.rows);

        const options = {
            title: 'Payment speed',
            legend: {
                position: 'none',
            },
            height: this.state.height,
            chartArea: {
                left: 50,
                right: 0,
                top: 30,
                bottom: 30,
            },
            tooltip: {
                trigger: 'none',
            },
            annotations: {
                alwaysOutside: true,
            },
            vAxis: {
                viewWindow: {
                    max: this.state.nbMax * 1.4, //Trial and error to find a factor which leaves enough room for outside annotation
                },
            },
        };

        const chart = new window.google.visualization.ColumnChart(document.getElementById('PaymentSpeedStats'));
        chart.draw(data, options);
    }

    handleChangeSenders(items){
        this.setState({
            billingSenderIds: items.map(item => item.value),
        });
    }

    handleChangeChannels(items){
        this.setState({
            channels: items.map(item => item.value),
        });
    }

    handleChangeDate(value){
        this.setState({
            date: value,
        });
    }

    handleClick(groupBy) {
        this.setState({
            groupBy: groupBy,
        });
    }

    render() {

        const height = this.state.height + 60;

        return (
            <div id="PaymentSpeedStatsWrapper">
                <Row>
                    <Col>
                        <FetchFromContext property="billingSenders">
                            {billingSenders => (
                                <Select
                                    placeholder="Billing senders"
                                    value={this.state.billingSenderIds}
                                    options={billingSenders.map(billingSender => ({
                                        value: billingSender.id,
                                        label: billingSender.name,
                                    }))}
                                    onChange={this.handleChangeSenders}
                                    multi
                                />
                            )}
                        </FetchFromContext>
                    </Col>
                    <Col xs="auto" style={{width: 150}}>
                        <FetchFromContext property="channels">
                            {channels => (
                                <Select
                                    placeholder="Channels"
                                    value={this.state.channels}
                                    options={channels.map(channel => ({
                                        value: channel,
                                        label: channel,
                                    }))}
                                    onChange={this.handleChangeChannels}
                                    multi
                                />
                            )}
                        </FetchFromContext>
                    </Col>
                    <Col xs="auto">
                        <input type="date" className="form-control form-control-sm" value={this.state.date} onChange={event => this.handleChangeDate(event.target.value)}/>
                    </Col>
                    <Col xs="auto">
                        <ButtonGroup size="sm">
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.groupBy === 'day'} color="success" onClick={() => this.handleClick('day')}>Day</Button>
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.groupBy === 'month'} color="success" onClick={() => this.handleClick('month')}>Month</Button>
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.groupBy === 'year'} color="success" onClick={() => this.handleClick('year')}>Year</Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col/>
                    <Col xs="auto">
                        # payments: {this.state.nbPayments}
                    </Col>
                </Row>
                <div style={{height: height + 'px'}}>
                    {this.state.isCommunicatingWithServer &&
                    <div className="d-flex flex-column justify-content-center h-100 text-center">
                        <Loader/>
                    </div>
                    }
                    {this.state.showError &&
                    <div className="d-flex flex-column justify-content-center h-100 text-center">
                        An error occured showing this graph. Try refreshing the page.
                    </div>
                    }
                    <div id="PaymentSpeedStats"/>
                </div>
            </div>
        );

    }
}

export default PaymentSpeedStats;
