import React from 'react';
import PropTypes from 'prop-types';

import FormGroupRowLargeSelect from "./FormGroupRowLargeSelect";

const FormGroupRowSender = ({senders, onChange, multi, ...props}) => {

    const options = senders.map(item => ({
        value: item.id,
        label: item.id + ': ' + item.name + ' (' + item.senderContractNumber + ')',
    }));

    return (
        <FormGroupRowLargeSelect
            {...props}
            onChange={onChange}
            options={options}
            multi={multi}
            addEmptyOption={!multi}
        />
    );
};

FormGroupRowSender.propTypes = {
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    value: PropTypes.any.isRequired,
    senders: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default FormGroupRowSender;