import React, {Component} from 'react';
import {PlusIcon, XIcon} from 'react-octicons';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class AddParentSettingsForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            parentSettings: props.parentSettings.map(setting => ({...setting, deleted: false})),
        };
        props.newParentSettings.current = this.sendParentSettings;
        this.handleAdd = this.handleAdd.bind(this);
        this.handlePaste = this.handlePaste.bind(this);
        this.handleDeleteSetting = this.handleDeleteSetting.bind(this);
        this.handleChangeSettings = this.handleChangeSettings.bind(this);
    }

    sendParentSettings = () => {
        let newParentSettings = this.state.parentSettings.filter(ps => ps.name && ps.value && ps.category);
        return newParentSettings;
    }

    handleChangeSettings(value, index, property) {
        this.setState((prevState, props) => ({
            parentSettings: [
                ...prevState.parentSettings.slice(0, index),
                {...prevState.parentSettings[index], [property]: value},
                ...prevState.parentSettings.slice(index + 1),
            ],
        }));
    }

    handlePaste(event) {
        const clipboardData = event.clipboardData.getData('Text').trim();
        if (clipboardData.includes("\t")) {
            event.preventDefault();

            let clipboardLines = [clipboardData];
            if (clipboardData.includes("\n")) {
                clipboardLines = clipboardData.split("\n");
            }
            if (clipboardData.includes("\r")) {
                clipboardLines = clipboardData.split("\r");
            }

            this.setState((prevState) => ({
                parentSettings: [
                    ...prevState.parentSettings,
                    ...clipboardLines.map(clipboardLine => {
                        const lineData = clipboardLine.split("\t");
                        return {
                            key: 'NEW',
                            category: lineData.shift().trim(),
                            name: lineData.shift().trim(),
                            value: lineData.join(' ').trim(),
                            deleted: false,
                        };
                    }),
                ],
            }));
        }
    }

    handleAdd() {
        this.setState((prevState, props) => ({
            parentSettings: [...prevState.parentSettings, {
                key: 'NEW',
                category: '',
                name: '',
                value: '',
                deleted: false,
            }],
        }));
    }

    handleDeleteSetting(index) {
        this.setState((prevState, props) => {
            if (prevState.parentSettings[index].key === 'NEW') {
                return {
                    parentSettings: [
                        ...prevState.parentSettings.slice(0, index),
                        ...prevState.parentSettings.slice(index + 1),
                    ],
                };
            } else {
                return {
                    parentSettings: [
                        ...prevState.parentSettings.slice(0, index),
                        {...prevState.parentSettings[index], deleted: true},
                        ...prevState.parentSettings.slice(index + 1),
                    ],
                };
            }
        });
    }

    render() {
        const parentSettings = this.state.parentSettings.map((setting, index) => {
            if (setting.deleted) {
                return "";
            }

            const hasOtherRowsWithSameSetting = this.state.parentSettings.filter(s => s.category === setting.category && s.name === setting.name).length > 1;

            return (
                <div className="form-group form-row" key={index}>
                    <div className="col">
                        <input
                            tabIndex="1"
                            type="text"
                            placeholder="category"
                            className={classNames("form-control", {
                                'is-invalid': !setting.category || hasOtherRowsWithSameSetting,
                            })}
                            value={setting.category}
                            onChange={event => this.handleChangeSettings(event.target.value, index, 'category')}
                            onPaste={(event) => this.handlePaste(event, index)}
                        />
                    </div>
                    <div className="col">
                        <input
                            tabIndex="1"
                            type="text"
                            placeholder="name"
                            className={classNames("form-control", {
                                'is-invalid': !setting.name || hasOtherRowsWithSameSetting,
                            })}
                            value={setting.name}
                            onChange={(event) => this.handleChangeSettings(event.target.value, index, 'name')}
                            onPaste={event => this.handlePaste(event, index)}
                        />
                    </div>
                    <div className="col">
                        <input
                            tabIndex="1"
                            type="text"
                            placeholder="value"
                            className={classNames('form-control', {
                                'is-invalid': !setting.value || hasOtherRowsWithSameSetting,
                            })}
                            value={setting.value}
                            onChange={event => this.handleChangeSettings(event.target.value, index, 'value')}
                            onPaste={(event) => this.handlePaste(event, index)}
                        />
                    </div>
                    <div className="col-1 col-form-label">
                        <button type="button" className="btn btn-link btn-sm"
                                onClick={() => this.handleDeleteSetting(index)}>
                            <XIcon/>
                        </button>
                    </div>
                </div>
            );
        });

        return (
            <>
                {parentSettings}
                <button type="button" className="btn btn-link btn-sm" onClick={this.handleAdd}>
                    <PlusIcon/>
                </button>
            </>
        );
    }
}

AddParentSettingsForm.propTypes = {
    parentSettings: PropTypes.array.isRequired,
};

export default AddParentSettingsForm;
