import React, {Component} from 'react';
import Loader from './Loader';
import PomModalAction from '../Elements/PomModalAction';
import {Button, Modal} from 'reactstrap';
import PropTypes from 'prop-types';

class PomModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            confirmAction: props.confirmAction,
            confirmMessage: props.confirmMessage,
            showFeedback: props.showFeedback,
            feedback: props.feedback,
            isSubmitting: false,
            error: '',
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.isOpen && this.props.isOpen !== prevProps.isOpen) {
            this.setState({
                showModal: true,
                confirmAction: null,
                confirmMessage: null,
                showFeedback: false,
                feedback: null,
                isSubmitting: false,
                error: '',
            });
        }
    }

    handleAction(action, showFeedback) {
        this.setState({
            isSubmitting: true,
        });

        this.props.onAction(action).then(feedback => {
            if (showFeedback) {
                this.setState({
                    confirmAction: null,
                    confirmMessage: null,
                    feedback: feedback,
                    isSubmitting: false,
                    error: '',
                });
            }
            else {
                this.props.onClose();
            }
        }).catch(errorMessage => {
            this.setState({
                isSubmitting: false,
                error: String(errorMessage),
            });
        });
    }

    handleConfirm(action, confirmMessage, showFeedback) {
        this.setState({
            confirmAction: action,
            confirmMessage: confirmMessage,
            showFeedback: showFeedback,
        });
    }

    render() {
        const isSubmitting = this.state.isSubmitting;

        let modalContent = this.props.children;

        let actions = this.props.actions.map(item =>
            <PomModalAction key={item.action} isSubmitting={isSubmitting} onAction={this.handleAction} onConfirm={this.handleConfirm} {...item}/>
        );
        let submitButton = '';
        if (!this.props.hideSubmit) {
            submitButton = <PomModalAction action="submit" isSubmitting={isSubmitting} onAction={this.handleAction} displayName={this.props.submitButton} disabledReason={this.props.disableSubmitReason}/>;
        }

        if (this.state.confirmAction) {
            modalContent = this.state.confirmMessage;
            actions = [
                <PomModalAction isSubmitting={isSubmitting} onAction={this.handleAction} displayName="Yes" key="yes" action={this.state.confirmAction} showFeedback={this.state.showFeedback}/>,
                <PomModalAction isSubmitting={isSubmitting} onAction={this.props.onClose} displayName="No" key="no" color="secondary"/>
            ];
            submitButton = '';
        }

        if (this.state.feedback) {
            modalContent = this.state.feedback;
            actions = [];
            submitButton = <Button color="success" className="ml-1" onClick={this.props.onClose}>Ok</Button>;
        }

        return (
            <Modal isOpen={this.props.isOpen} size="lg" centered toggle={this.props.onClose}>
                <div className="modal-header">
                    <h5 className="modal-title">{this.props.title}</h5>
                    <button type="button" className="close" onClick={this.props.onClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {this.state.error &&
                    <div className="alert alert-danger alert-dismissible">
                        {this.state.error}
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                    }
                    {modalContent}
                </div>
                {
                    (actions.length > 0 || submitButton) &&
                    <div className="modal-footer justify-content-between">
                        <div className="left">
                            {actions}
                        </div>
                        <div className="right d-flex align-items-center">
                            {isSubmitting && <div className="mr-1" style={{width: '100px'}}><Loader color="danger"/></div>}
                            {submitButton}
                        </div>
                    </div>
                }
            </Modal>
        );
    }
}

PomModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onAction: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired,
    actions: PropTypes.array.isRequired,
    disableSubmitReason: PropTypes.string,
    title: PropTypes.string.isRequired,
    submitButton: PropTypes.string.isRequired,
    hideSubmit: PropTypes.bool,
};

PomModal.defaultProps = {
    actions: [],
    submitButton: 'Save',
};

export default PomModal;