import React from "react";
import Fetch from "../Elements/Fetch";
import {fetchTaskLog} from "../services";
import TaskLog from "./TaskLog";

const TaskLogRoute = ({match}) => {
    const taskId = match.params.taskId
    return(
        <Fetch fetchFunction={fetchTaskLog} fetchFunctionParams={taskId}
               refreshKey={`logs:${taskId}`}>
            {logs => <TaskLog logs={logs} taskId={taskId}/>}
        </Fetch>
        )
}

export default TaskLogRoute;