import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {fetchTasks, downloadTasksUrl, fetchTaskNamesSelectOptions} from '../services';

import AbstractItemsTableWithLoader from '../Tables/AbstractItemsTableWithLoader';
import {columNames} from "./TaskTableConstants";
import Loader from "../Elements/Loader";

const TasksTable = ({name, itemsPerPage, searchParams, hideFilters, hideFiltersIfEmpty}) => {
    const [nameSelectOptions, setNameSelectOptions] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        fetchTaskNamesSelectOptions()
            .then(namesData => {
                setNameSelectOptions(namesData);
                setIsLoaded(true);
            })
            .catch(error => {
                setNameSelectOptions([]);
            });
    }, []);

    if (!isLoaded) {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="d-flex flex-column justify-content-center">
                            <Loader/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const fetchFunction = (searchParams) => {
        return fetchTasks(searchParams).then(data => ({
            ...data,
            items: data.items.map(item => ({
                ...item,
                taskId: <Link to={"/tasks/" + item.taskId}>{item.taskId}</Link>,
            })),
        }));
    };

    const paramSelectOperators = ['batchId', 'operator', 'originalDocumentId', 'script', 'transactionId', 'webhookUrl', 'workflow'];

    const downloadUrl = downloadTasksUrl();
    const namedSearchParameters = {
        taskId: {
            type: 'text',
            name: 'Task Id',
            operators: ['eq', 'contains'],
        },

        params: {
            type: 'text',
            name: 'Task Param',
            operators: paramSelectOperators
        },

        taskName: {
            type: 'select',
            name: 'Task Name',
            options: nameSelectOptions,
            operators: ['eq', 'contains'],
        },
        status: {
            type: 'select',
            name: 'Status',
            options: ['OK', 'UNSTABLE', 'FAILURE'],
            operators: ['eq'],
        },
        start: {
            type: 'datetime-local',
            name: 'Start',
            operators: ['eq', 'lt', 'gt', 'between'],
        },
        complete: {
            type: 'select',
            name: 'Complete',
            options: ['true', 'false'],
            operators: ['eq'],
        },
        lastUpdate: {
            type: 'date',
            name: 'Last Update',
            operators: ['eq', 'lt', 'gt', 'between'],
        },

    };

    return <AbstractItemsTableWithLoader
        name={name}
        itemsPerPage={itemsPerPage}
        tableColumns={columNames}
        namedSearchParameters={namedSearchParameters}
        fetchFunction={fetchFunction}
        searchParams={searchParams}
        hideFilters={hideFilters}
        hideFiltersIfEmpty={hideFiltersIfEmpty}
        downloadUrl={downloadUrl}
        downloadUrlMax={5000}
        minWidth={1400}
    />;
};

export default TasksTable;