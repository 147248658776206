import React from 'react';
import Fetch from "./Fetch";
import Select from 'react-select';
import Loader from "./Loader";
import FetchFromContext from "../Elements/FetchFromContext";

const FormSelect = ({optionsFetch, optionsFetchFromStateProperty, ...props}) => {
    if (optionsFetch) {
        return (
            <Fetch fetchFunction={optionsFetch} style={{width: 150}}>
                {options => (
                    <FormSelectInner {...props} options={options}/>
                )}
            </Fetch>
        );
    }
    if (optionsFetchFromStateProperty) {
        return (
            <FetchFromContext property={optionsFetchFromStateProperty}>
                {options => (
                    <FormSelectInner {...props} options={options}/>
                )}
            </FetchFromContext>
        );
    }
    return (
        <FormSelectInner {...props}/>
    );
};

const FormSelectInner = ({options, value, onChange, searchKey, tabIndex, multiple}) => {
    if (options === undefined) {
        return (
            <Loader/>
        );
    }

    return (
        <Select
            tabIndex={tabIndex}
            options={Array.isArray(options) ?
                options.map(val => ({value: val, label: val}))
                :
                Object.keys(options).map(key => ({value: key, label: options[key]}))}
            onChange={value => {
                if (value === null) {
                    onChange(null, searchKey);
                    return;
                }

                const values = value.split(',');

                if (values.length > 1) {
                    onChange(values, searchKey);
                } else {
                    onChange(values[0], searchKey);
                }
            }}
            simpleValue
            value={value}
            placeholder=""
            multi={multiple}
        />
    );
};

export default FormSelect;