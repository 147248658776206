import React from 'react';
import PropTypes from 'prop-types';
import BillingSenderAddForm from "./BillingSenderAddForm";

const BillingSenderAddRoute = ({match, history, onSubmit}) => {

    const handleSubmit = onSubmit || (() => history.push('/billing/senders'));

    return (
        <div style={{maxWidth: 800}}>
            <BillingSenderAddForm
                onSubmit={handleSubmit}
            />
        </div>
    );
};

BillingSenderAddRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default BillingSenderAddRoute;