import React, {Fragment} from "react";
import OverlayRoute from "../Elements/Overlay/OverlayRoute";
import PropTypes from "prop-types";
import TransactionEditRoute from "./TransactionEdit/TransactionEditRoute";

const TransactionRoutes = ({closeOverlay}) => {
    return (
        <Fragment>
            <OverlayRoute exact path="/transactions/:id/edit" closeOverlay={closeOverlay} render={({match}) => (
                <TransactionEditRoute match={match} onSubmit={closeOverlay}/>
            )}/>
        </Fragment>
    );
};

TransactionRoutes.propTypes = {
    closeOverlay: PropTypes.func.isRequired,
};

export default TransactionRoutes;