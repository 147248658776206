import React from 'react';
import PropTypes from 'prop-types';
import FormGroupRow from "./FormGroupRow";
import FormTextArea from "./FormTextArea";

const FormGroupRowTextArea = ({label, required, value, onChange, disabled, description, placeholder, errorMessage, cssClasses, rows}) => (
    <FormGroupRow label={label} required={required} errorMessage={errorMessage} description={description} cssClasses={cssClasses}>
        <FormTextArea
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={onChange}
            rows={rows}
        />
    </FormGroupRow>
);

FormGroupRowTextArea.propTypes = {
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    description: PropTypes.any,
    errorMessage: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    cssClasses: PropTypes.array,
    rows: PropTypes.number,
};

export default FormGroupRowTextArea;