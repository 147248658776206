import React from 'react';
import PropTypes from 'prop-types';
import FormGroupRow from "./FormGroupRow";

const FormGroupRowDate = ({label, required, value, onChange, description = 'Format: YYYY-MM-DD'}) => (
    <FormGroupRow label={label} required={required} description={description}>
        <input type="date" className="form-control" value={value} onChange={event => onChange(event.target.value)}/>
    </FormGroupRow>
);

FormGroupRowDate.propTypes = {
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    description: PropTypes.any,
};

export default FormGroupRowDate;