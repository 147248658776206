import React from 'react';
import PropTypes from 'prop-types';
import {fetchBillingSender} from "../../../services";
import Fetch from "../../../Elements/Fetch";
import BillingSenderEditForm from "./BillingSenderEditForm";

const BillingSenderEditRoute = ({match, history, onSubmit}) => (
    <Fetch fetchFunction={fetchBillingSender} fetchFunctionParams={match.params.id}>
        {billingSender => {
            const handleSubmit = onSubmit || (() => history.push('/billing/senders/' + billingSender.id));

            return (
                <div style={{maxWidth: 800}}>
                    <BillingSenderEditForm
                        billingSender={billingSender}
                        onSubmit={handleSubmit}
                    />
                </div>
            );
        }}
    </Fetch>
);

BillingSenderEditRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default BillingSenderEditRoute;