import React from 'react';
import CreateSenderStepCounter from '../wizard/CreateSenderStepCounter';
import CreateSenderStepButtons from '../wizard/CreateSenderStepButtons';
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import {ArrowDropDownOutlined} from "@material-ui/icons";
import {PartnerSetting} from "./partnersettings/partnerSettings";
import {covertToLabel} from "../CreateSenderHelper";
import FormGroupRowText from "../../Elements/FormGroupRowText";

const CreateSenderStepConfirm = (props: any) => {
    const accountSettings = props.accountSettings;

    function isCurrentStep(): boolean {
        return props.isActive;
    }

    function isParentOverride(ps: PartnerSetting) {
        return (props.data.parentSettings)
            .map((l: any) => l.category + l.name + l.value)
            .find((l: any) => l === (ps.category + ps.name + ps.value)) !== undefined;
    }

    function renderSummary(name: string, renderContent: (content: any, dataFor?: string) => any, content: any, dataFor?: string) {
        return (
            <Accordion key={name} defaultExpanded={false}>
                <AccordionSummary expandIcon={<ArrowDropDownOutlined fontSize="large"/>}>
                    <h5>{name}</h5>
                </AccordionSummary>
                <AccordionDetails>
                    {renderContent(content, dataFor)}
                </AccordionDetails>
            </Accordion>

        )
    }

    function renderCredUrlExpiryField() {
        return (
            <div className='my-4 row'>
                <div className='col-10'>
                    <FormGroupRowText
                        required={true}
                        label="Credentials URL expiry"
                        type="number"
                        value={props.data.credUrlExpiryDays}
                        onChange={value => updateBody(value)}
                    />
                </div>
                <span>days</span>
            </div>
        );
    }

    const updateBody = (value: any) => {
        props.updateBody({
            credUrlExpiryDays: value
        });
    };

    function getTableHead(dataFor?: string) {
        let head = dataFor === 'merchantProviders' ?
            <thead>
            <tr>
                <th>Type</th>
                <th>Attributes</th>
            </tr>
            </thead>
            :
            <thead>
            <tr>
                <th className='w-25'>Name</th>
                <th className='w-75'>Value</th>
            </tr>
            </thead>

        return head;
    }

    function hasNoData(data: any) {
       return Array.isArray(data) ? data.length === 0 :
                    (!Object.values(data).includes(true) && Object.values(data).includes(''));
    }

    const renderDataTable = (data: any, dataFor?: string) => {

        return (
            <table className="table">
                {getTableHead(dataFor)}
                <tbody>
                {hasNoData(data) &&
                    <tr>
                        <td colSpan={4} className="align-middle text-center">No data found</td>
                    </tr>
                }
                {!dataFor &&
                    Object.keys(data).map((key: any, rowIndex: number) => {
                        return (
                            data[key] && (
                                <tr key={rowIndex}>
                                    <td className="align-middle">{covertToLabel(key)}</td>
                                    <td className='align-middle'>
                                        {data[key]?.toString()}
                                    </td>
                                </tr>
                            )
                        );
                    })}
                {dataFor?.match(/partnerSettings|parentSettings|expectedResponse/) &&
                    data?.map((ps: any, rowIndex: number) => {
                        return (
                            <tr key={rowIndex}
                                className={`align-middle ${(dataFor?.match(/partnerSettings/) && isParentOverride(ps)) ? 'table-warning' : ''}`}>
                                <td className="align-middle">{ps.secondaryName || ps.label || covertToLabel(ps.name)}</td>
                                <td className='align-middle'>
                                    {ps.value.toString()}
                                    {(dataFor?.match(/partnerSettings/) && isParentOverride(ps)) &&
                                        <p className='mt-2'>{`This setting exists in the parent, and will be not added to the partner.`}</p>
                                    }
                                </td>
                            </tr>
                        );
                    })}
                {dataFor === 'merchantProviders' &&
                    data?.map((mp: any, rowIndex: number) => {
                        return (
                            <tr key={rowIndex}>
                                <td className="align-middle">{covertToLabel(mp.type)}</td>
                                <td className="align-middle">
                                    {mp.attributes.map((a: any, attIndex: number) => {
                                        return (
                                            <div key={attIndex+'attribute'}>
                                                {covertToLabel(a.key)}: {a.value}
                                            </div>
                                        );
                                    })
                                    }
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        )
    }

    function hasMerchantProviders() {
        return props.data.merchant.providers[0]?.attributes[0].value !== '';
    }

    function getOnlyRequiredSettings() {
        return props.data.partnerSettings.filter((ps: any) => (ps.value || ps.value.length > 0))
    }

    const renderBody = () => {
        if (!isCurrentStep()) {
            return (<></>);
        }

        return (
            <div className="card-body">
                <h4>Summary</h4>
                {renderSummary('Account settings', renderDataTable, props.accountSettings)}
                {renderSummary('Parent', renderDataTable, props.data.parent)}
                {renderSummary('Sender', renderDataTable, props.data.sender)}
                {accountSettings.newParent && renderSummary('New parent settings', renderDataTable, props.data.parentSettings, 'parentSettings')}
                {renderSummary('Partner settings', renderDataTable, getOnlyRequiredSettings(), 'partnerSettings')}
                {hasMerchantProviders() && renderSummary('Merchant providers', renderDataTable, props.data.merchant.providers, 'merchantProviders')}
                {(accountSettings.needsChildCredentials || accountSettings.newParent) && renderSummary('User roles', renderDataTable, props.data.user.roles)}
                {renderSummary('Expected response', renderDataTable, props.data.expectedResponse, 'expectedResponse')}
                {renderCredUrlExpiryField()}
                <CreateSenderStepButtons {...props} isValid={props.data.credUrlExpiryDays > 0}></CreateSenderStepButtons>
            </div>
        );
    };

    return (
        <div>
            <div className="card">
                <div className="card-header text-center">
                    <CreateSenderStepCounter {...props}></CreateSenderStepCounter>
                </div>
                {renderBody()}
            </div>

        </div>
    );

};
export default CreateSenderStepConfirm;