import React, {Component} from 'react';
import './Expandable.css';
import classNames from "classnames";

class Expandable extends Component {
    constructor(props) {
        super(props);
        this.changeExpandable = this.changeExpandable.bind(this);
        this.state = {
            expandable: true,
        };
    }

    changeExpandable() {
        this.setState({
            expandable: false,
        });
    }

    render() {
        let clickHtml = '';
        if (this.state.expandable) {
            clickHtml = (
                <div onClick={this.changeExpandable} className="expandable-click">
                    Click here to expand
                </div>
            );
        }

        return (
            <div style={{maxHeight: this.state.expandable ? (this.props.expandableHeight || 400) : 'none'}} className={classNames('mb-3', {expandable: this.state.expandable})}>
                {this.props.children}
                {clickHtml}
            </div>
        );
    }
}

export default Expandable;
