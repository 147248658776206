import React, {Fragment} from 'react';
import {Route, Switch} from "react-router-dom";
import BillingEventsSubmenu from "./BillingEventsSubmenu";
import BillingEventsBillableRoute from "./BillingEventsBillable/BillingEventsBillableRoute";

const BillingEventsRoutes = () => {
    return (
        <Fragment>

            <Route path="/billing/events" component={BillingEventsSubmenu}/>

            <Switch>
                <Route exact path="/billing/events/billable" component={BillingEventsBillableRoute}/>
            </Switch>
        </Fragment>
    );
};

export default BillingEventsRoutes;
