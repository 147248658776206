import React from 'react';

const CreateSenderStepCounter = (props: any) => {
    const {currentStep, totalSteps} = props;

    return (
        <div>
            <div>{currentStep} / {totalSteps}</div>
        </div>
    );
};
export default CreateSenderStepCounter;