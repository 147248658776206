import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import OverlayRoute from "../../Elements/Overlay/OverlayRoute";
import BillingCreditsAddRoute from "./BillingCreditsAdd/BillingCreditsAddRoute";
import BillingCreditsEditPurchaseRoute from "./BillingCreditsPurchaseEdit/BillingCreditsEditPurchaseRoute";

const BillingCreditsOverlayRoutes = ({closeOverlay}) => {
    return (
        <Fragment>
            <OverlayRoute exact path="/billing/credits/add/:id" closeOverlay={closeOverlay} render={({match}) => (
                <BillingCreditsAddRoute match={match} onSubmit={closeOverlay}/>
            )}/>
            <OverlayRoute exact path="/billing/credits/:id/edit" closeOverlay={closeOverlay} render={({match}) => (
                <BillingCreditsEditPurchaseRoute match={match} onSubmit={closeOverlay}/>
            )}/>
        </Fragment>
    );
};

BillingCreditsOverlayRoutes.propTypes = {
    closeOverlay: PropTypes.func.isRequired,
};

export default BillingCreditsOverlayRoutes;