import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Fetch, {RefreshContext} from '../../Elements/Fetch';
import {editGlobalSettings, fetchGlobalSettings} from '../../services';
import {GlobalSetting, GlobalSettingUpdate} from '../model/GlobalSetting.interface';
import SettingsForm from './SettingsForm';

// @ts-ignore
const GlobalSettingsUpdateRoute = ({history}) => {
    const {triggerRefresh} = useContext(RefreshContext);

    const handleSubmit = (settings: GlobalSettingUpdate[]) => {
        return editGlobalSettings(settings).then(() => {
            history.push('/global-settings')
        })
    };

    return (
        <div style={{maxWidth: 800}}>
            <Fetch fetchFunction={fetchGlobalSettings}>
                {(settings: GlobalSetting[]) => <SettingsForm onSubmit={handleSubmit} triggerRefresh={triggerRefresh} settings={settings}/>}
            </Fetch>
        </div>
    );
};

GlobalSettingsUpdateRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default GlobalSettingsUpdateRoute;