
const buckarooAttributes =  [
    {key: 'websiteKey', value: ''},
    {key: 'secret', value: ''},
];

export const appleAttributes =  [
    {key: 'applePayMerchantId', value: ''},
    {key: 'applePaySupportedNetworks', value: ''},
];

export const allPaymentFlows = [
    {
        label: 'Bancontact POD', type: 'buckaroo', isSelected: false,
        method: 'bancontact', option: 'pay-once-bc',
        attributes: buckarooAttributes,
    },
    {
        label: 'Bancontact', type: 'buckaroo', isSelected: false,
        method: 'bancontact', option: 'pay-directly',
        attributes: buckarooAttributes,
    },
    {
        label: 'Payconiq', type: 'buckaroo', isSelected: false,
        method: 'payconiq', option: 'pay-directly',
        attributes: buckarooAttributes,
    },
    {
        label: 'iDEAL', type: 'buckaroo', isSelected: false,
        method: 'ideal', option: 'pay-directly',
        attributes: buckarooAttributes,
    },
    {
        label: 'Maestro', type: 'buckaroo', isSelected: false,
        method: 'maestro', option: 'pay-directly',
        attributes: buckarooAttributes,
    },
    {
        label: 'Visa', type: 'buckaroo', isSelected: false,
        method: 'visa', option: 'pay-directly',
        attributes: buckarooAttributes,
    },
    {
        label: 'Mastercard', type: 'buckaroo', isSelected: false,
        method: 'mastercard', option: 'pay-directly',
        attributes: buckarooAttributes,
    },
    {
        label: 'Carte Bancaire', type: 'buckaroo', isSelected: false,
        method: 'cartebancaire', option: 'pay-directly',
        attributes: buckarooAttributes,
    },
    {
        label: 'Carte Bleue', type: 'buckaroo', isSelected: false,
        method: 'cartebleuevisa', option: 'pay-directly',
        attributes: buckarooAttributes,
    },
    {
        label: 'Applepay', type: 'buckaroo', isSelected: false,
        method: 'applepay', option: 'pay-directly',
        attributes: buckarooAttributes,
    },
];
