import React, {Fragment} from 'react';
import SpeosArchiveTable from './SpeosArchiveTable';

const SpeosArchiveRoute = () => {
    return (
        <Fragment>
            <h3 className="mb-5 mt-4">Archive batches</h3>
            <SpeosArchiveTable></SpeosArchiveTable>
        </Fragment>
    );
};

export default SpeosArchiveRoute;