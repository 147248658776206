const findRequiredSettings = (name: string, isEditable?: boolean) => {
    switch (name) {

        case 'usePaypage':
            return [
                {category: 'paylink', name: 'usePaypage', type: 'boolean', isEditable: isEditable, isRequired: false, value: true},
                {
                    category: 'paylink',
                    name: 'allow.empty.paymentMethod',
                    type: 'boolean',
                    isEditable: isEditable,
                    value: true
                },
                {category: 'paylink', name: 'paypage.logo.url', type: 'string', isEditable: isEditable, isRequired: false, value: ''},
                {category: 'paylink', name: 'allow.empty.email', type: 'boolean', isEditable: isEditable, isRequired: false, value: true},
            ]

        case  'shortlink.channels':

            return [
                {
                    category: 'paylink',
                    name: 'shortlink.channels',
                    type: 'multi-list',
                    isRequired: false,
                    isEditable: isEditable, value: [],
                    options: []
                }
            ]

        case 'paypage.optin.channels':
            return [
                {
                    category: 'paylink',
                    name: 'paypage.optin.channels',
                    type: 'multi-list',
                    isRequired: false,
                    isEditable: isEditable, value: [],
                    options: []
                }
            ]

        case 'maildelivery':
            return [
                {category: 'sender.delivery', name: 'maildelivery', type: 'boolean', isEditable: isEditable, isRequired: false, value: true},
                {category: 'sender.delivery', name: 'maildelivery.logo.url', type: 'string', isEditable: isEditable, isRequired: false, value: ''}
            ]

        case 'successCallbackUrl':
            return [
                {category: 'paylink', name: 'successCallbackUrl', type: 'string', isEditable: isEditable, value: ''},
                {
                    category: 'paylink',
                    name: 'successCallbackUrl.includeIban',
                    type: 'boolean',
                    isEditable: isEditable,
                    isRequired: false,
                    value: true
                }
            ]

        case 'redirectUrl':
            return [
                {category: 'sender.customview', name: 'redirectUrl', type: 'string', isEditable: isEditable, isRequired: false, value: ''},
            ]

        default:
            return [];
    }
}
export const getRequiredPartnerSettings = (templateSettings: any[]) => {
    const allTemplateSettings = templateSettings.filter(ts => ts.category !== 'account');
    let partnerSettings: any[] = [];
    allTemplateSettings.forEach(ts => {
        partnerSettings = partnerSettings.concat(findRequiredSettings(ts.name, ts.isEditable))
    });

    let allRequiredSettings = allTemplateSettings.concat(partnerSettings)
        .filter((setting, index, self) => self.findIndex(s => s.name === setting.name) === index);

    return allRequiredSettings.sort((a, b) => {
        if (a.type > b.type) {
            return 1;
        } else if (a.type < b.type) {
            return -1;
        } else {
            return a.name.localeCompare(b.name);
        }
    });
}

function isNecessaryForChild(settingName: string){
    const notNecessaryForChildSettingNames = [
        'usePaypage', 'allow.empty.paymentMethod', 'allow.empty.email',
        'successCallbackUrl.includeIban', 'short.url.service'
    ];
    return !notNecessaryForChildSettingNames.includes(settingName);
}

export const mergedWithParentSettings = (parentSettings: any[], childSettings: any[]) => {
    let mergedSettings: any[] = [];
    childSettings.forEach((childSetting: any) => {
        const parentSetting = parentSettings
              .find(parentSetting => parentSetting.category === childSetting.category && parentSetting.name === childSetting.name);

        if (parentSetting && isNecessaryForChild(childSetting.name)) {
            let value = childSetting.type==='multi-list'? convertStringToArray(parentSetting.value) : parentSetting.value;
            mergedSettings.push( {...childSetting, value: value});
        } else if(!parentSetting){
            mergedSettings.push(childSetting);
        }
    });

    return mergedSettings;
}

export function convertStringToArray(value: string[] | string): string[] {
    if(Array.isArray(value)) {
        return value;
    }
    return value.split(',');
}
