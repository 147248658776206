import React from 'react';
import {Route, Switch} from "react-router-dom";
import BillingReportsRoute from "./BillingReports/BillingReportsRoute";
import BillingReportRoute from "./BillingReport/BillingReportRoute";

const BillingReportsRoutes = () => {
    return (
        <Switch>
            <Route exact path="/billing/reports" component={BillingReportsRoute}/>
            <Route exact path="/billing/reports/:id" component={BillingReportRoute}/>
        </Switch>
    );
};

export default BillingReportsRoutes;