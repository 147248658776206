export const sectorTypes =
    [
        {value: 'ENERGY', label: 'ENERGY'},
        {value: 'WATER', label: 'WATER'},
        {value: 'TELECOM', label: 'TELECOM'},
        {value: 'HEALTH_CARE', label: 'HEALTH_CARE'},
        {value: 'EDUCATION', label: 'EDUCATION'},
        {value: 'PUBLIC_SECTOR', label: 'PUBLIC_SECTOR'},
        {value: 'SOCIAL_SECURITY', label: 'SOCIAL_SECURITY'},
        {value: 'INSURANCE', label: 'INSURANCE'},
        {value: 'ASSOCIATION', label: 'ASSOCIATION'},
        {value: 'NGO', label: 'NGO'},
        {value: 'ACCOUNTANCY', label: 'ACCOUNTANCY'},
        {value: 'SPORTS', label: 'SPORTS'},
        {value: 'OTHER', label: 'OTHER'}
    ]
