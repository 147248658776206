import React, {Component} from 'react';
import {Nav, NavItem} from 'reactstrap';
import {NavLink} from "react-router-dom";

class BillingEventsSubmenu extends Component {

    constructor(props){
        super(props);

        //This is necessary because parent nav should direct to /billing/events
        //If parent points to /billing/events/billable, the parent will not be
        //highlighted when navigating to /billing/events/non-billable

        this._checkRedirect = this._checkRedirect.bind(this);
    }

    componentDidMount(){
        this._checkRedirect();
    }

    componentDidUpdate(){
        this._checkRedirect();
    }

    _checkRedirect(){
        if (this.props.match.isExact) {
            this.props.history.replace('/billing/events/billable');
        }
    }

    render() {
        return (
            <Nav pills horizontal="end" className="mt-2">
                <NavItem><NavLink className="nav-link" to="/billing/events/billable">Billable</NavLink></NavItem>
            </Nav>
        );
    }
}

export default BillingEventsSubmenu;
