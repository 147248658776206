import React from 'react';
import PropTypes from 'prop-types';
import BillingRuleAddForm from "./BillingRuleAddForm";

const BillingRuleAddRoute = ({match, history, onSubmit}) => {

    const handleSubmit = onSubmit || (() => history.push('/billing/rules'));

    return (
        <div style={{maxWidth: 800}}>
            <BillingRuleAddForm
                onSubmit={handleSubmit}
            />
        </div>
    );
};

BillingRuleAddRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default BillingRuleAddRoute;