import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom';

const SpeosRoute = () => {
    return (
        <Fragment>
            <h1 className="mb-3">Speos</h1>
            <ul className="nav nav-pills">
                <li className="nav-item">
                    <NavLink className="nav-link" to="/speos/archive">Archive</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/speos/reprint">Reprint</NavLink>
                </li>
            </ul>
        </Fragment>
    );
};

export default SpeosRoute;