import React, {Component, Fragment} from 'react';

import {Input, Button} from 'reactstrap';

import FormText from './FormText';
import FormSelect from './FormSelect';
import FormDate from './FormDate';

class FormElementWrapper extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showOperators: false,
            operatorMapping: {
                eq: 'Equals',
                gt: 'Greater than',
                lt: 'Less than',
                contains: 'Contains',
                notcontains: 'Not contains',
                between: 'Between', //currently only supported by FormDate
            }
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleChangeOp = this.handleChangeOp.bind(this);
    }

    componentDidMount() {
        this.prepareOperatorMap();
    }

    prepareOperatorMap() {
        if (this.props.searchKey === 'params') {
            let operatorMapping = this.state.operatorMapping;
            let operators = this.props.operators;
            operators.forEach(operator => {
                if (!operatorMapping[operator]) {
                    operatorMapping[operator] = operator
                        .split(/(?=[A-Z])/)
                        .join(' ')
                        .replace(/^\w/, (c) => c.toUpperCase());
                }
            })
            this.setState({
                operatorMapping: operatorMapping,
            });
        }
    }

    handleClick(e) {
        e.preventDefault();
        this.setState({
            showOperators: true,
        });
    }

    handleChangeOp(e) {
        //handle special 'between' case
        this.props.onChange(null, this.props.searchKey + '_to');
        if (e.target.value === 'between') {
            this.props.onChange('', this.props.searchKey + '_to');
        }

        this.props.onChange(e.target.value, this.props.searchKey + '_op');

        this.setState({
            showOperators: false,
        });
    }

    render() {
        const searchKey = this.props.searchKey;
        const op = this.props.search[searchKey + '_op'];

        let operators;
        if (this.state.showOperators) {
            const operatorOptions = this.props.operators
                .filter(operator => this.state.operatorMapping[operator])
                .map(operator => <option value={operator}
                                         key={operator}>{this.state.operatorMapping[operator]}</option>);

            operators = (
                <div className="mb-1">
                    <div style={{fontSize: '64%'}}>{this.props.name}</div>
                    <Input type="select" bsSize="sm" style={{width: '100%'}} value={op} onChange={this.handleChangeOp}>
                        {operatorOptions}
                    </Input>
                </div>
            );
        } else {
            operators = (
                <div className="text-right d-flex justify-content-between" style={{fontSize: '64%'}}>
                    <div>{this.props.name}</div>
                    <Button color="link" onClick={this.handleClick} style={{
                        fontSize: 'inherit',
                        padding: 0,
                        color: '#2baf2b'
                    }}>{this.state.operatorMapping[op]}</Button>
                </div>
            );
        }

        let form;
        switch (this.props.type) {
            case 'select':
                form =
                    <FormSelect {...this.props} value={this.props.search[searchKey]}/>;
                break;
            case 'date':
            case 'datetime-local':
                if (op === 'between') {
                    form = (
                        <Fragment>
                            <FormDate
                                tabIndex={this.props.tabIndex}
                                name={this.props.name + ' from'}
                                searchKey={searchKey}
                                value={this.props.search[searchKey]}
                                onChange={this.props.onChange}
                                type={this.props.type}
                            />
                            <FormDate
                                tabIndex={this.props.tabIndex}
                                name={this.props.name + ' to'}
                                searchKey={searchKey + '_to'}
                                value={this.props.search[searchKey + '_to']}
                                onChange={this.props.onChange}
                                type={this.props.type}
                            />
                        </Fragment>
                    );
                } else {
                    form = (
                        <FormDate
                            tabIndex={this.props.tabIndex}
                            name={this.props.name}
                            searchKey={searchKey}
                            value={this.props.search[searchKey]}
                            onChange={this.props.onChange}
                            type={this.props.type}
                        />
                    );
                }

                break;
            default:
                form = (
                    <FormText
                        small
                        tabIndex={this.props.tabIndex}
                        name={this.props.name}
                        value={this.props.search[searchKey]}
                        onChange={(value) => this.props.onChange(value, searchKey)}
                    />
                );
        }

        return (
            <div className="pom-form-element-inner">
                {operators}
                {form}
            </div>
        );
    }
}

export default FormElementWrapper;