import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SimplePager = ({
                         currentPage = 0,
                         hasMore,
                         goPreviousPage,
                         goNextPage,
                     }) => {
    return (
        <ul className="pagination pagination-sm justify-content-center">
            <SimplePagerLink
                disabled={currentPage === 0}
                onClick={goPreviousPage}
                children="Previous"
            />

            {currentPage > 0 && (
                <>
                    {currentPage > 1 && (
                        <SimplePagerLink
                            children="..."
                        />
                    )}
                    <SimplePagerLink
                        onClick={goPreviousPage}
                        children={currentPage}
                    />
                </>
            )}

            <SimplePagerLink
                children={currentPage + 1}
                active
            />

            {hasMore && (
                <>
                    <SimplePagerLink
                        onClick={goNextPage}
                        children={currentPage + 2}
                    />
                    <SimplePagerLink
                        children="..."
                    />
                </>
            )}

            <SimplePagerLink
                disabled={!hasMore}
                onClick={goNextPage}
                children="Next"
            />
        </ul>
    );
};

const SimplePagerLink = ({disabled, onClick, children, active}) => {
    if (disabled) {
        return (
            <li className={classNames("page-item disabled", {active: active})}>
                <button className="page-link">{children}</button>
            </li>
        );
    } else {
        return (
            <li className={classNames("page-item", {active: active})}>
                <button className="page-link" onClick={onClick}>{children}</button>
            </li>
        );
    }
};

SimplePager.propTypes = {
    currentPage: PropTypes.number,
    hasMore: PropTypes.bool.isRequired,
    goPreviousPage: PropTypes.func.isRequired,
    goNextPage: PropTypes.func.isRequired,
};

export default SimplePager;
