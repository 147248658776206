import React from 'react';
import {Route, Switch} from "react-router-dom";
import BillingRuleRoute from "./BillingRules/BillingRulesRoute";
import BillingRuleAddRoute from "./BillingRuleAdd/BilingRuleAddRoute";
import BillingRuleEditRoute from "./BillingRuleEdit/BillingRuleEditRoute";

const BillingRuleRoutes = () => {
    return (
        <Switch>
            <Route exact path="/billing/rules" component={BillingRuleRoute}/>
            <Route exact path="/billing/rules/add" component={BillingRuleAddRoute}/>
            <Route exact path="/billing/rules/:id/edit" component={BillingRuleEditRoute}/>
        </Switch>
    );
};

export default BillingRuleRoutes;