import React from 'react';
import {fetchBillingCredits} from "../../../services";
import Fetch from "../../../Elements/Fetch";
import BillingCredits from "./BillingCredits";

const BillingCreditsRoute = ({match}) => (
    <Fetch fetchFunction={fetchBillingCredits} fetchFunctionParams={match.params.id} refreshKey={`billingCredits:${match.params.id}`}>
        {billingSender => (
            <BillingCredits billingSender={billingSender}/>
        )}
    </Fetch>
);

export default BillingCreditsRoute;