import React from 'react';
import Fetch from "../Elements/Fetch";

const GenericDetailRoute = ({id, fetchFunction, refreshKeyPrefix, DetailComponent}) => {
    return (
        <Fetch fetchFunction={fetchFunction} fetchFunctionParams={id} refreshKey={`${refreshKeyPrefix}:${id}`}>
            {object => <DetailComponent {...object}/>}
        </Fetch>
    );
};

export default GenericDetailRoute;
