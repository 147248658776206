import React, {useContext, useState} from 'react';
import FormWithActions from "../../../Elements/FormWithActions/FormWithActions";
import {sendBillingReport, validEmail} from "../../../services";
import {Col, Row} from "reactstrap";
import classNames from 'classnames';
import {RefreshContext} from "../../../Elements/Fetch";
import FormGroupRow from "../../../Elements/FormGroupRow";
import FormGroupRowTextList from "../../../Elements/FormGroupRowTextList";

const BillingReportMailForm = ({billingReport, onSubmit}) => {
    const [emails, setEmails] = useState(['']);
    const {triggerRefresh} = useContext(RefreshContext);

    const handleAction = (action) => {
            switch (action) {
                case 'submit':
                    return sendBillingReport(billingReport.id,{
                        id: billingReport.id,
                        emails: emails,
                    })
                        .then(data => {
                            triggerRefresh(`billingReports:${billingReport.id}`);
                            onSubmit(data);
                            return data;
                        });
                default:

            }
    };

    let disableSubmitReason = '';
    if (emails.some(email => email && !validEmail(email))) {
        disableSubmitReason = 'All emails need to be valid.';
    } else if (emails.length === 0 || emails.every(email => !email)) {
        disableSubmitReason = 'At least one email need to be present.';
    }

    return (
        <>
            <Row>
                <Col>
                    <h1 className="mb-5">
                        Send billing report
                    </h1>
                </Col>
            </Row>
            <FormWithActions onAction={handleAction} disableSubmitReason={disableSubmitReason} submitButton="Send">
                <FormGroupRow label="Billing sender contact email addresses" description="This is an informative list. Copy below to send reports to these email addresses.">
                    <div className="col-form-label pb-0">{billingReport.billingSender.emailAddressesForSendReport.join(', ') || '(empty)'}</div>
                </FormGroupRow>
                <FormGroupRow label="Billing reseller contact email addresses" description="This is an informative list. Copy below to send reports to these email addresses.">
                    <div className="col-form-label pb-0">{(billingReport.billingSender.billingReseller && billingReport.billingSender.billingReseller.contactEmailAddresses.join(', ')) || '(empty)'}</div>
                </FormGroupRow>
                <FormGroupRowTextList
                    label="Send report to"
                    value={emails}
                    onChange={setEmails}
                    itemClassNameFunc={email => classNames({'is-invalid': email && !validEmail(email)})}
                    itemProps={{autoFocus: true}}
                />
            </FormWithActions>
        </>
    );
};

BillingReportMailForm.propTypes = {};

export default BillingReportMailForm;