import React from 'react';
import {Role} from "../Auth/BackOfficeUserRole";
import Can from "../Auth/Can";
import LinkToOverlay from "../Elements/Overlay/LinkToOverlay";
import moment from "moment";
import Markdown from 'react-markdown';
import styles from './MaintenanceMode.module.css';

// @ts-ignore
import {PlusIcon} from "react-octicons";

export type MaintenanceModeType = {
    enabled?: string;
    from?: string;
    until?: string;
    markdownNL?: string;
    markdownFR?: string;
    markdownEN?: string;
};

type Props = {
    maintenanceMode: MaintenanceModeType;
};

const MaintenanceMode = ({
                             maintenanceMode,
                         }: Props) => {
    const {
        enabled,
        from,
        until,
        markdownNL,
        markdownFR,
        markdownEN,
    } = maintenanceMode;

    return (
        <div>
            <Can roles={[Role.WRITE]}>
                <LinkToOverlay
                    // @ts-ignore
                    overlay="/maintenance-mode/edit"
                >
                    <div className="mb-2 btn btn-success btn-sm"><PlusIcon fill="#fff"/>Edit maintenance mode</div>
                </LinkToOverlay>
            </Can>
            <div>
                <div>Enabled: {enabled ? 'Yes' : 'No'}</div>
                {from && <div>From: {moment(from).format('DD/MM/YYYY HH:mm')}</div>}
                {until && <div>Until: {moment(until).format('DD/MM/YYYY HH:mm')}</div>}
                {markdownNL && (
                    <div className={styles.markdown}>
                        <Markdown
                            components={{
                                a({node, children, ...rest}) {
                                    return <a target="_blank" {...rest}>{children}</a>;
                                },
                            }}
                            children={markdownNL}
                        />
                    </div>
                )}
                {markdownFR && (
                    <div className={styles.markdown}>
                        <Markdown
                            components={{
                                a({node, children, ...rest}) {
                                    return <a target="_blank" {...rest}>{children}</a>;
                                },
                            }}
                            children={markdownFR}
                        />
                    </div>
                )}
                {markdownEN && (
                    <div className={styles.markdown}>
                        <Markdown
                            components={{
                                a({node, children, ...rest}) {
                                    return <a target="_blank" {...rest}>{children}</a>;
                                },
                            }}
                            children={markdownEN}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default MaintenanceMode;