import React from 'react';
import Table from "./Table";

const TableJSON = ({json, ...props}) => {
    const flattenItem = (prev, key, item) => {
        if (item === null) {
            return {
                ...prev,
                [key]: item,
            };
        } else if (typeof item === 'boolean') {
            return {
                ...prev,
                [key]: item ? 'true' : 'false',
            };
        } else if (Array.isArray(item)) {
            return {
                ...prev,
                ...flattenArray(item, key),
            };
        } else if (typeof item === 'object') {
            return {
                ...prev,
                ...flattenObject(item, key + '.'),
            };
        } else {
            return {
                ...prev,
                [key]: item,
            };
        }
    };

    const flattenObject = (obj, keyPrefix = '') => {
        return Object.keys(obj).reduce((prev, currKey) => {
            const item = obj[currKey];
            const key = `${keyPrefix}${currKey}`;
            return flattenItem(prev, key, item);
        }, {});
    };

    const flattenArray = (arr, keyPrefix = '') => {
        return arr.reduce((prev, item, idx) => {
            const key = `${keyPrefix}[${idx}]`;
            return flattenItem(prev, key, item);
        }, {});
    };

    const obj = flattenObject(json);

    return (
        <Table
            {...props}
            columns={{
                key: 'Name',
                value: 'Value',
            }}
            items={Object.keys(obj).map(key => ({
                key: key,
                value: obj[key],
            }))}
        />
    );
};

export default TableJSON;