import React, {useContext, useEffect} from 'react';
import styles from './BillingReport.module.css';
import {Row, Col} from 'reactstrap';
import moment from 'moment';
import Field from "../../../Elements/Field";
import Table from "../../../Elements/Table/Table";
import {delay, downloadBillingReportUrl, fetchBillingReport} from "../../../services";
import {RefreshContext} from "../../../Elements/Fetch";
import LinkToOverlay from "../../../Elements/Overlay/LinkToOverlay";
import {PlusIcon} from "react-octicons";

const BillingReport = ({id, billingSender, period, status, mails, pllayUser}) => {
    const {triggerRefresh} = useContext(RefreshContext);

    useEffect(
        () => {

            const waitAndFetch = async () => {
                await delay(5000);
                const data = await fetchBillingReport(id);
                if (data.status === 'NEW') {
                    waitAndFetch(); //We don't need to await
                } else {
                    triggerRefresh(`billingReports:${id}`);
                }
            };

            if (status === 'NEW') {
                waitAndFetch(); //We don't need to await
            }
        },
        [status, id, triggerRefresh]
    );

    const mailTableHeaders = {
        dateCreatedString: {
            name: 'Date',
            rowStyle: {
                whiteSpace: 'nowrap',
            },
        },
        recipient: {
            name: 'Recipient',
        },
    };

    const mailTableItems = mails.map(mail => ({
        ...mail,
        key: mail.id,
        dateCreatedString: new Date(mail.dateCreated).toLocaleString(),
    }));

    return (
        <Row className={styles.row}>
            <Col xs="auto">
                <h2 className="mb-3">
                    {billingSender.name}
                </h2>
                <Field name='Period'>{moment(period).format('MMMM YYYY')}</Field>
                <Field name='Status'>{status}</Field>
                <Field name='Created by'>{pllayUser || '(system)'}</Field>
                {status === 'DONE' && (
                    <Table
                        columns={mailTableHeaders}
                        items={mailTableItems}
                        name={<>Mails: <LinkToOverlay overlay={`/billing/reports/${id}/mail`}><PlusIcon/></LinkToOverlay></>}
                        hideLabelColon
                        extraClassName='table-sm'
                        autoWidthColumns={['dateCreatedString', 'recipient']}
                    />
                )}
                <div className="mb-3">
                    <Row form>
                        {status === 'DONE' && (
                            <Col xs="auto">
                                <a className="btn btn-success" href={downloadBillingReportUrl(id, 'PDF', true)}>Download PDF</a>
                            </Col>
                        )}
                        {status !== 'NEW' && (
                            <Col xs="auto">
                                <a className="btn btn-success" href={downloadBillingReportUrl(id, 'XLSX', true)}>Download XLSX</a>
                            </Col>
                        )}
                    </Row>
                </div>
            </Col>
            <Col>
                {status === 'DONE' && <iframe title="iFrame" className={styles.iframe} src={downloadBillingReportUrl(id, 'PDF')}/>}
                {status === 'NEW' && (
                    <div className="d-flex justify-content-center mt-5">
                        <div className="spinner-border"/>
                    </div>
                )}
            </Col>
        </Row>
    );
};

export default BillingReport;