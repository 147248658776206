import React from 'react';
import PropTypes from 'prop-types';
import {fetchUser} from "../services";
import Fetch from "../Elements/Fetch";
import PartnerSettingsform from "../Senders/SenderPartnerSettingsEdit/PartnerSettingsForm";

const UserPartnerSettingsRoute = ({match, history, onSubmit}) => (
    <Fetch fetchFunction={fetchUser} fetchFunctionParams={match.params.id}>
        {user => {

            const handleSubmit = onSubmit || (() => history.push('/users/' + user.id));

            return (
                <div style={{maxWidth: 800}}>
                    <PartnerSettingsform
                        id={user.partnerId}
                        partnerSettings={user.partnerSettings}
                        refreshKey={`user:${user.id}`}
                        onSubmit={handleSubmit}
                    />
                </div>
            );
        }}
    </Fetch>
);

UserPartnerSettingsRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default UserPartnerSettingsRoute;
