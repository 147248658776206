import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import OverlayRoute from "../../Elements/Overlay/OverlayRoute";
import BillingReportAddRoute from "./BillingReportAdd/BillingReportAddRoute";
import BillingReportMailRoute from "./BillingReportMail/BillingReportMailRoute";

const BillingReportsOverlayRoutes = ({closeOverlay}) => {
    return (
        <Fragment>
            <OverlayRoute exact path="/billing/reports/add" closeOverlay={closeOverlay} render={() => <BillingReportAddRoute/>}/>
            <OverlayRoute exact path="/billing/reports/:id/mail" closeOverlay={closeOverlay} render={({match}) => (
                <BillingReportMailRoute match={match} onSubmit={closeOverlay}/>
            )}/>
        </Fragment>
    );
};

BillingReportsOverlayRoutes.propTypes = {
    closeOverlay: PropTypes.func.isRequired,
};

export default BillingReportsOverlayRoutes;