import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import OverlayRoute from "../Elements/Overlay/OverlayRoute";
import SenderEditRoute from "./SenderEdit/SenderEditRoute";
import SenderMerchantAddRoute from "./SenderMerchantAdd/SenderMerchantAddRoute";
import SenderMerchantEditRoute from "./SenderMerchantEdit/SenderMerchantEditRoute";
import SenderPartnerSettingsRoute from "./SenderPartnerSettingsEdit/SenderPartnerSettingsRoute";
import SenderUploadDocRefRoute from "./SenderUploadDocref/SenderUploadDocrefRoute";

const SenderRoutes = ({closeOverlay}) => {
    return (
        <Fragment>
            <OverlayRoute exact path="/senders/:id/edit" closeOverlay={closeOverlay} render={({match}) => (
                <SenderEditRoute match={match} onSubmit={closeOverlay}/>
            )}/>

            <OverlayRoute exact path="/senders/:id/merchant/add" closeOverlay={closeOverlay} render={({match}) => (
                <SenderMerchantAddRoute match={match} onSubmit={closeOverlay}/>
            )}/>
            <OverlayRoute exact path="/senders/:id/merchant/:merchantId/edit" closeOverlay={closeOverlay} render={({match}) => (
                <SenderMerchantEditRoute match={match} onSubmit={closeOverlay}/>
            )}/>
            <OverlayRoute exact path="/senders/:id/edit-partner-settings" closeOverlay={closeOverlay} render={({match}) => (
                <SenderPartnerSettingsRoute match={match} onSubmit={closeOverlay}/>
            )}/>

            <OverlayRoute exact path="/senders/:id/upload-docref" closeOverlay={closeOverlay} render={({match}) => (
                <SenderUploadDocRefRoute match={match} onSubmit={closeOverlay}/>
            )}/>
        </Fragment>
    );
};

SenderRoutes.propTypes = {
    closeOverlay: PropTypes.func.isRequired,
};

export default SenderRoutes;