import React from 'react';
import {Route} from "react-router-dom";
import GenericOverviewRoute from "./GenericOverviewRoute";
import GenericDetailRoute from "./GenericDetailRoute";

const GenericCrudRoutes = ({basePath, fetchFunctionDetail, Component, ...props}) => {
    return (
        <>
            <Route
                exact
                path={`/${basePath}`}
                render={() => (
                    <GenericOverviewRoute
                        {...props}
                        basePath={basePath}
                    />
                )}
            />
            {fetchFunctionDetail && (
                <Route
                    exact
                    path={`/${basePath}/:id`}
                    render={({match}) => (
                        <GenericDetailRoute
                            id={match.params.id}
                            fetchFunction={fetchFunctionDetail}
                            refreshKeyPrefix={basePath}
                            DetailComponent={Component}
                        />
                    )}
                />
            )}
        </>
    );
};

export default GenericCrudRoutes;
