import React from 'react';
import PropTypes from 'prop-types';
import FormText from "./FormText";
import FormGroupRow from "./FormGroupRow";
import {Row, Col, FormGroup} from "reactstrap";
import {PlusIcon, XIcon} from "react-octicons";

const FormGroupRowTextList = ({label, required, type = 'text', value, onChange, itemClassNameFunc, itemProps}) => {
    const handleChange = (itemValue, i) => {
        onChange([
            ...value.slice(0, i),
            itemValue,
            ...value.slice(i + 1, value.length),
        ]);
    };

    const handleAdd = () => {
        onChange([
            ...value,
            '',
        ]);
    };

    const handleRemove = (i) => {
        onChange([
            ...value.slice(0, i),
            ...value.slice(i + 1, value.length),
        ]);
    };

    return (
        <FormGroupRow label={label} required={required}>
            {value.map((item, i) => (
                <FormGroup key={i}>
                    <Row form>
                        <Col>
                            <FormText
                                type={type}
                                value={item}
                                onChange={value => handleChange(value, i)}
                                className={itemClassNameFunc && itemClassNameFunc(item)}
                                {...itemProps}
                            />
                        </Col>
                        <Col xs="auto" className="d-flex align-items-center">
                            <button type="button" className="btn btn-link btn-sm" onClick={() => handleRemove(i)}>
                                <XIcon/>
                            </button>
                        </Col>
                    </Row>
                </FormGroup>
            ))}
            <FormGroup>
                <button type="button" className="btn btn-link btn-sm" onClick={handleAdd}>
                    <PlusIcon/>
                </button>
            </FormGroup>
        </FormGroupRow>
    );
};

FormGroupRowTextList.propTypes = {
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    itemClassNameFunc: PropTypes.func,
    itemProps: PropTypes.object,
    description: PropTypes.any,
    placeholder: PropTypes.string,
    type: PropTypes.string,
};

export default FormGroupRowTextList;