import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import OverlayRoute from "../../Elements/Overlay/OverlayRoute";
import BillingResellerAddRoute from "./BillingResellerAdd/BillingResellerAddRoute";
import BillingResellerEditRoute from "./BillingResellerEdit/BillingResellerEditRoute";

const BillingResellerOverlayRoutes = ({closeOverlay}) => {
    return (
        <Fragment>
            <OverlayRoute exact path="/billing/resellers/add" closeOverlay={closeOverlay} render={({match}) => (
                <BillingResellerAddRoute match={match} onSubmit={closeOverlay}/>
            )}/>

            <OverlayRoute exact path="/billing/resellers/:id/edit" closeOverlay={closeOverlay} render={({match}) => (
                <BillingResellerEditRoute match={match} onSubmit={closeOverlay}/>
            )}/>
        </Fragment>
    );
};

BillingResellerOverlayRoutes.propTypes = {
    closeOverlay: PropTypes.func.isRequired,
};

export default BillingResellerOverlayRoutes;