import React, {Component} from 'react';
import {Button, Spinner} from 'reactstrap';

class LoadingButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
        this.handleClick = this.handleClick.bind(this);
    };

    async handleClick() {
        this.setState({
            loading: true,
        });

        await this.props.onClick();
        this.setState({
            loading: false,
        });
    }

    render() {
        return (
            <Button
                {...this.props}
                disabled={this.state.loading}
                onClick={this.handleClick}>
                {this.state.loading && (<Spinner size="sm"/>)}
                {!this.state.loading && this.props.text}
            </Button>
        );
    }
}

export default LoadingButton;