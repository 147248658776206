import React from 'react';
import useSubmittingButton from '../../hooks/useSubmittingButton';
import {Col} from 'reactstrap';
import {Link} from "react-router-dom";

const CreateTemplateStepButtons = (props: any) => {

    const {successMessage, errorMessage, loader, SubmittingButton} = useSubmittingButton();

    function goNext(): void {
        if (props.onNext) {
            props.onNext();
        }
        props.nextStep();
    }

    function goBack(): void {

        if (props.onBack) {
            props.onBack();
        }

        if (props.currentStep !== 1){
            props.previousStep();
        }

    }

    function isLastStep(): boolean {
        return props.currentStep === props.totalSteps;
    }

    function isFirstStep(): boolean {
        return props.currentStep === 1;
    }

    function renderBackToSenderTemplatesBtn() {
        return (
            <Link to="/sender-templates">
                <button className={"btn btn-success mr-2"}>Go back to templates</button>
            </Link>
        );
    }

    function renderPreviousButton(): JSX.Element {

        if(isFirstStep()) {
            return (
                <Link to="/sender-templates">
                    <button className={"btn btn-outline-success mr-2"}>Go back to templates</button>
                </Link>
            );
        }

        return (
            <button className={"btn btn-outline-success mr-2"} onClick={goBack}>
                Previous
            </button>
        );
    }

    function renderNextButton(): JSX.Element {
        if (isLastStep()) {
            return (
                <>
                    {renderBackToSenderTemplatesBtn()}
                    <SubmittingButton onClick={() => {
                        if (props.onNext) {
                            props.onNext();
                        }
                        return props.submit();
                    }} disabled={false}>Save template</SubmittingButton>
                    <Col xs="auto">{loader}</Col>
                    <div className="mt-2">{errorMessage}</div>
                    <div className="mt-2">{successMessage}</div>
                </>
            );
        }

        return (
            <>
                <button disabled={!props.isValid}
                        className={"btn btn-success"} onClick={goNext}>Next
                </button>
            </>
        );
    }

    return (
        <div className="mt-2">
            <div className="text-center border-top pt-3">
                {renderPreviousButton()}
                {renderNextButton()}
            </div>
        </div>
    );
};
export default CreateTemplateStepButtons;