import React, {useContext} from 'react';
import {downloadAuditTrails, fetchAuditTrails} from '../services';

import AbstractItemsTableWithLoader from '../Tables/AbstractItemsTableWithLoader';
import {AppContext} from "../AppContext";

const AuditingTable = ({name, itemsPerPage, searchParams, hideFiltersIfEmpty, hideFilters}) => {
    const {auditTrailSupportedTypes} = useContext(AppContext);

    const tableColumns = {
        type: {
            name: 'Type',
            sort: true
        },
        objectId: {
            name: 'Id',
            sort: true
        },
        objectVersion:{
            name: 'Version',
            sort: true
        },
        eventName: {
            name: 'Event',
            sort: true
        },
        propertyName: {
            name: 'Property',
            sort: true
        },
        oldValue: {
            name: 'Old value',
            sort: true
        },
        newValue: {
            name: 'New value',
            sort: true
        },
        actor: {
            name: 'Actor',
            sort: true
        },
        dateCreated: {
            name: 'Date',
            sort: true
        },

    };

    const namedSearchParameters = {
        type: {
            type: 'select',
            name: 'Type',
            options: auditTrailSupportedTypes,
            operators: ['eq'],
        },
        eventName: {
            type: 'select',
            name: 'Event',
            options: ['INSERT','UPDATE','DELETE'],
            operators: ['eq'],
        },
        "sender.id":{
            type: 'text',
            name: 'Sender Id',
            operators: ['eq'],
        },
        "sender.name":{
            type: 'text',
            name: 'Sender Name',
            operators: ['eq', 'contains'],
        },
        "sender.reference":{
            type: 'text',
            name: 'SCN',
            operators: ['eq', 'contains'],
        },
        dateCreated: {
            type: 'date',
            name: 'Date Created',
            operators: ['eq', 'lt', 'gt', 'between'],
        },

    };

    return <AbstractItemsTableWithLoader
        name={name}
        itemsPerPage={itemsPerPage}
        tableColumns={tableColumns}
        namedSearchParameters={namedSearchParameters}
        fetchFunction={fetchAuditTrails}
        autoWidthColumns={["newValue"]}
        searchParams={searchParams}
        hideFilters={hideFilters}
        hideFiltersIfEmpty={hideFiltersIfEmpty}
        downloadUrl={downloadAuditTrails()}
        downloadUrlMax={5000}
    />;
};


export default AuditingTable;