import React from 'react';
import GenericCrudRoutes from "../GenericCrud/GenericCrudRoutes";
import {fetchSenderOnboarding, fetchSenderOnboardings} from "../services";
import SenderOnboardingDetail from "./SenderOnboardingDetail";

const SenderOnboardingRoutes = () => {

    const tableColumns = {
        dateCreated: {
            name: 'Date',
            sort: true,
            style: {
                width: '185px',
            },
        },
        requestId: {
            name: 'Request Id',
            sort: true,
        },
        name: {
            name: 'Name',
            sort: true,
        },
        partnerName: {
            name: 'Partner Name',
            sort: true,
        },
        iban: {
            name: 'Iban',
            sort: true,
        },
        vatNumber: {
            name: 'Vat',
            sort: true,
        },
        email: {
            name: 'E-mail',
            sort: true,
        },
        buckarooFinalized: {
            name: 'Buckaroo finalized',
            sort: true,
            style: {
                width: '185px',
            },
        },
    };

    const namedSearchParameters = {
        requestId: {
            type: 'text',
            name: 'Request Id',
            operators: ['eq'],
        },
        name: {
            type: 'text',
            name: 'Name',
            operators: ['contains'],
        },
        partnerName: {
            type: 'text',
            name: 'Partner Name',
            operators: ['contains'],
        },
        iban: {
            type: 'text',
            name: 'Iban',
            operators: ['contains'],
        },
        vatNumber: {
            type: 'text',
            name: 'Vat',
            operators: ['contains'],
        },
        email: {
            type: 'text',
            name: 'Email',
            operators: ['contains'],
        },
        buckarooFinalized: {
            type: 'date',
            name: 'Buckaroo finalized',
            operators: ['eq', 'lt', 'gt', 'between'],
        },
    };

    return (
        <GenericCrudRoutes
            basePath="senderOnboarding"

            overviewTitle="Sender Onboarding"
            tableColumns={tableColumns}
            namedSearchParameters={namedSearchParameters}
            linkColumn="requestId"
            fetchFunction={fetchSenderOnboardings}

            autoWidthColumns={['iban', 'vatNumber', 'email']}

            fetchFunctionDetail={fetchSenderOnboarding}
            Component={SenderOnboardingDetail}
        />
    );
};

export default SenderOnboardingRoutes;
