import React, {useCallback, useContext, useState} from 'react';
import ModalContainer from "./ModalContainer";
import {RefreshContext} from "../Elements/Fetch";

export const ModalContext = React.createContext<ModalContextType>({
    showModal: () => null,
});

type Props = {
    children: React.ReactNode;
};

const ModalProvider = ({
                           children,
                       }: Props) => {
    const {triggerRefresh} = useContext(RefreshContext);
    const [visible, setVisible] = useState(false);
    const [modalContents, setModalContents] = useState<ModalContentsType>({type: 'empty'});

    const handleShowModal = useCallback((action: ModalContentsType) => {
        setModalContents(action);
        setVisible(true);
    }, []);

    const handleHideModal = () => {
        setVisible(false);

        if (modalContents.type === "download" && modalContents.triggerRefreshKeyAfterClose) {
            triggerRefresh(modalContents.triggerRefreshKeyAfterClose);
        }

        setModalContents({type: 'empty'});
    };

    return (
        <ModalContext.Provider value={{showModal: handleShowModal}}>
            {children}
            <ModalContainer visible={visible} onClose={handleHideModal} modalContents={modalContents}/>
        </ModalContext.Provider>
    );
};

export default ModalProvider;
