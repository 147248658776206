import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "reactstrap";
import './Overlay.css';

class Overlay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            match: props.match,
            isOpen: !!props.match,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.match && this.props.match) {
            this.setState({
                match: this.props.match,
                isOpen: true,
            });

            return;
        }

        if (prevProps.match && !this.props.match) {
            this.setState({
                isOpen: false,
            });

            return;
        }

        if (prevProps.match && this.props.match && JSON.stringify(prevProps.match.params) !== JSON.stringify(this.props.match.params)) {
            this.setState({
                match: this.props.match,
                isOpen: true,
            });
        }
    }

    render() {
        return (
            <Modal isOpen={this.state.isOpen} size={this.props.size || 'lg'} centered toggle={this.props.closeOverlay}>
                <button type="button" className="close overlay-close" onClick={this.props.closeOverlay}>
                    <span aria-hidden="true">&times;</span>
                </button>
                <div className="modal-body">
                    {this.props.render({match: this.state.match})}
                </div>
            </Modal>
        );
    }
}

Overlay.propTypes = {
    match: PropTypes.object,
    render: PropTypes.func.isRequired,
    closeOverlay: PropTypes.func.isRequired,
    size: PropTypes.string,
};

export default Overlay;
