import React, {useState} from 'react';
import {Col, Row} from "reactstrap";
import FormWithActions from "../Elements/FormWithActions/FormWithActions";
import FileUpload from "../FileUpload/FileUpload";
import FormGroupRowDate from "../Elements/FormGroupRowDate";
import moment from "moment";

const MtpFileAddForm = ({onAction}) => {
    const [files, setFiles] = useState([]);
    const [month, setMonth] = useState(moment().format('YYYY-MM-01'));

    const handleAction = () => {
        return onAction(month, files[0]);
    };

    let disableSubmitReason = '';
    if (!month) {
        disableSubmitReason = 'Select a month to continue.';
    } else if (!files[0]) {
        disableSubmitReason = 'Select a file to continue.';
    }

    return (
        <>
            <Row>
                <Col>
                    <h1 className="mb-5">
                        Add MailToPay file
                    </h1>
                </Col>
            </Row>
            <FormWithActions onAction={handleAction} disableSubmitReason={disableSubmitReason}>
                <FormGroupRowDate
                    label="Month"
                    value={month}
                    onChange={setMonth}
                    description="This must be the first day of the month."
                />

                <FileUpload
                    files={files}
                    setFiles={setFiles}
                    dropzoneOptions={{
                        accept: {
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                        },
                    }}
                />
            </FormWithActions>
        </>
    );
};

export default MtpFileAddForm;