import React, {useEffect, useState} from 'react';
import CreateTemplateStepButtons from '../wizard/CreateTemplateStepButtons';
import CreateTemplateStepCounter from '../wizard/CreateTemplateStepCounter';
import FormGroupRowText from '../../Elements/FormGroupRowText';
import {fetchSendersList} from "../../services";
import Select from "react-virtualized-select";
import FormGroupRow from "../../Elements/FormGroupRow";

const CreateTemplateStepName = (props: any) => {
    const template = props.template;
    const [senderParentId, setSenderParentId] = useState(template.senderParentId || null);
    const [senderParentName, setSenderParentName] = useState(template.senderParentName || '');
    const [templateName, setTemplateName] = useState(template.templateName);
    const [senderList, setSenderList] = useState([]);

    useEffect(() => {
        fetchSendersList({isParent: true})
            .then(list => {
                setSenderList(list)
            })
            .catch(error => {
                setSenderList([]);
            });
    }, [])

    function getSenderList(): any[] {
        return senderList || [];
    }

    function handleParentSelectChange(id: number) {
        let parent: any = senderList.find((s: any) => s.id === id);
        if(parent) {
            setSenderParentId(id);
            setSenderParentName(parent.name);
        } else {
            setSenderParentId(null);
            setSenderParentName('');
            updateBody();
        }
    }

    function updateBody(): void {
        props.updateBody({templateName, senderParentId, senderParentName});

    }

    function isFormValid(): boolean {
        return templateName !== '';
    }

    function isCurrentStep(): boolean {
        return props.isActive;
    }

    return (
        <div>
            <div className="card">
                <div className="card-header text-center">
                    <CreateTemplateStepCounter {...props}></CreateTemplateStepCounter>
                </div>
                {isCurrentStep() && (
                    <div className="card-body">
                        <h4>Sender template name</h4>

                        <FormGroupRowText required={true} label="Template name" value={templateName}
                                          onChange={setTemplateName}/>

                        <FormGroupRow label="Parent">
                            <Select
                                value={senderParentId}
                                onChange={(selection: any) => {
                                    if(selection) {
                                        handleParentSelectChange(selection.value);
                                    }
                                }}
                                options={[
                                    {value: null, label: '(empty)'},
                                    ...getSenderList().map(item => ({
                                        value: item.id,
                                        label: item.id + ': ' + item.name + ' (' + item.senderContractNumber + ')',
                                    })),
                                ]}
                                clearable={false}
                                matchProp="label"
                            />
                        </FormGroupRow>

                        <CreateTemplateStepButtons {...props}
                                                   isValid={isFormValid()}
                                                   onNext={updateBody}> </CreateTemplateStepButtons>
                    </div>
                )}
            </div>
        </div>
    );
};
export default CreateTemplateStepName;