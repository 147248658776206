import React, {Fragment} from 'react';
import SendersTable from './SendersTable';

const SendersRoute = () => {
    return (
        <Fragment>
            <h1 className="mb-5">Senders</h1>
            <SendersTable/>
        </Fragment>
    );
};

export default SendersRoute;