import React, {Component, createRef} from 'react';
import {fetchStats, retrieveFilenameFromHeaders, sendFileToBrowser} from '../services';
import googleChartLoader from '../GoogleChartLoader';
import Loader from '../Elements/Loader';
import {Button, ButtonGroup, Row, Col} from 'reactstrap';
import {DesktopDownloadIcon} from 'react-octicons';

class DocumentCountProgressBySenderStats extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isCommunicatingWithServer: true,
            showError: false,
            headers: [],
            rows: [],
            filteredData: [],
            groupBy: 'month',
            senderType: 'billingSender',
            height: this.props.height ? parseInt(this.props.height, 10) : 400,
            senderFilter: '',
        };

        this.drawDashboard = this.drawDashboard.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.filterRows = this.filterRows.bind(this);
        this.handleDownload = this.handleDownload.bind(this);

        this.fetchData = this.fetchData.bind(this);

        this.divRef = createRef();
    }

    componentDidMount() {
        googleChartLoader.init();
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.groupBy !== this.state.groupBy) {
            this.fetchData();
            return;
        }

        if (prevState.senderType !== this.state.senderType) {
            this.fetchData();
            return;
        }

        if (prevState.filteredData !== this.state.filteredData) {
            googleChartLoader.init().then(() => {
                this.drawDashboard();
            });
        }

        if (prevState.senderFilter !== this.state.senderFilter) {
            this.filterRows();
        }
    }

    filterRows() {
        this.setState({
            filteredData: this.state.rows.filter(item => item[0].toLowerCase().includes(this.state.senderFilter.toLowerCase()) === true),
        });
    }

    fetchData() {
        this.setState({
            isCommunicatingWithServer: true,
            showError: false,
            headers: [],
            rows: [],
        });

        fetchStats('documentCountProgressBySender', {
            groupBy: this.state.groupBy,
            senderType: this.state.senderType,
        })
            .then(data => {
                const processedData = data.results.map(row => {

                    const processedRow = [];
                    processedRow.push(row.name);
                    processedRow.push(row[data.headers[0]]);

                    data.headers.slice(1).forEach((header, idx) => {
                        const percentage = ((row[data.headers[idx]] / row[header]) - 1) * 100; //idx is already offset by -1 by slice

                        if (isNaN(percentage)) {
                            processedRow.push(null);
                        } else {
                            processedRow.push({
                                v: percentage,
                                f: percentage.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }) + '%',
                            });
                        }

                        processedRow.push(row[header]);
                    });

                    return processedRow;
                });

                this.setState({
                    isCommunicatingWithServer: false,
                    rows: processedData,
                    headers: data.headers,
                    filteredData: processedData.filter(item => item[0].toLowerCase().includes(this.state.senderFilter.toLowerCase()) === true),
                });
            })
            .catch(() => {
                this.setState({
                    isCommunicatingWithServer: false,
                    showError: true,
                });
            });
    }

    drawDashboard() {
        const data = new window.google.visualization.DataTable();
        data.addColumn("string", 'Sender');
        data.addColumn("number", this.state.headers[0]);

        this.state.headers.slice(1).forEach(header => {
            data.addColumn("number", "Growth");
            data.addColumn("number", header);
        });

        data.addRows(this.state.filteredData);

        const options = {
            height: this.state.height + 30,
            width: this.divRef.current.offsetWidth,
            sortColumn: 2,
            sortAscending: false,
            page: 'enabled',
            pageSize: 100,
        };

        const chart = new window.google.visualization.Table(document.getElementById('DocumentCountProgressBySenderStats'));
        chart.draw(data, options);
    }

    handleClick(groupBy) {
        this.setState({
            groupBy: groupBy,
        });
    }

    async handleDownload() {
        const response = await fetchStats('documentCountProgressBySender', {
            groupBy: this.state.groupBy,
            senderType: this.state.senderType,
            format: 'xlsx',
        }, true);

        if (response.status === 400) {
            const data = await response.json();
            throw new Error(data.message);
        }

        if (response.status !== 200) {
            throw new Error(`${response.status}: ${response.statusText}. Contact a site administrator. This error probably won't go away by itself.`);
        }

        //fallback filename if no filename from server is received
        let filename = retrieveFilenameFromHeaders(response.headers, 'Growth.xlsx');

        const blob = await response.blob();
        sendFileToBrowser(filename, blob);
    }

    render() {
        const height = this.state.height + 60;

        return (
            <div id="DocumentCountProgressBySenderStatsWrapper" ref={this.divRef}>
                <Row style={{marginBottom: 20}}>
                    <Col xs="auto">
                        <ButtonGroup size="sm">
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.senderType === 'billingSender'} color="success" onClick={() => this.setState({senderType: 'billingSender'})}>Billing sender</Button>
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.senderType === 'parentSender'} color="success" onClick={() => this.setState({senderType: 'parentSender'})}>Parent sender</Button>
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.senderType === 'sender'} color="success" onClick={() => this.setState({senderType: 'sender'})}>Sender</Button>
                        </ButtonGroup>
                    </Col>
                    <Col xs="auto">
                        <ButtonGroup size="sm">
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.groupBy === 'month'} color="success" onClick={() => this.handleClick('month')}>Month</Button>
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.groupBy === 'quarter'} color="success" onClick={() => this.handleClick('quarter')}>Quarter</Button>
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.groupBy === 'year'} color="success" onClick={() => this.handleClick('year')}>Year</Button>
                        </ButtonGroup>
                    </Col>
                    <Col xs="auto">
                        <DesktopDownloadIcon onClick={this.handleDownload} style={{cursor: 'pointer'}}/>
                    </Col>
                </Row>
                <div style={{height: height + 'px'}}>
                    {this.state.isCommunicatingWithServer &&
                        <div className="d-flex flex-column justify-content-center h-100 text-center">
                            <Loader/>
                        </div>
                    }
                    {this.state.showError &&
                        <div className="d-flex flex-column justify-content-center h-100 text-center">
                            An error occured showing this graph. Try refreshing the page.
                        </div>
                    }
                    Name: <input type="text" value={this.state.senderFilter} onChange={e => this.setState({senderFilter: e.target.value})}/>
                    <div id="DocumentCountProgressBySenderStats"/>
                </div>
            </div>
        );

    }
}

export default DocumentCountProgressBySenderStats;