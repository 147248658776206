import React, {Component} from 'react';
import {createSender, downloadUserCredentials, fetchPartnerSettingsForSender} from '../services';
import StepWizard from 'react-step-wizard';
import CreateSenderStepParent from './steps/CreateSenderStepParent';
import CreateSenderStepSender from './steps/CreateSenderStepSender';
import CreateSenderStepMerchant from './steps/merchant/CreateSenderStepMerchant';
import Loader from '../Elements/Loader';
import CreateSenderStepConfirm from './steps/CreateSenderStepConfirm';
import CreateSenderStepSelectTemplate from "./steps/CreateSenderStepSelectTemplate";
import {getRequiredPartnerSettings, mergedWithParentSettings} from "./steps/partnersettings/RequiredPartnerSettings";
import CreateSenderStepPartnerSettings from "./steps/partnersettings/CreateSenderStepPartnerSettings";
import {
    allPaymentFlows
} from "./steps/merchant/CreateSenderMerchantHelper";
import {prepareDataForSubmit} from "./CreateSenderHelper";
import {PartnerSetting} from "./steps/partnersettings/partnerSettings";
import ClickToCopy from "../Elements/ClickToCopy/ClickToCopy";

type Props = { history: any };
type State = { accountSettings: any, data: any, template: any, comment: string };

class CreateSender extends Component<Props, State> {
    submitting = false;

    constructor(props: Props) {
        super(props);
        this.state = {
            comment: '',
            data: {
                parent: {},
                sender: {},
                user: {},
                partnerSettings: [],
                parentSettings: [],
                newParentSettings: [],
                merchant: {paymentFlows: allPaymentFlows, providers: []},
                expectedResponse: {},
                credUrlExpiryDays: 5
            },
            template: {},
            accountSettings: {}
        };
    }

    updateBody(newData: any) {
        this.setState({
            data: {
                ...this.state.data,
                ...newData,
            }
        });
    }

    loadAndMergeParentSettings(senderParentId: any) {
        this.loadParentSettings(senderParentId).then(settings => {
            this.setState({
                data: {
                    ...this.state.data,
                    ...{parentSettings: settings},
                    ...{partnerSettings: mergedWithParentSettings(settings, this.state.data.partnerSettings)},
                }
            });
        });
    }

    async loadParentSettings(senderParentId: any) {
        return await fetchPartnerSettingsForSender(senderParentId);
    }

    updateTemplate(template: any) {
        this.setState({
            template: template,
            comment: template.comment,
            accountSettings: template.accountSettings
        });
        let requireSettings = getRequiredPartnerSettings(template.partnerSettings);
        this.updateBody({
            partnerSettings: mergedWithParentSettings(template.parentSettings, requireSettings),
            parentSettings: template.parentSettings,
            sender: {},
            parent: {senderParentId: template.senderParentId, parentName: template.senderParentName},
            user: this.getUserTemplateSettings(template.accountSettings),
            merchant: {paymentFlows: allPaymentFlows, providers: []},
            expectedResponse: template.expectedResponse.filter((ps: any) => ps.isVisible)
        })
    }

    getUserTemplateSettings(accountSettings: any) {
        return {
            needsChildCredentials: accountSettings.needsChildCredentials || false,
            parentPermissions: accountSettings.parentPermissions || false,
            roles: ['ROLE_SENDER_PAYLINK']
        }
    }

    resetPartnerSettings() {
        this.updateBody({
            partnerSettings: getRequiredPartnerSettings(this.state.template.partnerSettings),
            parentSettings: this.state.template.parentSettings
        })
    }

    updateSettingsRelevantConfigurations(partnerSettings: PartnerSetting[]) {
        this.updatePaymentFlows(partnerSettings);
        this.prepareRoles(partnerSettings);
    }

    updatePaymentFlows(partnerSettings: PartnerSetting[]) {
        const allPartnerSettings = partnerSettings.concat(this.state.data.parentSettings);
        const paypagePartnerSetting = allPartnerSettings.find((ps: any) => ps.name === 'usePaypage');
        const paymentFlowsToBeRemoved = ['bancontactPOD', 'applepay'];
        if (!paypagePartnerSetting || !paypagePartnerSetting.value) {
            let updatedPaymentFlows = this.state.data.merchant.paymentFlows.filter((pf: any) => !paymentFlowsToBeRemoved.includes(pf.name))
            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    merchant: {
                        ...prevState.data.merchant,
                        paymentFlows: updatedPaymentFlows
                    }
                }
            }));
        }
    }

    prepareRoles(partnerSettings: PartnerSetting[]) {
        const allSettings = partnerSettings.concat(this.state.data.parentSettings);
        let settingNames = ['maildelivery', 'paypage.optin.channels', 'successCallbackUrl.includeIban'];
        let settingShouldNotExist = allSettings.find((ps: any) => ps.name === 'successCallbackUrl');
        if (!settingShouldNotExist) {
            settingNames.forEach(sn => {
                let settingExist = allSettings.find((ps: any) => ps.name === sn);
                if (settingExist && this.checkSettingValue(settingExist)) {
                    // @ts-ignore
                    let newRoles = [...new Set([...this.state.data.user.roles, ...['ROLE_SENDER']])];
                    this.setState(prevState => ({
                        data: {
                            ...prevState.data,
                            user: {
                                ...prevState.data.user,
                                roles: newRoles
                            }
                        }
                    }));
                }
            })
        }
    }

    checkSettingValue(setting: PartnerSetting) {
        return setting.value.toString() === 'true' || String(setting.value).includes('QR');
    }

    displayResponse(response: any) {
        return Object.keys(response).map(key => (
            <table>
                <tbody>
                <tr>
                    {['Comment', 'Note'].includes(key) ?
                        <td className="text-danger py-2 text-left" colSpan={2}>{response[key]}</td> :
                        <>
                            <th className='text-muted  pr-2' scope="row">{key}</th>
                            <td className="preserve-line-breaks">{response[key]}</td>
                        </>
                    }
                    {key === 'Credentials' && <ClickToCopy value={response[key]} children={''}/>}
                </tr>
                </tbody>
            </table>
        ));
    }

    handleSubmit = () => {
        this.submitting = true;
        return createSender(prepareDataForSubmit(this.state.data))
            .then((response) => {
                this.submitting = false;
                this.downloadCredentials(response);
                if (this.state.comment) {
                    response.Comment = this.state.comment;
                }
                response.Note = 'The credentials are downloaded, please upload the file to LastPass and delete it for security reasons.'
                return this.displayResponse(response);
            }).catch((error) => {
                this.submitting = false;
                throw error;
            });
    };

    findResponsePropertyName = (name: string) => {
        let expectedResponseProperty = this.state.data.expectedResponse.find((s: any) => s.name === name);
        return expectedResponseProperty?.secondaryName || expectedResponseProperty?.label || name;
    }
    downloadCredentials = (response: any) => {
        let senderCredentials = {
            name: this.state.data.sender.senderName,
            contractNumber: this.state.data.sender.senderContractNumber,
            username: response[this.findResponsePropertyName('senderUsername')],
            password: response[this.findResponsePropertyName('password')],
            awsbucket: response[this.findResponsePropertyName('awsbucket')],
            awsUsername: response[this.findResponsePropertyName('awsUsername')],
            awsPassword: response[this.findResponsePropertyName('awsPassword')],
            senderId: '',
        };

        let childSenderId = response[this.findResponsePropertyName('childSenderId')];
        let parentSenderId = response[this.findResponsePropertyName('parentSenderId')]
        senderCredentials.senderId = childSenderId || parentSenderId;

        return downloadUserCredentials(senderCredentials);
    }

    render() {
        return (
            <div>
                <h3 className="mb-4">Create sender</h3>
                {this.submitting && (
                    <>
                        <Loader color="info" style={{width: '100px'}}/>
                    </>
                )}
                <div style={{margin: "0 auto"}} hidden={this.submitting}>
                    <StepWizard isLazyMount={true}>

                        <CreateSenderStepSelectTemplate
                            selectedTemplateId={this.state.template.id}
                            updateTemplate={this.updateTemplate.bind(this)}>
                        </CreateSenderStepSelectTemplate>

                        <CreateSenderStepParent
                            loadAndMergeParentSettings={this.loadAndMergeParentSettings.bind(this)}
                            parent={this.state.data.parent}
                            accountSettings={this.state.accountSettings}
                            resetPartnerSettings={this.resetPartnerSettings.bind(this)}
                            updateBody={this.updateBody.bind(this)}></CreateSenderStepParent>

                        <CreateSenderStepSender
                            submit={this.handleSubmit.bind(this)}
                            accountSettings={this.state.accountSettings}
                            sender={this.state.data.sender}
                            updateBody={this.updateBody.bind(this)}></CreateSenderStepSender>

                        <CreateSenderStepPartnerSettings {...this.props}
                                                         accountSettings={this.state.accountSettings}
                                                         data={this.state.data}
                                                         updateRelevantConfigurations={this.updateSettingsRelevantConfigurations.bind(this)}
                                                         updateBody={this.updateBody.bind(this)}>
                        </CreateSenderStepPartnerSettings>

                        <CreateSenderStepMerchant
                            data={this.state.data}
                            updateBody={this.updateBody.bind(this)}></CreateSenderStepMerchant>

                        <CreateSenderStepConfirm
                            data={this.state.data}
                            accountSettings={this.state.accountSettings}
                            updateBody={this.updateBody.bind(this)}
                            submit={this.handleSubmit.bind(this)}></CreateSenderStepConfirm>

                    </StepWizard>
                </div>
            </div>
        );
    }
};

export default CreateSender;
