import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Col, Row} from "reactstrap";
import {deleteBillingReseller, editBillingReseller} from "../../../services";
import {withRefresh} from "../../../Elements/Fetch";
import BillingResellerForm from "../BillingReseller/BillingResellerForm";
import {AppContext} from "../../../AppContext";

class BillingResellerEditForm extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.handleAction = this.handleAction.bind(this);
    }

    handleAction(action, state) {
        switch (action) {
            case 'submit':
                return editBillingReseller(this.props.billingReseller.id, state)
                    .then(data => {
                        this.props.triggerRefresh(`billingReseller:${this.props.billingReseller.id}`);
                        this.props.onSubmit();
                        return data;
                    });
            case 'delete':
                return deleteBillingReseller(this.props.billingReseller.id)
                    .then(() => {
                        this.context.triggerAppStateRefresh('downloadBillingResellers');
                        this.props.history.push('/billing/resellers');
                    });
            default:

        }
    }

    render() {

        const actions = [
            {
                action: 'delete',
                displayName: 'Delete',
                confirmMessage: 'Are you sure you want to continue?',
            },
        ];

        return (
            <Fragment>
                <Row>
                    <Col>
                        <h1 className="mb-5">
                            Edit billing reseller
                        </h1>
                    </Col>
                </Row>

                <BillingResellerForm
                    action={this.handleAction}
                    actions={actions}
                    billingReseller={this.props.billingReseller}
                />

            </Fragment>
        );
    }
}

BillingResellerEditForm.propTypes = {
    billingReseller: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        externalClientId: PropTypes.string,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default withRouter(withRefresh(BillingResellerEditForm));