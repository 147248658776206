import React, {Component} from 'react';
import {saveSenderTemplate} from '../services';
import StepWizard from 'react-step-wizard';
import Loader from '../Elements/Loader';
import CreateTemplateStepConfirm from './steps/CreateTemplateStepConfirm';
import CreateTemplateStepName from "./steps/CreateTemplateStepName";
import {allSettingGroups, PartnerSettingGroup} from "./steps/partnersettings/templateSettings";
import CreateTemplateStepSettings from "./steps/CreateTemplateStepSettings";

type Props = { template: any, history: any };
type State = { templateSettings: PartnerSettingGroup[], templateId: string, templateName: string, senderParentId: number, senderParentName: string, comment: string };

class ManageTemplate extends Component<Props, State> {
    submitting = false;
        constructor(props: Props) {
        super(props);
        let templateItem = props.template;
        this.state = {
            templateSettings:  templateItem?.templateSettings || allSettingGroups,
            templateId: templateItem?.id || '',
            senderParentId: templateItem?.senderParentId || null,
            templateName: templateItem?.templateName || '',
            comment: templateItem?.comment || '',
            senderParentName: templateItem?.senderParentName
        };
    }

    updateBody = (newData: any) => {
        this.setState(prevState => ({
            ...prevState,
            ...newData
        }));
    };

    handleSubmit = async () => {
        this.submitting = true;
        return await  saveSenderTemplate(this.state)
            .then((senderCreateResponseBody) => {
                this.submitting = false;
                return (<pre>{JSON.stringify(senderCreateResponseBody, null, 2)}</pre>);
            }).catch((error) => {
                this.submitting = false;
                throw error;
            });
    };

    render() {
        return (
            <div>
                {this.submitting && (
                    <>
                        <Loader color="info" style={{width: '100px'}}/>
                    </>
                )}

                    <div style={{maxWidth: 1000, margin: "0 auto"}} hidden={this.submitting}>
                        <StepWizard isLazyMount={true}>
                            <CreateTemplateStepName
                                template={this.state}
                                updateBody={this.updateBody.bind(this)}></CreateTemplateStepName>

                            <CreateTemplateStepSettings
                                templateSettings={this.state.templateSettings}
                                comment={this.state.comment}
                                updateBody={this.updateBody.bind(this)}></CreateTemplateStepSettings>

                            <CreateTemplateStepConfirm
                                template={this.state}
                                submitTemplate={this.handleSubmit.bind(this)}
                                submit={this.handleSubmit.bind(this)}></CreateTemplateStepConfirm>
                        </StepWizard>
                    </div>
            </div>
        );
    }
};

export default ManageTemplate;