import React from 'react';
import {fetchTransaction} from '../services';
import Transaction from "./Transaction";
import Fetch from "../Elements/Fetch";

const TransactionRoute = ({match}) => (
    <Fetch fetchFunction={fetchTransaction} fetchFunctionParams={match.params.id} refreshKey={`transaction:${match.params.id}`}>
        {transaction => <Transaction {...transaction} transactionId={match.params.id} />}
    </Fetch>
);

export default TransactionRoute;