import React from 'react';
import PropTypes from 'prop-types';
import {buckarooSendFile, fetchSenderOnboarding} from "../services";
import Fetch, {withRefresh} from "../Elements/Fetch";
import SenderOnboardingBuckarooSendFileForm from "./SenderOnboardingBuckarooSendFileForm";

const SenderOnboardingBuckarooSendFileRoute = ({match, onSubmit, triggerRefresh}) => (
    <Fetch fetchFunction={fetchSenderOnboarding} fetchFunctionParams={match.params.id}>
        {senderOnboarding => {
            const document = senderOnboarding.buckarooDocumentsToComplete.find(document => String(document.id) === match.params.documentId);
            const files = senderOnboarding.buckarooFiles;

            const handleAction = async (fileId) => {
                await buckarooSendFile(document.id, fileId);
                triggerRefresh(`senderOnboarding:${senderOnboarding.id}`, true);
                onSubmit();
            };

            return (
                <SenderOnboardingBuckarooSendFileForm
                    document={document}
                    files={files}
                    onAction={handleAction}
                />
            );
        }}
    </Fetch>
);

SenderOnboardingBuckarooSendFileRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default withRefresh(SenderOnboardingBuckarooSendFileRoute);