import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({color = 'success', style = {}}) => (
    <div className="progress" style={style}>
        <div className={"progress-bar progress-bar-striped progress-bar-animated bg-" + color} style={{width: '100%'}}/>
    </div>
);

Loader.propTypes = {
    color: PropTypes.string,
};

export default Loader;