import React from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import BillingCreditsListRoute from "./BillingCreditsList/BillingCreditsListRoute";
import BillingCreditsEditPurchaseRoute from "./BillingCreditsPurchaseEdit/BillingCreditsEditPurchaseRoute";
import BillingCreditsAddRoute from "./BillingCreditsAdd/BillingCreditsAddRoute";
import BillingCreditsRoute from "./BillingCredits/BillingCreditsRoute";

const BillingCreditsRoutes = () => (
    <Switch>
        <Route exact path="/billing/credits" component={BillingCreditsListRoute}/>
        <Route exact path="/billing/credits/add/:id" component={BillingCreditsAddRoute}/>
        <Route exact path="/billing/credits/:id" component={BillingCreditsRoute}/>
        <Route exact path="/billing/credits/:id/edit" component={BillingCreditsEditPurchaseRoute}/>
    </Switch>
);

export default withRouter(BillingCreditsRoutes);