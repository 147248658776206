import React from 'react';
import {fetchTask} from '../services';
import Task from "./Task";
import Fetch from "../Elements/Fetch";

const TaskRoute = ({match}) => (
    <Fetch fetchFunction={fetchTask} fetchFunctionParams={match.params.taskId} refreshKey={`task:${match.params.taskId}`}>
        {task => <Task {...task}/>}
</Fetch>
);

export default TaskRoute;