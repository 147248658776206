import React, {Component} from 'react';
import {fetchStats} from '../services';
import googleChartLoader from '../GoogleChartLoader';
import Loader from '../Elements/Loader';

class DocumentBySenderStats extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isCommunicatingWithServer: true,
            showError: false,
            data: [],
            filteredData: [],
            height: this.props.height ? parseInt(this.props.height, 10) : 400,
            senderFilter: '',
        };

        this.drawDashboard = this.drawDashboard.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.filterRows = this.filterRows.bind(this);
    }

    componentDidMount() {
        googleChartLoader.init(['corechart', 'controls']);
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.filteredData !== this.state.filteredData) {
            googleChartLoader.init().then(() => {
                this.drawDashboard();
            });
        }

        if (prevState.senderFilter !== this.state.senderFilter) {
            this.filterRows();
        }
    }

    filterRows() {
        this.setState({
            filteredData: this.state.data.filter(item => item[0].toLowerCase().includes(this.state.senderFilter.toLowerCase()) === true).slice(-200),
        });
    }

    fetchData() {
        fetchStats('documentBySender').then(data => {
            this.setState({
                data: data,
                filteredData: data.slice(-200),
                isCommunicatingWithServer: false,
            });
        }).catch(errorMessage => {
            this.setState({
                isCommunicatingWithServer: false,
                showError: true,
            });
        });
    }

    drawDashboard() {
        const rows = this.state.filteredData.map((item, key) => [key, item[1], item[0] + "\nDocuments created paid: " + item[1], item[2], item[0] + "\nDocuments created unpaid: " + item[2]]);
        const ticks = this.state.filteredData.map((item, key) => ({v: key, f: item[0]}));

        const data = new window.google.visualization.DataTable();
        data.addColumn("number", 'Sender');
        data.addColumn("number", 'Documents created paid');
        data.addColumn({type: 'string', role: 'tooltip'});
        data.addColumn("number", 'Documents created unpaid');
        data.addColumn({type: 'string', role: 'tooltip'});
        data.addRows(rows);

        const dashboard = new window.google.visualization.Dashboard(document.getElementById('DocumentBySenderStatsWrapper'));

        let end = rows.length;
        let start = Math.max(0, end - 10);

        const chart = new window.google.visualization.ChartWrapper({
            chartType: 'BarChart',
            containerId: 'DocumentBySenderStats',
            options: {
                title: 'Documents by sender (Top 200)',
                legend: {
                    position: 'none',
                },
                height: this.state.height,
                chartArea: {
                    left: 300,
                    right: 0,
                    top: 30,
                    bottom: 30,
                },
                isStacked: true,
                vAxis: {
                    ticks: ticks.slice(start, end),
                },
            },
        });

        const controls = new window.google.visualization.ControlWrapper({
            controlType: 'ChartRangeFilter',
            containerId: 'DocumentBySenderStatsControls',
            options: {
                filterColumnLabel: 'Sender',
                ui: {
                    chartType: 'AreaChart',
                    snapToData: true,
                    chartOptions: {
                        isStacked: true,
                        height: 40,
                        chartArea: {
                            left: 40,
                            right: 10,
                            top: 0,
                            bottom: 0,
                        },
                        hAxis: {
                            'textPosition': 'none',
                        },
                    },
                },
            },
            state: {
                range: {
                    start: start,
                    end: end,
                }
            },
        });

        window.google.visualization.events.addListener(controls, 'statechange', (event) => {
            if (event.startChanged || event.endChanged) {
                var state = controls.getState();
                chart.setOption('vAxis.ticks', ticks.slice(state.range.start, state.range.end + 1));
            }
        });

        dashboard.bind([controls], [chart]);
        dashboard.draw(data);
    }

    handleChange(event) {
        this.setState({senderFilter: event.target.value});
    }

    render() {

        const height = this.state.height + 40;

        return (
            <div id="DocumentBySenderStatsWrapper">
                <div style={{height: height + 'px'}}>
                    {this.state.isCommunicatingWithServer &&
                    <div className="d-flex flex-column justify-content-center h-100">
                        <Loader/>
                    </div>
                    }
                    {this.state.showError &&
                    <div className="d-flex flex-column justify-content-center h-100 text-center">
                        An error occured showing this graph. Try refreshing the page.
                    </div>
                    }
                    Sender: <input type="text" value={this.state.senderFilter} onChange={this.handleChange}/>
                    <div id="DocumentBySenderStats"></div>
                    <div id="DocumentBySenderStatsControls"></div>
                </div>
            </div>
        );

    }
}

export default DocumentBySenderStats;