import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Col, Row} from "reactstrap";
import {deleteBillingSender, editBillingSender} from "../../../services";
import {withRefresh} from "../../../Elements/Fetch";
import BillingSenderForm from "../BillingSender/BillingSenderForm";
import {AppContext} from "../../../AppContext";

class BillingSenderEditForm extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.handleAction = this.handleAction.bind(this);
    }

    handleAction(action, state) {
        switch (action) {
            case 'submit':
                return editBillingSender(this.props.billingSender.id, state)
                    .then(data => {
                        this.props.triggerRefresh(`billingSender:${this.props.billingSender.id}`);
                        this.props.onSubmit();
                        return data;
                    });
            case 'delete':
                return deleteBillingSender(this.props.billingSender.id)
                    .then(() => {
                        this.context.triggerAppStateRefresh('downloadBillingSenders');
                        this.props.history.push('/billing/senders');
                    });
            default:

        }
    }

    render() {

        const actions = [
            {
                action: 'delete',
                displayName: 'Delete',
                confirmMessage: 'Are you sure you want to continue?',
            },
        ];

        return (
            <Fragment>
                <Row>
                    <Col>
                        <h1 className="mb-5">
                            Edit billing sender
                        </h1>
                    </Col>
                </Row>

                <BillingSenderForm
                    action={this.handleAction}
                    actions={actions}
                    billingSender={this.props.billingSender}
                />

            </Fragment>
        );
    }
}

BillingSenderEditForm.propTypes = {
    billingSender: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default withRouter(withRefresh(BillingSenderEditForm));