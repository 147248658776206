import React, {Fragment} from 'react';
import TasksTable from './TasksTable';

const TasksRoute = () => (
    <Fragment>
        <h1 className="mb-5">Tasks</h1>
        <TasksTable/>
    </Fragment>
);

export default TasksRoute;