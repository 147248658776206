import React, {useContext, useEffect, useState} from 'react';
import FormGroupRowText from "../../Elements/FormGroupRowText";
import useSubmittingButton from "../../hooks/useSubmittingButton";
import {Button, ButtonGroup, Col, Row, FormGroup} from "reactstrap";
import FormGroupRow from "../../Elements/FormGroupRow";
import FormText from "../../Elements/FormText";
import {
    fetchSendersListForPaylink,
    refreshSendersListForPaylink,
    runScript,
    validAmount,
    validEmail
} from "../../services";
import FormGroupRowDate from "../../Elements/FormGroupRowDate";
import moment from 'moment';
import Loader from "../../Elements/Loader";
import {AppContext} from "../../AppContext";
import QRCode from 'qrcode.react';
import styles from './PaylinkGenerator.module.css';
import Select from "react-virtualized-select";
import Refresh from "../../Icons/Refresh/Refresh";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {ClippyIcon, ChecklistIcon} from 'react-octicons';

const PaylinkGenerator = () => {
    const {currentUser} = useContext(AppContext);
    const [senderList, setSenderList] = useState(null);
    const [isRefreshingSenderList, setRefreshingSenderList] = useState(false);

    useEffect(
        () => {
            (async () => {
                setSenderList(await fetchSendersListForPaylink());
            })();
        },
        []
    );

    const handleRefreshSenderList = async () => {
        setRefreshingSenderList(true);
        setSenderList(await refreshSendersListForPaylink());
        setRefreshingSenderList(false);
    };

    const [senderId, setSenderId] = useState('');
    const [documentType, setDocumentType] = useState('Invoice');
    const [documentId, setDocumentId] = useState('');
    const [amount, setAmount] = useState('');
    const [currency, setCurrency] = useState('EUR');
    const [customerId, setCustomerId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [street1, setStreet1] = useState('');
    const [street2, setStreet2] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [city, setCity] = useState('');
    const [title, setTitle] = useState('');
    const [documentDate, setDocumentDate] = useState(moment().format('YYYY-MM-DD'));
    const [dueDate, setDueDate] = useState(moment().add(3, 'days').format('YYYY-MM-DD'));
    const [memoDate, setMemoDate] = useState(moment().add(1, 'days').format('YYYY-MM-DD'));
    const [communicationType, setCommunicationType] = useState('CommunicationPart1');
    const [communication, setCommunication] = useState('');
    const [externalUrl, setExternalUrl] = useState('');
    const [email, setEmail] = useState(currentUser.email);
    const [ssnNumber, setSsnNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [bankAccountNumbers, setBankAccountNumbers] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [language, setLanguage] = useState('nl');
    const [channels, setChannels] = useState([]);

    const {errorMessage, loader, SubmittingButton} = useSubmittingButton();
    const [results, setResults] = useState([]);

    useEffect(
        () => {
            if (results.length > 0) {
                window.scrollTo(0, document.body.scrollHeight);
            }
        },
        [results]
    );

    const handleSubmit = async () => {
        setResults([]);

        const response = await runScript('PaylinkGenerate', {
            senderId: senderId,
            documentType: documentType,
            documentId: documentId || null,
            amount: amount,
            currency: currency,
            customerId: customerId || null,
            firstName: firstName || null,
            lastName: lastName || null,
            address: {
                street1: street1,
                street2: street2,
                zipCode: zipCode,
                city: city,
            },
            title: title || null,
            documentDate: documentDate || null,
            dueDate: documentType !== 'Contribution' ? dueDate : null,
            memoDate: memoDate || null,
            communicationPart1: communicationType === 'CommunicationPart1' ? communication : null,
            communicationStructured: communicationType === 'CommunicationStructured' ? communication : null,
            externalUrl: externalUrl || null,
            email: email || null,
            socialSecurityNumber: ssnNumber || null,
            phoneNumber: phoneNumber || null,
            paymentMethod: paymentMethod || null,
            expiryDate: expiryDate || null,
            language: language,
            channels: channels || [],
            bankAccountNumbers: !bankAccountNumbers.trim() ? null : bankAccountNumbers.replace(/\s/g, '').split(',').map(b => b.trim()).filter(b => b)
        });

        if (response.status === 400) {
            const data = await response.json();
            const error = data.errors ? data.errors.map(error => error.message) : data.message;
            throw new Error(error);
        }

        if (response.status !== 200) {
            throw new Error(`${response.status}: ${response.statusText}. Contact a site administrator. This error probably won't go away by itself.`);
        }

        const data = await response.json();

        if (data.additionalLinks) {
            const results = Object.keys(data.additionalLinks).map(channel => ({
                channel: channel,
                ...data.additionalLinks[channel],
            }));

            setResults(results);
        } else {
            const results = [{channel: 'default', paylink: data.paylink}]
            setResults(results);
        }
    };

    if (senderList === null) {
        return <Loader/>;
    }
    const senderConfig = senderList.find(sender => sender.id === senderId);

    const disabled =
        !senderId ||
        !validAmount(amount, documentType) ||
        !customerId ||
        !communication ||
        (!dueDate && documentType !== 'Contribution') ||
        (!senderConfig.allowEmptyEmail && !validEmail(email)) ||
        (!senderConfig.allowEmptyPaymentMethod && !paymentMethod) ||
        (senderConfig.availableChannels && !channels);

    return (
        <div style={{maxWidth: 750}}>

            <FormGroupRow label="Sender *" required>
                <Row form>
                    <Col>
                        <Select
                            value={senderId}
                            onChange={selection => setSenderId(selection.value)}
                            options={[
                                {value: '', label: '(empty)'},
                                ...senderList.map(item => ({
                                    value: item.id,
                                    label: item.id + ': ' + item.name + ' (' + item.senderContractNumber + ')',
                                })),
                            ]}
                            clearable={false}
                            matchProp="label"
                        />
                    </Col>
                    <Col xs="auto" className="d-flex align-items-center">
                        <Refresh spinning={isRefreshingSenderList} onClick={handleRefreshSenderList}/>
                    </Col>
                </Row>
            </FormGroupRow>

            {
                !!senderId &&
                <>
                    <FormGroupRow label="Document type *" required>
                        <ButtonGroup size="sm">
                            <Button outline active={documentType === 'Invoice'} color="success" onClick={() => setDocumentType('Invoice')}>Invoice</Button>
                            <Button outline active={documentType === 'Reminder'} color="success" onClick={() => setDocumentType('Reminder')}>Reminder</Button>
                            <Button outline active={documentType === 'OnboardingInvoice'} color="success" onClick={() => setDocumentType('OnboardingInvoice')}>OnboardingInvoice</Button>
                            <Button outline active={documentType === 'Contribution'} color="success" onClick={() => setDocumentType('Contribution')}>Contribution</Button>
                            <Button outline active={documentType === 'PaymentPlan'} color="success" onClick={() => setDocumentType('PaymentPlan')}>PaymentPlan</Button>
                            <Button outline active={documentType === 'ActivationPalmPayments'} color="success" onClick={() => setDocumentType('ActivationPalmPayments')}>ActivationPalmPayments</Button>
                        </ButtonGroup>
                    </FormGroupRow>

                    <FormGroupRowText
                        label="DocumentId"
                        value={documentId}
                        onChange={setDocumentId}
                    />

                    <FormGroupRowText
                        label="Amount *"
                        required
                        type="number"
                        value={amount}
                        onChange={value => {
                            value = Math.abs(value);
                            value = Math.round(value * 100) / 100;
                            setAmount(value);
                        }}
                    />

                    <FormGroupRow label="Currency *" required>
                        <ButtonGroup size="sm">
                            <Button outline active={currency === 'EUR'} color="success" onClick={() => setCurrency('EUR')}>EUR</Button>
                        </ButtonGroup>
                    </FormGroupRow>

                    <FormGroupRowText
                        label="CustomerId *"
                        required
                        value={customerId}
                        onChange={setCustomerId}
                    />

                    <FormGroupRow label="Name">
                        <Row form>
                            <Col>
                                <FormText
                                    placeholder="First name"
                                    value={firstName}
                                    onChange={setFirstName}
                                />
                            </Col>
                            <Col>
                                <FormText
                                    placeholder="Last name"
                                    value={lastName}
                                    onChange={setLastName}
                                />
                            </Col>
                        </Row>
                    </FormGroupRow>

                    <FormGroupRow label="Address">
                        <FormGroup>
                            <Row form>
                                <Col xs={8}>
                                    <FormText
                                        placeholder="Street1"
                                        value={street1}
                                        onChange={setStreet1}
                                    />
                                </Col>
                                <Col>
                                    <FormText
                                        placeholder="Street2"
                                        value={street2}
                                        onChange={setStreet2}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <Row form>
                            <Col>
                                <FormText
                                    placeholder="Zip Code"
                                    value={zipCode}
                                    onChange={setZipCode}
                                />
                            </Col>
                            <Col xs={8}>
                                <FormText
                                    placeholder="City"
                                    value={city}
                                    onChange={setCity}
                                />
                            </Col>
                        </Row>
                    </FormGroupRow>

                    <FormGroupRowText
                        label="Title"
                        value={title}
                        onChange={setTitle}
                    />

                    <FormGroupRowDate
                        label="DocumentDate"
                        value={documentDate}
                        onChange={setDocumentDate}
                    />

                    {
                        documentType !== 'Contribution' &&
                        <FormGroupRowDate
                            label="DueDate *"
                            required
                            value={dueDate}
                            onChange={setDueDate}
                        />
                    }

                    <FormGroupRowDate
                        label="MemoDate"
                        value={memoDate}
                        onChange={setMemoDate}
                    />

                    <FormGroupRow label="Communication *" required>
                        <FormGroup>
                            <ButtonGroup size="sm">
                                <Button outline active={communicationType === 'CommunicationPart1'} color="success" onClick={() => setCommunicationType('CommunicationPart1')}>CommunicationPart1</Button>
                                <Button outline active={communicationType === 'CommunicationStructured'} color="success" onClick={() => setCommunicationType('CommunicationStructured')}>CommunicationStructured</Button>
                            </ButtonGroup>
                        </FormGroup>
                        <FormText
                            value={communication}
                            onChange={setCommunication}
                        />
                    </FormGroupRow>

                    <FormGroupRowText
                        label="External download url"
                        value={externalUrl}
                        onChange={setExternalUrl}
                    />

                    <FormGroupRowText
                        label={!senderConfig.allowEmptyEmail ? 'Email *' : 'Email'}
                        required={!senderConfig.allowEmptyEmail}
                        type="email"
                        value={email}
                        onChange={setEmail}
                    />

                    <FormGroupRowText
                        label="SocialSecurityNumber"
                        value={ssnNumber}
                        onChange={setSsnNumber}
                    />

                    <FormGroupRowText
                        label="Phone number"
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                    />

                    <FormGroupRow
                        label={!senderConfig.allowEmptyPaymentMethod ? 'PaymentMethod *' : 'PaymentMethod'}
                        required={!senderConfig.allowEmptyPaymentMethod}
                    >
                        <ButtonGroup size="sm">
                            {senderConfig.availablePaymentMethods.map(availablePaymentMethod =>
                                <Button key={availablePaymentMethod} outline active={paymentMethod === availablePaymentMethod} color="success"
                                        onClick={() => setPaymentMethod(paymentMethod === availablePaymentMethod ? '' : availablePaymentMethod)}>{availablePaymentMethod}</Button>
                            )}
                        </ButtonGroup>
                    </FormGroupRow>

                    <FormGroupRowText
                        label="Bank account numbers"
                        description="E.g.: BE12123412341234, BE12 1234 1234 1234"
                        value={bankAccountNumbers}
                        onChange={setBankAccountNumbers}
                    />

                    <FormGroupRowDate
                        label="ExpiryDate"
                        value={expiryDate}
                        onChange={value => {
                            if (value && moment(value).isAfter()) {
                                setExpiryDate(value);
                            } else {
                                setExpiryDate('');
                            }
                        }}
                    />

                    <FormGroupRow label="Language *" required>
                        <ButtonGroup size="sm">
                            <Button outline active={language === 'nl'} color="success" onClick={() => setLanguage('nl')}>nl</Button>
                            <Button outline active={language === 'fr'} color="success" onClick={() => setLanguage('fr')}>fr</Button>
                            <Button outline active={language === 'en'} color="success" onClick={() => setLanguage('en')}>en</Button>
                        </ButtonGroup>
                    </FormGroupRow>

                    <FormGroupRow
                        label={senderConfig.availableChannels > 0 ? 'Channels *' : 'Channels'}
                        required={senderConfig.availableChannels > 0}
                    >
                        <ButtonGroup size="sm">
                            {senderConfig.availableChannels.map(channel => (
                                <Button
                                    key={channel}
                                    color="success"
                                    outline
                                    active={channels.includes(channel)}
                                    onClick={() => setChannels(channels.includes(channel) ? channels.filter(x => x !== channel) : [...channels, channel])}
                                    children={channel}
                                />
                            ))}
                        </ButtonGroup>
                    </FormGroupRow>

                    {errorMessage}

                    <Row form className="align-items-center">
                        <Col xs="auto">
                            <SubmittingButton onClick={handleSubmit} disabled={disabled}>Submit</SubmittingButton>
                        </Col>
                        <Col xs="auto">
                            {loader}
                        </Col>
                    </Row>

                    <PaylinkResults results={results}/>
                </>
            }

        </div>
    );
};

const PaylinkResults = ({results}) => {
    if (results.length === 0) return null;

    return (
        <div className={styles.wrapper}>
            {results.map(result => (
                <PaylinkResult
                    key={result.channel}
                    result={result}
                />
            ))}
        </div>
    );
};

const PaylinkResult = ({result: {channel, paylinkShort, paylink}}) => {
    const [copied, setCopied] = useState(false);
    const link = paylinkShort || paylink;

    return (
        <div className={styles.item}>
            <div className={styles.title}>{channel}</div>
            <div className={styles.row}>
                <QRCode value={link}/>
                <div className={styles.link}>
                    <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
                    <CopyToClipboard text={link} onCopy={() => setCopied(true)}>
                        <ClippyIcon className={styles.copy}/>
                    </CopyToClipboard>
                    {copied && <ChecklistIcon/>}
                </div>
            </div>
        </div>
    );
};

export default PaylinkGenerator;
