import React, {Component} from 'react';
import AbstractItemsTableWithLoader from "../../../Tables/AbstractItemsTableWithLoader";
import {fetchInvoices, formatCurrency, runScript} from "../../../services";
import {Link} from "react-router-dom";
import {PencilIcon} from "react-octicons";
import LinkToOverlay from "../../../Elements/Overlay/LinkToOverlay";
import {ModalContext} from "../../../Modal/ModalProvider";

class InvoicesTable extends Component {
    static contextType = ModalContext;

    constructor(props) {
        super(props);

        this.fetchFunction = this.fetchFunction.bind(this);
        this.sendInvoices = this.sendInvoices.bind(this);
    }

    static transformItem(item) {
        return {
            ...item,
            dateCreatedDate: new Date(item.dateCreated),
            dateCreatedDateString: <Link to={"/billing/invoices/" + item.key}>{new Date(item.dateCreated).toLocaleString()}</Link>,
            amountFormatted: formatCurrency(item.totalAmount),
            lockedFormatted: item.locked ? 'Locked' : '',
            edit: <LinkToOverlay overlay={`/billing/invoices/${item.key}/edit`}><PencilIcon/></LinkToOverlay>,
            billingSenderType: item.billingSender.type,
            billingSenderName: item.billingSender.name,
        };
    }

    static tableColumns(hideColumns = [], addSort = true) {
        const columns = {
            dateCreatedDateString: {
                name: 'Date',
                sort: addSort,
                sortKey: 'dateCreated',
            },
            reference: {
                name: 'Reference',
                sort: addSort,
            },
            billingSenderName: {
                name: 'Billing sender',
                sort: addSort,
                sortKey: 'billingSender.name',
            },
            amountFormatted: {
                name: 'Total',
                sort: addSort,
                sortKey: 'totalAmount',
            },
            lockedFormatted: {
                name: 'Locked / Processed',
                sort: addSort,
                sortKey: 'locked',
            },
            externalReadableId: {
                name: 'Eenvoudig Factureren',
                sort: addSort,
            },
            pllayUser: {
                name: 'Created by',
                sort: addSort,
                sortKey: 'pllayUser.firstName',
            },
            edit: {
                name: 'Edit',
                style: {
                    width: '60px',
                },
            },
        };

        hideColumns.forEach(column => delete columns[column]);

        return columns;
    }

    static sendInvoiceDisabledReason(invoice) {
        let sendToEFdisabledReason = '';

        if (!invoice.billingSender.externalClientId) {
            sendToEFdisabledReason = `Invoice ${new Date(invoice.dateCreated).toLocaleString()} has a billing sender that is not linked to an EF client id.`;
        }
        if (!invoice.billingSender.taxRate) {
            sendToEFdisabledReason = `Invoice ${new Date(invoice.dateCreated).toLocaleString()} has a billing sender that is not linked to a tax rate.`;
        }
        if (invoice.externalReadableId) {
            sendToEFdisabledReason = `Invoice ${new Date(invoice.dateCreated).toLocaleString()} already sent to Eenvoudig Factureren with id ${invoice.externalReadableId}.`;
        }
        if (invoice.totalAmount === 0) {
            sendToEFdisabledReason = `Invoice ${new Date(invoice.dateCreated).toLocaleString()} has an amount of 0 and cannot be sent to Eenvoudig Factureren.`;
        }

        return sendToEFdisabledReason;
    };

    fetchFunction(searchParams) {
        return fetchInvoices(searchParams).then(data => ({
            ...data,
            items: data.items.map(item => InvoicesTable.transformItem(item)),
        }));
    };

    async sendInvoices(ids) {
        const response = await runScript('SendInvoices', {
            ids: ids,
        });

        if (response.status === 200) {
            const data = await response.json();

            this.context.showModal({
                type: 'download',
                exportId: data.id,
                triggerRefreshKeyAfterClose: 'invoices',
            });
        } else {
            throw new Error(`${response.status}: ${response.statusText}. Contact a site administrator. This error probably won't go away by itself.`);
        }
    }

    render() {
        const namedSearchParameters = {
            dateCreated: {
                type: 'date',
                name: 'Date',
                operators: ['eq', 'lt', 'gt', 'between'],
            },
            reference: {
                type: 'text',
                name: 'Reference',
                operators: ['eq', 'contains'],
            },
            'billingSender.name': {
                type: 'text',
                name: 'Billing sender',
                operators: ['contains'],
            },
            total: {
                type: 'select',
                name: 'Total',
                options: {
                    has_total: 'Total > 0',
                    no_total: 'Total = 0',
                },
                operators: ['eq'],
            },
            locked: {
                type: 'select',
                name: 'Locked / Processed',
                options: {
                    'true': 'Is locked',
                    'false': 'Not locked',
                },
                operators: ['eq'],
            },
            sentToEF: {
                type: 'select',
                name: 'Sent to EF',
                options: {
                    sent_to_ef: 'Already sent to EF',
                    not_sent_to_ef: 'Not yet sent to EF',
                },
                operators: ['eq'],
            },
            externalReadableId: {
                type: 'text',
                name: 'Eenvoudig Factureren',
                operators: ['eq', 'contains'],
            },
        };

        return (
            <AbstractItemsTableWithLoader
                tableColumns={InvoicesTable.tableColumns()}
                namedSearchParameters={namedSearchParameters}
                fetchFunction={this.fetchFunction}
                refreshKey="invoices"

                selectableButton="Send invoices to Eenvoudig Factureren"
                selectableFunction={invoice => !InvoicesTable.sendInvoiceDisabledReason(invoice)}

                selectableButtonFunction={this.sendInvoices}
                skipRefreshAfterSelectableButton
                disabledReasonFunction={InvoicesTable.sendInvoiceDisabledReason}
            />
        );
    }
}

export default InvoicesTable;
