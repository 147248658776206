import React, {Fragment, useState} from 'react';
import {Button, Col, Row} from "reactstrap";
import Fetch, {withRefresh} from "../../../Elements/Fetch";
import {
    fetchBillingCreditsList,
    fetchBillingEvents,
    fetchBillingReports,
    fetchBillingRules,
    fetchInvoices, synchroniseSalesFlare
} from "../../../services";
import Table from "../../../Elements/Table/Table";
import {PencilIcon, PlusIcon} from "react-octicons";
import {Link} from "react-router-dom";
import Field from "../../../Elements/Field";
import FieldMultiple from '../../../Elements/FieldMultiple';
import BillingRulesRoute from "../../BillingRule/BillingRules/BillingRulesRoute";
import InvoicesTable from "../../Invoice/Invoices/InvoicesTable";
import BillingCreditsListRoute from "../../BillingCredits/BillingCreditsList/BillingCreditsListRoute";
import LinkToOverlay from "../../../Elements/Overlay/LinkToOverlay";
import {tableColumns as billingEventsBillableTableColumns, transformItem as billingEventsBillableTransformItem} from "../../BillingEvent/BillingEventsBillable/BillingEventsBillableTable";
import {tableColumns as billingReportsTableColumns, transformItem as billingReportsTableTransformItem} from "../../BillingReports/BillingReports/BillingReportsRoute";
import moment from "moment";
import {Refresh} from "@material-ui/icons";
import Loader from "../../../Elements/Loader";

const BillingSender = ({billingSender, triggerRefresh}) => {
    const [isUpdating, setIsUpdating] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    function updateAccount(id) {
        setIsUpdating(true)
        synchroniseSalesFlare(id).then(res => {
            setIsUpdating(false);
            triggerRefresh(`billingSender:${billingSender.id}`);
        }).catch(error => {
            setErrorMessage(error)
            setIsUpdating(false);
        });
    }

    return (
        <Fragment>

            <Row>
                <Col>
                    <h2 className="mb-2">
                        {billingSender.name} <LinkToOverlay
                        overlay={`/billing/senders/${billingSender.id}/edit`}><PencilIcon/></LinkToOverlay>
                    </h2>
                    {billingSender.isSalesFlareActive && (
                        <div className="mb-3 d-flex align-items-baseline">
                            <Button
                                onClick={() => updateAccount(billingSender.id)}
                                color='success'
                            >
                                Synchronise SalesFlare <Refresh/>
                            </Button>
                            {isUpdating && (
                                <div className="ml-1" style={{width: '100px'}}><Loader color="danger"/></div>
                            )}
                            {errorMessage && (
                                <div className="ml-2 alert alert-danger alert-dismissible">
                                    {errorMessage}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span onClick={() => setErrorMessage('')} aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </Col>
            </Row>

            <Row>
                <Col>
                    <Field name='Billable from'>{new Date(billingSender.billableFrom).toLocaleDateString()}</Field>
                    <Field name='Billable types prepaid'>{billingSender.prepaidBillingEventTypes.join(", ")}</Field>
                    <Field
                        name='Billable subtypes prepaid'>{billingSender.prepaidBillingEventSubtypes.join(", ")}</Field>
                    <Field name='Billable types postpaid'>{billingSender.billingEventTypes.join(", ")}</Field>
                    <Field name='Billable subtypes postpaid'>{billingSender.billingEventSubtypes.join(", ")}</Field>
                    <Field
                        name='Billable types monthly plan'>{billingSender.monthlyPlanBillingEventTypes.join(", ")}</Field>
                    <Field
                        name='Billable subtypes monthly plan'>{billingSender.monthlyPlanBillingEventSubtypes.join(", ")}</Field>
                    <Field name='PO number'>{billingSender.poNumber}</Field>
                    <Field name='Email addresses'>{billingSender.contactEmailAddresses.join(', ')}</Field>
                    <Field name='Senders'>
                        {
                            billingSender.senders.map((sender, index) => (
                                <Fragment key={index}>
                                    {!!index && ', '}
                                    <Link to={'/senders/' + sender.id}>{sender.name}</Link>
                                </Fragment>
                            ))
                        }
                    </Field>
                </Col>
                <Col>
                    <Field name='Portal logo'>{billingSender.portalLogo}</Field>
                    <Field name='Contract POM'>{billingSender.typeContractPOM}</Field>
                    <Field name='SLA getekend'>{billingSender.slaSigned}</Field>
                    <Field name='Data processing agreement'>{billingSender.dataProcessingAgreement}</Field>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Fetch fetchFunction={fetchBillingEvents}
                           fetchFunctionParams={{'billingSender.id': billingSender.id}}>
                        {(events) => (
                            <Table
                                extraClassName='table-sm'
                                name="Unbilled events"
                                columns={billingEventsBillableTableColumns(['billingSenderFormatted'])}
                                items={events.items.map(billingEventsBillableTransformItem)}
                            />
                        )}
                    </Fetch>
                </Col>
                <Col>
                    <Fetch fetchFunction={fetchBillingRules}
                           fetchFunctionParams={{'billingSender.id': billingSender.id}}
                           refreshKey="billingRules">
                        {(rules) => (
                            <Table
                                extraClassName='table-sm'
                                name="Billing rules"
                                columns={BillingRulesRoute.tableColumns(['billingSender'], false)}
                                items={rules.items.map(item => BillingRulesRoute.transformItem(item))}
                            />
                        )}
                    </Fetch>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Fetch fetchFunction={fetchInvoices} fetchFunctionParams={{'billingSender.id': billingSender.id}}
                           refreshKey="invoices">
                        {(invoices) => (
                            <Table
                                extraClassName='table-sm'
                                name="Invoices"
                                columns={InvoicesTable.tableColumns(['billingSender', 'pllayUser'], false)}
                                items={invoices.items.map(item => InvoicesTable.transformItem(item))}
                            />
                        )}
                    </Fetch>
                </Col>
                <Col>
                    <Fetch fetchFunction={fetchBillingCreditsList} fetchFunctionParams={{id: billingSender.id}}
                           refreshKey="billingCreditsList">
                        {(credits) => (
                            <Table
                                extraClassName='table-sm'
                                name="Billing credits"
                                columns={BillingCreditsListRoute.tableColumns(['billingSender'], false)}
                                items={credits.items.map(item => BillingCreditsListRoute.transformItem(item))}
                            />
                        )}
                    </Fetch>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Fetch fetchFunction={fetchBillingReports}
                           fetchFunctionParams={{'billingSender.id': billingSender.id}}
                           refreshKey="billingReports">
                        {(billingReports) => (
                            <Table
                                extraClassName='table-sm'
                                name="Reports"
                                columns={billingReportsTableColumns(['billingSenderName'], false)}
                                items={billingReports.items.map(billingReportsTableTransformItem)}
                            />
                        )}
                    </Fetch>
                </Col>
                <Col>
                    <div>
                        <FieldMultiple name={<span>
            <span>Contacts:</span>
            <LinkToOverlay overlay={`/billing/senders/${billingSender.id}/contact/add`}
            ><PlusIcon/></LinkToOverlay></span>} values={billingSender.billingContacts}>
                            {billingContact => {
                                return (
                                    <div>
                                    <span>
                                         <span>{billingContact.email} :</span>
                                         <LinkToOverlay
                                             overlay={`/billing/senders/${billingSender.id}/contact/${billingContact.id}/edit`}><PencilIcon/></LinkToOverlay>&nbsp;
                                    </span>
                                        <Field name='firstName' inline>{billingContact.firstName}</Field>
                                        <Field name='last name' inline>{billingContact.lastName}</Field>
                                        <Field name='email' inline>{billingContact.email}</Field>
                                        <Field name='lannguage' inline>{billingContact.language}</Field>
                                        <Field name='Get Billing Report'
                                               inline>{billingContact.getBillingReport ? 'Yes' : 'No'}</Field>
                                        <Field name='contact Point Type'
                                               inline>{billingContact.contactPointType}</Field>
                                        <Field name='sectorType' inline>{billingContact.sectorType}</Field>
                                    </div>
                                );
                            }}
                        </FieldMultiple>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Table
                        extraClassName='table-sm'
                        name="History"
                        columns={{
                            dateCreated: 'Date',
                            userName: 'User',
                            fieldName: 'Field',
                            previousValue: 'From',
                            newValue: 'Too',
                        }}
                        items={billingSender.history.map(item => ({
                            ...item,
                            dateCreated: moment(item.dateCreated).format('DD/MM/YYYY HH:mm:ss'),
                        }))}
                    />
                </Col>
            </Row>

        </Fragment>
    );
}

export default withRefresh(BillingSender);

