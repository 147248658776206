import React from 'react';
import FormElementWrapper from './FormElementWrapper';
import './Form.css';

const Form = ({formElements, search, onChange, onSubmit, onReset}) => {
    const formElementsHtml = Object.keys(formElements).map(key => {
        return <div style={formElements[key].wrapperStyle} className={`form-group mb-3 pom-form-element ${formElements[key].type === 'datetime-local' ? 'form-group-date-time' : 'mr-3'}`} key={key}>
            <FormElementWrapper tabIndex="1" searchKey={key} search={search} onChange={onChange} {...formElements[key]}/>
        </div>;
    });


    return (
        <form className="form-inline align-items-start" onSubmit={event => {
            event.preventDefault();
            onSubmit();
        }}>
            {formElementsHtml}
            <button type="submit" className="mb-3 mr-3 btn btn-success">Filter</button>
            <button type="submit" className="mb-3 mr-3 btn btn-secondary" onClick={event => {
                event.preventDefault();
                onReset();
            }}>Reset</button>
        </form>
    );
};

export default Form;