import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FormGroupRowText from "../../../Elements/FormGroupRowText";
import FormWithActions from "../../../Elements/FormWithActions/FormWithActions";
import FormGroupRowTextList from "../../../Elements/FormGroupRowTextList";
import {validEmail} from "../../../services";

class BillingResellerForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: (this.props.billingReseller && this.props.billingReseller.name) ? this.props.billingReseller.name : '',
            externalClientId: (this.props.billingReseller && this.props.billingReseller.externalClientId) ? this.props.billingReseller.externalClientId : '',
            contactEmailAddresses: (this.props.billingReseller && this.props.billingReseller.contactEmailAddresses) ? this.props.billingReseller.contactEmailAddresses : [],
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleAction(action) {
        return this.props.action(action, this.state);
    }

    handleChange(attribute) {
        return (value) => {
            this.setState({
                [attribute]: value,
            });
        };
    }

    render() {

        let disableSubmitReason = '';
        if (!this.state.name) {
            disableSubmitReason = 'Name is required.';
        }
        if (this.state.contactEmailAddresses.some(email => email && !validEmail(email))) {
            disableSubmitReason = 'All emails need to be valid.';
        }

        return (
            <FormWithActions actions={this.props.actions} onAction={this.handleAction} disableSubmitReason={disableSubmitReason}>
                <FormGroupRowText
                    label="Billing reseller name"
                    value={this.state.name}
                    onChange={this.handleChange('name')}
                />

                <FormGroupRowText
                    label="Eenvoudig Factureren client id"
                    value={this.state.externalClientId}
                    onChange={this.handleChange('externalClientId')}
                />

                <FormGroupRowTextList
                    label="Contact email addresses"
                    value={this.state.contactEmailAddresses}
                    onChange={this.handleChange('contactEmailAddresses')}
                />

            </FormWithActions>
        );
    }
}

BillingResellerForm.propTypes = {
    action: PropTypes.func.isRequired,
    actions: PropTypes.array,

    billingReseller: PropTypes.shape({
        name: PropTypes.string.isRequired,
        externalClientId: PropTypes.string,
    }),
};

BillingResellerForm.defaultProps = {
    actions: [],
};

export default BillingResellerForm;