import React from 'react';
import {Route, Switch} from "react-router-dom";
import BillingResellersRoute from "./BillingResellers/BillingResellersRoute";
import BillingResellerAddRoute from "./BillingResellerAdd/BillingResellerAddRoute";
import BillingResellerRoute from "./BillingReseller/BillingResellerRoute";
import BillingResellerEditRoute from "./BillingResellerEdit/BillingResellerEditRoute";

const BillingResellerRoutes = () => {
    return (
        <Switch>
            <Route exact path="/billing/resellers" component={BillingResellersRoute}/>
            <Route exact path="/billing/resellers/add" component={BillingResellerAddRoute}/>
            <Route exact path="/billing/resellers/:id" component={BillingResellerRoute}/>
            <Route exact path="/billing/resellers/:id/edit" component={BillingResellerEditRoute}/>
        </Switch>
    );
};

export default BillingResellerRoutes;