import styles from "./ClickToCopy.module.css";
import React, {useEffect, useState} from "react";
import {Check, FileCopyOutlined} from "@material-ui/icons";

type Props = {
    children: any;
    value: any;
};

const ClickToCopy = ({
                         children,
                         value
                     }: Props) => {
    const [copied, setCopied] = useState(false);

    useEffect(
        () => {
            if (copied) {
                setTimeout(() => {
                    setCopied(false);
                }, 1200);
            }
        },
        [copied]
    );
    return (
        <div className={styles.link}>
            {children && <div>{children}</div>}
            <FileCopyOutlined onClick={() => {
                setCopied(true)
                navigator.clipboard.writeText(value);
            }} className={styles.copy}/>

            {copied && <Check className={styles.copied}/>}

        </div>
    );
};

export default ClickToCopy;