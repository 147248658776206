import React from 'react';
import PropTypes from 'prop-types';
import {fetchSender} from "../../services";
import Fetch from "../../Elements/Fetch";
import SenderMerchantEditForm from "./SenderMerchantEditForm";

const SenderMerchantEditRoute = ({match, history, onSubmit}) => (
    <Fetch fetchFunction={fetchSender} fetchFunctionParams={match.params.id}>
        {sender => {

            const merchant = sender.paymentMethods.find(item => item.merchantId.toString() === match.params.merchantId);
            if (!merchant) {
                return <div className="alert alert-danger">404: Not Found</div>;
            }

            const handleSubmit = onSubmit || (() => history.push('/senders/' + sender.id));

            return (
                <div style={{maxWidth: 800}}>
                    <SenderMerchantEditForm
                        merchant={merchant}
                        senderId={sender.id}
                        onSubmit={handleSubmit}
                    />
                </div>
            );
        }}
    </Fetch>
);

SenderMerchantEditRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default SenderMerchantEditRoute;