import React, {useState} from 'react';
import {runScript} from "../../services";
import useSubmittingButton from "../../hooks/useSubmittingButton";
import {Col, Row} from "reactstrap";
import FormGroupRowText from "../../Elements/FormGroupRowText";

const DocRefCleanup = () => {
    const [taskId, setTaskId] = useState('');
    const [result, setResult] = useState(null);
    const {errorMessage, SubmittingButton} = useSubmittingButton();

    const handleSubmit = async () => {
        const confirmed = window.confirm('Are you sure you want do clean up this task?');
        if (confirmed) {
            const response = await runScript('DocRefCleanup', {
                taskId: taskId
            });
            setResult(await response.json());
            if (response.status !== 200) {
                throw new Error(`${response.status}: ${response.statusText}`);
            }
        }
    };

    const disabled = !taskId;
    return (
        <>
            <h4>DocRef Cleanup</h4>

            {errorMessage}

            <div style={{maxWidth: 600}}>

                <FormGroupRowText
                    label="TaskId"
                    value={taskId}
                    onChange={setTaskId}
                />


                <Row form className="align-items-center">
                    <Col xs="auto">
                        <SubmittingButton onClick={handleSubmit} disabled={disabled}>Submit</SubmittingButton>
                    </Col>
                </Row>
                <GetResults result={result}/>
            </div>
        </>
    );
};

const GetResults = ({result}) => {
    if (!result) return null;

    return (
        <div style={{width: '150%'}}>
            <pre>{JSON.stringify(result, null, 2)}</pre>
        </div>

    );
};


export default DocRefCleanup;
