import React, {Component} from "react";
import PropTypes from 'prop-types';
import Loader from "./Loader";
import classnames from 'classnames';
import hoistNonReactStatic from "hoist-non-react-statics";

export const RefreshContext = React.createContext();

export function withRefreshContainer(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);

            this.triggerRefresh = this.triggerRefresh.bind(this);

            this.state = {
                refreshMap: {}, //keep a map of keys and integers. When integer increases, children that have the key setup, must refresh
                triggerRefresh: this.triggerRefresh,
                silentRefresh: false,
            };
        }

        triggerRefresh(refreshKeys, silentRefresh = false) {
            if (!Array.isArray(refreshKeys)) {
                refreshKeys = [refreshKeys];
            }

            this.setState(state => ({
                ...state,
                silentRefresh: silentRefresh,
                refreshMap: {
                    ...state.refreshMap,
                    ...refreshKeys.reduce((acc, curr) => {
                        acc[curr] = (state.refreshMap[curr] || 0) + 1;
                        return acc;
                    }, {}),
                },
            }));
        }

        render() {
            return (
                <RefreshContext.Provider value={this.state}>
                    <WrappedComponent {...this.props}/>
                </RefreshContext.Provider>
            );
        }
    };
}

export const withRefresh = WrappedComponent => {
    function Enhance(props) {
        return (
            <RefreshContext.Consumer>
                {({triggerRefresh, refreshMap, silentRefresh}) => (
                    <WrappedComponent triggerRefresh={triggerRefresh} refreshMap={refreshMap} silentRefresh={silentRefresh} {...props}/>
                )}
            </RefreshContext.Consumer>
        );
    }
    hoistNonReactStatic(Enhance, WrappedComponent);
    return Enhance;
};

class Fetch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isCommunicatingWithServer: true,
            data: null,
            error: null,

            refreshVersion: props.refreshMap[props.refreshKey], //Keep track of current 'version'. The refreshContainer will increase this number when we need to refresh
        };

        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this._getDataFromServer();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.refreshKey !== this.props.refreshKey) {
            this.setState({
                refreshVersion: this.props.refreshMap[this.props.refreshKey],
            }, this.refresh);

            return;
        }

        if (this.props.refreshMap[this.props.refreshKey] !== this.state.refreshVersion) {
            this.setState({
                refreshVersion: this.props.refreshMap[this.props.refreshKey],
            }, this.refresh);
        }

        if (JSON.stringify(prevProps.fetchFunctionParams) !== JSON.stringify(this.props.fetchFunctionParams)) {
            this.refresh();
        }
    }

    refresh() {
        if (!this.props.silentRefresh) {
            this.setState({
                isCommunicatingWithServer: true,
            });
        }

        this._getDataFromServer();
    }

    _getDataFromServer() {
        this.props.fetchFunction(this.props.fetchFunctionParams)
            .then(data => {
                this.setState({
                    isCommunicatingWithServer: false,
                    data: data,
                    error: null,
                });
            })
            .catch(errorMessage => {
                this.setState({
                    isCommunicatingWithServer: false,
                    data: null,
                    error: String(errorMessage),
                });
            });
    }

    render() {
        if (this.state.isCommunicatingWithServer) {
            return (
                <div className={classnames('container', 'h-100', this.props.className)} style={this.props.style}>
                    <div className="row h-100">
                        <div className="col">
                            <div className="d-flex flex-column justify-content-center h-100">
                                <Loader/>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.error) {
            return <div className="alert alert-danger">{this.state.error}</div>;
        }

        return this.props.children(this.state.data);
    }
}

Fetch.propTypes = {
    fetchFunction: PropTypes.func.isRequired,
    fetchFunctionParams: PropTypes.any,
    children: PropTypes.func.isRequired,
    refreshKey: PropTypes.string,
};

Fetch.defaultProps = {
    fetchFunctionParams: null,
};

export default withRefresh(Fetch);