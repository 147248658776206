import React, {useContext, useState} from 'react';
import {fetchSendersList, runScript} from "../../services";
import Fetch from "../../Elements/Fetch";
import FormGroupRowSender from "../../Elements/FormGroupRowSender";
import FormGroupRowDate from "../../Elements/FormGroupRowDate";
import useSubmittingButton from "../../hooks/useSubmittingButton";
import {Col, Row} from "reactstrap";
import {ModalContext} from "../../Modal/ModalProvider";

const DocumentsOverviewReport = () => {

    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [senders, setSenders] = useState([]);

    const {errorMessage, loader, SubmittingButton} = useSubmittingButton();
    const {showModal} = useContext(ModalContext);

    const handleSubmit = async () => {
        const response = await runScript('DocumentsOverviewReport', {
            dateFrom: dateFrom,
            dateTo: dateTo,
            senderIds: senders,
        });

        if (response.status === 200) {
            const data = await response.json();

            showModal({
                type: 'download',
                exportId: data.id,
            });
        }
        else {
            throw new Error(`${response.status}: ${response.statusText}. Contact a site administrator. This error probably won't go away by itself.`);
        }
    };

    const disabled = !dateFrom || !dateTo || senders.length === 0;

    return (
        <>
            <h4>Documents Overview Report</h4>

            {errorMessage}

            <form style={{maxWidth: 600}}>
                <FormGroupRowDate
                    label="Date from"
                    value={dateFrom}
                    onChange={setDateFrom}
                    description="This date is included in the export."
                />

                <FormGroupRowDate
                    label="Date to"
                    value={dateTo}
                    onChange={setDateTo}
                    description="This date is included in the export."
                />

                <Fetch fetchFunction={fetchSendersList} className="form-group">
                    {senderList => (
                        <FormGroupRowSender
                            label="Sender"
                            value={senders}
                            onChange={setSenders}
                            senders={senderList}
                            multi
                        />
                    )}
                </Fetch>


                <Row form className="align-items-center">
                    <Col xs="auto">
                        <SubmittingButton onClick={handleSubmit} disabled={disabled}>Submit</SubmittingButton>
                    </Col>
                    <Col xs="auto">
                        {loader}
                    </Col>
                </Row>
            </form>
        </>
    );
};

export default DocumentsOverviewReport;
