import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {UncontrolledTooltip} from "reactstrap";

class PomTooltip extends Component {

    static count = 0;
    static counter(){
        return PomTooltip.count++;
    }

    constructor(props){
        super(props);

        this.state = {
            id: 'pom-tooltip-' + PomTooltip.counter(),
        };
    }

    render(){

        const {children, tooltip, placement = 'top', delay = 0, ...restOfProps} = this.props;

        if (!tooltip) {
            return children;
        }

        return (
            <Fragment>
                <span id={this.state.id}>
                    <span style={{pointerEvents: 'none'}}>{children}</span>
                </span>
                <UncontrolledTooltip {...restOfProps} placement={placement} delay={delay} target={this.state.id} autohide={false}>
                    <span style={{whiteSpace: 'pre-line'}}>{tooltip}</span>
                </UncontrolledTooltip>
            </Fragment>
        );
    }
}

PomTooltip.propTypes = {
    tooltip: PropTypes.node,
    children: PropTypes.node.isRequired,
};

export default PomTooltip;