import React from 'react';
import PropTypes from 'prop-types';
import {fetchBillingSender} from "../../../services";
import Fetch from "../../../Elements/Fetch";
import BillingContactEditForm from "./BillingContactEditForm";

const BillingContactEditRoute = ({match, history, onSubmit}) => (
    <Fetch fetchFunction={fetchBillingSender} fetchFunctionParams={match.params.id}>
        {billingSender => {
            const billingContact = billingSender.billingContacts.find(item => item.id.toString() === match.params.contactId);
            if (!billingContact) {
                return <div className="alert alert-danger">404: Not Found</div>;
            }

            const handleSubmit = onSubmit || (() => history.push('/billing/senders/' + billingSender.id));

            return (
                <div style={{maxWidth: 800}}>
                    <BillingContactEditForm
                        billingContact={billingContact}
                        billingSenderId={billingSender.id}
                        onSubmit={handleSubmit}
                    />
                </div>
            );
        }}
    </Fetch>
);

BillingContactEditRoute.propTypes = {
    onSubmit: PropTypes.func,
};


export default BillingContactEditRoute;