import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Refresh.css';

const Refresh = ({spinning, className, ...props}) => (
    <span className={classNames('refresh-icon', className, {spinning: spinning})} {...props}/>
);

Refresh.propTypes = {
    onClick: PropTypes.func,
    spinning: PropTypes.bool,
};

export default Refresh;