export type PartnerSetting = { category: string, name: string, type: 'string' | 'number' | 'multi-list' | 'boolean', value: string | number | boolean | string[], label?: string, options?: string[], isEditable?: boolean, isVisible?: boolean, isRequired?: boolean, secondaryName?: string, };

export const allPartnerSettings: PartnerSetting[] = [
    {category: 'paylink', name: 'usePaypage', type: 'boolean', isEditable: true, value: true},
    {category: 'paylink', name: 'paypage.background.image.url', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.logo.url', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'custom.template', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'availableAmounts', type: 'number', isEditable: true, value: 0},
    {category: 'paylink', name: 'language.switcher.disabled', type: 'boolean', isEditable: true, value: true},
    {category: 'paylink', name: 'paypage.warning.nl', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.warning.fr', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.warning.en', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'maxExpiryDateDays', type: 'number', isEditable: true, value: 0},
    {category: 'paylink', name: 'ignore.expiry.date', type: 'boolean', isEditable: true, value: true},
    {category: 'paylink', name: 'shortlink.enabled', type: 'boolean', isEditable: true, value: true},
    {category: 'paylink', name: 'allow.empty.paymentMethod', type: 'boolean', isEditable: true, value: true},
    {category: 'paylink', name: 'allow.empty.email', type: 'boolean', isEditable: true, value: true},
    {category: 'paylink', name: 'allow.duedate.in.the.past', type: 'boolean', isEditable: true, value: true},
    {category: 'paylink', name: 'allow.title.more.than.128.characters', type: 'boolean', isEditable: true, value: true},
    {category: 'paylink', name: 'successCallbackUrl', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'successCallbackSynchronously', type: 'boolean', isEditable: true, value: true},
    {category: 'paylink', name: 'successCallbackUrl.includeIban', type: 'boolean', isEditable: true, value: true},
    {category: 'paylinkFrontend', name: 'footer.nl', type: 'string', isEditable: true, value: ''},
    {category: 'paylinkFrontend', name: 'footer.fr', type: 'string', isEditable: true, value: ''},
    {category: 'paylinkFrontend', name: 'footer.en', type: 'string', isEditable: true, value: ''},
    {category: 'paylinkFrontend', name: 'template.dsc.certificate', type: 'string', isEditable: true, value: ''},
    {category: 'senderOnboarding', name: 'amount', type: 'number', isEditable: true, value: 0},

    {category: 'sender.customview', name: 'redirectUrl.nl', type: 'string', isEditable: true, value: ''},
    {category: 'sender.customview', name: 'redirectUrl.fr', type: 'string', isEditable: true, value: ''},
    {category: 'sender.customview', name: 'redirectUrl.en', type: 'string', isEditable: true, value: ''},
    {category: 'sender.customview', name: 'redirectUrl.de', type: 'string', isEditable: true, value: ''},
    {category: 'sender.customview', name: 'redirectUrl', type: 'string', isEditable: true, value: ''},
    {category: 'sender.customview', name: 'redirectUrlExcludeDocumentId', type: 'boolean', isEditable: true, value: true},
    {category: 'sender.customview', name: 'redirectUrl.include.senderDocumentId', type: 'boolean', isEditable: true, value: true},
    {category: 'sender.customview', name: 'return.url', type: 'string', isEditable: true, value: ''},
    {category: 'sender.customview', name: 'errorpage', type: 'string', isEditable: true, value: ''},
    {category: 'sender.customview', name: 'transactionstatus', type: 'string', isEditable: true, value: ''},
    {
        category: 'paylink',
        name: 'available.channels',
        type: 'multi-list',
        isEditable: true, value: [],
        options: ['SMS', 'QR', 'WEB', 'MAIL', 'SHARE', 'APP', 'E-QR', 'PAPIER']
    },

    {
        category: 'paylink',
        name: 'paypage.optin.channels',
        type: 'multi-list', isEditable: true, value: [],
        options: ['SMS', 'QR', 'WEB', 'MAIL', 'SHARE', 'APP', 'E-QR']
    },

    {
        category: 'paylink',
        name: 'shortlink.channels',
        type: 'multi-list',
        isEditable: true, value: [],
        options: ['QR', 'SMS', 'SHARE']
    },
    {category: 'paylink', name: 'short.url.service', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'documents.qrscan.not.editable', type: 'boolean', isEditable: true, value: true},
    {category: 'paylink', name: 'paypage.optin.verifyEmail', type: 'boolean', isEditable: true, value: true},
    {category: 'sender.optin', name: 'appendAddress', type: 'boolean', isEditable: true, value: true},
    {category: 'sender.optin', name: 'contractNumberType', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'upgrade.optin.email.fallback', type: 'boolean', isEditable: true, value: true},
    {category: 'paylink', name: 'paypage.optin.delay', type: 'number', isEditable: true, value: 0},
    {category: 'paylink', name: 'paypage.optin.delay.units', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.optinDescriptionOverride.nl', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.optinDescriptionOverride.fr', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.optinDescriptionOverride.en', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.email.asked.always', type: 'string', isEditable: true, value: ''},
    {
        category: 'paylink',
        name: 'paypage.editableFields',
        type: 'multi-list',
        isEditable: true, value: [],
        options: ['amount', 'communication']
    },
    {category: 'sender.delivery', name: 'documents.editable', type: 'boolean', isEditable: true, value: true},
    {category: 'paylink', name: 'allow.amount.zero.for.contribution', type: 'boolean', isEditable: true, value: true},
    {category: 'paylink', name: 'allow.multiple.payments', type: 'boolean', isEditable: true, value: true},
    {
        category: 'paylink', name: 'paypage.paymentOptions', type: 'multi-list',
        isEditable: true, value: [],
        options: ['pay-directly', 'pay-once', 'pay-once-bc', 'pay-recurring', 'sign- mandate', 'sign-mandate-buckaroo', 'wire-transfer', 'wire-transfer-buckaroo']
    },
    {
        category: 'paylink',
        name: 'pay-directly.paymentMethods',
        type: 'multi-list',
        isEditable: true, value: [],
        options: ['applepay', 'bancontactOCP', 'bancontact', 'maestro', 'visa', 'mastercard', 'cartebleuevisa', 'cartebancaire', 'ideal', 'payconiq', 'digicash', 'klarna', 'dummy-COMPLETED', 'dummy-REFUSED']
    },
    {category: 'sender.delivery', label: 'Payment confirmation', name: 'maildelivery.klarna.template', type: 'string', isEditable: true, value: 'DirectPaymentPaymentConfirmation'},
    {category: 'sender.delivery', name: 'maildelivery.klarna.template.includeSsn', type: 'boolean', isEditable: true, value: true},
    {category: 'sender.delivery', name: 'maildelivery.twikey.template.includeSsn', type: 'boolean', isEditable: true, value: true},
    {category: 'sender.delivery', name: 'maildelivery.reminderTemplate.includeSsn', type: 'boolean', isEditable: true, value: true},
    {category: 'sender.delivery', name: 'maildelivery.wireTransfer.bcc.address', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'maildelivery.twikey.template', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'maildelivery.twikey_reminder.template', type: 'string', isEditable: true, value: ''},
    {category: 'contactDetails', name: 'contact.url.nl', type: 'string', isEditable: true, value: ''},
    {category: 'contactDetails', name: 'contact.url.fr', type: 'string', isEditable: true, value: ''},
    {category: 'contactDetails', name: 'contact.url.en', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'includeCustomerIdForTechnicalUser', type: 'boolean', isEditable: true, value: true},
    {category: 'paylink', name: 'allow.directDebitNote', type: 'boolean', isEditable: true, value: true},
    {category: 'paylink', name: 'automatic.paylink.delivery.documentTypes', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.payrecurring.download.url.nl', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.payrecurring.download.url.en', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.payrecurring.download.url.fr', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.payrecurring.description.nl', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.payrecurring.description.en', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.payrecurring.description.fr', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.sdd.twikey.apiKey', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.sdd.twikey.contractTemplate', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.sdd.creditorNumber', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.sdd.mandatePurpose.nl', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.sdd.mandatePurpose.en', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.sdd.mandatePurpose.fr', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.sdd.twikey.privateKey', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.sdd.twikey.includeAmount', type: 'boolean', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.banner.link.url.nl', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.banner.link.url.en', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.banner.link.url.fr', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.banner.url.nl', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.banner.url.en', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.banner.url.fr', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.promo.banner.url.nl', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'paypage.promo.banner.url.mobile.nl', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'documents.editable', type: 'boolean', isEditable: true, value: true},
    {category: 'sender.delivery', name: 'documents.removable', type: 'boolean', isEditable: true, value: true},
    {category: 'sender.document', name: 'sensitive', type: 'boolean', isEditable: true, value: true},
    {category: 'sender.delivery', name: 'maildelivery', type: 'boolean', isEditable: true, value: true},
    {category: 'sender.delivery', name: 'smsdelivery', type: 'boolean', isEditable: true, value: true},
    {category: 'sender.delivery', name: 'sms.delivery.Invoice.nl', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'sms.delivery.Invoice.en', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'sms.delivery.Invoice.fr', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'sms.delivery.Reminder.nl', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'sms.delivery.Reminder.en', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'sms.delivery.Reminder.fr', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'sms.delivery.ringring.apiKey', type: 'string', isEditable: true, value: ''},
    {category: 'senderApi', name: 'docrefParser', type: 'string', isEditable: true, value: ''},
    {category: 'senderApi', name: 'docrefBatchProcessor', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'email.fallback.custom.workflow', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'appdelivery.disabled', type: 'boolean', isEditable: true, value: true},
    {category: 'sender.delivery', name: 'documentPrivateUrlType', type: 'string', isEditable: true, value: ''},
    {
        category: 'sender.delivery',
        name: 'maildelivery.replyto.address',
        type: 'string',
        isEditable: true, value: ''
    },
    {category: 'sender.delivery', name: 'maildelivery.bcc.address', type: 'string', isEditable: true, value: 'pieter.vangeel@pom.be'},
    {
        category: 'sender.delivery',
        name: 'maildelivery.custom.template',
        type: 'string',
        isEditable: true, value: ''
    },
    {
        category: 'sender.delivery',
        name: 'maildelivery.from.address',
        type: 'string',
        isEditable: true, value: 'Az Damiaan" <factuurinfo@azdamiaan.be>'
    },
    {
        category: 'sender.delivery',
        name: 'maildelivery.returnpath.address',
        type: 'string',
        isEditable: true, value: 'factuurinfo@azdamiaan.be'
    },
    {category: 'sender.delivery', name: 'maildelivery.custom.subject.nl', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'maildelivery.custom.subject.en', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'maildelivery.custom.subject.fr', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'maildelivery.predefined.locale', type: 'string', isEditable: true, value: 'nl'},
    {category: 'sender.delivery', name: 'maildelivery.promo.text.nl', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'maildelivery.promo.img', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'maildelivery.promo.banner', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'maildelivery.promo.bannerUrl', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'paymentconfirmation.promo.banner', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'paymentconfirmation.promo.bannerUrl', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'maildelivery.custom.intro.nl', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'maildelivery.custom.intro.en', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'maildelivery.custom.intro.fr', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'maildelivery.custom.body1.nl', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'maildelivery.custom.body1.en', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'maildelivery.custom.body1.fr', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'maildelivery.custom.body2.nl', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'maildelivery.custom.body2.en', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'maildelivery.custom.body2.fr', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'maildelivery.custom.greeting.nl', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'maildelivery.custom.greeting.en', type: 'string', isEditable: true, value: ''},
    {category: 'sender.delivery', name: 'maildelivery.custom.greeting.fr', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'smsCampaign.ringring.apiKey', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'smsCampaign.template', type: 'string', isEditable: true, value: ''},
    {category: 'paylink', name: 'smsCampaign.amount', type: 'number', isEditable: true, value: ''},
    {category: 'paylink', name: 'smsCampaign.documentId', type: 'number', isEditable: true, value: ''},
    {category: 'paylink', name: 'smsCampaign.generate.ogm', type: 'boolean', isEditable: true, value: true},
    {category: 'paylink', name: 'smsCampaign.title', type: 'number', isEditable: true, value: ''},
    {category: 'businessApp', name: 'communicationRequired', type: 'boolean', isEditable: true, value: true},
    {category: 'businessApp', name: 'communicationType', type: 'string', isEditable: true, value: ''},
    {category: 'businessApp', name: 'communicationTypeDisable', type: 'string', isEditable: true, value: ''},
    {category: 'businessApp', name: 'paymentMethod', type: 'string', isEditable: true, value: 'buckaroo'},
    {category: 'businessApp', name: 'communicationValidationRegex', type: 'string', isEditable: true, value: '^1[0-9]{7}$'},
    {
        category: 'businessApp',
        name: 'communicationUnstructuredDescription',
        type: 'string',
        isEditable: true, value: 'Geef een mededeling in die begint met \'1\' en gevolgd wordt door 7 cijfers.'
    },
    {category: 'businessApp', name: 'communicationUnstructuredKeyboardType', type: 'string', isEditable: true, value: 'number-pad'},
    {category: 'businessApp', name: 'shareMessage.nl', type: 'string', isEditable: true, value: ''},
    {category: 'businessApp', name: 'shareMessage.en', type: 'string', isEditable: true, value: ''},
    {category: 'businessApp', name: 'shareMessage.fr', type: 'string', isEditable: true, value: ''},
    {category: 'businessApp', name: 'createLinkDefaultEmail', type: 'string', isEditable: true, value: ''},
    {category: 'businessApp', name: 'enabledFeatures', type: 'boolean', isEditable: true, value: true},
    {category: 'businessApp', name: 'defaultSmsCountry', type: 'string', isEditable: true, value: ''},
    {category: 'businessAppBackend', name: 'smsTemplate', type: 'string', isEditable: true, value: ''},
    {category: 'businessAppBackend', name: 'ringring.apiKey', type: 'string', isEditable: true, value: ''},
    {category: 'businessApp', name: 'senderSCN', type: 'string', isEditable: true, value: ''},
    {
        category: 'invoicing',
        name: 'contact.email',
        type: 'string',
        isEditable: true, value: 'Het Pommetje <pieter.vangeel@gmail.com>'
    },
    {category: 'contactDetails', name: 'email', type: 'string', isEditable: true, value: ''},
    {category: 'contactDetails', name: 'contactPerson', type: 'string', isEditable: true, value: ''},
    {category: 'contactDetails', name: 'phoneNumber', type: 'string', isEditable: true, value: ''},
];
