import React from 'react';

import Field from '../Elements/Field';
import Table from '../Elements/Table/Table';
import DocumentsTable from '../Documents/DocumentsTable';
import {CheckIcon, XIcon, PencilIcon} from 'react-octicons';
import TransactionsTable from "../Transactions/TransactionsTable";
import LinkToOverlay from "../Elements/Overlay/LinkToOverlay";
import {Role} from "../Auth/BackOfficeUserRole";
import Can from "../Auth/Can";

const User = ({
                  id,
                  name,
                  idCardGender,
                  address,
                  email,
                  language,
                  externalStorage,
                  facebookId,
                  nbDocuments,
                  partnerSettings,
                  ibans,
                  sessions,
                  verifiedEmailaddress,
                  connections,
                  paymentMethods,
                  roles,
              }) => {

    const partnerSettingsTitle = (
        <span>
            <span>Partner Settings: </span>
            <Can roles={[Role.WRITE]}>
               <LinkToOverlay
                   overlay={`/users/${id}/edit-partner-settings`}
               >
                   <PencilIcon/>
               </LinkToOverlay>
            </Can>
        </span>
    );


    const rolesTitle = (
        <span>
            <span>Roles: </span>
            <Can roles={[Role.WRITE]}>
               <LinkToOverlay
                   overlay={`/users/${id}/edit-roles`}
               >
                   <PencilIcon/>
               </LinkToOverlay>
            </Can>
        </span>
    );

    return (
        <>

            <div className="form-row">
                <div className="col-auto">
                    <h1 className="mb-5">
                        User: {name}
                    </h1>
                </div>
                <div className="col" style={{marginTop: '12px'}}>
                    <LinkToOverlay overlay={'/users/' + id + '/edit'}><PencilIcon/></LinkToOverlay>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Field name='User Id'>{id}</Field>
                    <Field name='Name'>{name}</Field>
                    <Field name='Gender'>{idCardGender}</Field>
                    <Field name='Address'>{address}</Field>
                    <Field name='E-mail'>
                        <span className="align-middle">{email}</span>
                        {verifiedEmailaddress && <CheckIcon className="align-middle ml-1" style={{fill: 'green'}}/>}
                        {!verifiedEmailaddress && <XIcon className="align-middle ml-1" style={{fill: 'red'}}/>}
                    </Field>
                    <Field name='Language'>{language}</Field>
                    <Field name='External storage'>{externalStorage}</Field>
                    <Field name='Facebook Id'>{facebookId}</Field>
                    <Field name='# documents'>{nbDocuments}</Field>
                </div>
                <div className="col">

                    <Table
                        name={rolesTitle}
                        columns={{
                            role: 'Role',
                        }}
                        items={roles.sort((a, b) => a.localeCompare(b)).map(role => ({key: role, role: role}))}
                        extraClassName='table-sm'
                        hideHeader
                        hideLabelColon
                    />
                    <Table
                        name={partnerSettingsTitle}
                        columns={{
                            category: 'Category',
                            name: 'Name',
                            value: 'Value',
                        }}
                        items={partnerSettings}
                        extraClassName='table-sm'
                        hideLabelColon
                    />
                    <Table
                        name='Connections'
                        columns={{
                            sender: 'Sender',
                            customerId: 'Customer Id',
                            alias: 'Alias',
                            optIn: 'Opt in',
                            lastUpdated: 'Last Updated',
                        }}
                        items={connections}
                        extraClassName='table-sm'
                    />
                    <Table
                        name='Payment methods'
                        columns={{
                            sender: 'Sender',
                            customerId: 'Customer Id',
                            name: 'Provider Name',
                            description: 'Description',

                        }}
                        items={paymentMethods}
                        extraClassName='table-sm'
                    />
                    <Table
                        name='Bank accounts'
                        columns={{
                            iban: 'IBAN',
                            verified: 'Verified',
                            mandate: 'Mandate',
                        }}
                        items={ibans}
                        extraClassName='table-sm'
                    />
                    <Table
                        expandable name='Sessions'
                        columns={{
                            platform: 'Platform',
                            application: 'Application',
                            dateCreated: 'Created',
                            expires: 'Expires',
                        }}
                        items={sessions}
                        extraClassName='table-sm'
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col">
                    <DocumentsTable
                        name="Documents"
                        itemsPerPage={10}
                        searchParams={{userId: id}}
                        hideFiltersIfEmpty
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <TransactionsTable
                        name="Transactions"
                        itemsPerPage={10}
                        searchParams={{userId: id}}
                        hideFiltersIfEmpty
                    />
                </div>
            </div>

        </>
    );
};

export default User;
