import React from 'react';
import {Button} from 'reactstrap';
import {Link} from "react-router-dom";
import {fetchEmailFallBackSubscriptions, downloadEmailFallBackSubscriptionsUrl, subscribeUnsubscribe} from '../services';

import AbstractItemsTableWithLoader from '../Tables/AbstractItemsTableWithLoader';

const EmailFallBackSubscriptionsTable = ({name, itemsPerPage, searchParams, hideFiltersIfEmpty, hideFilters}) => {

    const fetchFunction = (searchParams) => {
        return fetchEmailFallBackSubscriptions(searchParams).then(data => ({
            ...data,
            items: data.items.map(item => ({
                ...item,
                key: item.token,
                senderContractNumber: <Link to={"/senders/" + item.senderId}>{item.senderContractNumber}</Link>,
                subscribed: (
                    <div>
                        <div>{item.subscribed}</div>
                        <div  style={{marginTop: "15px"}}>
                        <Button
                            color="danger"
                            onClick={() => subscribeUnsubscribeHandle(item.token)}
                        >{(item.subscribed.toLowerCase() === 'true') ? 'unsubscribe' : 'subscribe'}</Button>
                        </div>
                    </div>
                ),
            })),
        }));
    };

    const subscribeUnsubscribeHandle= async (token) => {
        const data = await subscribeUnsubscribe(token);
        window.location.reload(false);
        return data;
    };


    const downloadUrl = downloadEmailFallBackSubscriptionsUrl();
    const tableColumns = {
        email: {
            name: 'Email',
            sort: true
        },
        senderContractNumber: {
            name: 'Sender Contract Number',
            sort: true
        },
        contractNumber:{
            name: 'Contract Number',
            sort: true
        },
        dateCreated: {
            name: 'Date Created',
            sort: true
        },
        lastUpdated: {
            name: 'Last Updated',
            sort: true
        },
        subscribed: {
            name: 'Subscribed',
            sort: true
        },

    };
    const namedSearchParameters = {
        email: {
            type: 'text',
            name: 'Email',
            operators: ['eq'],
        },
        senderContractNumber: {
            type: 'text',
            name: 'Sender Contract Number',
            operators: ['eq', 'contains'],
        },
        contractNumber:{
            type: 'text',
            name: 'Contract Number',
            operators: ['eq', 'contains'],
        },
        dateCreated: {
            type: 'date',
            name: 'Date Created',
            operators: ['eq', 'lt', 'gt', 'between'],
        },
        lastUpdated: {
            type: 'date',
            name: 'Last Updated',
            operators: ['eq', 'lt', 'gt', 'between'],
        },
        subscribed: {
            type: 'select',
            name: 'Subscribed',
            options: ['true', 'false'],
            operators: ['eq'],
        },

    };

    return <AbstractItemsTableWithLoader
        name={name}
        itemsPerPage={itemsPerPage}
        tableColumns={tableColumns}
        namedSearchParameters={namedSearchParameters}
        fetchFunction={fetchFunction}
        searchParams={searchParams}
        hideFilters={hideFilters}
        hideFiltersIfEmpty={hideFiltersIfEmpty}
        downloadUrl={downloadUrl}
        downloadUrlMax={5000}
    />;
};


export default EmailFallBackSubscriptionsTable;