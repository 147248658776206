import React from 'react';
import ManageTemplate from "./ManageTemplate";
import {fetchSenderTemplate} from "../services";
import Fetch from "../Elements/Fetch";

const EditTemplateRoute = ({match}) => (
<Fetch fetchFunction={fetchSenderTemplate} fetchFunctionParams={match.params.id}>
        {template => {
            return (
                <>
                    <h1 className="mb-5">Edit template</h1>

                    <ManageTemplate template={template}/>
                </>
            );
        }}
    </Fetch>
);

export default EditTemplateRoute;