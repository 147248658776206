import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "reactstrap";
import {addBillingReseller} from "../../../services";
import {withRefresh} from "../../../Elements/Fetch";
import BillingResellerForm from "../BillingReseller/BillingResellerForm";

class BillingResellerAddForm extends Component {

    constructor(props) {
        super(props);

        this.handleAction = this.handleAction.bind(this);
    }

    handleAction(action, state) {
        switch (action) {
            case 'submit':
                return addBillingReseller(state)
                    .then(data => {
                        this.props.triggerRefresh('billingResellers');
                        this.props.onSubmit();
                        return data;
                    });
            default:

        }
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <h1 className="mb-5">
                            Add billing reseller
                        </h1>
                    </Col>
                </Row>

                <BillingResellerForm
                    action={this.handleAction}
                />

            </Fragment>
        );
    }
}

BillingResellerAddForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default withRefresh(BillingResellerAddForm);