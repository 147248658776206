import React, {Fragment} from 'react';
import {NavLink} from "react-router-dom";

const NavBar = () => (
    <Fragment>
        <img src="/pom-logo-white.svg" className="logo" alt="logo"/>
        <ul className="nav flex-column">
            <li className="nav-item"><NavLink exact className="nav-link" to="/">Home</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/users">Users</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/documents">Documents</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/transactions">Transactions</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/senders">Senders</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/create-sender">Create Sender</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/sender-templates">Sender Templates</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/billing/events">Billing</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/scripts">Scripts</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/tasks">Tasks</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/paypage-optins">PaypageOptins</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/emailFallBackSubscriptions">EmailSubscriptions</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/email-blacklist">Email blacklist</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/docrefs">DocRefs</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/senderOnboarding">Sender Onboarding</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/exports">Exports</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/global-settings">Global settings</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/speos">Speos</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/auditing">Auditing</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/logout">Logout</NavLink></li>
        </ul>
    </Fragment>
);

export default NavBar;
