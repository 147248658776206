import React, {Component, Fragment} from 'react';
import {editMerchant} from '../../services';
import PropTypes from 'prop-types';
import {Col, Row} from "reactstrap";
import {withRefresh} from "../../Elements/Fetch";
import FormWithActions from "../../Elements/FormWithActions/FormWithActions";
import {PlusIcon, XIcon} from "react-octicons";
import FormGroupRowCheckbox from "../../Elements/FormGroupRowCheckbox";

class SenderMerchantEditForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            enabled: props.merchant.enabled,
            payoutsEnabled: props.merchant.payoutsEnabled,
            attrs: (props.merchant.attrs || []).map(attr => ({...attr, deleted: false})),
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.handlePaste = this.handlePaste.bind(this);
        this.handleChangeAttrs = this.handleChangeAttrs.bind(this);
        this.handleChangeAttrsKey = this.handleChangeAttrsKey.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleDeleteAttr = this.handleDeleteAttr.bind(this);
    }

    handleAction(action) {
        return editMerchant(this.props.merchant.merchantId, this.state.enabled, this.state.payoutsEnabled, this.state.attrs, this.props.senderId).then(data => {
            this.props.triggerRefresh(`sender:${this.props.senderId}`);
            this.props.onSubmit();
            return data;
        });
    }

    handleChange(value, attribute) {
        this.setState({
            [attribute]: value
        });
    }

    handleChangeAttrs(value, index) {
        this.setState((prevState, props) => ({
            attrs: [
                ...prevState.attrs.slice(0, index),
                {...prevState.attrs[index], v: value},
                ...prevState.attrs.slice(index + 1),
            ],
        }));
    }

    handleChangeAttrsKey(newKey, index) {
        this.setState((prevState, props) => ({
            attrs: [
                ...prevState.attrs.slice(0, index),
                {...prevState.attrs[index], k: newKey},
                ...prevState.attrs.slice(index + 1),
            ],
        }));
    }

    handlePaste(event, index) {
        const clipboardData = event.clipboardData.getData('Text').trim();
        if (clipboardData.includes("\t")) {
            event.preventDefault();

            let clipboardLines = [clipboardData];
            if (clipboardData.includes("\n")) {
                clipboardLines = clipboardData.split("\n");
            }
            if (clipboardData.includes("\r")) {
                clipboardLines = clipboardData.split("\r");
            }

            this.setState((prevState, props) => {
                return {
                    attrs: [
                        ...prevState.attrs,
                        ...clipboardLines.map(clipboardLine => {
                            const lineData = clipboardLine.split("\t");
                            return {
                                id: 'NEW',
                                k: lineData.shift().trim(),
                                v: lineData.join(' ').trim(),
                                deleted: false,
                            };
                        }),
                    ],
                };
            });
        }
    }

    handleDeleteAttr(index) {
        this.setState((prevState, props) => {
            if (prevState.attrs[index].id === 'NEW') {
                return {
                    attrs: [
                        ...prevState.attrs.slice(0, index),
                        ...prevState.attrs.slice(index + 1),
                    ],
                };
            }
            else {
                return {
                    attrs: [
                        ...prevState.attrs.slice(0, index),
                        {...prevState.attrs[index], deleted: true},
                        ...prevState.attrs.slice(index + 1),
                    ],
                };
            }
        });
    }

    handleAdd() {
        this.setState((prevState, props) => ({
            attrs: [
                ...prevState.attrs,
                {
                    id: 'NEW',
                    k: '',
                    v: '',
                    deleted: false,
                }
            ],
        }));
    }

    render() {

        let disableSubmitReason = '';
        const merchantAttrs = this.state.attrs.map((item, index) => {
            if (item.deleted) {
                return "";
            }

            if (!item.k || !item.v) {
                disableSubmitReason = 'Attributes cannot be empty';
            }

            return <div className="form-group form-row" key={index}>
                <div className="col">
                    <input tabIndex="1" type="text" className={"form-control" + (!item.k ? ' is-invalid' : '')} placeholder="key" value={item.k} onChange={event => this.handleChangeAttrsKey(event.target.value, index)}
                           onPaste={event => this.handlePaste(event, index)}/>
                </div>
                <div className="col">
                    <input tabIndex="1" type="text" className={"form-control" + (!item.v ? ' is-invalid' : '')} placeholder="value" value={item.v} onChange={event => this.handleChangeAttrs(event.target.value, index)}
                           onPaste={event => this.handlePaste(event, index)}/>
                </div>
                <div className="col-1 col-form-label">
                    <button type="button" className="btn btn-link btn-sm" onClick={() => this.handleDeleteAttr(index)}>
                        <XIcon/>
                    </button>
                </div>
            </div>;
        });

        return (
            <Fragment>
                <Row>
                    <Col>
                        <h1 className="mb-5">
                            Edit merchant
                        </h1>
                    </Col>
                </Row>
                <FormWithActions onAction={this.handleAction} disableSubmitReason={disableSubmitReason}>

                    <FormGroupRowCheckbox
                        label="Visible"
                        checked={this.state.enabled}
                        onChange={checked => this.handleChange(checked, 'enabled')}
                    />

                    <FormGroupRowCheckbox
                        label="Payouts enabled"
                        checked={this.state.payoutsEnabled}
                        onChange={checked => this.handleChange(checked, 'payoutsEnabled')}
                    />

                    {merchantAttrs}

                    <div className="form-group form-row">
                        <div className="col">
                            <button type="button" className="btn btn-link btn-sm" onClick={this.handleAdd}>
                                <PlusIcon/>
                            </button>
                        </div>
                    </div>

                </FormWithActions>
            </Fragment>
        );
    }
}

SenderMerchantEditForm.propTypes = {
    senderId: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired,

    merchant: PropTypes.shape({
        merchantId: PropTypes.number.isRequired,
        enabled: PropTypes.bool.isRequired,
        payoutsEnabled: PropTypes.bool.isRequired,
        attrs: PropTypes.array.isRequired,
    }).isRequired,
};

export default withRefresh(SenderMerchantEditForm);