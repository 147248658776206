import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {fetchTransactions, startTransctionsDownload} from '../services';

import AbstractItemsTableWithLoader from '../Tables/AbstractItemsTableWithLoader';
import {ModalContext} from "../Modal/ModalProvider";

const TransactionsTable = ({name, itemsPerPage, searchParams, hideFiltersIfEmpty, hideFilters}) => {
    const {showModal} = useContext(ModalContext);

    const fetchFunction = (searchParams) => {
        //Remove id from searchParams on document detail page because this is the documentId
        //Otherwise backend will try to filter on this and give errors
        const {id, ...restSearchParams} = searchParams;

        return fetchTransactions(restSearchParams).then(data => ({
            ...data,
            items: data.items.map(item => ({
                ...item,
                documentId: <Link to={`/document?id=${encodeURIComponent(item.documentId)}`}>{item.documentId}</Link>,
                transactionId: <Link to={"/transactions/" + item.id}>{item.key}</Link>,
            })),
        }));
    };

    const tableColumns = {
        transactionId: {
            name: 'Transaction Id',
            sort: true,
            sortKey: 'transactionId',
        },
        externalTransactionId: {
            name: 'External transaction Id',
            sort: true,
            sortKey: 'externalTransactionId'
        },
        documentId: {
            name: 'Document Id',
            sort: true,
        },
        amount: {
            name: 'Amount',
            sort: true,
        },
        reason: {
            name: 'Reason',
            sort: true,
            sortKey: 'communication',
        },
        status: {
            name: 'Status',
            sort: true,
        },
        paymentMethod: {
            name: 'Payment method',
            sort: true,
        },
        descriptionCode: {
            name: 'Description code',
            sort: true,
        },
        transactionDate: {
            name: 'Transaction date',
            sort: true,
            style: {
                width: '185px',
            },
        },
        scheduledDate: {
            name: 'Scheduled date',
            sort: true,
            style: {
                width: '185px',
            },
        },
        senderName: {
            name: 'Sender name',
            sort: true,
        },
        documentChannel: {
            name: 'Channel',
            sort: true,
            sortKey: 'channel',
        },
    };

    const namedSearchParameters = {
        transactionId: {
            type: 'text',
            name: 'Transaction id',
            operators: ['eq'],
        },
        externalTransactionId: {
            type: 'text',
            name: 'External transaction id',
            operators: ['eq'],
        },
        documentId: {
            type: 'text',
            name: 'Document id',
            operators: ['eq', 'contains'],
        },
        amount: {
            type: 'text',
            name: 'Amount',
            operators: ['eq'],
        },
        communication: {
            type: 'text',
            name: 'Reason',
            operators: ['eq', 'contains'],
        },
        status: {
            type: 'select',
            name: 'Status',
            options: ['COMPLETED', 'REFUSED', 'CANCELED', 'DELAYED', 'NEW', 'ERROR', 'INTERNAL_ERROR', 'PROCESSING', 'COMPLETE', 'FAILED', 'TIMEOUT', 'CANCELLED', 'REFUNDED', 'AUTHORIZED', 'SCHEDULED', 'INVITED', 'RELEASED'],
            operators: ['eq'],
            multiple: true,
        },
        paymentMethod: {
            type: 'text',
            name: 'Payment method',
            operators: ['eq'],
        },
        descriptionCode: {
            type: 'text',
            name: 'Description code',
            operators: ['eq', 'contains'],
        },
        transactionDate: {
            type: 'date',
            name: 'Created date',
            operators: ['eq', 'lt', 'gt', 'between'],
        },
        senderName: {
            type: 'text',
            name: 'Sender name',
            operators: ['eq', 'contains'],
        },
        senderIban: {
            type: 'text',
            name: 'Sender IBAN',
            operators: ['eq'],
        },
        senderId: {
            type: 'text',
            name: 'Sender Id',
            operators: ['eq', 'lt', 'gt'],
        },
        parentId: {
            type: 'text',
            name: 'Parent Id',
            operators: ['eq', 'lt', 'gt'],
        },
        scheduledDate: {
            type: 'date',
            name: 'Scheduled date',
            operators: ['eq', 'lt', 'gt', 'between'],
        },
        channel: {
            type: 'select',
            name: 'Channel',
            optionsFetchFromStateProperty: 'channels',
            operators: ['eq'],
        },
        reportingId: {
            type: 'select',
            name: 'Reporting ID',
            optionsFetchFromStateProperty: 'reportingIds',
            operators: ['eq'],
            multiple: true,
        },
    };

    return <AbstractItemsTableWithLoader
        name={name}
        itemsPerPage={itemsPerPage}
        maxItemsForPager={10000}
        searchParams={searchParams}
        tableColumns={tableColumns}
        namedSearchParameters={namedSearchParameters}
        fetchFunction={fetchFunction}
        hideFiltersIfEmpty={hideFiltersIfEmpty}
        hideFilters={hideFilters}
        downloadModalFunc={startTransctionsDownload}
        showModal={showModal}
        minWidth={1700}
    />;
};

TransactionsTable.propTypes = {
    name: PropTypes.string,
    itemsPerPage: PropTypes.number,
    searchParams: PropTypes.object,
    hideFiltersIfEmpty: PropTypes.bool,
    hideFilters: PropTypes.bool,
};

export default TransactionsTable;
