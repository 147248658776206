import Fetch from "../../Elements/Fetch";
import {fetchTransaction} from "../../services";
import PropTypes from "prop-types";
import React from "react";
import TransactionEditForm from "./TransactionEditForm";

const TransactionEditRoute = ({match, history, onSubmit}) => (
    <Fetch fetchFunction={fetchTransaction} fetchFunctionParams={match.params.id}>
        {transaction => {
            const handleSubmit = onSubmit || (() => history.push('/transactions/' + transaction.id));

            return (
                <div style={{maxWidth: 800}}>
                    <TransactionEditForm
                        transaction={transaction}
                        onSubmit={handleSubmit}
                    />
                </div>
            );
        }}
    </Fetch>
);

TransactionEditRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default TransactionEditRoute;
