import React, {useState} from 'react';
import FormGroupRowText from "../../Elements/FormGroupRowText";
import useSubmittingButton from "../../hooks/useSubmittingButton";
import {sendOnetimeCredentialsUrl} from "../../services";
import {Button, Col, Row} from "reactstrap";
import {Add, DeleteForever} from "@material-ui/icons";
import ClickToCopy from "../../Elements/ClickToCopy/ClickToCopy";

const SenderOneTimeCredentials = () => {
    const [fields, setFields] = useState([
        {label: "Username", value: ""},
        {label: "Password", value: ""},
        {label: "Aws Key", value: ""},
        {label: "Aws Secret", value: ""}
    ]);
    const [senderId, setSenderId] = useState('');
    const [credUrl, setCredUrl] = useState('');
    const [credUrlExpiryDays, setCredUrlExpiryDays] = useState(1);
    const {successMessage, errorMessage, loader, SubmittingButton} = useSubmittingButton();

    const handleSubmit = async () => {
        let requiredData: any = {senderId, credUrlExpiryDays};
        let credentials: any = {};
        fields.forEach(field => {
            credentials[field.label] = field.value
        })

        let body = {credentials, requiredData}
        const response = await sendOnetimeCredentialsUrl(body);

        if (!response.url) {
            const data = await response.json();
            const error = data.errors ? data.errors.map((error: any) => error.message) : data.message;
            throw new Error(error);
        }
        setCredUrl(response.url);
        return response.url;
    };

    const disabled = !senderId || credUrlExpiryDays < 1 || fields.length < 1;

    function addField() {
        setFields([...fields, {label: "Label", value: "value"}])
    }

    function removeField(index: number) {
        setFields(prevState => {
            let existingFields = [...prevState];
            existingFields.splice(index, 1);
            return existingFields;
        });
    }

    function handleFormChange(rowIndex: number, field: string, value: any) {
        setFields(prevState => {
            let updated = [...prevState];
            updated[rowIndex] = {...prevState[rowIndex], [field]: value};
            return updated;
        });
    }

    return (
        <>
            <h3>Sender credentials</h3>
            <div style={{maxWidth: 830, marginTop: 30}}>
                <div className="d-flex flex-column">
                    <FormGroupRowText
                        label="Sender Id"
                        type="text"
                        required
                        value={senderId}
                        onChange={setSenderId}
                    />
                    <FormGroupRowText
                        label="Cred URL Expiry"
                        type="email"
                        required
                        value={credUrlExpiryDays}
                        onChange={setCredUrlExpiryDays}
                    />
                </div>

                {fields?.map((field, rowIndex: number) => {
                    return (
                        <div key={rowIndex} className="d-flex justify-content-between flex-wrap">
                            <div>
                                <FormGroupRowText
                                    label=""
                                    type="text"
                                    value={field.label}
                                    onChange={value => handleFormChange(rowIndex, 'label', value)}
                                />
                            </div>
                            <div>
                                <FormGroupRowText
                                    label=""
                                    type="email"
                                    value={field.value}
                                    onChange={value => handleFormChange(rowIndex, 'value', value)}
                                />
                            </div>
                            <div onClick={() => removeField(rowIndex)}>
                                <Button color="danger" onClick={() => removeField(rowIndex)}><DeleteForever
                                    fontSize="small"/></Button>
                            </div>
                        </div>
                    );
                })}

                <div onClick={() => addField()} className="d-flex mb-sm-5">
                    <Button color="success" className="mr-2" onClick={() => addField()}><Add fontSize="small"/></Button>
                </div>

                {errorMessage}

                {credUrl &&
                    <ClickToCopy value={credUrl} children={successMessage}/>
                }

                <Row form className="mt-4 align-items-center">
                    <Col xs="auto">
                        <SubmittingButton onClick={handleSubmit} disabled={disabled}>Submit</SubmittingButton>
                    </Col>
                    <Col xs="auto">
                        {loader}
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default SenderOneTimeCredentials;
