import React, {Component} from 'react';
import {fetchStats} from '../services';
import googleChartLoader from '../GoogleChartLoader';
import Loader from '../Elements/Loader';
import {Button, ButtonGroup, Row, Col} from 'reactstrap';
import FetchFromContext from "../Elements/FetchFromContext";
import Select from 'react-virtualized-select';

class SenderCreatedStats extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isCommunicatingWithServer: false,
            showError: false,
            rows: [],
            groupBy: 'day',
            senderTypes: [],
            parentSenderIds: [],
            allSenderTypes: ['ACTIVE_OPTIN', 'CONTRACT_MATCHING', 'SCAN_AND_PAY', 'MAIL_AND_PAY'],
            height: this.props.height ? parseInt(this.props.height, 10) : 400,
        };

        this.drawDashboard = this.drawDashboard.bind(this);
        this.handleClickGroupBy = this.handleClickGroupBy.bind(this);
        this.handleClickType = this.handleClickType.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.handleChangeParentSenders = this.handleChangeParentSenders.bind(this);
    }

    componentDidMount() {
        googleChartLoader.init(['corechart', 'controls']);
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.groupBy !== this.state.groupBy) {
            this.fetchData();
            return;
        }

        if (JSON.stringify(prevState.senderTypes) !== JSON.stringify(this.state.senderTypes)) {
            this.fetchData();
            return;
        }

        if (JSON.stringify(prevState.parentSenderIds) !== JSON.stringify(this.state.parentSenderIds)) {
            this.fetchData();
            return;
        }

        if (prevState.rows.length !== this.state.rows.length) {
            if (!this.state.isCommunicatingWithServer || this.state.rows.length === 0) {
                googleChartLoader.init().then(() => {
                    this.drawDashboard();
                });
            }
        }
    }

    fetchData() {
        this.setState({
            isCommunicatingWithServer: true,
            showError: false,
            rows: [],
        });

        fetchStats('senderCreated', {
            groupBy: this.state.groupBy,
            senderTypes: this.state.senderTypes,
            parentSenderIds: this.state.parentSenderIds,
        }).then((data) => {
            const processedData = data.map(item => [new Date(item[0]), item[1]]);
            this.setState({
                rows: processedData,
                isCommunicatingWithServer: false
            });
        }).catch((errorMessage) => {
            this.setState({
                isCommunicatingWithServer: false,
                showError: true,
            });
        });
    }

    drawDashboard() {
        const data = new window.google.visualization.DataTable();
        data.addColumn("date", 'Date');
        data.addColumn("number", 'Senders created');
        data.addRows(this.state.rows);

        const dashboard = new window.google.visualization.Dashboard(document.getElementById('SenderCreatedStatsWrapper'));

        let end = this.state.rows.length > 0 ? this.state.rows[this.state.rows.length - 1][0] : new Date();
        let start = new Date(end);
        let format = 'dd/MM/yy';

        switch (this.state.groupBy) {
            case 'month':
                start.setFullYear(start.getFullYear() - 2);
                format = 'MMM y';
                break;
            case 'quarter':
                start.setFullYear(start.getFullYear() - 2);
                format = 'y Q';
                break;
            case 'year':
                start = this.state.rows.length > 0 ? this.state.rows[0][0] : new Date(); //assume that all rows can be shown when grouped by year
                format = 'y';
                break;
            default:
                start.setMonth(start.getMonth() - 2);
        }

        const chart = new window.google.visualization.ChartWrapper({
            chartType: 'ColumnChart',
            containerId: 'SenderCreatedStats',
            options: {
                title: 'Sender creation',
                legend: {
                    position: 'none',
                },
                height: this.state.height,
                chartArea: {
                    left: 40,
                    right: 0,
                    top: 30,
                    bottom: 30,
                },
                hAxis: {
                    format: format,
                },
            },
        });

        const controls = new window.google.visualization.ControlWrapper({
            controlType: 'ChartRangeFilter',
            containerId: 'SenderCreatedStatsControls',
            options: {
                filterColumnLabel: 'Date',
                ui: {
                    chartOptions: {
                        height: 60,
                        chartArea: {
                            left: 40,
                            right: 10,
                            top: 0,
                            bottom: 20,
                        },
                        hAxis: {
                            'textPosition': 'out',
                            format: format,
                        },
                    },
                },
            },
            state: {
                range: {
                    start: start,
                    end: end,
                }
            },
        });

        new window.google.visualization.DateFormat({pattern: format}).format(data, 0);
        dashboard.bind([controls], [chart]);
        dashboard.draw(data);
    }

    handleClickGroupBy(groupBy) {
        this.setState({
            groupBy: groupBy,
        });
    }

    handleClickType(type) {
        this.setState(prevState => {
            const senderTypes = prevState.senderTypes;
            if (senderTypes.includes(type)) {
                return {
                    senderTypes: senderTypes.filter(s => s !== type)
                };
            } else {
                let newSenderTypes = [
                    ...senderTypes,
                    type,
                ].sort();

                if (newSenderTypes.length === this.state.allSenderTypes.length) {
                   newSenderTypes = [];
                }

                return {
                    senderTypes: newSenderTypes,
                };
            }
        });
    }

    handleChangeParentSenders(items) {
        this.setState({
            parentSenderIds: items.map(item => item.value),
        });
    }

    render() {

        const height = this.state.height + 60;

        return (
            <div id="SenderCreatedStatsWrapper">
                <Row form className="justify-content-end">
                    <Col style={{maxWidth: 400, marginBottom: 10}}>
                        <FetchFromContext property="parentSenders">
                            {parentSenders => (
                                <Select
                                    placeholder="Parent senders"
                                    value={this.state.parentSenderIds}
                                    options={parentSenders.map(parentSender => ({
                                        value: parentSender.id,
                                        label: parentSender.name,
                                    }))}
                                    onChange={this.handleChangeParentSenders}
                                    multi
                                />
                            )}
                        </FetchFromContext>
                    </Col>
                    <Col xs="auto" style={{marginBottom: 10}}>
                        <ButtonGroup size="sm">
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.groupBy === 'day'} color="success" onClick={() => this.handleClickGroupBy('day')}>Day</Button>
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.groupBy === 'month'} color="success" onClick={() => this.handleClickGroupBy('month')}>Month</Button>
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.groupBy === 'quarter'} color="success" onClick={() => this.handleClickGroupBy('quarter')}>Quarter</Button>
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.groupBy === 'year'} color="success" onClick={() => this.handleClickGroupBy('year')}>Year</Button>
                        </ButtonGroup>
                    </Col>
                    <Col xs="auto" style={{marginBottom: 10}}>
                        <ButtonGroup size="sm">
                            {
                                this.state.allSenderTypes.map(senderType => (
                                    <Button key={senderType} outline disabled={this.state.isCommunicatingWithServer} active={this.state.senderTypes.includes(senderType)} color="success" onClick={() => this.handleClickType(senderType)}>{senderType}</Button>
                                ))
                            }
                        </ButtonGroup>
                    </Col>
                </Row>
                <div style={{height: height + 'px'}}>
                    {this.state.isCommunicatingWithServer &&
                    <div className="d-flex flex-column justify-content-center h-100 text-center">
                        <Loader/>
                    </div>
                    }
                    {this.state.showError &&
                    <div className="d-flex flex-column justify-content-center h-100 text-center">
                        An error occured showing this graph. Try refreshing the page.
                    </div>
                    }
                    <div id="SenderCreatedStats"/>
                    <div id="SenderCreatedStatsControls"/>
                </div>
            </div>
        );

    }
}

export default SenderCreatedStats;
