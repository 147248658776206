import React, {useState} from 'react';
import PropTypes from 'prop-types';
import FormGroupRowText from "../Elements/FormGroupRowText";
import {Col, Row} from "reactstrap";
import {withRefresh} from "../Elements/Fetch";
import FormWithActions from "../Elements/FormWithActions/FormWithActions";

const SenderOnboardingEditForm = ({senderOnboarding, onSubmit}) => {
    const {
        requestId,
        contactPerson: defaultContactPerson,
    } = senderOnboarding;

    const [contactPerson, setContactPerson] = useState(defaultContactPerson || '');

    const handleAction = () => onSubmit({
        contactPerson,
    });

    return (
        <>
            <Row>
                <Col>
                    <h1 className="mb-5">
                        SenderOnboarding: {requestId}
                    </h1>
                </Col>
            </Row>
            <FormWithActions onAction={handleAction}>
                <FormGroupRowText
                    label="Contact person"
                    placeholder="Contact person"
                    type="text"
                    value={contactPerson}
                    onChange={setContactPerson}
                />
            </FormWithActions>
        </>
    );
};

SenderOnboardingEditForm.propTypes = {
    senderOnboarding: PropTypes.shape({
        id: PropTypes.number.isRequired,
        requestId: PropTypes.string.isRequired,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default withRefresh(SenderOnboardingEditForm);
