import React from 'react';
import {fetchBillingReseller} from "../../../services";
import Fetch from "../../../Elements/Fetch";
import BillingReseller from "./BillingReseller";

const BillingResellerRoute = ({match}) => (
    <Fetch fetchFunction={fetchBillingReseller} fetchFunctionParams={match.params.id} refreshKey={`billingReseller:${match.params.id}`}>
        {billingReseller => (
            <BillingReseller billingReseller={billingReseller}/>
        )}
    </Fetch>
);

export default BillingResellerRoute;