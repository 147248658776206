import React from 'react';
import {editSenderOnboardingData, fetchSenderOnboarding} from "../services";
import Fetch, {withRefresh} from "../Elements/Fetch";
import JsonEditor from "./JsonEditor";

const SenderOnboardingBuckarooOnboardingCustomerDataRoute = ({match, onSubmit, triggerRefresh}) => {
    return (
        <Fetch fetchFunction={fetchSenderOnboarding} fetchFunctionParams={match.params.id}>
            {senderOnboarding => {
                const handleSubmit = async (data) => {
                    await editSenderOnboardingData(senderOnboarding.id, 'buckarooOnboardingAPIData', data);
                    triggerRefresh(`senderOnboarding:${senderOnboarding.id}`, true);
                    onSubmit();
                };

                return (
                    <JsonEditor
                        title="Buckaroo onboarding API data"
                        defaultValue={senderOnboarding.buckarooOnboardingAPIData}
                        onSubmit={handleSubmit}
                    />
                );
            }}
        </Fetch>
    );
};

export default withRefresh(SenderOnboardingBuckarooOnboardingCustomerDataRoute);