import React, {Fragment} from 'react';
import PaypageOptinsTable from './PaypageOptinsTable';

const PaypageOptinsRoute = () => (
    <Fragment>
        <h1 className="mb-5">Paypage Optins</h1>
        <PaypageOptinsTable/>
    </Fragment>
);

export default PaypageOptinsRoute;