import React, {useEffect, useMemo, useState} from 'react';
import {Route} from "react-router-dom";

import NavBar from './NavBar';
import Home from './Home';
import Billing from "./Billing/Billing";
import UsersRoute from './Users/UsersRoute';
import UserRoute from './Users/UserRoute';
import DocumentsRoute from './Documents/DocumentsRoute';
import PaypageOptinsRoute from './PaypageOptin/PaypageOptinsRoute';
import EmailFallBackSubscriptionsRoute from './EmailFallBackSubscription/EmailFallBackSubscriptionsRoute';
import DocumentRoute from './Documents/DocumentRoute';
import TasksRoute from './Tasks/TasksRoute';
import TaskRoute from './Tasks/TaskRoute';
import DocRefsRoute from './DocRefs/DocRefsRoute'
import SendersRoute from './Senders/SendersRoute';
import TransactionsRoute from './Transactions/TransactionsRoute';
import TransactionRoute from './Transactions/TransactionRoute';
import Logout from './Logout';

import VersionChecker from "./Items/VersionChecker";
import Scripts from "./Scripts/Scripts";
import QueryRouter from "./Elements/QueryRouter";
import {withRefreshContainer} from "./Elements/Fetch";
import UserEditRoute from "./Users/UserEditRoute";
import OverlayContainer from "./Elements/Overlay/OverlayContainer";
import SenderEditRoute from "./Senders/SenderEdit/SenderEditRoute";
import SenderRoute from "./Senders/SenderRoute";
import SenderMerchantAddRoute from "./Senders/SenderMerchantAdd/SenderMerchantAddRoute";
import SenderMerchantEditRoute from "./Senders/SenderMerchantEdit/SenderMerchantEditRoute";
import SenderPartnerSettingsRoute from "./Senders/SenderPartnerSettingsEdit/SenderPartnerSettingsRoute";
import {
    fetchAuditTrailSupportedTypes,
    fetchBillingEventSubtypes,
    fetchBillingEventTypes,
    fetchBillingResellersList,
    fetchBillingSenders,
    fetchChannelList,
    fetchPaymentProvidersList,
    fetchReportingIds,
    fetchSendersList
} from "./services";
import {AppContext} from "./AppContext";
import SenderOnboardingRoutes from "./SenderOnboarding/SenderOnboardingRoutes";
import ExportRoutes from "./Export/ExportRoutes";
import UserPartnerSettingsRoute from "./Users/UserPartnerSettingsRoute";
import ModalProvider from "./Modal/ModalProvider";
import BillingContactsAddRoute from "./Billing/BillingSender/BillingContactAdd/BillingContactsAddRoute";
import GlobalSettingsRoute from "./GlobalSettings/GlobalSettingsRoute";
import SpeosRoute from "./SpeosAdmin/SpeosRoute";
import SpeosArchiveRoute from "./SpeosAdmin/archive/SpeosArchiveRoute";
import SpeosReprintRoute from "./SpeosAdmin/reprint/SpeosReprintRoute";
import SpeosReprintDetailRoute from "./SpeosAdmin/reprint/detail/SpeosReprintDetailRoute";
import SpeosArchiveDetailRoute from "./SpeosAdmin/archive/detail/SpeosArchiveDetailRoute";
import CreateSender from "./CreateSender/CreateSender";
import MandateRoute from "./Mandates/MandateRoute";
import UserRolesRoute from "./Users/UserRolesRoute";
import TemplatesRoute from "./SenderTemplate/TemplatesRoute";
import EditTemplateRoute from "./SenderTemplate/EditTemplateRoute";
import AddTemplateRoute from "./SenderTemplate/AddTemplateRoute";
import TaskLogRoute from "./Tasks/TaskLogRoute";
import SenderUploadDocRefRoute from "./Senders/SenderUploadDocref/SenderUploadDocrefRoute";
import AuditingRoute from './Auditing/AuditingRoute';
import EmailBlacklistRoute from "./EmailBlacklist/EmailBlacklistRoute";
import MaintenanceModeChecker from "./Items/MaintenanceModeChecker";

const LoggedInContainer = ({logout, currentUser}) => {
    const [appState, setAppState] = useState({
        currentUser: currentUser,
        triggerAppStateRefresh: (downloadFuncName) => downloadFuncs[downloadFuncName](),
    });

    const downloadFuncs = useMemo(() => ({
        downloadPaymentProviders: async () => {
            const paymentProviders = await fetchPaymentProvidersList();
            setAppState(prevState => ({
                ...prevState,
                paymentProviders: paymentProviders,
            }));
        },

        downloadBillingSenders: async () => {
            const billingSenders = await fetchBillingSenders({itemsPerPage: 100000});
            setAppState(prevState => ({
                ...prevState,
                billingSenders: billingSenders.items,
            }));
        },

        downloadChannels: async () => {
            const channels = await fetchChannelList();
            setAppState(prevState => ({
                ...prevState,
                channels: channels,
            }));
        },

        downloadAuditTrailSupportedTypes: async () => {
            const auditTrailSupportedTypes = await fetchAuditTrailSupportedTypes();
            setAppState(prevState => ({
                ...prevState,
                auditTrailSupportedTypes: auditTrailSupportedTypes.types
            }));
        },

        downloadBillingEventTypes: async () => {
            const billingEventTypes = await fetchBillingEventTypes();
            setAppState(prevState => ({
                ...prevState,
                billingEventTypes: billingEventTypes,
            }));
        },

        downloadBillingEventSubtypes: async () => {
            const billingEventSubtypes = await fetchBillingEventSubtypes();
            setAppState(prevState => ({
                ...prevState,
                billingEventSubtypes: billingEventSubtypes,
            }));
        },

        downloadBillingResellers: async () => {
            const billingResellers = await fetchBillingResellersList();
            setAppState(prevState => ({
                ...prevState,
                billingResellers: billingResellers,
            }));
        },

        downloadParentSenders: async () => {
            const parentSenders = await fetchSendersList({isParent: true});
            setAppState(prevState => ({
                ...prevState,
                parentSenders: parentSenders,
            }));
        },

        downloadSenders: async () => {
            const senders = await fetchSendersList({isParent: false});
            setAppState(prevState => ({
                ...prevState,
                senders: senders,
            }));
        },

        downloadReportingIds: async () => {
            const reportingIds = await fetchReportingIds();
            setAppState(prevState => ({
                ...prevState,
                reportingIds: reportingIds,
            }));
        },
    }), [setAppState]);

    useEffect(
        () => {
            (async () => {
                //Load stuff on homepage first: paymentProviders, billingSenders, channels
                await downloadFuncs.downloadPaymentProviders();
                await downloadFuncs.downloadBillingSenders();
                await downloadFuncs.downloadBillingEventTypes();
                await downloadFuncs.downloadBillingEventSubtypes();
                await downloadFuncs.downloadBillingResellers();
                await downloadFuncs.downloadChannels();
                await downloadFuncs.downloadParentSenders();
                await downloadFuncs.downloadSenders();
                await downloadFuncs.downloadReportingIds();
                await downloadFuncs.downloadAuditTrailSupportedTypes();
            })();
        },
        [downloadFuncs]
    );

    return (
        <AppContext.Provider value={appState}>
            <ModalProvider>
                <div className={"col-nav " + process.env.REACT_APP_POM_ENV}>
                    <NavBar/>
                </div>
                <div className="col-content p-3">
                    <VersionChecker/>
                    <MaintenanceModeChecker/>
                    <Route exact path="/" component={Home}/>

                    <Route exact path="/users" component={UsersRoute}/>
                    <Route exact path="/users/:id" component={UserRoute}/>
                    <Route exact path="/users/:id/edit" component={UserEditRoute}/>
                    <Route exact path="/users/:id/edit-partner-settings" component={UserPartnerSettingsRoute}/>
                    <Route exact path="/users/:id/edit-roles" component={UserRolesRoute}/>

                    <SenderOnboardingRoutes/>

                    <ExportRoutes/>

                    <Route exact path="/documents" component={DocumentsRoute}/>
                    <Route exact path="/document" component={DocumentRoute}/>

                    <Route exact path="/tasks" component={TasksRoute}/>
                    <Route exact path="/tasks/:taskId" component={TaskRoute}/>
                    <Route exact path="/tasks/:taskId/logs" component={TaskLogRoute}/>

                    <Route exact path="/docRefs" component={DocRefsRoute}/>

                    <Route exact path="/paypage-optins" component={PaypageOptinsRoute}/>
                    <Route exact path="/emailFallBackSubscriptions" component={EmailFallBackSubscriptionsRoute}/>

                    <Route exact path="/transactions" component={TransactionsRoute}/>
                    <Route exact path="/transactions/:id" component={TransactionRoute}/>

                    <Route exact path="/create-sender" component={CreateSender}/>

                    <Route exact path="/sender-templates" component={TemplatesRoute}/>
                    <Route exact path="/sender-templates/create" component={AddTemplateRoute}/>
                    <Route exact path="/sender-templates/:id/edit" component={EditTemplateRoute}/>

                    <Route exact path="/senders" component={SendersRoute}/>
                    <Route exact path="/senders/:id" component={SenderRoute}/>
                    <Route exact path="/senders/:id/edit" component={SenderEditRoute}/>
                    <Route exact path="/senders/:id/merchant/add" component={SenderMerchantAddRoute}/>
                    <Route exact path="/senders/:id/merchant/:merchantId/edit" component={SenderMerchantEditRoute}/>
                    <Route exact path="/senders/:id/edit-partner-settings" component={SenderPartnerSettingsRoute}/>
                    <Route exact path="/senders/:id/upload-docref" component={SenderUploadDocRefRoute}/>

                    <Route path="/billing" component={Billing}/>
                    <Route path="/billing/contact/:id/add" component={BillingContactsAddRoute}/>

                    <Route path="/scripts" component={Scripts}/>
                    <Route exact path="/speos" component={SpeosRoute}/>
                    <Route exact path="/speos/archive" component={SpeosArchiveRoute}/>
                    <Route exact path="/speos/archive/:id" component={SpeosArchiveDetailRoute}/>
                    <Route exact path="/speos/reprint" component={SpeosReprintRoute}/>
                    <Route exact path="/speos/reprint/:id" component={SpeosReprintDetailRoute}/>
                    <Route exact path="/global-settings" component={GlobalSettingsRoute}/>

                    <Route exact path="/mandates/:id" component={MandateRoute}/>
                    <Route exact path="/email-blacklist" component={EmailBlacklistRoute}/>

                    <Route exact path="/auditing" component={AuditingRoute}/>

                    <Route exact path="/logout" render={() => <Logout logout={logout}/>}/>
                </div>
                <QueryRouter queryKey="overlay">
                    <OverlayContainer/>
                </QueryRouter>
            </ModalProvider>
        </AppContext.Provider>
    );
};

export default withRefreshContainer(LoggedInContainer);
