import React, {Fragment, useState} from 'react';
import {Button} from "reactstrap";
import {deleteSenderTemplate, duplicateSenderTemplate, fetchSenderTemplatesList} from "../services";
import PomModal from "../Elements/PomModal";
import AbstractItemsTableWithLoader from "../Tables/AbstractItemsTableWithLoader";
import {Link} from "react-router-dom";


const TemplatesTable = (props: any) => {
    const [templateToDelete, setTemplateToDelete] = useState<any>(null)
    const [duplicateFeedBack, setDuplicateFeedBack] = useState('')
    const [refreshKey, setRefreshKey] = useState('')
    const fetchFunction = () => {
        return fetchSenderTemplatesList().then(data => ({
            ...data,
            items: data.map((item: { id: any, templateName: string, dateCreated: Date, lastUpdated: Date, templateSettings: any }) => ({
                ...item,
                key: item.id,
                id: item.id,
                name: item.templateName,
                dateCreated: item.dateCreated,
                lastUpdated: item.lastUpdated,
                action: (
                    <div>
                        <div className="d-flex justify-content-around">
                            <Button
                                color="success"
                                onClick={() => onDuplicateTemplate(item.id)}
                            >Duplicate</Button>
                            {renderEditTemplateBtn(item.id)}
                            <Button
                                color="danger"
                                onClick={() => setTemplateToDelete(item)}
                            >Delete</Button>
                        </div>
                    </div>
                ),
            })),
        }));
    };


    function renderAddNewTemplateBtn() {
        return (
            <Link to="/sender-templates/create">
                <button className={"btn btn-m btn btn-success w-25 float-right mb-2"}>Add new sender template</button>
            </Link>
        );
    }

    function renderEditTemplateBtn(templateId: any) {
        return (
            <Link to={`/sender-templates/${templateId}/edit`}>
                <button className={"btn-s btn btn-warning"}>Edit</button>
            </Link>
        );
    }

    const onDuplicateTemplate = (templateId: string) => {
        duplicateSenderTemplate(templateId)
            .then(duplicatedTemplate => {
                setDuplicateFeedBack(`${duplicatedTemplate.templateName} is successfully duplicated`);
                setRefreshKey(new Date().toString())
            })
            .catch(error => {})
    }
    const onConfirmDeleteTemplate = () => {
        if (templateToDelete) {
            return deleteSenderTemplate(templateToDelete.id)
                .then(res => {
                    setRefreshKey(new Date().toString())
                    return res
                })
        }
    }

    const onCloseModal = () => {
        setTemplateToDelete(null)
        setDuplicateFeedBack('')
    }
    const renderDeleteModal = () => {
        if (templateToDelete) {
            let confirmMessage = `Are you sure you want to delete ${templateToDelete.templateName} permanently?`
            return (
                <Fragment>
                    <PomModal showFeedback={true} onAction={onConfirmDeleteTemplate}
                              confirmAction={onConfirmDeleteTemplate} confirmMessage={confirmMessage}
                              isOpen={templateToDelete !== null}
                              onClose={onCloseModal} title={'Deleting template'} children={[]}></PomModal>
                </Fragment>
            );
        }
    };

    const renderSuccessfulDuplicateModal = () => {
        if (duplicateFeedBack !== '') {
            return (
                <Fragment>
                    <PomModal feedback={duplicateFeedBack} isOpen={duplicateFeedBack !== ''}
                              onClose={onCloseModal} title={'Duplicating template'} children={[]}></PomModal>
                </Fragment>
            );
        }
    };

    const tableColumns = {
        id: {
            name: 'Id',
            style: {
                width: '90px'
            },
            sort: true
        },

        name: {
            name: 'Template name',
            style: {
                width: '190px'
            },
            sort: true
        },

        dateCreated: {
            name: 'Created At',
            style: {
                width: '190px'
            },
            sort: true
        },

        lastUpdated: {
            name: 'Updated At',
            style: {
                width: '190px'
            },
            sort: true
        },

        action: {
            name: 'Actions',
            style: {
                width: '190px'
            }
        }

    };

    return (
        <>
            {renderDeleteModal()}
            {renderSuccessfulDuplicateModal()}
            {renderAddNewTemplateBtn()}
            <AbstractItemsTableWithLoader
                itemsPerPage={10}
                tableColumns={tableColumns}
                hideFilters={true}
                hideFiltersIfEmpty={true}
                fetchFunction={fetchFunction}
                refreshKey={refreshKey}
            />
        </>
    )
};
export default TemplatesTable;
