import React, {Component, Fragment} from 'react';
import {Row, Col} from 'reactstrap';
import AbstractItemsTableWithLoader from "../../../Tables/AbstractItemsTableWithLoader";
import {fetchBillingCreditsList, formatNumber} from "../../../services";
import {Link} from 'react-router-dom';


class BillingCreditsListRoute extends Component {

    static transformItem(item) {
        const futureDay = new Date();
        futureDay.setDate(futureDay.getDate() + 30);
        const cachedEstimatedDate = item.cachedEstimatedEnd ? new Date(item.cachedEstimatedEnd) : '';
        return {
            ...item,
            key: item.id,
            billingSenderLink: <Link to={`/billing/credits/${item.id}`}>{item.name}</Link>,
            cachedEstimatedDateFormatted: cachedEstimatedDate ? (cachedEstimatedDate > futureDay ? cachedEstimatedDate.toLocaleDateString() :
                <span className="text-danger">{cachedEstimatedDate.toLocaleDateString()}</span>) : '',
            cachedRemainingCreditsFormatted: formatNumber(item.cachedRemainingCredits),
            isFreeTier: item.isFreeTier ? 'Yes' : '',
            inactive: item.inactive ? 'Yes' : '',
        };
    }

    static tableColumns(hideColumns = [], addSort = true) {
        const columns = {
            billingSenderLink: {
                name: 'Billing sender',
                sort: addSort,
                sortKey: 'billingSender.name',
            },
            cachedRemainingCreditsFormatted: {
                name: 'Remaining credits',
                sort: addSort,
                sortKey: 'cachedRemainingCredits',
            },
            cachedEstimatedDateFormatted: {
                name: 'Estimated end',
                sort: addSort,
                sortKey: 'cachedEstimatedEnd',
            },
            isFreeTier: {
                name: 'Is free tier',
                sort: addSort,
            },
            inactive: {
                name: 'Inactive',
                sort: addSort,
            },
        };

        hideColumns.forEach(column => delete columns[column]);

        return columns;
    }

    render() {
        const fetchFunction = searchParams => {
            return fetchBillingCreditsList(searchParams).then(data => ({
                ...data,
                items: data.items.map(item => BillingCreditsListRoute.transformItem(item)),
            }));
        };

        const namedSearchParameters = {
            name: {
                type: 'text',
                name: 'Billing sender',
                operators: ['contains'],
            },
            isFreeTier: {
                type: 'select',
                name: 'Is free tier',
                options: {
                    'true': 'Yes',
                    'false': 'No',
                },
                operators: ['eq'],
            },
            inactive: {
                type: 'select',
                name: 'Inactive',
                options: {
                    'true': 'Yes',
                    'false': 'No',
                },
                operators: ['eq'],
            },
        };

        return (
            <Fragment>
                <Row className="form-row">
                    <Col xs="auto"><h2 className="mb-5">Credits</h2></Col>
                </Row>

                <AbstractItemsTableWithLoader
                    tableColumns={BillingCreditsListRoute.tableColumns()}
                    namedSearchParameters={namedSearchParameters}
                    fetchFunction={fetchFunction}
                    refreshKey="billingCreditsList"
                />
            </Fragment>
        );
    }
}

export default BillingCreditsListRoute;
