import React from 'react';
import {Link} from "react-router-dom";
import {downloadPaypageOptinsUrl, fetchPaypageOptinsUrl} from '../services';

import AbstractItemsTableWithLoader from '../Tables/AbstractItemsTableWithLoader';
import {Role} from "../Auth/BackOfficeUserRole";

const PaypageOptinsTable = ({name, itemsPerPage, searchParams, hideFiltersIfEmpty, hideFilters}) => {

    const fetchFunction = (searchParams) => {
        return fetchPaypageOptinsUrl(searchParams).then(data => ({
            ...data,
            items: data.items.map(item => ({
                ...item,
                key: item.id,
                senderId: <Link to={"/senders/" + item.senderId}>{item.senderId}</Link>,
            })),
        }));
    };



    const downloadUrl = downloadPaypageOptinsUrl();
    const tableColumns = {
        senderId: {
            name: 'Sender Id',
            sort: true
        },
        email: {
            name: 'Email',
            sort: true
        },
        customerId: {
            name: 'Customer Id',
            sort: true
        },
        documentId: {
            name: 'Document Id',
            sort: true
        },
        documentPaid: {
            name: 'Document Paid',
            sort: true
        },
        emailConfirmationStatus: {
            name: 'Email Confirmation Status',
            sort: true
        },
        socialSecurityNumber: {
            name: 'Social Security Number',
            sort: true
        },
        optinAction: {
            name: 'Action',
            sort: true
        },
        dateCreated: {
            name: 'Date Created',
            sort: true,
            style: {
                width: '180px',
            },
        },
        lastUpdated: {
            name: 'Last Updated',
            sort: true,
            style: {
                width: '180px',
            },
        },
        paymentTransactionId: {
            name: 'Transaction Id',
            sort: true,
        },

    };
    const namedSearchParameters = {
        'sender.id': {
            type: 'text',
            name: 'Sender Id',
            operators: ['eq', 'lt', 'gt'],
        },
        email: {
            type: 'text',
            name: 'Email',
            operators: ['eq'],
        },
        customerId: {
            type: 'text',
            name: 'Customer Id',
            operators: ['eq'],
        },
        documentId: {
            type: 'text',
            name: 'Document Id',
            operators: ['eq', 'contains'],
        },
        documentPaid: {
            type: 'select',
            name: 'Document Paid',
            options: ['true', 'false'],
            operators: ['eq'],
        },
        optinAction: {
            type: 'select',
            name: 'Action',
            options: ['OPTIN', 'OPTOUT'],
            operators: ['eq'],
        },
        dateCreated: {
            type: 'date',
            name: 'Date Created',
            operators: ['eq', 'lt', 'gt', 'between'],
        },
        lastUpdated: {
            type: 'date',
            name: 'Last Updated',
            operators: ['eq', 'lt', 'gt', 'between'],
        },
        paymentTransactionId: {
            type: 'text',
            name: 'Transaction Id',
            operators: ['eq'],
        },
    };

    return <AbstractItemsTableWithLoader
        name={name}
        itemsPerPage={itemsPerPage}
        tableColumns={tableColumns}
        namedSearchParameters={namedSearchParameters}
        fetchFunction={fetchFunction}
        searchParams={searchParams}
        hideFilters={hideFilters}
        hideFiltersIfEmpty={hideFiltersIfEmpty}
        downloadUrl={downloadUrl}
        downloadUrlMax={5000}
        downloadUrlRoles={[Role.WRITE]}
        minWidth={1700}
    />;
};


export default PaypageOptinsTable;