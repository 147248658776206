import React, {Component} from 'react';
import {fetchStats, formatCurrency, retrieveFilenameFromHeaders, sendFileToBrowser} from '../services';
import googleChartLoader from '../GoogleChartLoader';
import Loader from '../Elements/Loader';
import {Button, ButtonGroup, Row, Col} from 'reactstrap';
import FetchFromContext from "../Elements/FetchFromContext";
import Select from 'react-virtualized-select';
import {DesktopDownloadIcon} from "react-octicons";
import moment from "moment";

class DocumentCreatedStats extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isCommunicatingWithServer: false,
            showError: false,
            rows: [],
            groupBy: 'day',
            groupByField: 'receivedDate',
            billingSenderIds: [],
            parentSenderIds: [],
            paymentMethodIds: [],
            onlyPaylinks: false,
            noMtpIds: false,
            height: this.props.height ? parseInt(this.props.height, 10) : 400,
        };

        this.drawDashboard = this.drawDashboard.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSenders = this.handleChangeSenders.bind(this);
        this.handleChangeParentSenders = this.handleChangeParentSenders.bind(this);
        this.handleChangePaymentMethods = this.handleChangePaymentMethods.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        googleChartLoader.init(['corechart', 'controls']);
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.groupBy !== this.state.groupBy) {
            this.fetchData();
            return;
        }

        if (prevState.onlyPaylinks !== this.state.onlyPaylinks) {
            this.fetchData();
            return;
        }

        if (prevState.noMtpIds !== this.state.noMtpIds) {
            this.fetchData();
            return;
        }

        if (prevState.groupByField !== this.state.groupByField) {
            this.fetchData();
            return;
        }

        if (JSON.stringify(prevState.billingSenderIds) !== JSON.stringify(this.state.billingSenderIds)) {
            this.fetchData();
            return;
        }

        if (JSON.stringify(prevState.parentSenderIds) !== JSON.stringify(this.state.parentSenderIds)) {
            this.fetchData();
            return;
        }

        if (JSON.stringify(prevState.paymentMethodIds) !== JSON.stringify(this.state.paymentMethodIds)) {
            this.fetchData();
            return;
        }

        if (prevState.rows.length !== this.state.rows.length) {
            if (!this.state.isCommunicatingWithServer || this.state.rows.length === 0) {
                googleChartLoader.init().then(() => {
                    this.drawDashboard();
                });
            }
        }
    }

    fetchData() {
        this.setState({
            isCommunicatingWithServer: true,
            showError: false,
            rows: [],
        });

        fetchStats('documentCreated', {
            groupBy: this.state.groupBy,
            groupByField: this.state.groupByField,
            billingSenderIds: this.state.billingSenderIds,
            parentSenderIds: this.state.parentSenderIds,
            paymentMethodIds: this.state.paymentMethodIds,
            onlyPaylinks: this.state.onlyPaylinks,
            noMtpIds: this.state.noMtpIds,
        }).then(data => {
            const processedData = data.map(item => [
                new Date(item[0]),
                item[1], //Paid
                `${moment(item[0]).format('DD/MM/YYYY')}\nDocuments created paid: ${item[1]}\nTotal amount: ${formatCurrency(item[3])}`, //Paid tooltip
                item[2], //Unpaid
                `${moment(item[0]).format('DD/MM/YYYY')}\nDocuments created unpaid: ${item[2]}\nTotal amount: ${formatCurrency(item[4])}`, //Unpaid tooltip
            ]);
            this.setState({
                rows: processedData,
                isCommunicatingWithServer: false
            });
        }).catch((errorMessage) => {
            this.setState({
                isCommunicatingWithServer: false,
                showError: true,
            });
        });
    }

    handleChangeSenders(items) {
        this.setState({
            billingSenderIds: items.map(item => item.value),
        });
    }

    handleChangeParentSenders(items) {
        this.setState({
            parentSenderIds: items.map(item => item.value),
        });
    }

    handleChangePaymentMethods(items) {
        this.setState({
            paymentMethodIds: items.map(item => item.value),
        });
    }

    handleChange(attribute) {
        return value => {
            this.setState({
                [attribute]: value,
            });
        };
    }

    async handleDownload() {
        const response = await fetchStats('documentCreated', {
            groupBy: this.state.groupBy,
            groupByField: this.state.groupByField,
            billingSenderIds: this.state.billingSenderIds,
            parentSenderIds: this.state.parentSenderIds,
            paymentMethodIds: this.state.paymentMethodIds,
            onlyPaylinks: this.state.onlyPaylinks,
            noMtpIds: this.state.noMtpIds,
            format: 'xlsx',
        }, true);

        if (response.status === 400) {
            const data = await response.json();
            throw new Error(data.message);
        }

        if (response.status !== 200) {
            throw new Error(`${response.status}: ${response.statusText}. Contact a site administrator. This error probably won't go away by itself.`);
        }

        //fallback filename if no filename from server is received
        let filename = retrieveFilenameFromHeaders(response.headers, 'DocumentCreatedStats.xlsx');

        const blob = await response.blob();
        sendFileToBrowser(filename, blob);
    }

    drawDashboard() {
        const data = new window.google.visualization.DataTable();
        data.addColumn("date", 'Date');
        data.addColumn("number", 'Documents created paid');
        data.addColumn({type: 'string', role: 'tooltip'});
        data.addColumn("number", 'Documents created unpaid');
        data.addColumn({type: 'string', role: 'tooltip'});
        data.addRows(this.state.rows);

        const dashboard = new window.google.visualization.Dashboard(document.getElementById('DocumentCreatedStatsWrapper'));

        let end = this.state.rows.length > 0 ? this.state.rows[this.state.rows.length - 1][0] : new Date();
        let start = new Date(end);
        let format = 'dd/MM/yy';

        switch (this.state.groupBy) {
            case 'month':
                start.setFullYear(start.getFullYear() - 2);
                format = 'MMM y';
                break;
            case 'quarter':
                start.setFullYear(start.getFullYear() - 2);
                format = 'y Q';
                break;
            case 'year':
                start = this.state.rows.length > 0 ? this.state.rows[0][0] : new Date(); //assume that all rows can be shown when grouped by year
                format = 'y';
                break;
            default:
                start.setMonth(start.getMonth() - 2);
        }

        const chart = new window.google.visualization.ChartWrapper({
            chartType: 'ColumnChart',
            containerId: 'DocumentCreatedStats',
            options: {
                title: this.state.groupByField === 'paymentDate' ? 'Document paid' : 'Document created',
                legend: {
                    position: 'none',
                },
                height: this.state.height,
                chartArea: {
                    left: 40,
                    right: 0,
                    top: 30,
                    bottom: 30,
                },
                hAxis: {
                    format: format,
                },
                isStacked: true,
            },
        });

        const controls = new window.google.visualization.ControlWrapper({
            controlType: 'ChartRangeFilter',
            containerId: 'DocumentCreatedStatsControls',
            options: {
                filterColumnLabel: 'Date',
                ui: {
                    chartType: 'AreaChart',
                    chartOptions: {
                        isStacked: true,
                        height: 60,
                        chartArea: {
                            left: 40,
                            right: 10,
                            top: 0,
                            bottom: 20,
                        },
                        hAxis: {
                            'textPosition': 'out',
                            format: format,
                        },
                    },
                },
            },
            state: {
                range: {
                    start: start,
                    end: end,
                }
            },
        });

        new window.google.visualization.DateFormat({pattern: format}).format(data, 0);
        dashboard.bind([controls], [chart]);
        dashboard.draw(data);
    }

    render() {

        const height = this.state.height + 60;

        return (
            <div id="DocumentCreatedStatsWrapper">
                <Row form className="justify-content-end">
                    <Col style={{maxWidth: 400, marginBottom: 10}}>
                        <FetchFromContext property="paymentProviders">
                            {paymentMethods => (
                                <Select
                                    placeholder="Payment methods"
                                    value={this.state.paymentMethodIds}
                                    options={paymentMethods.map(paymentMethod => ({
                                        value: paymentMethod.id,
                                        label: paymentMethod.name,
                                    }))}
                                    onChange={this.handleChangePaymentMethods}
                                    multi
                                />
                            )}
                        </FetchFromContext>
                    </Col>
                    <Col style={{marginBottom: 10}}>
                        <FetchFromContext property="billingSenders">
                            {billingSenders => (
                                <Select
                                    placeholder="Billing senders"
                                    value={this.state.billingSenderIds}
                                    options={billingSenders.map(billingSender => ({
                                        value: billingSender.id,
                                        label: billingSender.name,
                                    }))}
                                    onChange={this.handleChangeSenders}
                                    multi
                                />
                            )}
                        </FetchFromContext>
                    </Col>
                    <Col style={{marginBottom: 10}}>
                        <FetchFromContext property="parentSenders">
                            {parentSenders => (
                                <Select
                                    placeholder="Parent senders"
                                    value={this.state.parentSenderIds}
                                    options={parentSenders.map(parentSender => ({
                                        value: parentSender.id,
                                        label: parentSender.name,
                                    }))}
                                    onChange={this.handleChangeParentSenders}
                                    multi
                                />
                            )}
                        </FetchFromContext>
                    </Col>
                </Row>
                <Row form className="justify-content-end">
                    <Col xs="auto" style={{marginBottom: 10}}>
                        <ButtonGroup size="sm">
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.noMtpIds} color="success" onClick={() => this.handleChange('noMtpIds')(!this.state.noMtpIds)}>No Mail to Pay Id</Button>
                        </ButtonGroup>
                    </Col>
                    <Col xs="auto" style={{marginBottom: 10}}>
                        <ButtonGroup size="sm">
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.groupByField === 'receivedDate'} color="success"onClick={() => this.handleChange('groupByField')('receivedDate')}>Created</Button>
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.groupByField === 'paymentDate'} color="success" onClick={() => this.handleChange('groupByField')('paymentDate')}>Paid</Button>
                        </ButtonGroup>
                    </Col>
                    <Col xs="auto" style={{marginBottom: 10}}>
                        <ButtonGroup size="sm">
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.onlyPaylinks} color="success" onClick={() => this.handleChange('onlyPaylinks')(!this.state.onlyPaylinks)}>Paylinks</Button>
                        </ButtonGroup>
                    </Col>
                    <Col xs="auto" style={{marginBottom: 10}}>
                        <ButtonGroup size="sm">
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.groupBy === 'day'} color="success" onClick={() => this.handleChange('groupBy')('day')}>Day</Button>
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.groupBy === 'month'} color="success" onClick={() => this.handleChange('groupBy')('month')}>Month</Button>
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.groupBy === 'quarter'} color="success" onClick={() => this.handleChange('groupBy')('quarter')}>Quarter</Button>
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.groupBy === 'year'} color="success" onClick={() => this.handleChange('groupBy')('year')}>Year</Button>
                        </ButtonGroup>
                    </Col>
                    <Col xs="auto">
                        <DesktopDownloadIcon onClick={this.handleDownload} style={{cursor: 'pointer'}}/>
                    </Col>
                </Row>
                <div style={{height: height + 'px'}}>
                    {this.state.isCommunicatingWithServer &&
                    <div className="d-flex flex-column justify-content-center h-100">
                        <Loader/>
                    </div>
                    }
                    {this.state.showError &&
                    <div className="d-flex flex-column justify-content-center h-100 text-center">
                        An error occured showing this graph. Try refreshing the page.
                    </div>
                    }
                    <div id="DocumentCreatedStats"/>
                    <div id="DocumentCreatedStatsControls"/>
                </div>
            </div>
        );

    }
}

export default DocumentCreatedStats;
