import React, {Fragment} from 'react';
import BillingEventsBillableTable from "./BillingEventsBillableTable";
import {withRouter} from 'react-router-dom';

const BillingEventsBillableRoute = ({history}) => {
    return (
        <Fragment>
            <h1 className="mb-5">Events</h1>
            <BillingEventsBillableTable history={history}/>
        </Fragment>
    );
};

export default withRouter(BillingEventsBillableRoute);