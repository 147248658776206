import React from 'react';
import PropTypes from 'prop-types';
import {fetchBillingReseller} from "../../../services";
import Fetch from "../../../Elements/Fetch";
import BillingResellerEditForm from "./BillingResellerEditForm";

const BillingResellerEditRoute = ({match, history, onSubmit}) => (
    <Fetch fetchFunction={fetchBillingReseller} fetchFunctionParams={match.params.id}>
        {billingReseller => {
            const handleSubmit = onSubmit || (() => history.push('/billing/resellers/' + billingReseller.id));

            return (
                <div style={{maxWidth: 800}}>
                    <BillingResellerEditForm
                        billingReseller={billingReseller}
                        onSubmit={handleSubmit}
                    />
                </div>
            );
        }}
    </Fetch>
);

BillingResellerEditRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default BillingResellerEditRoute;