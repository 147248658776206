import React, {useContext} from 'react';
import GenericCrudRoutes from "../GenericCrud/GenericCrudRoutes";
import {fetchExports} from "../services";
import {ModalContext} from "../Modal/ModalProvider";

const ExportRoutes = () => {
    const {showModal} = useContext(ModalContext);

    const tableColumns = {
        dateCreated: {
            name: 'Date',
            sort: true,
            style: {
                width: '170px',
            },
        },
        status: {
            name: 'Status',
            sort: true,
        },
        totalRows: {
            name: 'Results',
            sort: true,
        },
        type: {
            name: 'Type',
            sort: true,
        },
        reference: {
            name: 'Reference',
            sort: true,
        },
        downloadLink: {
            name: 'Download',
        },
    };

    const namedSearchParameters = {
        reference: {
            type: 'text',
            name: 'Reference',
            operators: ['eq', 'contains'],
        },
        type: {
            type: 'text',
            name: 'Type',
            operators: ['eq'],
        },
    };

    const handleOpenModal = (e, id) => {
        e.preventDefault();

        showModal({
            type: 'download',
            exportId: id,
        });
    };

    const fetchFunction = (searchParams) => {
        return fetchExports(searchParams)
            .then(data => {
                return {
                    ...data,
                    items: data.items.map(item => ({
                        ...item,
                        // eslint-disable-next-line
                        downloadLink: <a href="#" onClick={e => handleOpenModal(e, item.id)}>Download</a>,
                    })),
                };
            });
    };

    return (
        <GenericCrudRoutes
            basePath="exports"
            refreshKey="exports"

            overviewTitle="Exports"
            tableColumns={tableColumns}
            namedSearchParameters={namedSearchParameters}
            fetchFunction={fetchFunction}

            autoWidthColumns={['status', 'totalRows', 'type']}
        />
    );
};

export default ExportRoutes;
