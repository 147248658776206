import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Col, Row} from "reactstrap";
import {deleteInvoice, editInvoice, sendInvoiceToEF} from "../../../services";
import FormGroupRowText from "../../../Elements/FormGroupRowText";
import FormGroupRowCheckbox from "../../../Elements/FormGroupRowCheckbox";
import FormWithActions from "../../../Elements/FormWithActions/FormWithActions";
import {withRefresh} from "../../../Elements/Fetch";
import InvoicesTable from "../Invoices/InvoicesTable";

class InvoiceEditForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            reference: this.props.invoice.reference || '',
            locked: this.props.invoice.locked,
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleAction(action) {
        switch (action) {
            case 'submit':
                return editInvoice(this.props.invoice.id, this.state.reference, this.state.locked)
                    .then(data => {
                        this.props.triggerRefresh([`invoice:${this.props.invoice.id}`, 'invoices']);
                        this.props.onSubmit();
                        return data;
                    });
            case 'sendToEF':
                return sendInvoiceToEF(this.props.invoice.id)
                    .then(() => {
                        this.props.triggerRefresh([`invoice:${this.props.invoice.id}`, 'invoices']);
                        this.props.onSubmit();
                    });
            case 'delete':
                return deleteInvoice(this.props.invoice.id)
                    .then(data => {
                        this.props.history.push('/billing/invoices');
                        return data;
                    });
            default:
            //do nothing
        }
    }

    handleChange(value, attribute) {
        this.setState({
            [attribute]: value
        });
    }

    render() {


        //Can't send to EF if:
        //- No client id set
        //- Already sent to EF
        //- Total amount is zero


        const sendToEFdisabledReason = InvoicesTable.sendInvoiceDisabledReason(this.props.invoice);

        let deleteDisabledReason = '';
        if (this.props.invoice.locked) {
            deleteDisabledReason = 'This invoice is locked.';
        } else if (this.props.invoice.isMtpInvoice) {
            deleteDisabledReason = 'This invoice only contains MailToPay events and must be deleted by deleting the MailToPay file.';
        }

        const actions = [
            {
                action: 'delete',
                displayName: 'Delete',
                showFeedback: false,
                confirmMessage: 'Are you sure you want to delete this invoice? The events will be readded to the events page.',
                disabledReason: deleteDisabledReason,
            },
            {
                action: 'sendToEF',
                displayName: 'Send to Eenvoudig Factureren',
                showFeedback: false,
                confirmMessage: 'This will lock the invoice and send it to Eenvoudig Factureren. This cannot be undone.',
                disabledReason: sendToEFdisabledReason,
            },
        ];

        return (
            <Fragment>
                <Row>
                    <Col>
                        <h1 className="mb-5">
                            Edit invoice
                        </h1>
                    </Col>
                </Row>
                <FormWithActions onAction={this.handleAction} actions={actions}>

                    <FormGroupRowText
                        label="Reference"
                        placeholder="Reference"
                        value={this.state.reference}
                        onChange={value => this.handleChange(value, 'reference')}
                    />

                    <FormGroupRowCheckbox
                        label="Locked / Processed"
                        checked={this.state.locked}
                        onChange={checked => this.handleChange(checked, 'locked')}
                    />

                </FormWithActions>
            </Fragment>
        );
    }
}

InvoiceEditForm.propTypes = {
    invoice: PropTypes.shape({
        id: PropTypes.number.isRequired,
        reference: PropTypes.string,
        locked: PropTypes.bool.isRequired,
        externalReadableId: PropTypes.string,
        totalAmount: PropTypes.number.isRequired,
        billingSender: PropTypes.shape({
            externalClientId: PropTypes.string,
            taxRate: PropTypes.string,
        }).isRequired,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default withRouter(withRefresh(InvoiceEditForm));
