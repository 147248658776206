import React, {Component, Fragment} from 'react';
import {PlusIcon, XIcon} from 'react-octicons';
import {editPartnerSettings} from '../../services';
import PropTypes from 'prop-types';
import {Col, Row} from "reactstrap";
import {withRefresh} from "../../Elements/Fetch";
import FormWithActions from "../../Elements/FormWithActions/FormWithActions";
import classNames from 'classnames';

class PartnerSettingsForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            partnerSettings: props.partnerSettings.map(setting => ({...setting, deleted: false})),
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handlePaste = this.handlePaste.bind(this);
        this.handleDeleteSetting = this.handleDeleteSetting.bind(this);
        this.handleChangeSettings = this.handleChangeSettings.bind(this);
    }

    handleAction(action) {
        return editPartnerSettings(this.props.id, this.state.partnerSettings).then(data => {
            this.props.triggerRefresh(this.props.refreshKey);
            this.props.onSubmit();
            return data;
        });
    }

    handleChangeSettings(value, index, property) {
        this.setState((prevState, props) => ({
            partnerSettings: [
                ...prevState.partnerSettings.slice(0, index),
                {...prevState.partnerSettings[index], [property]: value},
                ...prevState.partnerSettings.slice(index + 1),
            ],
        }));
    }

    handlePaste(event, index) {
        const clipboardData = event.clipboardData.getData('Text').trim();
        if (clipboardData.includes("\t")) {
            event.preventDefault();

            let clipboardLines = [clipboardData];
            if (clipboardData.includes("\n")) {
                clipboardLines = clipboardData.split("\n");
            }
            if (clipboardData.includes("\r")) {
                clipboardLines = clipboardData.split("\r");
            }

            this.setState((prevState, props) => ({
                partnerSettings: [
                    ...prevState.partnerSettings,
                    ...clipboardLines.map(clipboardLine => {
                        const lineData = clipboardLine.split("\t");
                        return {
                            key: 'NEW',
                            category: lineData.shift().trim(),
                            name: lineData.shift().trim(),
                            value: lineData.join(' ').trim(),
                            deleted: false,
                        };
                    }),
                ],
            }));
        }
    }

    handleAdd() {
        this.setState((prevState, props) => ({
            partnerSettings: [...prevState.partnerSettings, {
                key: 'NEW',
                category: '',
                name: '',
                value: '',
                deleted: false,
            }],
        }));
    }

    handleDeleteSetting(index) {
        this.setState((prevState, props) => {
            //only keep deleted state for items that exists on the server
            if (prevState.partnerSettings[index].key === 'NEW') {
                return {
                    partnerSettings: [
                        ...prevState.partnerSettings.slice(0, index),
                        ...prevState.partnerSettings.slice(index + 1),
                    ],
                };
            } else {
                return {
                    partnerSettings: [
                        ...prevState.partnerSettings.slice(0, index),
                        {...prevState.partnerSettings[index], deleted: true},
                        ...prevState.partnerSettings.slice(index + 1),
                    ],
                };
            }
        });
    }

    render() {

        let disableSubmitReason = '';
        const partnerSettings = this.state.partnerSettings.map((setting, index) => {
            if (setting.deleted) {
                return "";
            }

            const hasOtherRowsWithSameSetting = this.state.partnerSettings.filter(s => s.category === setting.category && s.name === setting.name).length > 1;

            if (!setting.category || !setting.name || !setting.value) {
                disableSubmitReason = 'Attributes cannot be empty';
            }

            if (hasOtherRowsWithSameSetting) {
                disableSubmitReason = 'Duplicate attributes are not allowed';
            }

            return (
                <div className="form-group form-row" key={index}>
                    <div className="col">
                        <input
                            tabIndex="1"
                            type="text"
                            placeholder="category"
                            className={classNames("form-control", {
                                'is-invalid': !setting.category || hasOtherRowsWithSameSetting,
                            })}
                            value={setting.category}
                            onChange={event => this.handleChangeSettings(event.target.value, index, 'category')}
                            onPaste={(event) => this.handlePaste(event, index)}
                        />
                    </div>
                    <div className="col">
                        <input
                            tabIndex="1"
                            type="text"
                            placeholder="name"
                            className={classNames("form-control", {
                                'is-invalid': !setting.name || hasOtherRowsWithSameSetting,
                            })}
                            value={setting.name}
                            onChange={(event) => this.handleChangeSettings(event.target.value, index, 'name')}
                            onPaste={event => this.handlePaste(event, index)}
                        />
                    </div>
                    <div className="col">
                        <input
                            tabIndex="1"
                            type="text"
                            placeholder="value"
                            className={classNames('form-control', {
                                'is-invalid': !setting.value || hasOtherRowsWithSameSetting,
                            })}
                            value={setting.value}
                            onChange={event => this.handleChangeSettings(event.target.value, index, 'value')}
                            onPaste={(event) => this.handlePaste(event, index)}
                        />
                    </div>
                    <div className="col-1 col-form-label">
                        <button type="button" className="btn btn-link btn-sm" onClick={() => this.handleDeleteSetting(index)}>
                            <XIcon/>
                        </button>
                    </div>
                </div>
            );
        });

        return (
            <Fragment>
                <Row>
                    <Col>
                        <h1 className="mb-5">
                            Partner settings
                        </h1>
                    </Col>
                </Row>
                <FormWithActions onAction={this.handleAction} disableSubmitReason={disableSubmitReason}>

                    {partnerSettings}
                    <div className="form-group form-row">
                        <div className="col">
                            <button type="button" className="btn btn-link btn-sm" onClick={this.handleAdd}>
                                <PlusIcon/>
                            </button>
                        </div>
                    </div>

                </FormWithActions>
            </Fragment>
        );
    }
}

PartnerSettingsForm.propTypes = {
    id: PropTypes.number.isRequired,
    partnerSettings: PropTypes.array.isRequired,
    refreshKey: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default withRefresh(PartnerSettingsForm);
