import React from 'react';
import PropTypes from 'prop-types';
import BillingReportAddForm from "./BillingReportAddForm";
import {withRouter} from "react-router-dom";

const BillingReportAddRoute = ({history}) => {
    const handleSubmit = (data) => {
        history.push(`/billing/reports/${data.id}`);
    };

    return (
        <div style={{maxWidth: 800}}>
            <BillingReportAddForm onSubmit={handleSubmit}/>
        </div>
    );
};

BillingReportAddRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default withRouter(BillingReportAddRoute);