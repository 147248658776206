import React, {useEffect, useState} from 'react';
import {fetchSendersList} from '../../services';
import CreateSenderStepCounter from '../wizard/CreateSenderStepCounter';
import CreateSenderStepButtons from '../wizard/CreateSenderStepButtons';
import FormGroupRowText from "../../Elements/FormGroupRowText";
import FormGroupRowCheckbox from "../../Elements/FormGroupRowCheckbox";
import FormGroupRow from "../../Elements/FormGroupRow";
import Select from "react-virtualized-select";

const CreateSenderStepParent = (props: any) => {
    const parent = props?.parent;
    const accountSettings = props.accountSettings;
    const [parentName, setParentName] = useState(parent.parentName || '');
    const [newSenderParent, setNewSenderParent] = useState(parent.newSenderParent);
    const [senderParentId, setSenderParentId] = useState(parent?.senderParentId || '');
    const [senderList, setSenderList] = useState([]);

    useEffect(() => {
        fetchSendersList({isParent: true})
            .then(list => {
                setSenderList(list)
            })
            .catch(error => {
                setSenderList([]);
            });
    }, [])

    function getSenderList(): any[] {
        return senderList || [];
    }

    function updateBody(): void {
        props.updateBody({
            parent: {
                senderParentId: senderParentId,
                parentName: parentName,
                parentSenderType: accountSettings.senderType,
                newSenderParent: newSenderParent,
                parentEncryptionKey: accountSettings.newParentEncryptionKey || false
            }
        });
        loadAndMergeParentSettings();
    }

    function loadAndMergeParentSettings() {
        if (!accountSettings.newParent && senderParentId !== '') {
            props.loadAndMergeParentSettings(senderParentId);
        }
    }

    function isFormValid(): boolean {
        if (accountSettings.newParent) {
            return parentName !== '' && parentName !== undefined;
        }

        return senderParentId !== '';
    }

    function isCurrentStep(): boolean {
        return props.isActive;
    }

    function handleParentSelectChange(id: any) {
        let parent: any = senderList.find((s: any) => s.id === id);
        if(parent) {
            setParentName(parent.name);
            setSenderParentId(id);
            props.resetPartnerSettings();
        }
    }

    return (
        <div>
            <div className="card">
                <div className="card-header text-center">
                    <CreateSenderStepCounter {...props}></CreateSenderStepCounter>
                </div>
                {isCurrentStep() && (
                    <div className="card-body">
                        <h4>Parent Sender</h4>

                        {accountSettings.newParent &&
                            <>
                                <FormGroupRowCheckbox checked={newSenderParent}
                                                      onChange={setNewSenderParent}
                                                      label="New parent"
                                ></FormGroupRowCheckbox>


                            </>
                        }

                        {newSenderParent ?
                            <FormGroupRowText label="Parent name"
                                              required={true}
                                              value={parentName}
                                              onChange={setParentName}/> :
                            <FormGroupRow label="Parent" required={true}>
                                <Select
                                    value={senderParentId}
                                    onChange={(selection: any) => {
                                        if(selection) {
                                            handleParentSelectChange(selection.value);
                                        }
                                    }}
                                    options={[
                                        ...getSenderList().map(item => ({
                                            value: item.id,
                                            label: item.id + ': ' + item.name + ' (' + item.senderContractNumber + ')',
                                        })),
                                    ]}
                                    clearable={false}
                                    matchProp="label"
                                />

                            </FormGroupRow>
                        }

                        <CreateSenderStepButtons {...props}
                                                 isValid={isFormValid()}
                                                 onBack={updateBody}
                                                 onNext={updateBody}> </CreateSenderStepButtons>
                    </div>
                )}
            </div>

        </div>
    );

};
export default CreateSenderStepParent;