import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "reactstrap";
import {deleteBillingCreditsPurchase, editBillingCreditsPurchase} from "../../../services";
import {withRefresh} from "../../../Elements/Fetch";
import FormGroupRowDate from "../../../Elements/FormGroupRowDate";
import FormGroupRowText from "../../../Elements/FormGroupRowText";
import FormWithActions from "../../../Elements/FormWithActions/FormWithActions";

class BillingCreditsEditPurchaseForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            purchasedCredits: props.billingCreditsPurchase.purchasedCredits,
            purchaseAmount: props.billingCreditsPurchase.purchaseAmount || '',
            purchasedDate: new Date(props.billingCreditsPurchase.purchasedDate).toISOString().slice(0, 10),
            expirationDate: new Date(props.billingCreditsPurchase.expirationDate).toISOString().slice(0, 10),
            comment: props.billingCreditsPurchase.comment || '',
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleAction(action) {
        switch (action) {
            case 'submit':
                return editBillingCreditsPurchase(this.props.billingCreditsPurchase.id, {
                    billingCreditsPurchaseId: this.props.billingCreditsPurchase.id,
                    ...this.state,
                }).then(data => {
                    this.props.triggerRefresh(`billingCredits:${this.props.billingCreditsPurchase.billingSenderId}`);
                    this.props.onSubmit();
                    return data;
                });
            case 'delete':
                return deleteBillingCreditsPurchase(this.props.billingCreditsPurchase.id).then(data => {
                    this.props.triggerRefresh(`billingCredits:${this.props.billingCreditsPurchase.billingSenderId}`);
                    this.props.onSubmit();
                    return data;
                });
            default:

        }
    }

    handleChange(attribute) {
        return value => {
            this.setState({
                [attribute]: value,
            });
        };
    }

    render() {

        const actions = [
            {
                action: 'delete',
                displayName: 'Delete',
                confirmMessage: 'Are you sure you want to delete these purchased credits? This cannot be undone.',
            },
        ];

        return (
            <Fragment>
                <Row>
                    <Col>
                        <h1 className="mb-5">
                            Edit credits
                        </h1>
                    </Col>
                </Row>

                <FormWithActions onAction={this.handleAction} actions={actions}>

                    <FormGroupRowText
                        label="Credits"
                        value={this.state.purchasedCredits}
                        onChange={this.handleChange('purchasedCredits')}
                    />

                    <FormGroupRowText
                        label="Purchase amount"
                        value={this.state.purchaseAmount}
                        onChange={this.handleChange('purchaseAmount')}
                    />

                    <FormGroupRowDate
                        label="Purchase date"
                        value={this.state.purchasedDate}
                        onChange={this.handleChange('purchasedDate')}
                    />

                    <FormGroupRowDate
                        label="Expiration date"
                        value={this.state.expirationDate}
                        onChange={this.handleChange('expirationDate')}
                        description="Credits will expire at 00:00:00 of this date. This must be the first day of the month."
                    />

                    <FormGroupRowText label="Comment" value={this.state.comment} onChange={this.handleChange('comment')}/>

                </FormWithActions>
            </Fragment>
        );
    }
}

BillingCreditsEditPurchaseForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default withRefresh(BillingCreditsEditPurchaseForm);