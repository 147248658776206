import React, {Component, Fragment} from 'react';
import {BrowserRouter} from "react-router-dom";

import Login from './Login';
import LoggedInContainer from './LoggedInContainer';

import Loader from './Elements/Loader';

import Raven from 'react-raven';
import RavenJS from 'raven-js';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentUser: null,
            networkIssue: false,
            checkingAuthToken: true,
        };

        this._check_session = this._check_session.bind(this);
        this.logout = this.logout.bind(this);
        this.login = this.login.bind(this);
    }

    componentDidMount() {
        this._check_session();
    }

    _check_session(){
        if (!sessionStorage.authToken) {
            this.setState({
                checkingAuthToken: false,
            });
            return;
        }

        fetch(process.env.REACT_APP_BACKEND_URL + '/users/session', {
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                'X-Authentication': sessionStorage.authToken
            }
        })
            .then(response => {
                if (response.ok) {
                    response.json()
                        .then(json => {
                            this.setState({
                                checkingAuthToken: false,
                                currentUser: json,
                            });
                        });
                }
                else {
                    this.setState({
                        checkingAuthToken: false,
                    });
                }
            })
            .catch(() => {
                this.setState({
                    checkingAuthToken: false,
                    networkIssue: true,
                });
            });
    }

    logout() {
        fetch(process.env.REACT_APP_BACKEND_URL + '/users/session', {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
                'X-Authentication': sessionStorage.authToken
            }
        }).catch(errorMessage => {
            RavenJS.captureException(new Error(errorMessage));
        });

        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('roles');

        this.setState({
            currentUser: null,
        });
    };

    login() {
        this._check_session();
    }

    render() {
        let html = null;

        if (this.state.networkIssue) {
            html = (
                <div className="mx-auto align-self-center">
                    <div className="alert alert-danger alert-dismissible">There is a network issue or the server appears to be offline. Please try again later.</div>
                </div>
            );
        } else if (this.state.checkingAuthToken) {
            html = (
                <div className="container">
                    <div className="row h-100">
                        <div className="col">
                            <div className="d-flex flex-column justify-content-center h-100">
                                <Loader/>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (!this.state.currentUser) {
            html = (
                <Login login={this.login}/>
            );
        } else {
            html = (
                <BrowserRouter>
                    <LoggedInContainer logout={this.logout} currentUser={this.state.currentUser}/>
                </BrowserRouter>
            );
        }

        let raven = null;
        if (process.env.REACT_APP_POM_ENV !== 'dev') {
            raven = <Raven dsn="https://2620139b8a18446a819df5dae98a12a9@sentry.io/1248168"/>;
        }

        return (
            <Fragment>
                {raven}
                {html}
            </Fragment>
        );

    }
}

export default App;
