import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "reactstrap";
import {addBillingRule} from "../../../services";
import {withRefresh} from "../../../Elements/Fetch";
import BillingRuleForm from "../BillingRule/BillingRuleForm";

class BillingRuleAddForm extends Component {

    constructor(props) {
        super(props);

        this.handleAction = this.handleAction.bind(this);
    }

    handleAction(action, state) {
        switch (action) {
            case 'submit':
                return addBillingRule(state)
                    .then(data => {
                        this.props.triggerRefresh('billingRules');
                        this.props.onSubmit();
                        return data;
                    });
            default:

        }
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <h1 className="mb-5">
                            Add billing rule
                        </h1>
                    </Col>
                </Row>

                <BillingRuleForm
                    action={this.handleAction}
                />
            </Fragment>
        );
    }
}

BillingRuleAddForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default withRefresh(BillingRuleAddForm);