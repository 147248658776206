import React from 'react';
import Modal from "./Modal";
import ModalDownloadContainer from "./ModalDownloadContainer";

type Props = {
    onClose: () => void;
    visible: boolean;
    modalContents: ModalContentsType;
};

const ModalContainer = ({
                            visible,
                            onClose,
                            modalContents,
                        }: Props) => {
    let children: React.ReactNode = '';

    switch (modalContents.type) {
        case "download":
            children = (
                <ModalDownloadContainer
                    startDownloadFunction={modalContents.startDownloadFunction}
                    queryString={modalContents.queryString}
                    exportId={modalContents.exportId}
                    downloadModalDomainObjectName={modalContents.downloadModalDomainObjectName}
                    onClose={onClose}
                />
            );
            break;
        case "empty":
            //Leave children empty
            break;
        default:
            //Union Exhaustiveness checking (https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html)
            return assertNever(modalContents);
    }

    return (
        <Modal visible={visible} onClose={onClose}>
            {children}
        </Modal>
    );
};

const assertNever = (x: never): null => {
    return null;
};

export default ModalContainer;
