import React from 'react';
import PropTypes from 'prop-types';
import FormGroupRow from "./FormGroupRow";

const FormGroupRowCheckbox = ({label, description, checked, required, disabled, onChange, cssClasses}) => (
    <FormGroupRow label={label} required={required} description={description} cssClasses={cssClasses}>
        <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
            <input type="checkbox" disabled={disabled} checked={checked} onChange={event => onChange(event.target.checked)}/>
        </div>
    </FormGroupRow>
);

FormGroupRowCheckbox.propTypes = {
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    checked: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    cssClasses: PropTypes.array
};

export default FormGroupRowCheckbox;
