import React, {useState} from 'react';
import {Col, Row} from "reactstrap";
import FormWithActions from "../Elements/FormWithActions/FormWithActions";
import FormGroupRowSelect from "../Elements/FormGroupRowSelect";
import Field from "../Elements/Field";

const SenderOnboardingBuckarooSendFileForm = ({document, files, onAction}) => {
    const [fileId, setFileId] = useState();

    const handleAction = () => {
        return onAction(fileId);
    };

    return (
        <>
            <Row>
                <Col>
                    <h1 className="mb-5">
                        Buckaroo send document
                    </h1>
                </Col>
            </Row>
            <FormWithActions onAction={handleAction} disableSubmitReason={!fileId ? 'Select a file to continue' : ''}>
                <Field name="Document type">{document.documentType}</Field>
                <Field name="Document description">{document.documentDescription}</Field>
                <FormGroupRowSelect
                    label="Choose file"
                    value={fileId}
                    onChange={setFileId}
                    options={files.map(file => ({value: file.id, label: file.name}))}
                />
            </FormWithActions>
        </>
    );
};

export default SenderOnboardingBuckarooSendFileForm;