import React, {useContext} from 'react';
import Loader from "./Loader";
import {AppContext} from "../AppContext";

const FetchFromContext = ({property, children, className}) => {
    const appContext = useContext(AppContext);
    const data = appContext[property];

    if (data === undefined) {
        return (
            <div className={className}>
                <div className="col">
                    <div className="d-flex flex-column justify-content-center h-100">
                        <Loader/>
                    </div>
                </div>
            </div>
        );
    }

    return children(data);
};

export const fetchFromContext = () => {
    return new Promise((resolve, reject) => {

    });
};

export default FetchFromContext;
