import React, {useState} from 'react';
import FormGroupRowText from "../../Elements/FormGroupRowText";
import useSubmittingButton from "../../hooks/useSubmittingButton";
import {Col, Row} from "reactstrap";
import {createPaylinkSenderAndUpdateScanSender} from "../../services";

const CreatePaylinkSenderAndUpdateScanSender = () => {

    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [parentName, setParentName] = useState('');
    const [vatNumber, setVatNumber] = useState('');
    const [bankAccountNumber, setBankAccountNumber] = useState('');
    const [senderContractNumber, setSenderContractNumber] = useState('');
    const [website, setWebsite] = useState('');
    const [secret, setSecret] = useState('');
    const [availableChannels, setAvailableChannels] = useState('');

    const [paymentMethods, setPaymentMethods] = useState('');
    const [parentSenderContractNumber, setParentSenderContractNumber] = useState('');

    const [logoUrl, setLogoUrl] = useState('');
    const [scanLogoUrl, setScanLogoUrl] = useState('');

    const {errorMessage, loader, SubmittingButton} = useSubmittingButton();
    const [results, setResults] = useState(null);

    const handleSubmit = async () => {
        setResults(null);

        const data = await createPaylinkSenderAndUpdateScanSender({
            name: name.trim(),
            shortName: shortName.trim(),
            parentName: parentName.trim(),
            vatNumber: vatNumber.trim(),
            bankAccountNumber: bankAccountNumber.trim(),
            senderContractNumber: senderContractNumber.trim(),
            website: website.trim(),
            secret: secret.trim(),
            paymentMethods: paymentMethods.trim(),
            parentSenderContractNumber: parentSenderContractNumber.trim(),
            logoUrl: logoUrl.trim(),
            scanLogoUrl: scanLogoUrl.trim(),
            availableChannels: availableChannels.trim()
        });

        setResults(data);
    };

    const disabled =
        !name ||
        !shortName ||
        !parentName ||
        !vatNumber ||
        !bankAccountNumber ||
        !senderContractNumber ||
        !website ||
        !secret ||
        !paymentMethods ||
        !parentSenderContractNumber ||
        !logoUrl ||
        !scanLogoUrl ||
        !availableChannels ||
        results;

    return (
        <div style={{maxWidth: 750}}>
            <FormGroupRowText
                label="name *"
                required
                value={name}
                onChange={setName}
            />

            <FormGroupRowText
                label="shortName *"
                required
                value={shortName}
                onChange={setShortName}
            />


            <FormGroupRowText
                label="parentName *"
                required
                value={parentName}
                onChange={setParentName}
            />

            <FormGroupRowText
                label="vatNumber *"
                required
                value={vatNumber}
                onChange={setVatNumber}
            />

            <FormGroupRowText
                label="bankAccountNumber *"
                required
                value={bankAccountNumber}
                onChange={setBankAccountNumber}
            />

            <FormGroupRowText
                label="senderContractNumber *"
                required
                value={senderContractNumber}
                onChange={setSenderContractNumber}
            />

            <FormGroupRowText
                label="website *"
                required
                value={website}
                onChange={setWebsite}
            />

            <FormGroupRowText
                label="secret *"
                required
                value={secret}
                onChange={setSecret}
            />

            <FormGroupRowText
                label="paymentMethods *"
                required
                value={paymentMethods}
                onChange={setPaymentMethods}
            />

            <FormGroupRowText
                label="parentSenderContractNumber *"
                required
                value={parentSenderContractNumber}
                onChange={setParentSenderContractNumber}
            />

            <FormGroupRowText
                label="logoUrl *"
                required
                value={logoUrl}
                onChange={setLogoUrl}
            />

            <FormGroupRowText
                label="scanLogoUrl *"
                required
                value={scanLogoUrl}
                onChange={setScanLogoUrl}
            />

            <FormGroupRowText
                label="availableChannels *"
                required
                value={availableChannels}
                onChange={setAvailableChannels}
            />

            {errorMessage}

            <Row form className="align-items-center">
                <Col xs="auto">
                    <SubmittingButton onClick={handleSubmit} disabled={disabled}>Submit</SubmittingButton>
                </Col>
                <Col xs="auto">
                    {loader}
                </Col>
            </Row>

            <CreateSenderForScanSenderResults results={results}/>

        </div>
    );
};

const CreateSenderForScanSenderResults = ({results} : {results:any}) => {
    if (!results) return null;

    return (
        <div>
            <pre>{ JSON.stringify(results, null, 2) }</pre>
        </div>
    );
};

export default CreatePaylinkSenderAndUpdateScanSender;
