import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-virtualized-select';
import 'react-select/dist/react-select.css';
import "react-virtualized-select/styles.css";
import "./FormGroupRowLargeSelect.css";
import FormGroupRow from "./FormGroupRow";

const FormGroupRowLargeSelect = ({
                                     label,
                                     required,
                                     value,
                                     onChange,
                                     options,
                                     multi,
                                     addEmptyOption,
                                     description,
                                     selectAll,
                                     ...props
                                 }) => {
    if (multi && selectAll) {
        const handleSelectAll = e => {
            e.preventDefault();
            onChange(options.map(item => item.value));
        };

        //eslint-disable-next-line
        const selectAllLink = <a href="#" onClick={handleSelectAll}>Select all</a>;
        description = <>{description} {selectAllLink}</>;
    }

    return (
        <FormGroupRow label={label} required={required} description={description}>
            <Select
                {...props}
                value={value}
                multi={multi}
                onChange={selection => onChange(multi ? selection.map(item => item.value) : selection.value)}
                options={[...(addEmptyOption ? [{value: '', label: '(empty)'}] : []), ...options]}
                clearable={false}
                matchProp="label"
            />
        </FormGroupRow>
    );
};


FormGroupRowLargeSelect.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    required: PropTypes.bool,
};

export default FormGroupRowLargeSelect;