import React, {useState} from 'react';
import {runScript} from "../../services";
import useSubmittingButton from "../../hooks/useSubmittingButton";
import {Col, Row} from "reactstrap";
import FormGroupRowText from "../../Elements/FormGroupRowText";

const PaylinkDecoder = () => {
    const [paylink, setPaylink] = useState('');
    const [result, setResult] = useState(null);
    const {successMessage, errorMessage, loader, SubmittingButton} = useSubmittingButton();

    const handleSubmit = async () => {

        const response = await runScript('PaylinkDecoder', {
            paylink: paylink,
        });

        if (response.status === 200) {
            setPaylink('');

        } else {
            throw new Error(`${response.status}: ${response.statusText}.`);
        }
        setResult(await response.json())
    };
    const disabled = !paylink;

    return (
        <>
            <h4>Paylink Decoder</h4>

            {errorMessage}

            <div style={{maxWidth: 600}}>

                <FormGroupRowText
                    label="paylink"
                    value={paylink}
                    onChange={setPaylink}
                />

                {successMessage}

                <Row form className="align-items-center">
                    <Col xs="auto">
                        <SubmittingButton onClick={handleSubmit} disabled={disabled}>Submit</SubmittingButton>
                    </Col>
                    <Col xs="auto">
                        {loader}
                    </Col>
                </Row>
                <GetPaylinkDecodeResult result={result}/>
            </div>
        </>
    );
};
const GetPaylinkDecodeResult = ({result}) => {
    if (!result) return null;

    return (
        <div style={{width: '150%'}}>
            <pre>{ JSON.stringify(result, null, 2) }</pre>
        </div>
    );
};
export default PaylinkDecoder;
