import React from 'react';
import BillingReport from "./BillingReport";
import Fetch from "../../../Elements/Fetch";
import {fetchBillingReport} from "../../../services";

const BillingReportRoute = ({match}) => {
    return (
        <Fetch fetchFunction={fetchBillingReport} fetchFunctionParams={match.params.id} refreshKey={`billingReports:${match.params.id}`}>
            {billingReport => <BillingReport {...billingReport}/>}
        </Fetch>
    );
};

export default BillingReportRoute;