import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import {fetchSenders, startSendersDownload} from '../services';

import AbstractItemsTableWithLoader from '../Tables/AbstractItemsTableWithLoader';
import {ModalContext} from "../Modal/ModalProvider";

const SendersTable = () => {
    const {showModal} = useContext(ModalContext);

    const fetchFunction = (searchParams) => {
        return fetchSenders(searchParams).then(data => ({
            ...data,
            items: data.items.map(item => ({
                ...item,
                id: <Link to={"/senders/" + item.id}>{item.id}</Link>,
                parentId: item.parentId ? <Link to={"/senders/" + item.parentId}>{item.parentId}</Link> : null,
            })),
        }));
    };

    const tableColumns = {
        id: {
            name: 'Sender Id',
            sort: true,
        },
        dateCreated: {
            name: 'Created',
            sort: true,
            style: {
                width: '185px',
            },
        },
        name: {
            name: 'Name',
            sort: true,
            sortKey: 'sender.name',
        },
        parentId: 'Parent',
        senderContractNumber: {
            name: 'Sender contract number',
            sort: true,
            sortKey: 'sender.reference',
        },
        bankAccountNumber: {
            name: 'Bank account number',
            style: {
                width: '215px',
            },
            sort: true,
        },
        vatNumber: {
            name: 'Vat number',
            sort: true,
            sortKey: 'sender.vatNumber',
        },

        paymentMethods: 'Payment methods',
        billingSenderName: 'Billing sender',
    };

    const namedSearchParameters = {
        id: {
            type: 'text',
            name: 'Sender Id',
            operators: ['eq', 'lt', 'gt'],
        },
        'sender.name': {
            type: 'text',
            name: 'Name',
            operators: ['contains'],
        },
        'sender.reference': {
            type: 'text',
            name: 'SCN',
            operators: ['contains', 'notcontains'],
        },
        'parent.id': {
            type: 'text',
            name: 'Parent Id',
            operators: ['eq', 'lt', 'gt'],
        },
        'parent.sender.reference': {
            type: 'text',
            name: 'Parent SCN',
            operators: ['contains', 'notcontains'],
        },
        bankAccount: {
            type: 'text',
            name: 'Bank account number',
            operators: ['contains'],
        },
        'sender.vatNumber': {
            type: 'text',
            name: 'Vat number',
            operators: ['contains'],
        },
        'billingSender.name': {
            type: 'text',
            name: 'Billing sender',
            operators: ['contains'],
        },
        reportingId: {
            type: 'select',
            name: 'Reporting ID',
            optionsFetchFromStateProperty: 'reportingIds',
            operators: ['eq'],
            multiple: true,
        },
    };

    return <AbstractItemsTableWithLoader
        tableColumns={tableColumns}
        namedSearchParameters={namedSearchParameters}
        fetchFunction={fetchFunction}
        autoWidthColumns={['id', 'created', 'parentId', 'bankAccount', 'vatNumber', 'nbDocuments']}
        downloadModalFunc={startSendersDownload}
        downloadModalDomainObjectName="senders"
        showModal={showModal}
    />;
};

export default SendersTable;
