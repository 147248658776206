import React from 'react';
import {fetchSpeosReprintBatches, getMessageDownloadUrl} from '../../services';
import AbstractItemsTableWithLoader from '../../Tables/AbstractItemsTableWithLoader';
import {Link} from 'react-router-dom';

const SpeosReprintData = () => {
    const fetchFunction = (searchParams: any[]) => {
        return fetchSpeosReprintBatches(searchParams).then(data => ({
            ...data,
            items: data.items.map((item: any) => ({
                ...item,
                key: item.id,
                id: <Link to={"./reprint/" + item.id}>{item.id}</Link>,
                file: <a target="_blank" rel="noopener noreferrer" href={getMessageDownloadUrl(item.messageId)}>Download</a>,
            })),
        }));
    };

    const tableColumns = {
        id: 'Id',
        dateCreated: 'Created',
        file: 'File',
    };

    return (
        <AbstractItemsTableWithLoader
            tableColumns={tableColumns}
            fetchFunction={fetchFunction}
            autoWidthColumns={['id']}
            downloadModalDomainObjectName="speos"
        />
    );
};

export default SpeosReprintData;