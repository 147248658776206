import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './FormGroupRow.module.css';

const classNamesBind = classNames.bind(styles);

const FormGroupRow = ({label, description, required, children, errorMessage, cssClasses}) => (
    <div className={`form-group form-row ${cssClasses}`}>
        {
            label &&
            <label className={classNamesBind('col-3', 'col-form-label', {required: required})}>{label}</label>
        }
        <div className="col">
            {children}
            {
                description &&
                <div className="small text-muted">
                    <small>{description}</small>
                </div>
            }

            {
                errorMessage &&
                <div className="text-danger">
                    <small>{errorMessage}</small>
                </div>
            }
        </div>
    </div>
);

FormGroupRow.propTypes = {
    label: PropTypes.string.isRequired,
    description: PropTypes.any,
    required: PropTypes.bool,
    children: PropTypes.any,
    errorMessage: PropTypes.string,
    cssClasses: PropTypes.array
};

export default FormGroupRow;