import React, {useContext, useRef, useState} from 'react';
import {runScript, validEmail, readFile} from "../../services";
import {AppContext} from "../../AppContext";
import useSubmittingButton from "../../hooks/useSubmittingButton";
import {Col, Row} from "reactstrap";
import FormGroupRowText from "../../Elements/FormGroupRowText";

const Sms = () => {
    const {currentUser} = useContext(AppContext);
    const [email, setEmail] = useState(currentUser.email);
    const [scn, setScn] = useState('');
    const [validFile, setValidFile] = useState(false);
    const inputRef = useRef();
    const {successMessage, errorMessage, loader, SubmittingButton} = useSubmittingButton();

    const handleSubmit = async () => {
        const fileContents = await readFile(inputRef.current.files[0]);

        const response = await runScript('Optin', {
            email: email,
            fileContents: fileContents,
            scn: scn,
        });

        if (response.status === 200) {
            setScn('');
            setValidFile(false);
            inputRef.current.value = '';
            return "File successfully queued for processing.";
        } else {
            throw new Error(`${response.status}: ${response.statusText}`);
        }
    };

    const disabled = !validEmail(email) || !validFile || !scn;

    return (
        <>
            <h4>Optin</h4>

            {errorMessage}

            <form style={{maxWidth: 600}}>

                <FormGroupRowText
                    label="Email"
                    value={email}
                    onChange={setEmail}
                    placeholder="Email"
                />

                <div className="form-group row">
                    <label className="col-3 col-form-label">File</label>
                    <div className="col">
                        <input type="file" required ref={inputRef} onChange={e => setValidFile(!!e.target.value)} accept=".csv"/>
                    </div>
                </div>

                <FormGroupRowText
                    label="SCN"
                    value={scn}
                    onChange={setScn}
                />


                {successMessage}

                <Row form className="align-items-center">
                    <Col xs="auto">
                        <SubmittingButton onClick={handleSubmit} disabled={disabled}>Submit</SubmittingButton>
                    </Col>
                    <Col xs="auto">
                        {loader}
                    </Col>
                </Row>
            </form>
        </>
    );
};

export default Sms;
