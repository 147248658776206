import React from 'react';
import GenericCrudRoutes from "../GenericCrud/GenericCrudRoutes";
import {fetchMtpFiles, mtpDownloadFile} from "../services";
import moment from "moment";
import LinkToOverlay from "../Elements/Overlay/LinkToOverlay";
import {PencilIcon} from "react-octicons";

const MtpFileRoutes = () => {
    const tableColumns = {
        month: {
            name: 'month',
            sort: true,
        },
        name: {
            name: 'name',
        },
        edit: {
            name: 'Edit',
            style: {
                width: '60px',
            },
        },
    };

    const fetchFunction = async (searchParams) => {
        const data = await fetchMtpFiles(searchParams);

        return {
            ...data,
            items: data.items.map(item => ({
                ...item,
                month: moment(item.month).format('MMMM YYYY'),
                name: (
                    //eslint-disable-next-line
                    <a
                        href="#"
                        children={item.name}
                        onClick={async (e) => {
                            e.preventDefault();
                            await mtpDownloadFile(item.id);
                        }}
                    />
                ),
                edit: <LinkToOverlay overlay={`/billing/mtp-files/${item.id}/edit`}><PencilIcon/></LinkToOverlay>,
            })),
        };
    };

    return (
        <GenericCrudRoutes
            basePath="billing/mtp-files"
            refreshKey="mtp-files"

            overviewTitle="MTP Files"
            tableColumns={tableColumns}
            fetchFunction={fetchFunction}
            addOverlayRoute="/billing/mtp-files/add"
        />
    );
};

export default MtpFileRoutes;