import React, {Fragment, useState} from 'react';
import {Link} from "react-router-dom";
import LoadingButton from '../Elements/LoadingButton';
import Table from '../Elements/Table/Table';
import Field from '../Elements/Field';
import {fetchTasksByTransactionId, runScript} from '../services';
import AbstractItemsTableWithLoader from "../Tables/AbstractItemsTableWithLoader";
import {columNames} from "../Tasks/TaskTableConstants";
import LinkToOverlay from "../Elements/Overlay/LinkToOverlay";
import {PencilIcon} from "react-octicons";

const Transaction = ({
                         id,
                         dates,
                         reason,
                         amount,
                         netAmount,
                         fee,
                         status,
                         senderId,
                         senderName,
                         userId,
                         userName,
                         transactionAttributes,
                         paymentTxLog,
                         channel,
                         reportStatus,
                         canBeReported,
                         externalTransactionId,
                         documentId,
                         externalDocumentId,
                         mandateId,
                         transactionId,
                         internalId,
                         testingFeatureFlagEnabled,
                     }) => {
    const [reportSuccessfulPaymentMessage, setReportSuccessfulPaymentMessage] = useState('');

    const tasksFetchFunction = () => {
        return fetchTasksByTransactionId(transactionId).then(data => ({
            ...data,
            items: data.items.map(item => ({
                ...item,
                taskId: <Link to={"/tasks/" + item.taskId}>{item.taskId}</Link>,
            })),
        }));
    };

    const handleClick = async () => {
        const response = await runScript('ReportSuccessfulPayment', {transactionId: id});
        if (response.status !== 200) {
            setReportSuccessfulPaymentMessage(`Communication error with POM backend. ${response.status}: ${response.statusText}`);
            return;
        }

        const data = await response.json();

        setReportSuccessfulPaymentMessage(`Response from client webhook: ${data.status}: ${data.message}`);
    };

    const transactionAttributesHeaders = {
        name: '',
        value: '',
        dateCreated: ''
    };
    const paymentTxLogHeaders = {
        dateCreated: '',
        message: ''
    };
    const datesTableHeaders = {
        key: '',
        value: ''
    };
    let docLink = <Link to={`/document?id=${encodeURIComponent(documentId)}`}>{documentId}</Link>;

    let senderLink = null;
    if (senderId && senderName) {
        senderLink = <Link to={'/senders/' + senderId}>{senderName}</Link>;
    }

    let userLink = null;
    if (userId) {
        if (userName) {
            userLink = <Link to={'/users/' + userId}>{userName}</Link>;
        } else {
            userLink = <Link to={'/users/' + userId}>{userId}</Link>;
        }
    }

    return (
        <Fragment>
            <div className="form-row">
                <div className="col-auto">
                    <h1 className="mb-5">Transaction</h1>
                </div>
                <div className="col" style={{marginTop: '12px'}}>
                    { testingFeatureFlagEnabled && <LinkToOverlay overlay={`/transactions/${internalId}/edit`}><PencilIcon/></LinkToOverlay> }
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Field name='Transaction Id'>{id}</Field>
                    <Field name='External Transaction Id'>{externalTransactionId}</Field>
                    <Field name='Sender Document Id'>{externalDocumentId}</Field>
                    <Field name='Document Id'>{docLink}</Field>
                    <Table columns={transactionAttributesHeaders} items={transactionAttributes} hideHeader
                           name="Transaction Attributes" extraClassName='table-sm'/>
                    <Table columns={paymentTxLogHeaders} items={paymentTxLog} hideHeader name="Log"
                           extraClassName='table-sm'/>
                    <Field name='Sender'>{senderLink}</Field>
                    <Field name='User'>{userLink}</Field>
                </div>
                <div className="col">
                    {(canBeReported === true) && <div>
                        <Field name='Report Status'>{reportStatus}</Field>
                        <LoadingButton
                            color="danger"
                            onClick={handleClick}
                            text="report"
                        />
                        <div>{reportSuccessfulPaymentMessage}</div>
                    </div>
                    }
                    <Table columns={datesTableHeaders} items={dates} hideHeader name="dates" extraClassName='table-sm'/>
                    <Field name='Amount' inline>{amount}</Field>
                    <Field name='netAmount' inline>{netAmount}</Field>
                    <Field name='fee' inline>{fee}</Field>
                    <Field name='Reason' inline>{reason}</Field>
                    <Field name='Channel' inline>{channel}</Field>
                    {mandateId != null && (
                        <Field name='Mandate' inline>
                            <a href={"/mandates/" + mandateId}>{mandateId}</a>
                        </Field>
                    )}
                </div>
            </div>

            <AbstractItemsTableWithLoader
                name={'Tasks'}
                fetchFunction={tasksFetchFunction}
                tableColumns={columNames}
                hideFilters={true}

            />
        </Fragment>
    );
};

export default Transaction;
