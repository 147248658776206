import React, {useContext, useRef, useState} from 'react';
import {runScript, validEmail, readFile} from "../../services";
import {AppContext} from "../../AppContext";
import useSubmittingButton from "../../hooks/useSubmittingButton";
import {Col, Row} from "reactstrap";
import FormGroupRowCheckbox from "../../Elements/FormGroupRowCheckbox";
import FormGroupRowText from "../../Elements/FormGroupRowText";
import FormGroupRowSelect from "../../Elements/FormGroupRowSelect";

const Sms = () => {
    const {currentUser} = useContext(AppContext);
    const [email, setEmail] = useState(currentUser.email);
    const [dryRun, setDryRun] = useState(true);
    const [scn, setScn] = useState('');
    const [profile, setProfile] = useState('');
    const [validFile, setValidFile] = useState(false);
    const inputRef = useRef();
    const {successMessage, errorMessage, loader, SubmittingButton} = useSubmittingButton();

    const handleSubmit = async () => {
        const fileContents = await readFile(inputRef.current.files[0]);

        const response = await runScript('GenericSms', {
            email: email,
            fileContents: fileContents,
            filename: inputRef.current.files[0].name,
            scn: scn,
            dryRun: dryRun,
            profile: profile,
        });

        if (response.status === 200) {
            setScn('');
            setProfile('');
            setValidFile(false);
            inputRef.current.value = '';
            return "File successfully queued for processing.";
        } else {
            throw new Error(`${response.status}: ${response.statusText}. Contact a site administrator. This error probably won't go away by itself.`);
        }
    };

    const disabled = !validEmail(email) || !validFile || !scn || !profile;

    return (
        <>
            <h4>SMS</h4>

            {errorMessage}

            <form style={{maxWidth: 600}}>

                <FormGroupRowText
                    label="Email"
                    value={email}
                    onChange={setEmail}
                    placeholder="Email"
                />

                <div className="form-group row">
                    <label className="col-3 col-form-label">File</label>
                    <div className="col">
                        <input type="file" required ref={inputRef} onChange={e => setValidFile(!!e.target.value)} accept=".xlsx"/>
                    </div>
                </div>

                <FormGroupRowText
                    label="SCN"
                    value={scn}
                    onChange={setScn}
                />

                <FormGroupRowCheckbox
                    label="Dry run"
                    checked={dryRun}
                    onChange={setDryRun}
                />

                <FormGroupRowSelect
                    label="Profile"
                    onChange={setProfile}
                    value={profile}
                    options={[
                        {
                          value: 'LUMINUS',
                          label: 'LUMINUS ADD000031',
                        },
                        {
                            value: 'SECUREX',
                            label: 'SECUREX ADD000362',
                        },
                        {
                            value: 'ESSENT',
                            label: 'ESSENT ADD000157',
                        },
                        {
                            value: 'CHIREC',
                            label: 'CHIREC ADD001527',
                        },
                        {
                            value: 'JOLIMONT_LOBBES',
                            label: 'JOLIMONT_LOBBES ADD001911',
                        },
                        {
                            value: 'JOLIMONT_NIVELLES_TUBIZE',
                            label: 'JOLIMONT_NIVELLES_TUBIZE ADD001912',
                        },
                        {
                            value: 'JOLIMONT_MONS_HAINAUT',
                            label: 'JOLIMONT_MONS_HAINAUT ADD001913',
                        },
                    ]}
                />

                {successMessage}

                <Row form className="align-items-center">
                    <Col xs="auto">
                        <SubmittingButton onClick={handleSubmit} disabled={disabled}>Submit</SubmittingButton>
                    </Col>
                    <Col xs="auto">
                        {loader}
                    </Col>
                </Row>
            </form>
        </>
    );
};

export default Sms;
