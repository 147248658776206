import React, {useState} from 'react';
import {Button} from "reactstrap";
import Loader from "../Elements/Loader";

const useSubmittingButton = () => {

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const SubmittingButton = ({children, disabled, onClick}) => {

        const handleSubmit = async () => {
            setIsSubmitting(true);
            setErrorMessage('');
            setSuccessMessage('');

            try {
                const data = await onClick();
                if (data) {
                    setSuccessMessage(data);
                }
            } catch (errorMessage) {
                //make sure to cast errorMessage to a string
                setErrorMessage(String(errorMessage));
            }

            setIsSubmitting(false);
        };

        return (
            <Button color="danger" className="mr-2" onClick={handleSubmit} disabled={isSubmitting || disabled}>{children}</Button>
        );
    };

    return {
        successMessage: successMessage && (
            <div className="alert alert-success" role="alert">
                {successMessage}
            </div>
        ),
        errorMessage: errorMessage && (
            <div className="alert alert-danger" role="alert">
                {errorMessage}
            </div>
        ),
        loader: isSubmitting && <Loader color="danger" style={{width: '100px'}}/>,
        SubmittingButton: SubmittingButton,
    };
};

export default useSubmittingButton;
