import React, {Component} from 'react';
import {fetchStats} from '../services';
import Refresh from '../Icons/Refresh/Refresh';

class LastUpdatedStats extends Component {

    constructor(props) {
        super(props);

        this.state = {
            lastUpdated: '-',
            spinning: false,
        };

        this.fetchData = this.fetchData.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        fetchStats('lastUpdated').then(data => {
            if (data.lastUpdated) {
                this.setState({
                    lastUpdated: data.lastUpdated,
                });
            }
        }).catch(errorMessage => {
        });
    }

    handleRefresh() {
        this.setState({
            spinning: true,
        });

        fetchStats('forceCreateAllStats')
            .then(() => window.location.reload(true))
            .catch(errorMessage => {
                this.setState({
                    spinning: false,
                });
            });
    }

    render() {
        return (
            <div className="text-right">
                <span>Last updated: {this.state.lastUpdated}</span>
                <Refresh className="ml-1" onClick={this.handleRefresh} spinning={this.state.spinning}/>
            </div>
        );
    }
}

export default LastUpdatedStats;