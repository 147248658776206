import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import BillingResellerAddForm from "./BillingResellerAddForm";
import {AppContext} from "../../../AppContext";

const BillingResellerAddRoute = ({match, history, onSubmit}) => {
    const {triggerAppStateRefresh} = useContext(AppContext);

    const handleSubmit = () => {
        triggerAppStateRefresh('downloadBillingResellers');

        if (onSubmit) {
            onSubmit();
        } else {
            history.push('/billing/resellers');
        }
    };

    return (
        <div style={{maxWidth: 800}}>
            <BillingResellerAddForm
                onSubmit={handleSubmit}
            />
        </div>
    );
};

BillingResellerAddRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default BillingResellerAddRoute;