import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import BillingSenderEditRoute from "./BillingSenderEdit/BillingSenderEditRoute";
import BillingSenderAddRoute from "./BillingSenderAdd/BillingSenderAddRoute";
import OverlayRoute from "../../Elements/Overlay/OverlayRoute";
import BillingContactsAddRoute from "./BillingContactAdd/BillingContactsAddRoute";
import BillingContactEditRoute from "./BillingContactEdit/BillingContactEditRoute"

const BillingSenderOverlayRoutes = ({closeOverlay}) => {
    return (
        <Fragment>
            <OverlayRoute exact path="/billing/senders/add" closeOverlay={closeOverlay} render={({match}) => (
                <BillingSenderAddRoute match={match} onSubmit={closeOverlay}/>
            )}/>

            <OverlayRoute exact path="/billing/senders/:id/edit" closeOverlay={closeOverlay} render={({match}) => (
                <BillingSenderEditRoute match={match} onSubmit={closeOverlay}/>
            )}/>
            <OverlayRoute exact path="/billing/senders/:id/contact/add" closeOverlay={closeOverlay} render={({match}) => (
                <BillingContactsAddRoute match={match} onSubmit={closeOverlay}/>
            )}/>
            <OverlayRoute exact path="/billing/senders/:id/contact/:contactId/edit" closeOverlay={closeOverlay} render={({match}) => (
                <BillingContactEditRoute match={match} onSubmit={closeOverlay}/>
            )}/>
        </Fragment>
    );
};

BillingSenderOverlayRoutes.propTypes = {
    closeOverlay: PropTypes.func.isRequired,
};

export default BillingSenderOverlayRoutes;