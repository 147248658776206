import {allPartnerSettings, PartnerSetting} from "./steps/partnersettings/partnerSettings";

export function prepareDataForSubmit(data: any) {
    let clonedData = JSON.parse(JSON.stringify(data));
    getOnlyRequiredSettings(clonedData);
    removeDuplicatedPartnerSettings(clonedData);
    checkMerchantProviders(clonedData);
    copyPartnerSettingsFromMerchantProviders(clonedData);
    convertArrayValueToString(clonedData);
    removeUnnecessaryPartnerSettings(clonedData);
    addExtraRequiredSettings(clonedData);
    return clonedData;
}

function getOnlyRequiredSettings(cloneData: any) {
    cloneData.partnerSettings = cloneData.partnerSettings.filter((ps: any) => (ps.value || ps.value.length > 0));
}

const addExtraRequiredSettings = (cloneData: any) => {
    let extraRequiredSettings = new Map()
        .set('paypage.optin.channels', 'maildelivery.klarna.template');
    extraRequiredSettings.forEach((reqSettingName, settingName) => {
        let settingExistInParent = cloneData.parentSettings.find((s: PartnerSetting) => s.name === settingName);
        let settingExistInChild = cloneData.partnerSettings.find((s: PartnerSetting) => s.name === settingName);

        let requiredSettingExistInChild = cloneData.partnerSettings.find((s: PartnerSetting) => s.name === reqSettingName);
        let requiredSettingExistInParent = cloneData.parentSettings.find((s: PartnerSetting) => s.name === reqSettingName);

        let settingExist = settingExistInParent || settingExistInChild;
        let requiredSettingExist = requiredSettingExistInChild || requiredSettingExistInParent;
        if (settingExist && !requiredSettingExist) {
            cloneData.partnerSettings.push(allPartnerSettings.find(s => s.name === reqSettingName));
        }
    })
}

function convertArrayValueToString(clonedData: any) {
    clonedData.partnerSettings.forEach((ps: any) => {
        if (ps.type === 'multi-list') {
            ps.value = ps.value.join(',');
        }
    });
    if (!clonedData.parent.senderParentId) {
        clonedData.parentSettings.forEach((ps: any) => {
            if (ps.type === 'multi-list') {
                ps.value = getCorrectSettingValue(ps.value);
            }
        });
    }
}

function getCorrectSettingValue(value: any) {
    if (Array.isArray(value)) {
        return (value as string[]).join(',');
    }
    return value;
}

function checkMerchantProviders(clonedData: any) {
    let hasAttributeValue = clonedData.merchant.providers[0]?.attributes[0].value !== '';
    if (!hasAttributeValue) {
        clonedData.merchant.providers = [];
    }
}

function copyPartnerSettingsFromMerchantProviders(cloneData: any) {

    let paymentOptions: any[] = [];
    let paymentMethods: any[] = [];

    cloneData.merchant.paymentFlows.forEach((p: any) => {
        if(p.isSelected) {
            // @ts-ignore
            paymentOptions = [...new Set([...paymentOptions, p.option])];
            // @ts-ignore
            paymentMethods = [...new Set([...paymentMethods, p.method])];
        }
    })

    cloneData.partnerSettings.push({category: 'paylink', name: 'paypage.paymentOptions', value: paymentOptions.join(',')});
    cloneData.partnerSettings.push({category: 'paylink', name: 'pay-directly.paymentMethods', value: paymentMethods.join(',')});
}

function removeDuplicatedPartnerSettings(clonedData: any) {
    let duplicatedSettingNames = [
        'available.channels', 'shortlink.channels', 'successCallbackUrl',
        'paypage.optin.channels', 'maildelivery', 'redirectUrl'
    ];

    duplicatedSettingNames.forEach(settingName => {
        let settingExistInParent = clonedData.parentSettings.find((s: any) => s.name === settingName);
        let settingExistInChild = clonedData.partnerSettings.find((s: any) => s.name === settingName);
        if (settingExistInParent && settingExistInChild) {
            let valueInParent = JSON.stringify(getCorrectSettingValue(settingExistInParent.value));
            let valueInChild = JSON.stringify(getCorrectSettingValue(settingExistInChild.value));
            if (valueInParent === valueInChild) {
                let indexSetting = clonedData.partnerSettings.findIndex((s: any) => s.name === settingName);
                clonedData.partnerSettings.splice(indexSetting, 1);
            }
        }
    });
}

function removeUnnecessaryPartnerSettings(clonedData: any) {
    let unnecessaryPartnerSettings = ['maildelivery.logo.url'];

    unnecessaryPartnerSettings.forEach(settingName => {
        let settingIndexInChild = clonedData.partnerSettings.findIndex((s: any) => s.name === settingName);
        if (settingIndexInChild > -1) {
            clonedData.partnerSettings.splice(settingIndexInChild, 1);
        }
    });

}

export function covertToLabel(option: string): string {
    let converted = option
        .replace(/\./g, ' ')
        .split(/(?=[A-Z])/)
        .join(' ')
        .toLowerCase();
    return converted.charAt(0).toUpperCase() + converted.slice(1).toLowerCase();
}

export function getFormattedIban(iban: string) {
    let countryCode = iban.substring(0, 2);
    // @ts-ignore
    let format = CODE_FORMAT[countryCode];

    if (format) {
        return iban.replace(format, "$1 $2 $3 $4");
    }
    return iban;
}

const CODE_FORMAT = {
    BE: /(\d{2})(\d{4})(\d{4})(\d{4})/
};
