import React, {Fragment, useEffect, useState} from 'react';
import DocumentCreatedStats from './Stats/DocumentCreatedStats';
import DocumentBySenderStats from './Stats/DocumentBySenderStats';
import LastUpdatedStats from "./Stats/LastUpdatedStats";
import {Row, Col} from 'reactstrap';
import SenderCreatedStats from "./Stats/SenderCreatedStats";
import PaymentSpeedStats from "./Stats/PaymentSpeedStats";
import PaymentHeatMapStats from "./Stats/PaymentHeatMapStats";
import DocumentPaymentDateDueDateDifferenceStats from "./Stats/DocumentPaymentDateDueDateDifferenceStats";
import DocumentCountProgressBySenderStats from "./Stats/DocumentCountProgressBySenderStats";
import {fetchApplicationInfo} from "./services";

const Home = () => {
    const [appInfo, setAppInfo] = useState(undefined);
    useEffect(() => {
        fetchApplicationInfo()
            .then(res => {
                let updatedAppInfo = {};
                if (typeof res.buildNumber === "string") {
                    updatedAppInfo.buildNumber = res.buildNumber;
                }

                if (typeof res.buildDate === "string") {
                    updatedAppInfo.buildDate = res.buildDate;
                }
                setAppInfo(updatedAppInfo);
            })
            .catch(error => {
                setAppInfo(null);
            })
    }, []);
    return (
        <Fragment>
            <h1 className="mb-5">Welcome</h1>
            <Row className="mb-5">
                <Col>
                    <LastUpdatedStats/>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col>
                    <DocumentCreatedStats height="200"/>
                </Col>
                <Col>
                    <SenderCreatedStats height="200"/>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col>
                    <PaymentSpeedStats height="200"/>
                </Col>
                <Col>
                    <PaymentHeatMapStats height="200"/>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col>
                    <DocumentPaymentDateDueDateDifferenceStats height="200"/>
                </Col>
                <Col>
                    <DocumentCountProgressBySenderStats height="200"/>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col>
                    <DocumentBySenderStats height="400"/>
                </Col>
            </Row>

            {appInfo?.buildNumber &&
                <span className='text-muted'>Build version:  <a rel="noopener noreferrer"
                                                                href={`https://bitbucket.org/Addocted/ghost/pipelines/results/${appInfo.buildNumber}`}
                                                                target="_blank">{appInfo.buildNumber}</a>&nbsp;</span>
            }

            {appInfo?.buildDate &&
                <span className='text-muted'>{`Build date: ${appInfo.buildDate}`}</span>
            }

        </Fragment>
    )
};

export default Home;
