import React from 'react';
import PropTypes from 'prop-types';
import {fetchBuckarooRequest} from "../services";
import Fetch from "../Elements/Fetch";
import Field from "../Elements/Field";

const SenderOnboardingBuckarooRequestRoute = ({match, onSubmit}) => (
    <Fetch fetchFunction={fetchBuckarooRequest} fetchFunctionParams={match.params.id}>
        {({dateCreated, requestPath, requestJSON, responseStatusCode, responseJSON}) => {

            let requestBody = requestJSON;
            try {
                requestBody = JSON.stringify(JSON.parse(requestJSON), null, 2);
            } catch (e) {
                //Fail silently and just show raw data
            }

            let responseBody = responseJSON;
            try {
                responseBody = JSON.stringify(JSON.parse(responseJSON), null, 2)
            } catch (e) {
                //Fail silently and just show raw data
            }

            return (
                <div>
                    <Field name='Date Created'>{dateCreated}</Field>
                    <Field name='Response Code'>{responseStatusCode}</Field>
                    <Field name='Request Path'>{requestPath}</Field>
                    <Field name='Request'>
                        <pre>{requestBody}</pre>
                    </Field>
                    <Field name='Response'>
                        <pre>{responseBody}</pre>
                    </Field>
                </div>
            );
        }}
    </Fetch>
);

SenderOnboardingBuckarooRequestRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default SenderOnboardingBuckarooRequestRoute;