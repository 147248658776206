import React, {useState} from 'react';
import {MaintenanceModeType} from "../MaintenanceMode";
import {Col, Row} from "reactstrap";
import FormWithActions from "../../Elements/FormWithActions/FormWithActions";
import FormGroupRowCheckbox from "../../Elements/FormGroupRowCheckbox";
import FormGroupRowTextArea from "../../Elements/FormGroupRowTextArea";
import FormGroupRowDateTime from "../../Elements/FormGroupRowDateTime";
import moment from "moment";

type Props = {
    maintenanceMode: MaintenanceModeType;
    onSubmit: (maintenanceMode: MaintenanceModeType) => Promise<void>;
};

const MaintenanceModeForm = ({
                                 maintenanceMode,
                                 onSubmit,
                             }: Props) => {
    const [enabled, setEnabled] = useState(maintenanceMode.enabled);
    const [from, setFrom] = useState(maintenanceMode.from);
    const [until, setUntil] = useState(maintenanceMode.until);
    const [markdownNL, setMarkdownNL] = useState(maintenanceMode.markdownNL);
    const [markdownFR, setMarkdownFR] = useState(maintenanceMode.markdownFR);
    const [markdownEN, setMarkdownEN] = useState(maintenanceMode.markdownEN);

    const handleAction = () => {
        return onSubmit({enabled, from, until, markdownNL, markdownFR, markdownEN});
    };

    let disableSubmitReason = '';
    if (enabled) {
        if (!from) {
            disableSubmitReason = 'From is required';
        } else if (!until) {
            disableSubmitReason = 'Until is required';
        } else if (moment(from).isAfter(moment(until))) {
            disableSubmitReason = 'Until must be after from';
        }
    }

    return (
        <>
            <Row>
                <Col>
                    <h1 className="mb-5">
                        Edit maintenance mode
                    </h1>
                </Col>
            </Row>
            <FormWithActions onAction={handleAction} disableSubmitReason={disableSubmitReason}>
                <FormGroupRowCheckbox
                    label="Enabled"
                    checked={enabled}
                    onChange={setEnabled}
                />
                <FormGroupRowDateTime
                    label="From"
                    value={from ? moment(from).format('YYYY-MM-DD[T]HH:mm') : ''}
                    onChange={value => setFrom(value && moment(value).format())}
                />
                <FormGroupRowDateTime
                    label="Until"
                    value={until ? moment(until).format('YYYY-MM-DD[T]HH:mm') : ''}
                    onChange={value => setUntil(value && moment(value).format())}
                />
                <FormGroupRowTextArea
                    value={markdownNL || ''}
                    onChange={setMarkdownNL}
                    label="Message NL"
                    rows={15}
                    description={<span>Markdown Cheat Sheet: <a href='https://www.markdownguide.org/cheat-sheet/' target="_blank" rel="noopener noreferrer">https://www.markdownguide.org/cheat-sheet/</a>. We only support header H1, bold, italic and link. Other markdown syntax elements are not tested.</span>}
                />
                <FormGroupRowTextArea
                    value={markdownFR || ''}
                    onChange={setMarkdownFR}
                    label="Message FR"
                    rows={15}
                    description={<span>Markdown Cheat Sheet: <a href='https://www.markdownguide.org/cheat-sheet/' target="_blank" rel="noopener noreferrer">https://www.markdownguide.org/cheat-sheet/</a>. We only support header H1, bold, italic and link. Other markdown syntax elements are not tested.</span>}
                />
                <FormGroupRowTextArea
                    value={markdownEN || ''}
                    onChange={setMarkdownEN}
                    label="Message EN"
                    rows={15}
                    description={<span>Markdown Cheat Sheet: <a href='https://www.markdownguide.org/cheat-sheet/' target="_blank" rel="noopener noreferrer">https://www.markdownguide.org/cheat-sheet/</a>. We only support header H1, bold, italic and link. Other markdown syntax elements are not tested.</span>}
                />
            </FormWithActions>
        </>
    );
};

export default MaintenanceModeForm;