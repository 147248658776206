import React, {Component} from 'react';
import {fetchStats, formatDate} from '../services';
import googleChartLoader from '../GoogleChartLoader';
import Loader from '../Elements/Loader';
import {Button, ButtonGroup, Row, Col} from 'reactstrap';
import Select from 'react-virtualized-select';
import FetchFromContext from "../Elements/FetchFromContext";
import interpolate from 'color-interpolate';

class PaymentHeatMapStats extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isCommunicatingWithServer: true,
            showError: false,
            rows: [],
            date: formatDate(new Date()),
            nbPayments: 0,
            groupBy: 'month',
            billingSenderIds: [],
            channels: [],
            height: this.props.height ? parseInt(this.props.height, 10) : 400,
        };

        this.drawDashboard = this.drawDashboard.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleChangeSenders = this.handleChangeSenders.bind(this);
        this.handleChangeChannels = this.handleChangeChannels.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);

        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        googleChartLoader.init(['corechart']);
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.groupBy !== this.state.groupBy) {
            this.fetchData();
            return;
        }

        if (prevState.date !== this.state.date) {
            this.fetchData();
            return;
        }

        if (JSON.stringify(prevState.billingSenderIds) !== JSON.stringify(this.state.billingSenderIds)) {
            this.fetchData();
            return;
        }

        if (JSON.stringify(prevState.channels) !== JSON.stringify(this.state.channels)) {
            this.fetchData();
            return;
        }

        if (prevState.rows.length !== this.state.rows.length) {
            googleChartLoader.init().then(() => {
                this.drawDashboard();
            });
        }
    }

    fetchData() {
        this.setState({
            isCommunicatingWithServer: true,
            showError: false,
            rows: [],
        });

        fetchStats('paymentHeatMap', {
            groupBy: this.state.groupBy,
            billingSenderIds: this.state.billingSenderIds,
            channels: this.state.channels,
            date: this.state.date || formatDate(new Date()),
        })
            .then(data => {
                const processedData = [];

                var i = 0;
                var j = 0;
                let maxValue = 0;
                let nbPayments = 0;

                for (i = 1; i <= 7; i++) {
                    for (j = 0; j < 12; j++) {
                        maxValue = Math.max(maxValue, data[i][2 * j + 1]);
                        nbPayments += data[i][2 * j + 1];
                    }
                }

                const colormap = interpolate(['white', 'red']);
                for (i = 1; i <= 7; i++) {
                    for (j = 0; j < 12; j++) {
                        const relativeValue = maxValue ? data[i][2 * j + 1] / maxValue : 0;
                        processedData.push([
                            2 * j + 1,
                            i,
                            `Payments: ${data[i][2 * j + 1]}`,
                            `color: ${colormap(relativeValue)}; stroke-width: 1; stroke-color: rgb(204, 204, 204);`,
                        ]);
                    }
                }

                this.setState({
                    isCommunicatingWithServer: false,
                    rows: processedData,
                    nbPayments: nbPayments,
                });
            })
            .catch(() => {
                this.setState({
                    isCommunicatingWithServer: false,
                    showError: true,
                });
            });
    }

    drawDashboard() {
        const data = new window.google.visualization.DataTable();
        data.addColumn("number", 'Time');
        data.addColumn("number", 'Day');
        data.addColumn({type: 'string', role: 'tooltip'});
        data.addColumn({type: 'string', role: 'style'});
        data.addRows(this.state.rows);

        const options = {
            title: 'Payment heat map',
            legend: {
                position: 'none',
            },
            height: this.state.height,
            chartArea: {
                left: 30,
                right: 0,
                top: 20,
                bottom: 30,
            },
            vAxis: {
                viewWindow: {
                    min: 0,
                    max: 8,
                },
                ticks: [
                    {v: 1, f: 'Zo'},
                    {v: 2, f: 'Za'},
                    {v: 3, f: 'Vr'},
                    {v: 4, f: 'Do'},
                    {v: 5, f: 'Wo'},
                    {v: 6, f: 'Di'},
                    {v: 7, f: 'Ma'},
                ],
            },
            hAxis: {
                viewWindow: {
                    min: 0,
                    max: 24,
                },
                ticks: [
                    {v: 1, f: '00h'},
                    {v: 3, f: '02h'},
                    {v: 5, f: '04h'},
                    {v: 7, f: '06h'},
                    {v: 9, f: '08h'},
                    {v: 11, f: '10h'},
                    {v: 13, f: '12h'},
                    {v: 15, f: '14h'},
                    {v: 17, f: '16h'},
                    {v: 19, f: '18h'},
                    {v: 21, f: '20h'},
                    {v: 23, f: '22h'},
                ],
            },
            pointSize: 12,
        };

        const chart = new window.google.visualization.ScatterChart(document.getElementById('PaymentHeatMapStats'));
        chart.draw(data, options);
    }

    handleChangeSenders(items) {
        this.setState({
            billingSenderIds: items.map(item => item.value),
        });
    }

    handleChangeChannels(items) {
        this.setState({
            channels: items.map(item => item.value),
        });
    }

    handleChangeDate(value) {
        this.setState({
            date: value,
        });
    }

    handleClick(groupBy) {
        this.setState({
            groupBy: groupBy,
        });
    }

    render() {

        const height = this.state.height + 60;

        return (
            <div id="PaymentSpeedStatsWrapper">
                <Row>
                    <Col>
                        <FetchFromContext property="billingSenders">
                            {billingSenders => (
                                <Select
                                    placeholder="Billing senders"
                                    value={this.state.billingSenderIds}
                                    options={billingSenders.map(billingSender => ({
                                        value: billingSender.id,
                                        label: billingSender.name,
                                    }))}
                                    onChange={this.handleChangeSenders}
                                    multi
                                />
                            )}
                        </FetchFromContext>
                    </Col>
                    <Col xs="auto" style={{width: 150}}>
                        <FetchFromContext property="channels">
                            {channels => (
                                <Select
                                    placeholder="Channels"
                                    value={this.state.channels}
                                    options={channels.map(channel => ({
                                        value: channel,
                                        label: channel,
                                    }))}
                                    onChange={this.handleChangeChannels}
                                    multi
                                />
                            )}
                        </FetchFromContext>
                    </Col>
                    <Col xs="auto">
                        <input type="date" className="form-control form-control-sm" value={this.state.date} onChange={event => this.handleChangeDate(event.target.value)}/>
                    </Col>
                    <Col xs="auto">
                        <ButtonGroup size="sm">
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.groupBy === 'day'} color="success" onClick={() => this.handleClick('day')}>Day</Button>
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.groupBy === 'month'} color="success" onClick={() => this.handleClick('month')}>Month</Button>
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.groupBy === 'quarter'} color="success" onClick={() => this.handleClick('quarter')}>Quarter</Button>
                            <Button outline disabled={this.state.isCommunicatingWithServer} active={this.state.groupBy === 'year'} color="success" onClick={() => this.handleClick('year')}>Year</Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col/>
                    <Col xs="auto">
                        # payments: {this.state.nbPayments}
                    </Col>
                </Row>
                <div style={{height: height + 'px'}}>
                    {this.state.isCommunicatingWithServer &&
                    <div className="d-flex flex-column justify-content-center h-100 text-center">
                        <Loader/>
                    </div>
                    }
                    {this.state.showError &&
                    <div className="d-flex flex-column justify-content-center h-100 text-center">
                        An error occured showing this graph. Try refreshing the page.
                    </div>
                    }
                    <div id="PaymentHeatMapStats"/>
                </div>
            </div>
        );

    }
}

export default PaymentHeatMapStats;