import React from 'react';

type Props = {
    values: any[];
    name: string;
    children?: (value: any) => any;
};

const FieldMultiple = ({
                           values,
                           name,
                           children,
                       }: Props) => {
    if (values.length === 0) {
        return (
            <div className="mb-3">
                <div className="font-weight-bold">{name}</div>
                <small className="font-italic text-muted">(empty)</small>
            </div>
        );
    }

    return (
        <div className="mb-3">
            <div className="font-weight-bold">{name}</div>
            <ul>
                {values.map((value, index) => {
                    if (children) {
                        return <li key={index}>{children(value)}</li>;
                    } else {
                        return <li key={index}>{value}</li>;
                    }
                })}
            </ul>
        </div>
    );
};

export default FieldMultiple;