import React from 'react';
import CreateTemplateStepCounter from '../wizard/CreateTemplateStepCounter';
import CreateTemplateStepButtons from '../wizard/CreateTemplateStepButtons';
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import {ArrowDropDownOutlined} from "@material-ui/icons";
import {PartnerSettingGroup} from "./partnersettings/templateSettings";
import {PartnerSetting} from "../../CreateSender/steps/partnersettings/partnerSettings";

const CreateTemplateStepConfirm = (props: any) => {
    function isCurrentStep(): boolean {
        return props.isActive;
    }

    function getRequiredSettings() {
        const requiredSettings = props.template.templateSettings
            .flatMap((group: PartnerSettingGroup) => group.name !== 'New parent settings' && group.settings)
            .filter((ps: PartnerSetting) => ps.isVisible || ps.isRequired || ps.name === 'senderType');
        return requiredSettings;
    }

    function hasParentSettings() {
        let result = getRequiredSettings().find((setting: PartnerSetting) => setting.name === 'newParent') !== undefined;
        return result;
    }

    function covertToLabel(name: string): string {
        let converted = name
            .replace(/\./g, ' ')
            .split(/(?=[A-Z])/)
            .join(' ')
            .toLowerCase();
        return converted.charAt(0).toUpperCase() + converted.slice(1).toLowerCase();
    }

    function getParentSettings() {
        const requiredSettings = props.template.templateSettings
            .flatMap((group: PartnerSettingGroup) => group.name === 'New parent settings' && group.settings)
            .filter((ps: PartnerSetting) => ps);
        return requiredSettings;
    }

    const renderTemplateSummary = (name: string, settings: PartnerSetting[]) => {
        return (
            <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon={<ArrowDropDownOutlined fontSize="large"/>}>
                    <h5>{name}</h5>
                </AccordionSummary>
                <AccordionDetails>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Name</th>
                            {name === 'New parent settings' ?
                                <th>Value</th> :
                                <>
                                    <th>Visible</th>
                                    <th>Editable</th>
                                    <th>Required</th>
                                </>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {(settings === undefined || settings.length === 0) &&
                            <tr>
                                <td className="text-center" colSpan={4}>No settings found</td>
                            </tr>
                        }
                        {settings?.map((ps: any, rowIndex: number) => {
                            return (
                                <tr key={rowIndex}>
                                    <td className="align-middle">{name === 'New parent settings' ? covertToLabel(ps.name) : ps.secondaryName || ps.label}</td>
                                    {name === 'New parent settings' ?
                                        <td className="align-middle">
                                            {ps.value?.toString()}
                                        </td> :
                                        <>
                                            <td className="align-middle">
                                                {ps.name === 'senderType' ? ps.value : ps.isVisible?.toString()}
                                            </td>
                                            <td className="align-middle">
                                                {ps.isEditable?.toString()}
                                            </td>
                                            <td className="align-middle">
                                                {ps.isRequired?.toString()}
                                            </td>
                                        </>
                                    }
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>
        );
    };

    const renderBody = () => {
        if (!isCurrentStep()) {
            return (<></>);
        }

        return (
            <div className="card-body">
                <div className="d-flex justify-content-around">
                    <p><strong>Template name: </strong> {props.template.templateName}</p>
                    {props.template.senderParentName &&
                        <p><strong>Parent: </strong>{props.template.senderParentName}</p>}
                </div>
                {props.template.comment &&
                    <div className="d-flex preserve-line-breaks">
                        <p>
                            <div>Comment: </div>
                            <div> {props.template.comment}</div>
                        </p>
                    </div>
                }
                {hasParentSettings() && renderTemplateSummary('New parent settings', getParentSettings())}
                {renderTemplateSummary('Partner settings', getRequiredSettings())}

                <CreateTemplateStepButtons  {...props}> </CreateTemplateStepButtons>
            </div>
        );
    };

    return (
        <div>
            <div className="card">
                <div className="card-header text-center">
                    <CreateTemplateStepCounter {...props}></CreateTemplateStepCounter>
                </div>
                {renderBody()}
            </div>

        </div>
    );

};
export default CreateTemplateStepConfirm;
