import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter, Route} from 'react-router-dom';
import queryString from 'query-string';
import hoistNonReactStatic from 'hoist-non-react-statics';

const QueryRouterContext = React.createContext();

export const withQueryRouterOriginalLocation = (WrappedComponent) => {
    class Enhance extends Component {
        render() {
            return (
                <QueryRouterContext.Consumer>
                    {(originalLocation) => (
                        <WrappedComponent originalLocation={originalLocation} {...this.props}/>
                    )}
                </QueryRouterContext.Consumer>
            );
        }
    }

    hoistNonReactStatic(Enhance, WrappedComponent);
    return Enhance;
};

class QueryRouter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pathname: '',
            search: '',
        };

        this.updateState = this.updateState.bind(this);
    }

    updateState() {
        const parsed = queryString.parse(this.props.location.search);
        const parsedString = parsed[this.props.queryKey] || '';

        const [pathname = '', search = ''] = parsedString.split('?');

        if (pathname !== this.state.pathname || search !== this.state.search) {
            this.setState({
                pathname: pathname,
                search: search,
            });
        }
    }

    componentDidMount() {
        this.updateState();
    }

    componentDidUpdate() {
        this.updateState();
    }

    render() {
        return (
            <QueryRouterContext.Provider value={this.props.location}>
                <Route location={{pathname: this.state.pathname, search: this.state.search}}>
                    {this.props.children}
                </Route>
            </QueryRouterContext.Provider>
        );
    }
}

QueryRouter.propTypes = {
    queryKey: PropTypes.string.isRequired,
};

export default withRouter(QueryRouter);
