import React, {Fragment} from 'react';
import {Col, Row} from "reactstrap";
import Table from "../../../Elements/Table/Table";
import {PlusIcon, PencilIcon} from "react-octicons";
import Field from "../../../Elements/Field";
import LinkToOverlay from "../../../Elements/Overlay/LinkToOverlay";
import BillingCreditsConsumeHistoryStats from "../../../Stats/BillingCreditsConsumeHistoryStats";
import {formatCurrency, formatNumber} from "../../../services";


const BillingCredits = ({billingSender}) => {

    const totalPurchasedCredits = billingSender.purchaseHistory.reduce((acc, cur) => acc + cur.purchasedCredits - cur.expiredCredits, 0);
    const totalConsumedCredits = billingSender.consumeHistoryByDate.reduce((acc, cur) => acc + cur.creditsConsumed, 0);

    return (
        <Fragment>

            <Row>
                <Col>
                    <h2 className="mb-5">
                        {billingSender.name} <LinkToOverlay overlay={`/billing/credits/add/${billingSender.id}`}><PlusIcon/></LinkToOverlay>
                    </h2>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Field name='Total purchased credits'>{formatNumber(totalPurchasedCredits)}</Field>
                    <Field name='Total consumed credits'>{formatNumber(totalConsumedCredits)}</Field>
                    <Field name='Total remaining credits'>{formatNumber(totalPurchasedCredits - totalConsumedCredits)}</Field>
                    <Field name='Estimated end'>{billingSender.cachedEstimatedEnd && new Date(billingSender.cachedEstimatedEnd).toLocaleDateString()}</Field>

                    <Table
                        name='Purchase history'
                        columns={{
                            purchasedDate: 'Purchase date',
                            purchasedCredits: 'Purchased credits',
                            purchaseAmount: 'Purchase amount',
                            expirationDate: 'Expiration date',
                            comment: 'Comment',
                            expired: 'Expired',
                            expiredCredits: 'Expired credits',
                            pllayUser: 'User',
                            edit: {
                                name: 'Edit',
                                style: {
                                    width: '60px',
                                },
                            },
                        }}
                        items={billingSender.purchaseHistory.map(item => ({
                            ...item,
                            key: item.id,
                            purchasedDate: new Date(item.purchasedDate).toLocaleDateString(),
                            purchasedCredits: formatNumber(item.purchasedCredits),
                            purchaseAmount: formatCurrency(item.purchaseAmount),
                            expirationDate: new Date(item.expirationDate).toLocaleDateString(),
                            expired: item.expired ? 'Yes' : 'No',
                            expiredCredits: formatNumber(item.expiredCredits),
                            edit: (!item.expired || billingSender.idOfLastPurchase === item.id) ? <LinkToOverlay overlay={`/billing/credits/${item.id}/edit`}><PencilIcon/></LinkToOverlay> : '',
                        }))}
                        extraClassName='table-sm'
                    />

                    <Field name='Consume history'>
                        <BillingCreditsConsumeHistoryStats height="200" rows={billingSender.consumeHistoryByDate}/>
                    </Field>

                    <Table
                        name='Consume details'
                        columns={{
                            type: 'Type',
                            units: 'Units',
                            usedCredits: 'Used credits',
                            sumUsedCredits: 'Sum credits',
                            minDate: 'First event',
                            maxDate: 'Last event',
                        }}
                        items={billingSender.consumeHistoryBySubtype.map(item => ({
                            ...item,
                            key: item.type,
                            units: formatNumber(item.units),
                            usedCredits: formatNumber(item.usedCredits),
                            sumUsedCredits: formatNumber(item.sumUsedCredits),
                            minDate: new Date(item.minDate).toLocaleDateString(),
                            maxDate: new Date(item.maxDate).toLocaleDateString(),
                        }))}
                        extraClassName='table-sm'
                    />
                </Col>
            </Row>

        </Fragment>
    );
};

export default BillingCredits;
