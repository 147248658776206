import React, {useEffect, useState} from 'react';
import {Col, Row} from "reactstrap";
import FormWithActions from "../Elements/FormWithActions/FormWithActions";
import {validate} from "jsonschema";
import schema from "./schema.json";

const SenderOnboardingSendBuckarooOnboardingMerchantRequestForm = ({json, onAction}) => {
    const [errors, setErrors] = useState(null);

    useEffect(
        () => {
            const validatorResult = validate(json, schema);
            setErrors(validatorResult.errors);
        },
        [json]
    );

    return (
        <>
            <Row>
                <Col>
                    <h1 className="mb-5">
                        Send buckaroo onboarding merchant request
                    </h1>
                </Col>
            </Row>
            <FormWithActions
                onAction={onAction}
                submitButton="Send buckaroo onboarding merchant request"
                disableSubmitReason={(errors === null || errors.length > 0) ? "Fix the errors before sending this request to Buckaroo." : ""}
            >
                <ol>
                    {errors !== null && errors.map((error, i) => {
                        let {property, message} = error;

                        //Transform validation result so it's a little more readable
                        property = property.substring(9);

                        if (message === 'does not meet minimum length of 1') {
                            message = 'is required';
                        }

                        return (
                            <li key={i}>{property} {message}</li>
                        );
                    })}
                </ol>
            </FormWithActions>
        </>
    );
};

export default SenderOnboardingSendBuckarooOnboardingMerchantRequestForm;