import React from 'react';
import {Link} from "react-router-dom";
import {downloadTasksUrl, fetchDocRefs,} from '../services';

import AbstractItemsTableWithLoader from '../Tables/AbstractItemsTableWithLoader';

const DocRefsTable = ({name, itemsPerPage, searchParams, hideFilters, hideFiltersIfEmpty}) => {

    const fetchFunction = (searchParams) => {
        return fetchDocRefs(searchParams).then(data => ({
            ...data,
            items: data.items.map(item => ({
                ...item,
                params: (
                    <div>
                        <ul>
                            {Object.keys(item.params).map(key => (
                                <li key={key}>{key}: {item.params[key]}</li>
                            ))}
                        </ul>
                    </div>
                ),
                taskId: <Link to={"/tasks/" + item.taskId}>{item.taskId}</Link>,
            })),
        }));
    };

    const downloadUrl = downloadTasksUrl();

    const tableColumns = {
        taskId: {
            name: 'Task Id',
            sort: true,
            style: {
                width: '360px',
            },
        },
        taskName: {
            name: 'Task Name',
            sort: true
        },
        status: {
            name: 'Status',
            sort: true
        },
        start: {
            name: 'Start',
            style: {
                width: '230px',
            },
            sort: true
        },
        complete: {
            name: 'Complete',
            sort: true
        },
        lastUpdate: {
            name: 'Last Update',
            style: {
                width: '230px',
            },
            sort: true
        },
        params: {
            name: 'params',
            style: {
                width: '250px'
            },
        }

    };
    const namedSearchParameters = {
        taskId: {
            type: 'text',
            name: 'Task Id',
            operators: ['eq', 'contains'],
        },
        batchId: {
            type: 'text',
            name: 'Batch Id',
            operators: ['eq','contains'],
        },
        status: {
            type: 'select',
            name: 'Status',
            options: ['OK', 'UNSTABLE', 'FAILURE'],
            operators: ['eq'],
        },
        start: {
            type: 'date',
            name: 'Start',
            operators: ['eq', 'lt', 'gt', 'between'],
        },
        senderId: {
            type: 'text',
            name: 'Sender Id',
            operators: ['eq'],
        },
        lastUpdate: {
            type: 'date',
            name: 'Last Update',
            operators: ['eq', 'lt', 'gt', 'between'],
        },
        complete: {
            type: 'select',
            name: 'Complete',
            options: ['true', 'false'],
            operators: ['eq'],
        },
        operator: {
            type: 'text',
            name: 'Operator',
            operators: ['eq'],
        },
    };

    return <AbstractItemsTableWithLoader
        name={name}
        itemsPerPage={itemsPerPage}
        tableColumns={tableColumns}
        namedSearchParameters={namedSearchParameters}
        fetchFunction={fetchFunction}
        searchParams={searchParams}
        hideFilters={hideFilters}
        hideFiltersIfEmpty={hideFiltersIfEmpty}
        downloadUrl={downloadUrl}
        downloadUrlMax={5000}
        minWidth={1700}
    />;
};


export default DocRefsTable;