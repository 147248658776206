import React, {Fragment} from 'react';
import {Col, Row} from "reactstrap";
import {PencilIcon} from "react-octicons";
import Field from "../../../Elements/Field";
import LinkToOverlay from "../../../Elements/Overlay/LinkToOverlay";

const BillingReseller = ({billingReseller}) => (
    <Fragment>

        <Row>
            <Col>
                <h2 className="mb-5">
                    {billingReseller.name} <LinkToOverlay overlay={`/billing/resellers/${billingReseller.id}/edit`}><PencilIcon/></LinkToOverlay>
                </h2>
            </Col>
        </Row>

        <Row>
            <Col>
                <Field name='Eenvoudig Factureren client id'>{billingReseller.externalClientId}</Field>
                <Field name='Email addresses'>{billingReseller.contactEmailAddresses.join(', ')}</Field>
            </Col>
        </Row>

    </Fragment>
);

export default BillingReseller;