import React, {Fragment, useEffect, useState} from 'react';
import CreateTemplateStepButtons from '../wizard/CreateTemplateStepButtons';
import CreateTemplateStepCounter from '../wizard/CreateTemplateStepCounter';
import FormGroupRowCheckbox from '../../Elements/FormGroupRowCheckbox';
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import {ArrowDropDownOutlined} from "@material-ui/icons";
import {PartnerSettingGroup} from "./partnersettings/templateSettings";
import AddParentSettingsForm from "./partnersettings/AddParentSettingsForm";
import {PartnerSetting} from "../../CreateSender/steps/partnersettings/partnerSettings";
import FormGroupRowSelect from "../../Elements/FormGroupRowSelect";
import {SenderTypes} from "../../CreateSender/steps/SenderTypes";
import {Option} from "react-select/src/filters";
import FormGroupRowText from "../../Elements/FormGroupRowText";
import FormGroupRowTextArea from "../../Elements/FormGroupRowTextArea";

const CreateTemplateStepSettings = (props: any) => {
    const [settingGroups, setSettingGroups] = useState<PartnerSettingGroup[]>(props.templateSettings);
    const [newParent, setNewParent] = useState<boolean>(false);
    const [parentSettings, setParentSettings] = useState<PartnerSetting[]>([]);
    const [comment, setComment] = useState(props.comment);
    const newParentSettings = React.useRef(null)

    useEffect(() => {
        let isNewParent = settingGroups.find(group => group.name === 'Create account')?.settings
            .find(s => s.name === 'newParent')?.isVisible;
        if (isNewParent) {
            setParentSettings(settingGroups[7].settings);
            setNewParent(true);
        }
    }, [settingGroups])

    function handleFormChange(groupIndex: number, rowIndex: number, value: any, valueFor = 'value') {
        const settings = [...settingGroups[groupIndex].settings];
        let partnerSetting = {...settings[rowIndex], [valueFor]: value};
        checkIfNeedNewParent(partnerSetting, value)
        setSettingGroups(prevState => {
            settings[rowIndex] = partnerSetting
            const updatedSettingGroups = [...prevState];
            updatedSettingGroups[groupIndex] = {...prevState[groupIndex], settings: settings};
            partnerSetting = settings[rowIndex];
            return updatedSettingGroups;
        });
    }

    function checkIfNeedNewParent(ps: PartnerSetting, value: boolean) {
        if (ps.name === 'newParent') {
            setNewParent(value);
        }

        if (ps.name === 'newParent' && !value) {
            setSettingGroups(prevState => {
                let updatedSettingGroups = prevState;
                updatedSettingGroups[6].settings = [];
                return updatedSettingGroups;
            });
        }
    }

    function updateSettings() {
        let updatedSettingGroups = [...settingGroups];
        if (newParent) {
            // @ts-ignore
            updatedSettingGroups[7].settings = newParentSettings.current();
        }
        props.updateBody({
            comment: comment,
            templateSettings: updatedSettingGroups
        });
    }

    function renderCheckBox(groupIndex: number, rowIndex: number, ps: any, isChecked: boolean, valueFor = 'value') {
        let description = (ps.isVisible && ps.name === 'newParent') ? "Don't forget to check settings for parent." : '';
        return (
            <FormGroupRowCheckbox checked={isChecked || false}
                                  key={groupIndex + rowIndex}
                                  cssClasses={['mt-3']}
                                  description={description}
                                  onChange={value => handleFormChange(groupIndex, rowIndex, value, valueFor)}
                                  label=""/>
        )
    }

    function renderTextInput(groupIndex: number, rowIndex: number, ps: any, value: any, valueFor = 'value') {
        let description = (ps.isVisible && ps.name === 'newParent') ? "Don't forget to check settings for parent." : '';
        return (
            <FormGroupRowText value={value}
                              key={groupIndex + rowIndex}
                              cssClasses={['mt-3']}
                              description={description}
                              onChange={value => handleFormChange(groupIndex, rowIndex, value, valueFor)}
                              label=""/>
        )
    }

    function renderPartnerAndAccountSettings(group: any, groupIndex: number) {
        return (
            <>
                <Accordion key={groupIndex} defaultExpanded={group.isExpanded}>
                    <AccordionSummary expandIcon={<ArrowDropDownOutlined fontSize="large"/>}>
                        <h5>{group.name}</h5>
                    </AccordionSummary>
                    <AccordionDetails>
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Name</th>

                                {group.name.match(/Create response/) &&
                                    <th className='w-50'>Secondary name</th>
                                }
                                <th className={`${group.name.match(/Create account/) ? 'w-50' : ''}`}>Show</th>
                                {!group.name.match(/Create account|Create response/) &&
                                    <>
                                        <th>Editable</th>
                                        <th>Mandatory</th>
                                    </>
                                }
                            </tr>
                            </thead>
                            <tbody>

                            {(group.settings === undefined || group.settings.length === 0) &&
                                <tr>
                                    <td className='text-center' colSpan={4}>
                                        No settings found
                                    </td>
                                </tr>
                            }
                            {group.settings?.map((ps: any, rowIndex: number) => {
                                return (
                                    ps.name !== 'senderType' ?
                                        <tr key={rowIndex + groupIndex}>
                                            <td className="align-middle">{ps.label}</td>
                                            {group.name.match(/Create response/) &&
                                                <td className="align-middle">
                                                    {renderTextInput(groupIndex, rowIndex, ps, ps.secondaryName || '', 'secondaryName')}
                                                </td>
                                            }

                                            <td className="align-middle">
                                                {renderCheckBox(groupIndex, rowIndex, ps, ps.isVisible, 'isVisible')}
                                            </td>
                                            {!group.name.match(/Create account|Create response/) &&
                                                <>
                                                    <td className="align-middle">
                                                        {renderCheckBox(groupIndex, rowIndex, ps, ps.isEditable, 'isEditable')}
                                                    </td>

                                                    <td className="align-middle">
                                                        {renderCheckBox(groupIndex, rowIndex, ps, ps.isRequired, 'isRequired')}
                                                    </td>
                                                </>
                                            }
                                        </tr> :
                                        <tr key={rowIndex + groupIndex}>
                                            <td colSpan={3}>
                                                <FormGroupRowSelect
                                                    label="Sender type"
                                                    multi={false}
                                                    required={true}
                                                    value={group.settings[4].value}
                                                    onChange={value => handleFormChange(groupIndex, rowIndex, value)}
                                                    options={SenderTypes.map(l => {
                                                        return {value: l.value, label: l.label} as Option
                                                    })}
                                                />
                                            </td>
                                        </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    function renderNewParentSettings() {
        return (
            <>
                <Accordion key='newParent' defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ArrowDropDownOutlined fontSize="large"/>}>
                        <h5>New parent settings</h5>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <AddParentSettingsForm newParentSettings={newParentSettings}
                                                   parentSettings={parentSettings}/>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    return (
        <div>
            <div className="card">
                <div className="card-header text-center">
                    <CreateTemplateStepCounter {...props}></CreateTemplateStepCounter>
                </div>
                <div className="card-body">
                <FormGroupRowTextArea value={comment}
                                      key="comment"
                                      required={false}
                                      cssClasses={['mt-3']}
                                      description={'Instructions for using this template'}
                                      onChange={value => {setComment(value)}}
                                      label="Comment"/>
                    {settingGroups.map((group: PartnerSettingGroup, index: number) => (
                        <Fragment key={index}>
                            {group.name !== 'New parent settings' && renderPartnerAndAccountSettings(group, index)}
                        </Fragment>
                    ))}

                    {newParent && renderNewParentSettings()}
                    <CreateTemplateStepButtons {...props}
                                               isValid={true}
                                               onBack={updateSettings}
                                               onNext={updateSettings}> </CreateTemplateStepButtons>
                </div>
            </div>
        </div>
    );
};
export default CreateTemplateStepSettings;