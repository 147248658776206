import React from 'react';
import {fetchSpeosArchiveBatch} from '../../../services';
import Fetch from '../../../Elements/Fetch';
import SpeosBatchDetail from '../../batchDetail/SpeosBatchDetailProps';

const SpeosArchiveDetailRoute = (props: any) => {
    return (
        <Fetch
            fetchFunction={fetchSpeosArchiveBatch}
            fetchFunctionParams={props.match.params.id}
            refreshKey={`speosArchive:${props.match.params.id}`}>
            {(reprintBatch: any) => <SpeosBatchDetail {...reprintBatch} type="Archive"/>}</Fetch>
    );
};

export default SpeosArchiveDetailRoute;