import React from 'react';
import PropTypes from 'prop-types';
import MtpFileAddForm from "./MtpFileAddForm";
import {mtpAddFile} from "../services";
import {withRefresh} from "../Elements/Fetch";

const MtpFileAddRoute = ({triggerRefresh, onSubmit}) => {
    const handleAction = async (month, file) => {
        await mtpAddFile(month, file);
        triggerRefresh('mtp-files');
        return onSubmit();
    };

    return (
        <MtpFileAddForm
            onAction={handleAction}
        />
    );
};

MtpFileAddRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default withRefresh(MtpFileAddRoute);