import React from 'react';
import PropTypes from 'prop-types';
import BillingCreditsAddForm from "./BillingCreditsAddForm";

const BillingCreditsAddRoute = ({match, history, onSubmit}) => {

    const billingSenderId = match.params.id;
    const handleSubmit = onSubmit || (() => history.push(`/billing/credits/${billingSenderId}`));

    return (
        <div style={{maxWidth: 800}}>
            <BillingCreditsAddForm
                onSubmit={handleSubmit}
                billingSenderId={billingSenderId}
            />
        </div>
    );
};

BillingCreditsAddRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default BillingCreditsAddRoute;