export const columNames = {
    taskId: {
        name: 'Task Id',
        sort: true,
        style: {
            width: '360px',
        },
    },
    taskName: {
        name: 'Task Name',
        sort: true
    },
    status: {
        name: 'Status',
        sort: true
    },
    start: {
        name: 'Start',
        style: {
            width: '230px',
        },
        sort: true
    },
    complete: {
        name: 'Complete',
        sort: true
    },
    lastUpdate: {
        name: 'Last Update',
        style: {
            width: '230px',
        },
        sort: true
    },
}