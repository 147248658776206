import {PartnerSetting} from "../../../CreateSender/steps/partnersettings/partnerSettings";

export type PartnerSettingGroup = { isExpanded: boolean, name: string, settings: PartnerSetting[] };

export const allSettingGroups: PartnerSettingGroup[] = [
    {
        isExpanded: true, name: 'Pay Page',
        settings: [
            {
                label: 'Use pay page',
                category: 'paylink',
                name: 'usePaypage',
                value: true,
                type: 'boolean',
                isRequired: false,
                isEditable: false,
                isVisible: false
            },
            {
                label: 'Paypage logo',
                category: 'paylink',
                name: 'paypage.logo.url',
                value: '',
                type: 'string',
                isRequired: false,
                isEditable: false,
                isVisible: false
            },
            {
                label: 'Background',
                category: 'paylink',
                name: 'paypage.background.image.url',
                value: '',
                type: 'string',
                isRequired: false,
                isEditable: false,
                isVisible: false
            }
        ]
    },
    {
        isExpanded: false, name: 'Channels',
        settings: [
            {
                label: 'Available channels',
                category: 'paylink',
                name: 'available.channels',
                type: 'multi-list',
                isRequired: false, isEditable: false, isVisible: false,
                value: '',
                options: ['SMS', 'QR', 'WEB', 'MAIL', 'SHARE', 'APP', 'E-QR', 'PAPIER']
            },
            {
                label: 'Short url channels',
                category: 'paylink',
                name: 'shortlink.channels',
                type: 'multi-list',
                isRequired: false, isEditable: false, isVisible: false,
                value: '',
                options: []
            },
            {
                label: 'Short url service',
                category: 'paylink',
                name: 'short.url.service',
                value: 'lambda',
                type: 'string',
                isRequired: false,
                isEditable: false,
                isVisible: false
            },
            {
                label: 'Optin channels',
                category: 'paylink',
                name: 'paypage.optin.channels',
                type: 'multi-list',
                isRequired: false, isEditable: false, isVisible: false,
                value: '',
                options: []
            },
        ]
    },
    {
        isExpanded: false, name: 'Payment confirmation',
        settings: [
            {
                label: 'Payment confirmation',
                category: 'sender.delivery',
                name: 'maildelivery.klarna.template',
                value: 'DirectPaymentPaymentConfirmation',
                type: 'string',
                isRequired: false,
                isEditable: false,
                isVisible: false
            }
        ]
    },
    {
        isExpanded: false, name: 'Document delivery',
        settings: [
            {
                label: 'E-mail delivery',
                category: 'sender.delivery',
                name: 'maildelivery',
                value: true,
                type: 'boolean',
                isRequired: false,
                isEditable: false,
                isVisible: false
            },
            {
                label: 'Mail logo',
                category: 'sender.delivery',
                name: 'maildelivery.logo.url',
                value: '',
                type: 'string',
                isRequired: false,
                isEditable: false,
                isVisible: false
            },
            {
                label: 'Reply address',
                category: 'sender.delivery',
                name: 'maildelivery.replyto.address',
                type: 'string',
                isEditable: false, value: ''
            }
        ]
    },
    {
        isExpanded: false, name: 'Other',
        settings: [
            {
                label: 'Webhook',
                category: 'paylink',
                name: 'successCallbackUrl',
                value: '',
                type: 'string',
                isRequired: false,
                isEditable: false,
                isVisible: false
            },
            {
                label: 'Redirect',
                category: 'sender.customview',
                name: 'redirectUrl',
                value: '',
                type: 'string',
                isRequired: false,
                isEditable: false,
                isVisible: false
            }
        ]
    },
    {
        isExpanded: false, name: 'Create account',
        settings: [
            {
                label: 'Parent permissions',
                category: 'account',
                name: 'parentPermissions',
                value: true,
                type: 'boolean',
                isVisible: false
            },
            {
                label: 'Needs child credentials',
                category: 'account',
                name: 'needsChildCredentials',
                value: true,
                type: 'boolean',
                isVisible: false
            },
            {
                label: 'Allow new parent',
                category: 'account',
                name: 'newParent',
                value: true,
                type: 'boolean',
                isVisible: false
            },
            {
                label: 'AWS bucket',
                category: 'account',
                name: 'withS3Bucket',
                value: true,
                type: 'boolean',
                isVisible: false
            },
            {
                label: 'Sender type',
                category: 'account',
                name: 'senderType',
                value: 'contract_matching',
                type: 'string',
                isVisible: false
            }
        ]
    },
    {
        isExpanded: false, name: 'Create response',
        settings: [
            {
                label: 'Sender name',
                category: 'response',
                name: 'senderName',
                secondaryName: '',
                value: true,
                type: 'boolean',
                isVisible: false
            },
            {
                label: 'SCN',
                category: 'response',
                name: 'scn',
                secondaryName: '',
                value: true,
                type: 'boolean',
                isVisible: false
            },
            {
                label: 'IBAN',
                category: 'response',
                name: 'iban',
                secondaryName: '',
                value: true,
                type: 'boolean',
                isVisible: false
            },
            {
                label: 'Child sender id',
                category: 'response',
                name: 'childSenderId',
                secondaryName: '',
                value: true,
                type: 'boolean',
                isVisible: false
            },
            {
                label: 'Parent sender id',
                category: 'response',
                name: 'parentSenderId',
                secondaryName: '',
                value: true,
                type: 'boolean',
                isVisible: false
            },
            {
                label: 'Sender user name',
                category: 'response',
                name: 'senderUsername',
                secondaryName: '',
                value: true,
                type: 'boolean',
                isVisible: false
            },
            {
                label: 'Password',
                category: 'response',
                name: 'password',
                secondaryName: '',
                value: true,
                type: 'boolean',
                isVisible: false
            },
            {
                label: 'Child encryption key',
                category: 'response',
                name: 'childEncryptionKey',
                secondaryName: '',
                value: true,
                type: 'boolean',
                isVisible: false
            },
            {
                label: 'Parent encryption key',
                category: 'response',
                name: 'parentEncryptionKey',
                secondaryName: '',
                value: true,
                type: 'boolean',
                isVisible: false
            },
            {
                label: 'AWS bucket',
                category: 'response',
                name: 'awsbucket',
                secondaryName: '',
                value: true,
                type: 'boolean',
                isVisible: false
            },
            {
                label: 'AWS user name',
                category: 'response',
                name: 'awsUsername',
                secondaryName: '',
                value: true,
                type: 'boolean',
                isVisible: false
            },
            {
                label: 'AWS password',
                category: 'response',
                name: 'awsPassword',
                secondaryName: '',
                value: true,
                type: 'boolean',
                isVisible: false
            }
        ]
    },

    {
        isExpanded: false, name: 'New parent settings',
        settings: []
    }

]


