import React from 'react';
import styles from './Modal.module.css';
import classNamesBind from 'classnames/bind';

const classNames = classNamesBind.bind(styles);

export interface ModalProps {
    children: React.ReactNode;
    onClose: () => void;
    visible: boolean;
}

const Modal = ({
                     children,
                     onClose,
                     visible,
                 }: ModalProps) => {
    if (!visible) {
        return null;
    }

    return (
        <div>
            <div className={styles.background} onClick={onClose}/>
            <div className={classNames(styles.wrapper, {[styles.rendered]: visible})}>
                <div className={styles.close} onClick={onClose}/>

                {children}

            </div>
        </div>
    );
};

export default Modal;
