import React from 'react';
import PropTypes from 'prop-types';
import {buckarooAddFile, fetchSenderOnboarding} from "../services";
import Fetch, {withRefresh} from "../Elements/Fetch";
import SenderOnboardingBuckarooAddFileForm from "./SenderOnboardingBuckarooAddFileForm";

const SenderOnboardingBuckarooAddFileRoute = ({match, onSubmit, triggerRefresh}) => (
    <Fetch fetchFunction={fetchSenderOnboarding} fetchFunctionParams={match.params.id}>
        {senderOnboarding => {
            const handleAction = async (files) => {
                for (let i = 0; i < files.length; i++) {
                    await buckarooAddFile(senderOnboarding.id, files[i]);
                }

                triggerRefresh(`senderOnboarding:${senderOnboarding.id}`, true);
                onSubmit();
            };

            return (
                <SenderOnboardingBuckarooAddFileForm
                    onAction={handleAction}
                />
            );
        }}
    </Fetch>
);

SenderOnboardingBuckarooAddFileRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default withRefresh(SenderOnboardingBuckarooAddFileRoute);