import React from 'react';
import {fetchMaintenance} from "../services";
import moment from "moment";
import {MaintenanceModeType} from "../GlobalSettings/MaintenanceMode";
import Fetch from "../Elements/Fetch";

const MaintenanceModeChecker = () => {
    return (
        <Fetch fetchFunction={fetchMaintenance} refreshKey="maintenanceMode">
            {({enabled, from, until}: MaintenanceModeType) => {
                if (enabled) {
                    const fromDate = moment(from);
                    const untilDate = moment(until);
                    const now = moment();

                    if (now.isAfter(fromDate) && untilDate.isAfter(now)) {
                        return (
                            <div className="alert alert-danger">Maintenance mode is enabled and visible on the pay pages.</div>
                        );
                    }
                }

                return null;
            }}
        </Fetch>
    );
};

export default MaintenanceModeChecker;