export function validateVATNumber(vatNumber: string) {
    let vatNumberLength = vatNumber.length;
    if(vatNumberLength !== 12) {
        return false;
    }
    // Step a) - remove non-numeric characters
    const cleanNumber = vatNumber.replace(/[^0-9]/g, '');
    // Step b) - take the last 2 digits
    const lastTwoDigits = cleanNumber.substr(-2);

    // Step c) - remove the last 2 digits
    const remainingNumber = cleanNumber.substr(0, cleanNumber.length - 2);

    // Step d) - divide the remaining number by 97 and round down
    const divisionResult = Math.floor(parseInt(remainingNumber, 10) / 97);

    // Step e) - multiply the result of step d) by 97
    const multiplicationResult = divisionResult * 97;

    // Step f) - subtract step e) from step c)
    const difference = parseInt(remainingNumber, 10) - multiplicationResult;

    // Step g) - compare the result of step f) to the last 2 digits of step b)
    const expectedLastTwoDigits = 97 - difference;
    const actualLastTwoDigits = parseInt(lastTwoDigits, 10);

    return expectedLastTwoDigits === actualLastTwoDigits;
}
