import React, {Component} from 'react';
import {fetchStats} from '../services';
import googleChartLoader from '../GoogleChartLoader';
import Loader from '../Elements/Loader';
import {Row, Col} from 'reactstrap';
import Select from 'react-virtualized-select';
import FetchFromContext from "../Elements/FetchFromContext";

class DocumentPaymentDateDueDateDifferenceStats extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isCommunicatingWithServer: false, //Don't do initial load since user has to select sender first
            showError: false,
            rows: [],
            billingSenderIds: [],
            parentSenderIds: [],
            height: this.props.height ? parseInt(this.props.height, 10) : 400,
            nbPayments: 0,
        };

        this.drawDashboard = this.drawDashboard.bind(this);

        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        googleChartLoader.init(['corechart']);
        // this.fetchData(); //Don't do initial load since user has to select sender first
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevState.billingSenderIds) !== JSON.stringify(this.state.billingSenderIds)) {
            this.fetchData();
            return;
        }

        if (JSON.stringify(prevState.parentSenderIds) !== JSON.stringify(this.state.parentSenderIds)) {
            this.fetchData();
            return;
        }

        if (prevState.rows.length !== this.state.rows.length) {
            googleChartLoader.init().then(() => {
                this.drawDashboard();
            });
        }
    }

    fetchData() {
        this.setState({
            isCommunicatingWithServer: true,
            showError: false,
            rows: [],
        });

        fetchStats('paymentDateDueDateDifferenceBySender', {
            billingSenderIds: this.state.billingSenderIds,
            parentSenderIds: this.state.parentSenderIds,
        })
            .then(data => {
                const amountPerDaysDifference = data.reduce((amountPerDaysDifference, daysDifference) => {
                    amountPerDaysDifference[daysDifference] = (amountPerDaysDifference[daysDifference] || 0) + 1;
                    return amountPerDaysDifference;
                }, {});

                const processedData = Object.keys(amountPerDaysDifference).map(daysDifference => [parseInt(daysDifference), amountPerDaysDifference[daysDifference]]);

                this.setState({
                    isCommunicatingWithServer: false,
                    rows: processedData,
                    nbPayments: data.length,
                });
            })
            .catch(() => {
                this.setState({
                    isCommunicatingWithServer: false,
                    showError: true,
                });
            });
    }

    drawDashboard() {
        const data = new window.google.visualization.DataTable();
        data.addColumn("number", 'Days difference');
        data.addColumn("number", 'Number');
        data.addRows(this.state.rows);

        const dashboard = new window.google.visualization.Dashboard(document.getElementById('DocumentPaymentDateDueDateDifferenceStatsWrapper'));

        const chart = new window.google.visualization.ChartWrapper({
            chartType: 'ColumnChart',
            containerId: 'DocumentPaymentDateDueDateDifferenceStats',
            options: {
                title: `Payment Date - DueDate - Difference - Last 100.000 payments (Less is better) - # payments: ${this.state.nbPayments}`,
                legend: {
                    position: 'none',
                },
                height: this.state.height,
                chartArea: {
                    left: 50,
                    right: 0,
                    top: 30,
                    bottom: 30,
                },
                hAxis: {
                    format: '#',
                },
            },
        });

        const controls = new window.google.visualization.ControlWrapper({
            controlType: 'ChartRangeFilter',
            containerId: 'DocumentPaymentDateDueDateDifferenceStatsControls',
            options: {
                filterColumnLabel: 'Days difference',
                ui: {
                    chartOptions: {
                        height: 60,
                        chartArea: {
                            left: 40,
                            right: 10,
                            top: 0,
                            bottom: 20,
                        },
                        hAxis: {
                            textPosition: 'out',
                        },
                    },
                },
            },
            state: {
                range: {
                    start: -30,
                    end: 30,
                }
            },
        });

        dashboard.bind([controls], [chart]);
        dashboard.draw(data);
    }

    render() {

        const height = this.state.height + 60;

        return (
            <div id="DocumentPaymentDateDueDateDifferenceStatsWrapper">
                <Row>
                    <Col xs="4">
                        <FetchFromContext property="billingSenders">
                            {billingSenders => (
                                <Select
                                    placeholder="Billing senders"
                                    value={this.state.billingSenderIds}
                                    options={billingSenders.map(billingSender => ({
                                        value: billingSender.id,
                                        label: billingSender.name,
                                    }))}
                                    onChange={(items) => this.setState({billingSenderIds: items.map(item => item.value)})}
                                    multi
                                />
                            )}
                        </FetchFromContext>
                    </Col>
                    <Col xs="4">
                        <FetchFromContext property="parentSenders">
                            {parentSenders => (
                                <Select
                                    placeholder="Parent senders"
                                    value={this.state.parentSenderIds}
                                    options={parentSenders.map(parentSender => ({
                                        value: parentSender.id,
                                        label: parentSender.name,
                                    }))}
                                    onChange={(items) => this.setState({parentSenderIds: items.map(item => item.value)})}
                                    multi
                                />
                            )}
                        </FetchFromContext>
                    </Col>

                </Row>
                <div className="overflow-hidden" style={{height: height + 'px'}}>
                    {this.state.isCommunicatingWithServer &&
                        <div className="d-flex flex-column justify-content-center h-100 text-center">
                            <Loader/>
                        </div>
                    }
                    {this.state.showError &&
                        <div className="d-flex flex-column justify-content-center h-100 text-center">
                            An error occured showing this graph. Try refreshing the page.
                        </div>
                    }
                    {(this.state.billingSenderIds.length === 0 && this.state.parentSenderIds.length === 0) &&
                        <div className="d-flex flex-column justify-content-center h-100 text-center">
                            Select at least one sender to continue.
                        </div>
                    }
                    <div id="DocumentPaymentDateDueDateDifferenceStats"/>
                    <div id="DocumentPaymentDateDueDateDifferenceStatsControls"/>
                </div>
            </div>
        );

    }
}

export default DocumentPaymentDateDueDateDifferenceStats;
