import React from 'react';
import {sectorTypes} from "../dropDownData";
import FormGroupRowSelect from "../../../Elements/FormGroupRowSelect";
import FormGroupRowCheckbox from "../../../Elements/FormGroupRowCheckbox";
import FormGroupRowText from "../../../Elements/FormGroupRowText";
import {Button, ButtonGroup} from "reactstrap";

export default function BillingSenderContactForm(props: ContactFormType) {

    return (
        <>

            <FormGroupRowText label="First Name" value={props.contact.firstName}
                              onChange={value => props.onChange({firstName: value})}/>
            <FormGroupRowText label="Last Name" value={props.contact.lastName}
                              onChange={value => props.onChange({lastName: value})}/>
            <FormGroupRowText label="Email" type="email" value={props.contact.email}
                              onChange={value => props.onChange({email: value})}/>

            <ButtonGroup size="sm">
                <Button outline active={props.contact.language === 'nl'} color="success"
                        onClick={() => props.onChange({language: 'nl'})}>nl</Button>
                <Button outline active={props.contact.language === 'fr'} color="success"
                        onClick={() => props.onChange({language: 'fr'})}>fr</Button>
                <Button outline active={props.contact.language === 'en'} color="success"
                        onClick={() => props.onChange({language: 'en'})}>en</Button>
            </ButtonGroup>
            <FormGroupRowCheckbox
                label="Get Billing Report"
                checked={props.contact.getBillingReport}
                onChange={checked => props.onChange({getBillingReport: checked})}
            />
            <FormGroupRowSelect
                label="Contact Point Type"
                value={props.contact.contactPointType}
                onChange={value => props.onChange({contactPointType: value})}
                options={[
                    {value: 'MAIN_SPOC', label: 'MAIN_SPOC'},
                    {value: 'TECH_SPOC', label: 'TECH_SPOC'},
                ]}
            />

            <FormGroupRowSelect
                label="Sector Type"
                value={props.contact.sectorType}
                onChange={value => props.onChange({sectorType: value})}
                options={sectorTypes}
            />
        </>
    )
}

type ContactFormType = {
    contact: any;
    onChange: (value: {}) => void;
}