import React from 'react';
import PropTypes from 'prop-types';
import BillingContactAddForm from "./BillingContactAddForm";

const BillingContactsAddRoute = ({match, history, onSubmit}) => {

    const handleSubmit = onSubmit || (() => history.push('/billing/sender' + match.params.id));

    return (
        <div style={{maxWidth: 800}}>
            <BillingContactAddForm
                billingSenderId={match.params.id}
                onSubmit={handleSubmit}
            />
        </div>
    );
};

BillingContactsAddRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default BillingContactsAddRoute;