import React, {useContext, useState} from 'react';
import useSubmittingButton from "../../hooks/useSubmittingButton";
import {runScript} from "../../services";
import FormGroupRowDate from "../../Elements/FormGroupRowDate";
import {Col, Row} from "reactstrap";
import {ModalContext} from "../../Modal/ModalProvider";

const BillingCreditsReport = () => {
    const [date, setDate] = useState('');
    const {successMessage, errorMessage, loader, SubmittingButton} = useSubmittingButton();
    const {showModal} = useContext(ModalContext);

    const handleSubmit = async () => {
        const response = await runScript('BillingCreditsReport', {
            date: date,
        });

        if (response.status === 200) {
            const data = await response.json();

            showModal({
                type: 'download',
                exportId: data.id,
            });
        }
        else {
            throw new Error(`${response.status}: ${response.statusText}. Contact a site administrator. This error probably won't go away by itself.`);
        }
    };

    const disabled = !date;

    return (
        <>
            <h4>Billing Credits Report</h4>

            {errorMessage}

            <form style={{maxWidth: 600}}>

                <FormGroupRowDate
                    label="Date"
                    value={date}
                    onChange={setDate}
                    description="Only month and year will be used."
                />

                {successMessage}

                <Row form className="align-items-center">
                    <Col xs="auto">
                        <SubmittingButton onClick={handleSubmit} disabled={disabled}>Download XLSX</SubmittingButton>
                    </Col>
                    <Col xs="auto">
                        {loader}
                    </Col>
                </Row>
            </form>
        </>
    );
};

export default BillingCreditsReport;