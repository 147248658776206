import React, {useContext, useState} from 'react';
import {editSender} from '../../services';
import PropTypes from 'prop-types';
import FormGroupRowText from "../../Elements/FormGroupRowText";
import {Col, Label, Row} from "reactstrap";
import {RefreshContext} from "../../Elements/Fetch";
import FormWithActions from "../../Elements/FormWithActions/FormWithActions";
import {AppContext} from "../../AppContext";
import Select from "react-select";
import FormGroupRowCheckbox from "../../Elements/FormGroupRowCheckbox";
import FormText from "../../Elements/FormText";
import FormGroupRow from "../../Elements/FormGroupRow";
import FormGroupRowSelect from "../../Elements/FormGroupRowSelect";

const availablePaymentMethods = [
    'bancontact',
    'payconiq',
    'ideal',
    'digicash',
    'maestro',
    'visa',
    'mastercard',
    'cartebancaire',
    'cartebleuevisa',
    'payByBank',
    'applepay',
    'paypal',
];

const SenderEditForm = ({sender, countries, onSubmit}) => {
    const {triggerAppStateRefresh} = useContext(AppContext);
    const {triggerRefresh} = useContext(RefreshContext);

    const [name, setName] = useState(sender.name);
    const [vatNumber, setVatNumber] = useState(sender.vatNumber || '');
    const [reportingId, setReportingId] = useState(sender.reportingId || '');
    const [bic, setBic] = useState(sender.bic || '');

    const [street1, setStreet1] = useState(sender.address?.street1 || '');
    const [street2, setStreet2] = useState(sender.address?.street2 || '');
    const [street3, setStreet3] = useState(sender.address?.street3 || '');
    const [street4, setStreet4] = useState(sender.address?.street4 || '');
    const [zipCode, setZipCode] = useState(sender.address?.zipCode || '');
    const [city, setCity] = useState(sender.address?.city || '');
    const [country, setCountry] = useState(sender.address?.country || '');

    const partnerSettingPayDirectlyPaymentMethods = sender.partnerSettings.find(setting => setting.name === 'pay-directly.paymentMethods');
    const [payDirectlyPaymentMethods, setPayDirectlyPaymentMethods] = useState(partnerSettingPayDirectlyPaymentMethods?.value.split(',')
        .filter(method => availablePaymentMethods.includes(method))
        .sort((a, b) => availablePaymentMethods.indexOf(a) - availablePaymentMethods.indexOf(b)) || []);

    const partnerSettingPaymentOptions = sender.partnerSettings.find(setting => setting.name === 'paypage.paymentOptions');
    const [hasPayOnceBC, setHasPayOnceBC] = useState(partnerSettingPaymentOptions?.value.split(',').includes('pay-once-bc') || false);

    const buckarooPaymentMethod = sender.paymentMethods.find(method => method.name === 'buckaroo');
    const showFormForPaymentMethods = sender.parentId && buckarooPaymentMethod;

    const hasApplePay = payDirectlyPaymentMethods.includes('applepay');
    const [applePayMerchantId, setApplePayMerchantId] = useState(buckarooPaymentMethod?.attrs?.find(attr => attr.k === 'applePayMerchantId')?.v || '');

    const handleAction = async () => {
        const params = {
            name: name,
            vatNumber: vatNumber,
            reportingId: reportingId,
            bic: bic,
            address: (street1 || street2 || street3 || street4 || zipCode || city) ? {
                street1: street1,
                street2: street2,
                street3: street3,
                street4: street4,
                zipCode: zipCode,
                city: city,
                country: country,
            } : null,
            payDirectlyPaymentMethods: null,
            hasPayOnceBC: null,
            applePayMerchantId: null,
        };

        if (showFormForPaymentMethods) {
            //Pay directly paymentMethods
            params.payDirectlyPaymentMethods = payDirectlyPaymentMethods.length === 0 ? '_none' : payDirectlyPaymentMethods.join(',');

            //Payment options
            params.hasPayOnceBC = hasPayOnceBC;

            //Apple Pay
            params.applePayMerchantId = applePayMerchantId;
        }

        const data = await editSender(sender.id, params);

        triggerAppStateRefresh('downloadReportingIds');
        triggerRefresh(`sender:${sender.id}`);
        onSubmit();

        return data;
    };

    const selectedMethodsDisableReason = (showFormForPaymentMethods && payDirectlyPaymentMethods.length === 0) ? "Payment methods can't be empty" : "";
    const payOnceBcDisableReason = (hasPayOnceBC && !payDirectlyPaymentMethods.includes('bancontact')) ? "Payment method 'bancontact' must be selected if Bancontact POD is enabled" : "";
    const applepayDisableMerchantIdReason = (hasApplePay && !applePayMerchantId) ? "To enable Apple Pay, ApplepayMerchantId can't be empty" : "";
    const usePaypage = [...sender.partnerSettings, ...sender.parentPartnerSettings].find(setting => {
        return setting.category === 'paylink' && ['usePaypage', 'usePaypageV2'].includes(setting.name) && setting.value === 'true';
    });
    const applepayDisableUsePayPageReason = (hasApplePay && !usePaypage) ? "To enable Apple Pay, usePaypage or usePaypageV2 must be enabled as partner settings" : "";
    const disableSubmitReason = selectedMethodsDisableReason || payOnceBcDisableReason || applepayDisableMerchantIdReason || applepayDisableUsePayPageReason;

    return (
        <>
            <Row>
                <Col>
                    <h1 className="mb-5">
                        Sender: {sender.name}
                    </h1>
                </Col>
            </Row>
            <FormWithActions disableSubmitReason={disableSubmitReason} onAction={handleAction}>

                <FormGroupRowText
                    label="Name"
                    placeholder="Name"
                    value={name}
                    onChange={setName}
                />

                <FormGroupRowText
                    label="VAT number"
                    placeholder="VAT number"
                    value={vatNumber}
                    onChange={setVatNumber}
                    description="E.g. : BE0XXXXXXXXX"
                />

                <FormGroupRowText
                    label="Reporting ID"
                    placeholder="Reporting ID"
                    value={reportingId}
                    onChange={setReportingId}
                />

                {showFormForPaymentMethods && (
                    <>
                        <Row form className="form-group">
                            <Col xs="3">
                                <Label className="col-form-label">Payment Methods</Label>
                            </Col>
                            <Col>
                                <Select
                                    value={payDirectlyPaymentMethods.map(method => ({value: method, label: method}))}
                                    onChange={newSelectedMethods => {
                                        setPayDirectlyPaymentMethods(newSelectedMethods
                                            .map(method => method.value)
                                            .sort((a, b) => availablePaymentMethods.indexOf(a) - availablePaymentMethods.indexOf(b))
                                        );
                                    }}
                                    options={availablePaymentMethods.map(method => ({value: method, label: method}))}
                                    multi
                                    clearable={false}
                                />
                            </Col>
                        </Row>
                        {hasApplePay &&
                            <FormGroupRowText
                                label="ApplepayMerchantId"
                                value={applePayMerchantId}
                                onChange={setApplePayMerchantId}
                            />
                        }
                        <FormGroupRowCheckbox
                            label="Bancontact POD"
                            checked={hasPayOnceBC}
                            onChange={setHasPayOnceBC}
                        />
                    </>
                )}

                <FormGroupRowText
                    label="BIC"
                    placeholder="BIC"
                    value={bic}
                    onChange={setBic}
                />

                <FormGroupRow label="Address">
                    <div className="row">
                        <div className="col">
                            <FormText
                                type="text"
                                placeholder="Straat 1"
                                value={street1}
                                onChange={setStreet1}
                            />
                        </div>
                        <div className="col">
                            <FormText
                                type="text"
                                placeholder="Straat 2"
                                value={street2}
                                onChange={setStreet2}
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col">
                            <FormText
                                type="text"
                                placeholder="Straat 3"
                                value={street3}
                                onChange={setStreet3}
                            />
                        </div>
                        <div className="col">
                            <FormText
                                type="text"
                                placeholder="Straat 4"
                                value={street4}
                                onChange={setStreet4}
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col col-3">
                            <FormText
                                type="text"
                                placeholder="Zip"
                                value={zipCode}
                                onChange={setZipCode}
                            />
                        </div>
                        <div className="col col-9">
                            <FormText
                                type="text"
                                placeholder="City"
                                value={city}
                                onChange={setCity}
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col">
                            <FormGroupRowSelect
                                label=""
                                value={country}
                                addEmptyOption
                                onChange={setCountry}
                                options={countries.map(country => ({
                                    value: country.id,
                                    label: `${country.name} (${country.iso2Code})`,
                                }))}
                            />
                        </div>
                    </div>
                </FormGroupRow>

            </FormWithActions>
        </>
    );
};

SenderEditForm.propTypes = {
    sender: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        vatNumber: PropTypes.string,
        reportingId: PropTypes.string,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default SenderEditForm;
