import React, {useState} from 'react';
import FormGroupRowText from "../../Elements/FormGroupRowText";
import useSubmittingButton from "../../hooks/useSubmittingButton";
import {runScript, validEmail} from "../../services";
import {BillingSenderType} from "../../AppContext";
import FormGroupRowSelect from "../../Elements/FormGroupRowSelect";
import FetchFromContext from "../../Elements/FetchFromContext";
import {Button, ButtonGroup, Col, Row} from "reactstrap";
import FormGroupRowCheckbox from "../../Elements/FormGroupRowCheckbox";
import FormGroupRow from "../../Elements/FormGroupRow";

const CreateUpdateUser = () => {
    const [updateExistingUser, setUpdateExistingUser] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');

    const [enablePomBusinessApp, setEnablePomBusinessApp] = useState<boolean | null>(null);
    const [scn, setScn] = useState('');

    const [enablePomPortal, setEnablePomPortal] = useState<boolean | null>(null);
    const [billingSenderIds, setBillingSenderIds] = useState([]);

    const [createPasswordResetLink, setCreatePasswordResetLink] = useState(false);
    const [createPasswordResetNbDays, setCreatePasswordResetNbDays] = useState(7);

    const {successMessage, errorMessage, loader, SubmittingButton} = useSubmittingButton();

    const handleSubmit = async () => {
        const response = await runScript('CreateOrUpdateUser', {
            firstName: firstName,
            lastName: lastName,
            email: email,
            enablePomBusinessApp: enablePomBusinessApp,
            scn: scn,
            enablePomPortal: enablePomPortal,
            billingSenderIds: billingSenderIds,
            updateExistingUser: updateExistingUser,
            createPasswordResetLink: createPasswordResetLink,
            createPasswordResetNbDays: createPasswordResetNbDays,
        });

        if (response.status === 400) {
            const data = await response.json();
            const error = data.errors ? data.errors.map((error: any) => error.message) : data.message;
            throw new Error(error);
        }

        if (response.status !== 200) {
            throw new Error(`${response.status}: ${response.statusText}. Contact a site administrator. This error probably won't go away by itself.`);
        }

        const data = await response.json();
        if (!updateExistingUser) {
            if (data.passwordResetLink) {
                return `New user created. Password reset link: ${data.passwordResetLink}`;
            } else {
                return 'New user created.';
            }
        } else {
            if (data.passwordResetLink) {
                return `Existing user updated. Password reset link: ${data.passwordResetLink}`;
            } else {
                return 'Existing user updated.';
            }
        }
    };

    const disabled =
        (!updateExistingUser && !firstName) ||
        (!updateExistingUser && !lastName) ||
        !validEmail(email) ||
        (enablePomBusinessApp && !scn) ||
        (enablePomPortal && billingSenderIds.length === 0) ||
        (createPasswordResetLink && createPasswordResetNbDays < 1);

    return (
        <div style={{maxWidth: 750}}>
            <FormGroupRow label="">
                <ButtonGroup size="sm">
                    <Button outline active={!updateExistingUser} color="success" onClick={() => setUpdateExistingUser(false)}>Create user</Button>
                    <Button outline active={updateExistingUser} color="success" onClick={() => setUpdateExistingUser(true)}>Update user</Button>
                </ButtonGroup>
            </FormGroupRow>

            <FormGroupRowText
                label="Email"
                type="email"
                value={email}
                onChange={setEmail}
            />

            {!updateExistingUser && (
                <>
                    <FormGroupRowText
                        label="First name"
                        value={firstName}
                        onChange={setFirstName}
                    />

                    <FormGroupRowText
                        label="Last name"
                        value={lastName}
                        onChange={setLastName}
                    />
                </>
            )}

            <FormGroupRow label="Pom Business App">
                <ButtonGroup size="sm">
                    <Button outline active={enablePomBusinessApp === null} color="success" onClick={() => setEnablePomBusinessApp(null)}>Don't change</Button>
                    <Button outline active={enablePomBusinessApp === true} color="success" onClick={() => setEnablePomBusinessApp(true)}>Enable</Button>
                    {updateExistingUser && <Button outline active={enablePomBusinessApp === false} color="success" onClick={() => setEnablePomBusinessApp(false)}>Disable</Button>}
                </ButtonGroup>
            </FormGroupRow>

            {enablePomBusinessApp && (
                <FormGroupRowText
                    label="SCN"
                    value={scn}
                    onChange={setScn}
                    description="The user will be able to make paylinks in the POM Business App for this SCN"
                />
            )}

            <FormGroupRow label="Pom Portal">
                <ButtonGroup size="sm">
                    <Button outline active={enablePomPortal === null} color="success" onClick={() => setEnablePomPortal(null)}>Don't change</Button>
                    <Button outline active={enablePomPortal === true} color="success" onClick={() => setEnablePomPortal(true)}>Enable</Button>
                    {updateExistingUser && <Button outline active={enablePomPortal === false} color="success" onClick={() => setEnablePomPortal(false)}>Disable</Button>}
                </ButtonGroup>
            </FormGroupRow>

            {enablePomPortal && (
                <FetchFromContext property="billingSenders" className="form-group">
                    {(billingSenders: BillingSenderType[]) => (
                        <FormGroupRowSelect
                            label="Billing sender"
                            value={billingSenderIds}
                            onChange={setBillingSenderIds}
                            options={billingSenders.map(billingSender => ({value: String(billingSender.id), label: billingSender.name}))}
                            multi
                            description="The user will have access to the portal of these billing senders"
                        />
                    )}
                </FetchFromContext>
            )}

            <FormGroupRowCheckbox
                label="Create login link"
                checked={createPasswordResetLink}
                onChange={setCreatePasswordResetLink}
            />

            {createPasswordResetLink && (
                <FormGroupRowText
                    label="Expiration"
                    type="number"
                    value={createPasswordResetNbDays}
                    description="The password reset link will be valid for these amount of days"
                    onChange={value => {
                        value = Math.abs(value);
                        value = Math.round(value * 100) / 100;
                        setCreatePasswordResetNbDays(value);
                    }}
                />
            )}

            {successMessage}
            {errorMessage}

            <Row form className="align-items-center">
                <Col xs="auto">
                    <SubmittingButton onClick={handleSubmit} disabled={disabled}>Submit</SubmittingButton>
                </Col>
                <Col xs="auto">
                    {loader}
                </Col>
            </Row>
        </div>
    );
};

export default CreateUpdateUser;
