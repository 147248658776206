import React, {Fragment} from 'react';
import TemplatesTable from "./TemplatesTable";

const TemplatesRoute = () => (
    <Fragment>
        <h1 className="mb-5">Templates</h1>
        <TemplatesTable/>
    </Fragment>
);

export default TemplatesRoute;