import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import './Table.css';
import Expandable from "../Expandable/Expandable";

const Table = ({
                   hideHeader,
                   columns,
                   items,
                   name,
                   description,
                   extraClassName,
                   hideLabelColon,
                   expandable,
                   expandableHeight,
                   search = {},
                   location,
                   autoWidthColumns = [],
                   minWidth,
               }) => {
    const {sortField, sortOrder} = search;

    const colon = !hideLabelColon ? ':' : '';

    if (items.length === 0 && name) {
        return (
            <div className="mb-3">
                <div className="font-weight-bold">{name}{colon}</div>
                <small className="font-italic text-muted">(empty)</small>
            </div>
        );
    }

    let thColumns = null;
    if (!hideHeader) {
        thColumns = Object.keys(columns).map((columnKey) => {
            const columnVal = columns[columnKey];
            if (typeof columnVal === 'object') {
                let val = columnVal['name'];

                if (columnVal['sort']) {
                    const queryString = require('query-string');
                    const linkParams = {
                        ...search,
                        sortOrder: (sortField === columnVal['sortKey'] || sortField === columnKey) && sortOrder === 'asc' ? 'desc' : 'asc',
                        sortField: columnVal['sortKey'] || columnKey,
                    };

                    if (sortField && (sortField === columnVal['sortKey'] || sortField === columnKey)) {
                        if (sortOrder === 'asc') {
                            val = <div className="dropup"><Link
                                to={{pathname: location.pathname, search: '?' + queryString.stringify(linkParams)}}
                                className="dropdown-toggle">{columnVal['name']}</Link></div>;
                        } else {
                            val = <Link
                                to={{pathname: location.pathname, search: '?' + queryString.stringify(linkParams)}}
                                className="dropdown-toggle">{columnVal['name']}</Link>;
                        }
                    } else {
                        val = <Link to={{
                            pathname: location.pathname,
                            search: '?' + queryString.stringify(linkParams)
                        }}>{columnVal['name']}</Link>;
                    }
                }

                return <th key={columnKey} className={classNames({fitWidth: autoWidthColumns.includes(columnKey)})}
                           style={columnVal['style']}>{val}</th>;
            }

            return <th key={columnKey}
                       className={classNames({fitWidth: autoWidthColumns.includes(columnKey)})}>{columnVal}</th>;
        });
    }

    const rows = items.map(item =>
        <tr key={item.key}>
            {Object.keys(columns).map(columnKey =>
                <td key={columnKey}
                    style={{'wordWrap': 'break-word', ...columns[columnKey]['rowStyle']}}>{item[columnKey]}</td>
            )}
        </tr>
    );

    const tableHtml = (
        <div className="mb-3">
            {name && <div className="font-weight-bold">{name}{colon}</div>}
            <table
                style={{
                    marginBottom: 0,
                    tableLayout: autoWidthColumns.length > 0 ? 'auto' : 'fixed',
                    minWidth: minWidth,
                }}
                className={"table table-striped table-bordered table-hover " + (extraClassName || '')}
            >
                {thColumns && <thead>
                <tr>{thColumns}</tr>
                </thead>}
                <tbody>
                {rows}
                </tbody>
            </table>
            {description && (
                <div className="small text-muted">
                    <small>{description}</small>
                </div>
            )}
        </div>
    );

    if (items.length > 5 && expandable) {
        return (
            <Expandable expandableHeight={expandableHeight}>
                {tableHtml}
            </Expandable>
        );
    }

    return tableHtml;
};

Table.propTypes = {
    hideHeader: PropTypes.bool,
    hideLabelColon: PropTypes.bool,
    expandable: PropTypes.bool,
    expandableHeight: PropTypes.number,
    name: PropTypes.any,
    extraClassName: PropTypes.string,
    columns: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    autoWidthColumns: PropTypes.array,
    description: PropTypes.string,
    location: PropTypes.object,
    minWidth: PropTypes.number,
};

export default Table;
