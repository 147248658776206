import React, {Component, Fragment} from 'react';
import {addBillingContact} from '../../../services';
import PropTypes from 'prop-types';
import FormGroupRowSelect from "../../../Elements/FormGroupRowSelect";
import FormGroupRowText from "../../../Elements/FormGroupRowText";
import {Button, ButtonGroup, Col, Row} from "reactstrap";
import {withRefresh} from "../../../Elements/Fetch";
import FormWithActions from "../../../Elements/FormWithActions/FormWithActions";
import FormGroupRowCheckbox from "../../../Elements/FormGroupRowCheckbox";
import {sectorTypes} from "../dropDownData";

class BillingContactAddForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            language: 'nl',
            getBillingReport: false,
            contactPointType: '',
            sector: '',
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleAction() {
        return addBillingContact(this.props.billingSenderId, this.state).then(data => {
            this.props.triggerRefresh(`billingSender:${this.props.billingSenderId}`);
            this.props.onSubmit();
            return data;
        });
    }

    handleChange(value, attribute) {
        this.setState({
            [attribute]: value,
        });

    }

    render() {
        const propertyNames = {
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'email',
            contactPointType: 'Contact Point Type',
            sectorType: 'Sector Type'
        };
        const requiredProperties = [];
        if (!this.state.email) {
            requiredProperties.push('email');
        }
        if (!this.state.contactPointType) {
            requiredProperties.push('contactPointType');
        }
        if (!this.state.sectorType) {
            requiredProperties.push('sectorType');
        }
        let disableSubmitReason = '';
        if (requiredProperties.length > 0) {
            disableSubmitReason = `The following properties are required: ${requiredProperties.map(p => propertyNames[p]).join(', ')}`;
        }
        return (
            <Fragment>
                <Row>
                    <Col>
                        <h1 className="mb-5">
                            Add Contact Person
                        </h1>
                    </Col>
                </Row>
                <FormWithActions onAction={this.handleAction} disableSubmitReason={disableSubmitReason}>

                    <FormGroupRowText label="First Name" value={this.state.firstName}
                                      onChange={value => this.handleChange(value, 'firstName')}/>
                    <FormGroupRowText label="Last Name" value={this.state.lastName}
                                      onChange={value => this.handleChange(value, 'lastName')}/>
                    <FormGroupRowText label="Email" type="email" value={this.state.email}
                                      onChange={value => this.handleChange(value, 'email')}/>

                    <ButtonGroup size="sm">
                        <Button outline active={this.state.language === 'nl'} color="success"
                                onClick={() => this.handleChange('nl', 'language')}>nl</Button>
                        <Button outline active={this.state.language === 'fr'} color="success"
                                onClick={() => this.handleChange('fr', 'language')}>fr</Button>
                        <Button outline active={this.state.language === 'en'} color="success"
                                onClick={() => this.handleChange('en', 'language')}>en</Button>
                    </ButtonGroup>
                    <FormGroupRowCheckbox
                        label="Get Billing Report"
                        checked={this.state.getBillingReport}
                        onChange={checked => this.handleChange(checked, 'getBillingReport')}
                    />
                    <FormGroupRowSelect
                        label="Contact Point Type"
                        value={this.state.contactPointType}
                        onChange={value => this.handleChange(value, 'contactPointType')}
                        options={[
                            {value: 'MAIN_SPOC', label: 'MAIN_SPOC'},
                            {value: 'TECH_SPOC', label: 'TECH_SPOC'},
                        ]}
                    />

                    <FormGroupRowSelect
                        label="Sector Type"
                        value={this.state.sectorType}
                        onChange={value => this.handleChange(value, 'sectorType')}
                        options={sectorTypes}
                    />
                </FormWithActions>
            </Fragment>
        );
    }
}

BillingContactAddForm.propTypes = {
    billingSenderId: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default withRefresh(BillingContactAddForm);