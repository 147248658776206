import React from 'react';
import {Col, Row} from "reactstrap";
import FormWithActions from "../Elements/FormWithActions/FormWithActions";
import {mtpDeleteFile} from "../services";
import {withRefresh} from "../Elements/Fetch";

const MtpFileEditRoute = ({triggerRefresh, match, onSubmit}) => {
    const handleAction = async (action) => {
        if (action === 'delete') {
            await mtpDeleteFile(match.params.id);
            triggerRefresh('mtp-files');
            onSubmit();
        }
    };

    const actions = [
        {
            action: 'delete',
            displayName: 'Delete',
            showFeedback: false,
            confirmMessage: 'Are you sure you want to delete this MailToPay file?',
        },
    ];

    return (
        <div>
            <Row>
                <Col>
                    <h1 className="mb-5">
                        Edit MTP File
                    </h1>
                </Col>
            </Row>
            <FormWithActions onAction={handleAction} actions={actions} hideSubmit>

            </FormWithActions>
        </div>
    );
};

export default withRefresh(MtpFileEditRoute);