import React, {Fragment} from 'react';
import {Link} from "react-router-dom";

import Field from '../Elements/Field';
import FieldMultiple from '../Elements/FieldMultiple';
import Table from '../Elements/Table/Table';
import DocumentsTable from '../Documents/DocumentsTable';
import {PencilIcon, PlusIcon} from 'react-octicons';
import LinkToOverlay from "../Elements/Overlay/LinkToOverlay";
import {Role} from "../Auth/BackOfficeUserRole";
import Can from "../Auth/Can";


const Sender = ({
                    parentId,
                    parentName,
                    id,
                    name,
                    created,
                    senderContractNumber,
                    bankAccountNumber,
                    vatNumber,
                    type,
                    provisionable,
                    nbDocuments,
                    partnerSettings,
                    paymentMethods,
                    senderUsers,
                    logoUrl,
                    campaignBannerUrl,
                    campaignUrl,
                    portalUrl,
                    billingSenderId,
                    billingSenderName,
                    bic,
                    address,
                }) => {
    let parentLink;
    if (parentId) {
        parentLink = <Link to={'/senders/' + parentId}>{parentName}</Link>;
    }

    const senderUsersTableHeaders = {
        username: "Username",
        roles: "Roles"
    };

    const partnerSettingsTableHeaders = {
        category: 'Category',
        name: 'Name',
        value: 'Value',
    };

    const merchantAttrsTableHeaders = {
        k: 'Key',
        v: 'Val',
    };

    const partnerSettingsTitle = (
        <span>
            <span>Partner Settings: </span>
               <Can roles={[Role.WRITE]}>
                  <LinkToOverlay
                      overlay={`/senders/${id}/edit-partner-settings`}
                  >
                     <PencilIcon/>
                  </LinkToOverlay>
              </Can>
        </span>
    );

    const paymentMethodsTitle = (
        <span>
            <span>Payment methods: </span>
            <Can roles={[Role.WRITE]}>
              <LinkToOverlay
                  overlay={`/senders/${id}/merchant/add`}
              >
                  <PlusIcon/>
              </LinkToOverlay>
            </Can>
        </span>
    );

    const senderUserItems = senderUsers.map(senderUser => {
        return {
            key: senderUser.key,
            username: <Link to={'/users/' + senderUser.key}>{senderUser.username}</Link>,
            roles: senderUser.roles.join(', ')
        };
    });

    return (
        <Fragment>
            <div className="form-row">
                <div className="col-auto">
                    <h1 className="mb-5">
                        Sender: {name}
                    </h1>
                </div>
                <div className="col" style={{marginTop: '12px'}}>
                    <LinkToOverlay overlay={`/senders/${id}/edit`}><PencilIcon/></LinkToOverlay>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Field name='Sender name'>{name}</Field>
                    <Field name='Sender Id'>{id}</Field>
                    <Field name='Created'>{created}</Field>
                    <Field name='Sender contract number'>{senderContractNumber}</Field>
                    <Field name='Bank account number'>{bankAccountNumber}</Field>
                    <Field name='Vat number'>{vatNumber}</Field>
                    <Field name='Parent'>{parentLink}</Field>
                    <Field name='Billing Sender'>
                        {billingSenderName && (
                            <Link to={`/billing/senders/${billingSenderId}`}>{billingSenderName}</Link>
                        )}
                    </Field>
                    <Field name='Type'>{type}</Field>
                    <Field name='Provisionable'>{provisionable}</Field>

                    <div className="mb-3">
                        <Field name='Logo URL' externalLink={logoUrl} inline>{logoUrl}</Field>
                        <Field name='Campaign banner URL' externalLink={campaignBannerUrl}
                               inline>{campaignBannerUrl}</Field>
                        <Field name='Campaign URL' externalLink={campaignUrl} inline>{campaignUrl}</Field>
                        <Field name='Portal URL' externalLink={portalUrl} inline>{portalUrl}</Field>
                    </div>

                    <Field name='# Documents'>{nbDocuments}</Field>

                    <div className="mb-3">
                        <LinkToOverlay overlay={`/senders/${id}/upload-docref`} className="btn btn-outline-success">Upload
                            docref</LinkToOverlay>
                    </div>


                    <Field name='BIC'>{bic}</Field>
                    <Field name="Address">
                        <div>{address?.street1} {address?.street2}</div>
                        <div>{address?.street3} {address?.street4}</div>
                        <div>{address?.zipCode} {address?.city}</div>
                        <div>{address?.countryName}</div>
                    </Field>
                </div>
                <div className="col">
                    <Table
                        expandable
                        columns={senderUsersTableHeaders}
                        items={senderUserItems}
                        name="Sender users:"
                        extraClassName='table-sm'
                        hideLabelColon
                    />
                    <Table
                        columns={partnerSettingsTableHeaders}
                        items={partnerSettings}
                        name={partnerSettingsTitle}
                        extraClassName='table-sm'
                        hideLabelColon
                    />
                    <FieldMultiple name={paymentMethodsTitle} values={paymentMethods}>
                        {senderMerchant => {
                            return (
                                <div>
                                    <Field name='Name' inline>
                                        {senderMerchant.name}&nbsp;
                                        <Can roles={[Role.WRITE]}>
                                            <LinkToOverlay
                                                overlay={`/senders/${id}/merchant/${senderMerchant.merchantId}/edit`}>
                                                <PencilIcon/>
                                            </LinkToOverlay>&nbsp;
                                        </Can>
                                        {
                                            senderMerchant.senderId !== id &&
                                            <span className="badge badge-secondary">Inherited from parent</span>
                                        }
                                    </Field>
                                    <Field name='Visible' inline>{senderMerchant.enabled ? 'Yes' : 'No'}</Field>
                                    <Field name='Payouts' inline>{senderMerchant.payoutsEnabled ? 'Yes' : 'No'}</Field>
                                    <Field name='Merchant Id' inline>{senderMerchant.merchantId}</Field>
                                    {
                                        senderMerchant.otherSenderIds.ids.length > 0 &&
                                        <Field name='Shared with' inline>
                                            {senderMerchant.otherSenderIds.ids.map((otherSenderInfo, index) => (
                                                <Fragment key={otherSenderInfo.senderId}>
                                                    {!!index && ', '}
                                                    <Link
                                                        to={'/senders/' + otherSenderInfo.senderId}>{otherSenderInfo.senderName}</Link>
                                                </Fragment>
                                            ))}
                                            {
                                                senderMerchant.otherSenderIds.more > 0 &&
                                                <span> (+ {senderMerchant.otherSenderIds.more} more)</span>
                                            }
                                        </Field>
                                    }
                                    <Table columns={merchantAttrsTableHeaders} items={senderMerchant.attrs || []}
                                           extraClassName='table-sm'/>
                                </div>
                            );
                        }}
                    </FieldMultiple>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <DocumentsTable name="Documents" searchParams={{senderId: id}} hideFiltersIfEmpty/>
                </div>
            </div>

        </Fragment>
    );
};

export default Sender;
