import React from 'react';
import Table from "../../Elements/Table/Table";
import {Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import FieldMultiple from "../../Elements/FieldMultiple";

type Props = {
    subscriptionsStatus: string[];
    ADDSendersWithoutBillingSender: {
        id: number;
        name: string;
        senderContractNumber: string;
    }[];
    billingSendersWithoutADDSender: {
        id: number;
        name: string;
    }[];
};

const BillingStatus = ({
                           subscriptionsStatus,
                           ADDSendersWithoutBillingSender,
                           billingSendersWithoutADDSender,
                       }: Props) => {
    return (
        <Row>
            <Col xs={6}>
                <h2 className="mb-3">
                    Billing status
                </h2>

                <FieldMultiple
                    name="Subscription messages:"
                    values={subscriptionsStatus}
                />

                <Table
                    name="ADD senders without billing sender"
                    description="All ADD senders that aren't linked to a billing sender. Senders that contain the word 'POM' are not shown."
                    columns={{link: 'Sender', senderContractNumber: 'Sender contract number'}}
                    items={ADDSendersWithoutBillingSender.map(item => ({
                        ...item,
                        key: item.id,
                        link: <Link to={`/senders/${item.id}`}>{item.name}</Link>,
                    }))}
                    extraClassName='table-sm'
                />

                <Table
                    name="Billing senders without ADD sender"
                    description="All billing senders that aren't linked to an ADD sender."
                    columns={{link: 'Billing sender'}}
                    items={billingSendersWithoutADDSender.map(item => ({
                        ...item,
                        key: item.id,
                        link: <Link to={`/billing/senders/${item.id}`}>{item.name}</Link>,
                    }))}
                    extraClassName='table-sm'
                />
            </Col>
        </Row>
    );
};

export default BillingStatus;
