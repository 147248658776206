import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';
import PomTooltip from "./PomTooltip";

const PomModalAction = ({disabledReason, action, displayName, confirmMessage, showFeedback, isSubmitting, onConfirm, onAction, color = 'danger'}) => {
    if (disabledReason) {
        return (
            <PomTooltip tooltip={disabledReason}>
                <span className="d-inline-block mr-1">
                    <Button className="mx-1" color={color} disabled style={{pointerEvents: 'none'}}>{displayName}</Button>
                </span>
            </PomTooltip>
        );
    }

    if (confirmMessage) {
        return <Button color={color} className="mx-1" key={action} onClick={() => onConfirm(action, confirmMessage, showFeedback)}
                       disabled={isSubmitting}>{displayName}</Button>;
    }

    return <Button color={color} className="mx-1" key={action} onClick={() => onAction(action, showFeedback)} disabled={isSubmitting}>{displayName}</Button>;
};

PomModalAction.propTypes = {
    disabledReason: PropTypes.string,
    color: PropTypes.string,
    displayName: PropTypes.string.isRequired,
    confirmMessage: PropTypes.string,

    action: PropTypes.string,
    onAction: PropTypes.func.isRequired,

    showFeedback: PropTypes.bool,
    isSubmitting: PropTypes.bool,
};

export default PomModalAction;