import React, {Component} from 'react';
import Select from 'react-select';
import {Col, Label, Row} from "reactstrap";
import FormWithActions from "../Elements/FormWithActions/FormWithActions";
import PropTypes from "prop-types";
import {editRoles} from "../services";
import {withRefresh} from "../Elements/Fetch";


class UserRoleForm extends Component {
    constructor(props) {
        super(props);

        const allRoles = this.props.roles.map(role => ({value: role.id, label: role.authority}));
        const selectedRoleIds = this.props.selectedRoles.map(l => l.id)
        const selectedRoles = allRoles.filter(role => (selectedRoleIds.indexOf(role.value) > -1))

        this.state = {
            selectedRoles: selectedRoles,
            roles: allRoles,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleAction = this.handleAction.bind(this);
    }

    handleChange(attribute) {
        return (value) => {
            this.setState({
                [attribute]: value,
            });
        };
    }

    handleAction() {
        const body = this.state.selectedRoles.map(l => {
            return {
                id: l.value,
                authority: l.label
            }
        })
        return editRoles(this.props.userId, body).then(data => {
            this.props.triggerRefresh(this.props.refreshKey);
            this.props.onSubmit();
            return data;
        });
    }

    render() {
        return (
            <FormWithActions
                onAction={this.handleAction}>

                <Row form className="form-group">
                    <Col xs="3">
                        <Label className="col-form-label">Roles</Label>
                    </Col>
                    <Col>
                        <Select
                            value={this.state.selectedRoles}
                            multi
                            onChange={selection => this.handleChange('selectedRoles')(selection)}
                            options={this.state.roles}
                            clearable={false}
                        />
                    </Col>
                </Row>
            </FormWithActions>
        )
    }
}

UserRoleForm.propTypes = {
    roles: PropTypes.array,
    selectedRoles: PropTypes.array,

    userId: PropTypes.string,
    onSubmit: PropTypes.func
}

export default withRefresh(UserRoleForm)