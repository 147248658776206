import React, {useState} from 'react';
import FormWithActions from "../../../Elements/FormWithActions/FormWithActions";
import {addBillingReport} from "../../../services";
import FormGroupRowSelect from "../../../Elements/FormGroupRowSelect";
import FormGroupRowDate from "../../../Elements/FormGroupRowDate";
import {Col, Row} from "reactstrap";
import FetchFromContext from "../../../Elements/FetchFromContext";

const BillingReportAddForm = ({onSubmit}) => {
    const [billingSenderId, setBillingSenderId] = useState('');
    const [period, setPeriod] = useState('');

    const handleAction = (action) => {
        switch (action) {
            case 'submit':
                return addBillingReport({
                    billingSenderId: billingSenderId,
                    period: period,
                })
                    .then(data => {
                        // triggerRefresh('billingReports');
                        onSubmit(data);
                        return data;
                    });
            default:

        }
    };

    let disableSubmitReason = '';
    if (!billingSenderId) {
        disableSubmitReason = 'Billing sender is required.';
    }
    if (!period) {
        disableSubmitReason = 'Period is required.';
    }

    return (
        <>
            <Row>
                <Col>
                    <h1 className="mb-5">
                        Add billing report
                    </h1>
                </Col>
            </Row>
            <FormWithActions onAction={handleAction} disableSubmitReason={disableSubmitReason}>
                <FetchFromContext property="billingSenders" className="form-group">
                    {billingSenders => (
                        <FormGroupRowSelect
                            label="Billing sender"
                            value={billingSenderId}
                            onChange={value => setBillingSenderId(value)}
                            options={billingSenders.map(billingSender => ({value: billingSender.id, label: billingSender.name}))}
                        />
                    )}
                </FetchFromContext>

                <FormGroupRowDate
                    label="Period"
                    value={period}
                    onChange={setPeriod}
                    description="Format: YYYY-MM-DD. Only month and year will be used."
                />
            </FormWithActions>
        </>
    );
};

BillingReportAddForm.propTypes = {};

export default BillingReportAddForm;
