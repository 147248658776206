import React, {useState} from 'react';
import FormGroupRowText from '../Elements/FormGroupRowText';
import {Col, Row} from 'reactstrap';
import useSubmittingButton from '../hooks/useSubmittingButton';
import {checkEmailBlacklist, deleteBlacklistedEmail} from '../services';
import {Role} from "../Auth/BackOfficeUserRole";
import Can from "../Auth/Can";

const EmailBlacklist = () => {
    const [email, setEmail] = useState('');
    const {loader, SubmittingButton} = useSubmittingButton();
    const [response, setResponse] = useState<{ blacklisted?: boolean, date?: any }>();
    const handleSubmit = async () => {
        let response = await checkEmailBlacklist(email);
        setResponse(response);
    };
    const handleDelete = async () => {
        await deleteBlacklistedEmail(email);
        let response = await checkEmailBlacklist(email);
        setResponse(response)
    }

    return (
        <div>

            <Row form className="align-items-center">
                <Col xs="4">
                    <FormGroupRowText
                        label="Email"
                        description="The email you want to check if it's blacklisted or not"
                        value={email}
                        onChange={ value => setEmail(value) }
                    />
                </Col>
                <Col xs="auto" style={{marginBottom:"35px"}}>
                    <SubmittingButton onClick={handleSubmit} disabled={false}>Submit</SubmittingButton>
                </Col>
            </Row>

            <Row>
                <Col>
                    {loader ? loader : response && <div className="card mb-3">
                        <div className="card-body">

                            This email addres is {(response?.blacklisted)
                            ? (<b>BLACKLISTED ({response.date})</b>)
                            : (<b>NOT BLACKLISTED</b>)}</div>
                    </div>
                    }
                </Col>
                <Can roles={[Role.WRITE]}>
                    <Col xs="auto" style={{marginTop: "13px"}}>
                        {!loader && response?.blacklisted && <SubmittingButton onClick={handleDelete} disabled={false}>Remove</SubmittingButton>}
                    </Col>
                </Can>
            </Row>
        </div>
    );
};

export default EmailBlacklist;
