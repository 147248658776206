import Fetch from "../Elements/Fetch";
import React from "react";
import TaskDocumentIdsNotReported from "./TaskDocumentIdsNotReported";
import {fetchTaskDocumentIdsNotReported} from "../services";

const TaskDocumentIdsNotReportedRoute = ({match}) => {
    return (
        <Fetch fetchFunction={fetchTaskDocumentIdsNotReported} fetchFunctionParams={match.params.taskId}
               refreshKey={`task:${match.params.taskId}`}>
            {task => <TaskDocumentIdsNotReported {...task}/>}
        </Fetch>
    )
}
export default TaskDocumentIdsNotReportedRoute;