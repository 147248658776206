import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "reactstrap";
import {addBillingSender} from "../../../services";
import {withRefresh} from "../../../Elements/Fetch";
import BillingSenderForm from "../BillingSender/BillingSenderForm";
import FetchFromContext from "../../../Elements/FetchFromContext";
import {AppContext} from "../../../AppContext";

class BillingSenderAddForm extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.handleAction = this.handleAction.bind(this);
    }

    handleAction(action, state) {
        switch (action) {
            case 'submit':
                return addBillingSender(state)
                    .then(data => {
                        this.context.triggerAppStateRefresh('downloadBillingSenders');
                        this.props.triggerRefresh('billingSenders');
                        this.props.onSubmit();
                        return data;
                    });
            default:

        }
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <h1 className="mb-5">
                            Add billing sender
                        </h1>
                    </Col>
                </Row>

                <FetchFromContext property="billingEventTypes" className="form-group">
                    {(billingEventTypes) => (
                        <BillingSenderForm
                            action={this.handleAction}
                        />
                    )}
                </FetchFromContext>

            </Fragment>
        );
    }
}

BillingSenderAddForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default withRefresh(BillingSenderAddForm);