import React, {Fragment} from 'react';
import EmailFallBackSubscriptionsTable from './EmailFallBackSubscriptionsTable';

const EmailFallBackSubscriptionsRoute = () => (
    <Fragment>
        <h1 className="mb-5">EmailFallBackSubscriptions</h1>
        <EmailFallBackSubscriptionsTable/>
    </Fragment>
);

export default EmailFallBackSubscriptionsRoute;