import React from 'react';
import {fetchBillingSender} from "../../../services";
import Fetch from "../../../Elements/Fetch";
import BillingSender from "./BillingSender";

const BillingSenderRoute = ({match}) => (
    <Fetch fetchFunction={fetchBillingSender} fetchFunctionParams={match.params.id} refreshKey={`billingSender:${match.params.id}`}>
        {billingSender => (
            <BillingSender billingSender={billingSender}/>
        )}
    </Fetch>
);

export default BillingSenderRoute;