import React from 'react';
import GlobalSettings from './GlobalSettings';
import Fetch from '../Elements/Fetch';
import {fetchGlobalSettings, fetchMaintenance} from '../services';
import {GlobalSetting} from './model/GlobalSetting.interface';
import MaintenanceMode, {MaintenanceModeType} from "./MaintenanceMode";

const GlobalSettingsRoute = () => {

    return (
        <div style={{maxWidth: 800}}>
            <h1 className="mb-5">Global settings</h1>
            <Fetch fetchFunction={fetchGlobalSettings}>
                {(settings: GlobalSetting[]) => <GlobalSettings settings={settings}/>}
            </Fetch>

            <h1 className="my-5">Maintenance mode</h1>
            <Fetch fetchFunction={fetchMaintenance} refreshKey="maintenanceMode">
                {(maintenanceMode: MaintenanceModeType) => <MaintenanceMode maintenanceMode={maintenanceMode}/>}
            </Fetch>
        </div>
    );
};

export default GlobalSettingsRoute;