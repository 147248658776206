import React from 'react';
import Table from '../Elements/Table/Table';
import {GlobalSetting} from './model/GlobalSetting.interface';
import Can from '../Auth/Can';
import {Role} from '../Auth/BackOfficeUserRole';
import LinkToOverlay from '../Elements/Overlay/LinkToOverlay';
// @ts-ignore
import {PlusIcon} from 'react-octicons';

interface GlobalSettingsProps {
    settings: GlobalSetting[];
}

const GlobalSettings = (props: GlobalSettingsProps) => {
    const tableHeaders = {
        category: 'Category',
        name: 'Name',
        value: 'Value',
    };

    const overlay: any = `/global-settings/update`;
    const tableTitle = (
        <span>
            <Can roles={[Role.READ]}>
             {renderOverlay()}
            </Can>
        </span>
    );

    function renderOverlay() {
        // @ts-ignore
        return <LinkToOverlay overlay={overlay}>
           <div className="mb-2 btn btn-success btn-sm" ><PlusIcon fill="#fff"/> Edit global settings</div>
        </LinkToOverlay>;
    }

    return (
        <div>
            <Table
                columns={tableHeaders}
                items={props.settings}
                name={tableTitle}
                description={""}
                extraClassName="table-sm"
                hideLabelColon
            />
        </div>
    );
};

export default GlobalSettings;