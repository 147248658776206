import React from 'react';
import Fetch from "../../Elements/Fetch";
import {fetchStatus} from "../../services";
import BillingStatus from "./BillingStatus";

const BillingStatusRoute = () => {
    return (
        <Fetch fetchFunction={fetchStatus}>
            {billingStatus => <BillingStatus {...billingStatus}/>}
        </Fetch>
    );
};

export default BillingStatusRoute;
