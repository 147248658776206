import React, {useState} from 'react';
import {Col, Row} from "reactstrap";
import FormWithActions from "../Elements/FormWithActions/FormWithActions";
import FormGroupRowSelect from "../Elements/FormGroupRowSelect";

const SenderOnboardingMailForm = ({senderOnboarding, onAction}) => {
    const [language, setLanguage] = useState(senderOnboarding.language);

    const handleAction = () => {
        return onAction(language);
    };

    return (
        <>
            <Row>
                <Col>
                    <h1 className="mb-5">
                        Send buckaroo e-mail
                    </h1>
                </Col>
            </Row>
            <FormWithActions onAction={handleAction} submitButton="Send buckaroo e-mail">
                <FormGroupRowSelect
                    label="Language"
                    value={language}
                    onChange={setLanguage}
                    options={[
                        {value: 'nl' ,label: 'NL'},
                        {value: 'fr' ,label: 'FR'},
                        {value: 'en' ,label: 'EN'},
                    ]}

                />
            </FormWithActions>
        </>
    );
};

export default SenderOnboardingMailForm;