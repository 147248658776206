import React, {Component} from 'react';
import {PlusIcon, PencilIcon} from 'react-octicons';
import {Row, Col} from 'reactstrap';
import AbstractItemsTableWithLoader from "../../../Tables/AbstractItemsTableWithLoader";
import {fetchBillingRules, formatCurrency, formatNumber} from "../../../services";
import LinkToOverlay from "../../../Elements/Overlay/LinkToOverlay";
import {AppContext} from "../../../AppContext";


class BillingRulesRoute extends Component {
    static contextType = AppContext;

    static transformItem(item) {
        return {
            ...item,
            activeFromDateString: new Date(item.activeFrom).toLocaleDateString(), //nodig in tableColumns
            edit: <LinkToOverlay overlay={`/billing/rules/${item.id}/edit`}><PencilIcon/></LinkToOverlay>,
            absoluteCostFormatted: formatCurrency(item.absoluteCost, 3, 3),
            relativeFractionFormatted: item.relativeFraction ? `${item.relativeFraction.toLocaleString('nl-BE', {minimumFractionDigits: 2, maximumFractionDigits: 2})} %` : '',
            maxBillableAmountFormatted: item.maxBillableAmount ? formatCurrency(item.maxBillableAmount) : '',
            nbEvents: formatNumber(item.nbEvents),
        };
    }

    static tableColumns(hideColumns = [], addSort = true) {
        const columns = {
            billingSender: {
                name: 'Billing sender',
                sort: addSort,
                sortKey: 'billingSender.name',
            },
            billingEventType: {
                name: 'Type',
                sort: addSort,
            },
            billingEventSubtype: {
                name: 'Subtype',
                sort: addSort,
            },
            absoluteCostFormatted: {
                name: 'Absolute',
                sort: addSort,
                sortKey: 'absoluteCost',
                style: {
                    width: '120px',
                },
                rowStyle: {
                    textAlign: 'right',
                },
            },
            relativeFractionFormatted: {
                name: 'Relative',
                sort: addSort,
                sortKey: 'relativeFraction',
                style: {
                    width: '120px',
                },
                rowStyle: {
                    textAlign: 'right',
                },
            },
            maxBillableAmountFormatted: {
                name: 'Max amount',
                sort: addSort,
                sortKey: 'maxBillableAmount',
                style: {
                    width: '125px',
                },
                rowStyle: {
                    textAlign: 'right',
                },
            },
            activeFromDateString: {
                name: 'Active from',
                sort: addSort,
                sortKey: 'activeFrom',
                style: {
                    width: '120px',
                },
            },
            nbEvents: '# unbilled events',
            edit: {
                name: 'Edit',
                style: {
                    width: '60px',
                },
            },
        };

        hideColumns.forEach(column => delete columns[column]);

        return columns;
    };

    render() {
        const fetchFunction = searchParams => {
            return fetchBillingRules(searchParams).then(data => ({
                ...data,
                items: data.items.map(item => BillingRulesRoute.transformItem(item)),
            }));
        };

        const namedSearchParameters = {
            'billingSender.name': {
                type: 'text',
                name: 'Billing sender',
                operators: ['contains'],
            },
            billingEventType: {
                type: 'select',
                name: 'Type',
                options: this.context.billingEventTypes,
                operators: ['eq'],
            },
            billingEventSubtype: {
                type: 'select',
                name: 'Subtype',
                options: this.context.billingEventSubtypes,
                operators: ['eq'],
            },
        };

        return (
            <>
                <Row className="form-row">
                    <Col xs="auto"><h2 className="mb-5">Rules</h2></Col>
                    <Col style={{marginTop: '8px'}}><LinkToOverlay overlay="/billing/rules/add"><PlusIcon/></LinkToOverlay></Col>
                </Row>

                <AbstractItemsTableWithLoader
                    tableColumns={BillingRulesRoute.tableColumns()}
                    fetchFunction={fetchFunction}
                    namedSearchParameters={namedSearchParameters}
                    refreshKey="billingRules"
                />
            </>
        );
    }
}

export default BillingRulesRoute;
