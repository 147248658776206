import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {withQueryRouterOriginalLocation} from "../QueryRouter";
import BillingSenderOverlayRoutes from "../../Billing/BillingSender/BillingSenderOverlayRoutes";
import BillingRuleOverlayRoutes from "../../Billing/BillingRule/BillingRuleOverlayRoutes";
import InvoiceOverlayRoutes from "../../Billing/Invoice/InvoiceOverlayRoutes";
import BillingCreditsOverlayRoutes from "../../Billing/BillingCredits/BillingCreditsOverlayRoutes";
import queryString from 'query-string';
import UserRoutes from "../../Users/UserRoutes";
import SenderRoutes from "../../Senders/SenderRoutes";
import BillingResellerOverlayRoutes from "../../Billing/BillingReseller/BillingResellerOverlayRoutes";
import BillingReportsOverlayRoutes from "../../Billing/BillingReports/BillingReportsOverlayRoutes";
import OverlayRoute from "./OverlayRoute";
import GlobalSettingsUpdateRoute from "../../GlobalSettings/update/GlobalSettingsUpdateRoute";
import DocumentJsonRoute from "../../Documents/DocumentJsonRoute";
import TaskDocumentIdsNotReportedRoute from "../../Tasks/TaskDocumentIdsNotReportedRoute";
import MtpFileOverlayRoutes from "../../MtpFile/MtpFileOverlayRoutes";
import SenderOnboardingOverlayRoutes from "../../SenderOnboarding/SenderOnboardingOverlayRoutes";
import MaintenanceModeEditRoute from "../../GlobalSettings/update/MaintenanceModeEditRoute";
import TransactionRoutes from "../../Transactions/TransactionRoutes";

class OverlayContainer extends Component {
    constructor(props) {
        super(props);

        this.closeOverlay = this.closeOverlay.bind(this);
    }

    closeOverlay() {
        const searchParsed = queryString.parse(this.props.originalLocation.search);
        delete searchParsed['overlay'];

        this.props.history.push({
            pathname: this.props.originalLocation.pathname,
            search: queryString.stringify(searchParsed),
        });
    }

    static pushToOverlayUrl(query, overlayLocation, originalLocation, history) {
        //See if we can solve this by overriding push method on history object so we push the 'to' location to the overlay query parameter of the URL

        const newOverlaySearchString = queryString.stringify(Object.assign(
            queryString.parse(overlayLocation.search),
            query,
        ));

        const originalSearchParsed = queryString.parse(originalLocation.search);

        originalSearchParsed['overlay'] = overlayLocation.pathname;
        if (newOverlaySearchString) {
            originalSearchParsed['overlay'] = `${overlayLocation.pathname}?${newOverlaySearchString}`;
        }

        history.push({
            pathname: originalLocation.pathname,
            search: queryString.stringify(originalSearchParsed),
        });
    };

    render() {
        return (
            <Fragment>
                <UserRoutes closeOverlay={this.closeOverlay}/>
                <SenderRoutes closeOverlay={this.closeOverlay}/>
                <TransactionRoutes closeOverlay={this.closeOverlay}/>
                <InvoiceOverlayRoutes closeOverlay={this.closeOverlay} originalLocation={this.props.originalLocation}/>
                <BillingCreditsOverlayRoutes closeOverlay={this.closeOverlay}/>
                <BillingRuleOverlayRoutes closeOverlay={this.closeOverlay}/>
                <BillingSenderOverlayRoutes closeOverlay={this.closeOverlay}/>
                <BillingResellerOverlayRoutes closeOverlay={this.closeOverlay}/>
                <BillingReportsOverlayRoutes closeOverlay={this.closeOverlay}/>
                <MtpFileOverlayRoutes closeOverlay={this.closeOverlay}/>
                <SenderOnboardingOverlayRoutes closeOverlay={this.closeOverlay}/>
                <OverlayRoute
                    exact
                    path="/global-settings/update"
                    closeOverlay={this.closeOverlay}
                    render={() => <GlobalSettingsUpdateRoute history={this.props.history}/>}
                />
                <OverlayRoute
                    exact
                    path="/maintenance-mode/edit"
                    closeOverlay={this.closeOverlay}
                    render={() => <MaintenanceModeEditRoute closeOverlay={this.closeOverlay}/>}
                />
                <OverlayRoute
                    exact
                    path="/json-details/:id"
                    closeOverlay={this.closeOverlay}
                    render={({match}) => <DocumentJsonRoute match={match}/>}
                />
                <OverlayRoute
                    exact
                    path="/not-reported-document-ids/:taskId"
                    closeOverlay={this.closeOverlay}
                    render={({match}) => <TaskDocumentIdsNotReportedRoute match={match}/>}
                />
            </Fragment>
        );
    }
}

export default withQueryRouterOriginalLocation(withRouter(OverlayContainer));
