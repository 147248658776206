import React, {Fragment} from 'react';
import {Row, Col} from 'reactstrap';
import moment from 'moment';
import {Link} from "react-router-dom";
import {PlusIcon} from "react-octicons";
import LinkToOverlay from "../../../Elements/Overlay/LinkToOverlay";
import AbstractItemsTableWithLoader from "../../../Tables/AbstractItemsTableWithLoader";
import {fetchBillingReports} from "../../../services";

export const transformItem = (item) => {
    return {
        ...item,
        key: item.id,
        dateCreatedDate: new Date(item.dateCreated).toLocaleString(),
        periodDate: moment(item.period).format('MMMM YYYY'),
        pllayUser: item.pllayUser || '(system)',
        link: <Link to={'/billing/reports/' + item.id}>View</Link>,
    };
};

export const tableColumns = (hideColumns = [], addSort = true) => {
    const columns = {
        dateCreatedDate: {
            name: 'Date',
            sort: addSort,
            sortKey: 'dateCreated',
        },
        billingSenderName: {
            name: 'Billing sender',
            sort: addSort,
            sortKey: 'billingSender.name',
        },
        periodDate: {
            name: 'Period',
            sort: addSort,
            sortKey: 'period',
        },
        status: {
            name: 'Status',
        },
        pllayUser: {
            name: 'Created by',
        },
        link: {
            name: '',
            style: {
                width: '70px',
            },
        },
    };

    hideColumns.forEach(column => delete columns[column]);

    return columns;
};

const BillingReportsRoute = () => {

    const fetchFunction = (searchParams) => {
        return fetchBillingReports(searchParams).then(data => ({
            ...data,
            items: data.items.map(transformItem),
        }));
    };

    const namedSearchParameters = {
        dateCreated: {
            type: 'date',
            name: 'Date',
            operators: ['eq', 'lt', 'gt', 'between'],
        },
        'billingSender.name': {
            type: 'text',
            name: 'Billing sender',
            operators: ['contains'],
        },
    };

    return (
        <Fragment>

            <Row className="form-row">
                <Col xs="auto"><h2 className="mb-5">Reports</h2></Col>
                <Col style={{marginTop: '8px'}}><LinkToOverlay overlay="/billing/reports/add"><PlusIcon/></LinkToOverlay></Col>
            </Row>

            <AbstractItemsTableWithLoader
                tableColumns={tableColumns()}
                namedSearchParameters={namedSearchParameters}
                fetchFunction={fetchFunction}
                refreshKey="billingReports"
            />
        </Fragment>
    );
};

export default BillingReportsRoute;