import React, {Fragment, useContext} from 'react';
import {PlusIcon} from 'react-octicons';
import {Row, Col} from 'reactstrap';
import {Link} from 'react-router-dom';
import {fetchBillingSenders, startBillingSendersDownload} from "../../../services";
import AbstractItemsTableWithLoader from "../../../Tables/AbstractItemsTableWithLoader";
import LinkToOverlay from "../../../Elements/Overlay/LinkToOverlay";
import {ModalContext} from "../../../Modal/ModalProvider";

const BillingSendersRoute = () => {
    const {showModal} = useContext(ModalContext);

    const fetchFunction = (searchParams) => {
        return fetchBillingSenders(searchParams).then(data => ({
            ...data,
            items: data.items.map(item => {
                return {
                    ...item,
                    key: item.id,
                    billableFromDateString: new Date(item.billableFrom).toLocaleDateString(),
                    link: <Link to={'/billing/senders/' + item.id}>{item.name}</Link>,
                    inactiveString: item.inactive ? 'Inactive' : '',
                };
            }),
        }));
    };

    const tableColumns = {
        link: {
            name: 'Name',
            sort: true,
            sortKey: 'name',
        },
        billableFromDateString: {
            name: 'Billable From',
            sort: true,
            sortKey: 'billableFrom',
        },
        externalClientId: {
            name: 'Eenvoudig Factureren client id',
            sort: true,
        },
        billingResellerName: {
            name: 'Billing reseller',
            sort: true,
            sortKey: 'billingReseller.name',
        },
        billingResellerType: {
            name: 'Billing Reseller Type',
            sort: true,
        },
        typeContractPOM: {
            name: 'Contract POM',
            sort: true,
        },
        slaSigned: {
            name: 'SLA getekend',
            sort: true,
        },
        dataProcessingAgreement: {
            name: 'Data processing agreement',
            sort: true,
        },
        inactiveString: {
            name: 'Inactive',
            sort: true,
            sortKey: 'inactive',
        },
    };

    const namedSearchParameters = {
        name: {
            type: 'text',
            name: 'Billing sender',
            operators: ['contains'],
        },
        billableFrom: {
            type: 'date',
            name: 'Billable From',
            operators: ['eq', 'lt', 'gt', 'between'],
        },
        externalClientId: {
            type: 'text',
            name: 'EF client id',
            operators: ['eq'],
        },
        'billingReseller.name': {
            type: 'text',
            name: 'Billing reseller',
            operators: ['contains'],
        },
        billingResellerType: {
            type: 'select',
            name: 'Billing Reseller Type',
            options: {
                'DIRECT_BILLING': 'DIRECT_BILLING',
                'BILLING_RESELLER': 'BILLING_RESELLER',
            },
            operators: ['eq'],
        },
        typeContractPOM: {
            type: 'select',
            name: 'Contract POM',
            options: {
                'Geen contract': 'Geen contract',
                'POM sender agreement': 'POM sender agreement',
                'Getekende offerte': 'Getekende offerte',
                'Custom contract': 'Custom contract',
                'Onboarding direct': 'Onboarding direct',
                'Onboarding via partner': 'Onboarding via partner',
                'Contract via partner': 'Contract via partner',
                'POM order form': 'POM order form',
            },
            operators: ['eq'],
        },
        slaSigned: {
            type: 'select',
            name: 'SLA getekend',
            options: {
                'Ja': 'Ja',
                'Nee': 'Nee',
            },
            operators: ['eq'],
        },
        dataProcessingAgreement: {
            type: 'select',
            name: 'Data processing agreement',
            options: {
                'Geen': 'Geen',
                'Onderdeel van de algemene voorwaarden': 'Onderdeel van de algemene voorwaarden',
                'Custom contract': 'Custom contract',
            },
            operators: ['eq'],
            wrapperClass: 'test',
            wrapperStyle: {
                'width': 185,
            },
        },
        inactive: {
            type: 'select',
            name: 'Inactive',
            options: ['true', 'false'],
            operators: ['eq'],
        },
    };

    return (
        <Fragment>

            <Row className="form-row">
                <Col xs="auto"><h2 className="mb-5">Billing senders</h2></Col>
                <Col style={{marginTop: '8px'}}>
                    <LinkToOverlay overlay="/billing/senders/add">
                        <PlusIcon/>
                    </LinkToOverlay>
                </Col>
            </Row>

            <AbstractItemsTableWithLoader
                tableColumns={tableColumns}
                namedSearchParameters={namedSearchParameters}
                fetchFunction={fetchFunction}
                refreshKey="billingSenders"
                downloadModalFunc={startBillingSendersDownload}
                downloadModalDomainObjectName="billing senders"
                showModal={showModal}
            />
        </Fragment>
    );
};

export default BillingSendersRoute;