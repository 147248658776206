import React, {Component, Fragment} from 'react';
import {Button} from 'reactstrap';
import PropTypes from 'prop-types';
import PomModalAction from "../PomModalAction";
import Loader from "../Loader";
import './FormWithActions.css';
import Can from "../../Auth/Can";

class FormWithActions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            confirmAction: null,
            confirmMessage: null,
            showFeedback: false,
            feedback: null,
            isSubmitting: false,
            error: '',
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    handleAction(action, showFeedback) {
        this.setState({
            isSubmitting: true,
        });

        this.props.onAction(action).then(feedback => {
            if (showFeedback) {
                this.setState({
                    confirmAction: null,
                    confirmMessage: null,
                    feedback: feedback,
                    isSubmitting: false,
                    error: '',
                });
            }
        }).catch(errorMessage => {
            this.setState({
                isSubmitting: false,
                error: String(errorMessage),
            });
        });
    }

    handleConfirm(action, confirmMessage, showFeedback) {
        this.setState({
            confirmAction: action,
            confirmMessage: confirmMessage,
            showFeedback: showFeedback,
        });
    }

    handleReset() {
        this.setState({
            confirmAction: null,
            confirmMessage: null,
            showFeedback: false,
            feedback: null,
        });
    }

    render() {
        let content = this.props.children;

        let actions = this.props.actions.map(item => (
            <Can roles={item.roles} key={item.action}>
                <PomModalAction
                    key={item.action}
                    isSubmitting={this.state.isSubmitting}
                    onAction={this.handleAction}
                    onConfirm={this.handleConfirm}
                    {...item}
                />
            </Can>
        ));

        let submitButton = '';
        if (!this.props.hideSubmit) {
            submitButton = <PomModalAction action="submit" isSubmitting={this.state.isSubmitting} onAction={this.handleAction} displayName={this.props.submitButton} disabledReason={this.props.disableSubmitReason}/>;
        }

        if (this.state.confirmAction) {
            content = this.state.confirmMessage;
            actions = [
                <PomModalAction isSubmitting={this.state.isSubmitting} onAction={this.handleAction} displayName="Yes" key="yes" action={this.state.confirmAction} showFeedback={this.state.showFeedback}/>,
                <PomModalAction isSubmitting={this.state.isSubmitting} onAction={this.handleReset} displayName="No" key="no" color="secondary"/>
            ];
            submitButton = '';
        }

        if (this.state.feedback) {
            content = this.state.feedback;
            actions = [];
            submitButton = <Button color="success" className="ml-1" onClick={this.handleReset}>Ok</Button>;
        }

        return (
            <Fragment>
                {
                    this.state.error &&
                    <div className="alert alert-danger alert-dismissible">
                        {this.state.error}
                    </div>
                }
                {content}
                {
                    (actions.length > 0 || submitButton) &&
                    <div className="form-button-bar">
                        <div className="left d-flex align-items-center">
                            {actions}
                        </div>
                        <div className="right d-flex align-items-center">
                            {this.state.isSubmitting && <div className="mr-1" style={{width: '100px'}}><Loader color="danger"/></div>}
                            {submitButton}
                        </div>
                    </div>
                }
            </Fragment>
        );
    }
}

FormWithActions.propTypes = {
    actions: PropTypes.array,
    submitButton: PropTypes.string,
    disableSubmitReason: PropTypes.string,
    onAction: PropTypes.func.isRequired,
    children: PropTypes.any,
    hideSubmit: PropTypes.bool,
};

FormWithActions.defaultProps = {
    actions: [],
    submitButton: 'Save',
};

export default FormWithActions;