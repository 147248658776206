import React from 'react';
import PropTypes from 'prop-types';
import {fetchBillingCreditsPurchase} from "../../../services";
import Fetch from "../../../Elements/Fetch";
import BillingCreditsEditPurchaseForm from "./BillingCreditsEditPurchaseForm";

const BillingCreditsEditPurchaseRoute = ({match, history, onSubmit}) => (
    <Fetch fetchFunction={fetchBillingCreditsPurchase} fetchFunctionParams={match.params.id}>
        {billingCreditsPurchase => {
            const handleSubmit = onSubmit || (() => history.push(`/billing/credits/${billingCreditsPurchase.billingSenderId}`));

            return (
                <div style={{maxWidth: 800}}>
                    <BillingCreditsEditPurchaseForm
                        billingCreditsPurchase={billingCreditsPurchase}
                        onSubmit={handleSubmit}
                    />
                </div>
            );
        }}
    </Fetch>
);

BillingCreditsEditPurchaseRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default BillingCreditsEditPurchaseRoute;