import React from 'react';
import {Col, Row} from "reactstrap";
import FormWithActions from "../Elements/FormWithActions/FormWithActions";

const SenderOnboardingCompleteBuckarooOnboardingMerchantRequestForm = ({onAction}) => {
    return (
        <>
            <Row>
                <Col>
                    <h1 className="mb-5">
                        Finalize buckaroo onboarding merchant request
                    </h1>
                </Col>
            </Row>
            <FormWithActions
                onAction={onAction}
                submitButton="Finalize buckaroo onboarding merchant request"
            />
        </>
    );
};

export default SenderOnboardingCompleteBuckarooOnboardingMerchantRequestForm;