import React, {Fragment, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "reactstrap";
import useSubmittingButton from "../../hooks/useSubmittingButton";
import {uploadDocRef} from "../../services";

const SenderUploadDocRefRoute = ({match, history, onSubmit}) => {

    const inputRef = useRef();
    const [validFile, setValidFile] = useState(false);
    const {loader, SubmittingButton} = useSubmittingButton();
    const disabled = !validFile;

    const handleSubmit = async (e) => {
        let file = inputRef.current.files[0];

        const formData = new FormData();
        formData.append('batch', file);

        return uploadDocRef(match.params.id, formData).then(data => {
            onSubmit();
            return data;
        });
    };
    return (
        <Fragment>
            <Row>
                <Col>
                    <h1 className="mb-5">
                        Upload DocRef
                    </h1>
                    <form style={{maxWidth: 600}}>
                        <div className="form-group">
                            <input
                                type="file"
                                required
                                ref={inputRef}
                                onChange={e => setValidFile(!!e.target.value)}
                                accept=".zip"
                            />
                        </div>

                        <Row form className="align-items-center">
                            <Col xs="auto">
                                <SubmittingButton onClick={handleSubmit} disabled={disabled}>Submit</SubmittingButton>
                            </Col>
                            <Col xs="auto">
                                {loader}
                            </Col>
                        </Row>
                    </form>
                </Col>
            </Row>
        </Fragment>

    )
};

SenderUploadDocRefRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default SenderUploadDocRefRoute;