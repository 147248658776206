import React, {Fragment} from 'react';
import DocumentsTable from './DocumentsTable';

const DocumentsRoute = () => (
    <Fragment>
        <h1 className="mb-5">Documents</h1>
        <DocumentsTable/>
    </Fragment>
);

export default DocumentsRoute;