import React, {useEffect, useState} from 'react';
import FormGroupRow from '../../Elements/FormGroupRow';
import Select from 'react-virtualized-select';
import {fetchSenderTemplatesList} from "../../services";
import CreateSenderStepCounter from "../wizard/CreateSenderStepCounter";
import CreateSenderStepButtons from "../wizard/CreateSenderStepButtons";

const CreateSenderStepSelectTemplate = (props: any) => {
    const [templates, setTemplates] = useState<any[]>([])
    const [selectedTemplateId, setSelectedTemplateId] = useState(props.selectedTemplateId)

    useEffect(() => {
        fetchSenderTemplatesList()
            .then((templates: any[]) => {
                let sortedTemplates = templates.sort((a, b) => {
                    const removeSymbols = (name: string) => name.replace(/-/g, ' ');
                    const nameA = removeSymbols(a.templateName.toLowerCase());
                    const nameB = removeSymbols(b.templateName.toLowerCase());
                    if (nameA > nameB) {
                        return 1;
                    } else if (nameA < nameB) {
                        return -1;
                    }
                    return 0;
                })
                setTemplates(sortedTemplates)
            })
            .catch(error => {
            })
    }, [])

    function getSenderTemplates(): any[] {
        return templates || [];
    }

    function isFormValid(): boolean {
        if (!selectedTemplateId) {
            return false;
        }
        return true
    }

    function updateTemplate(templateId: any): void {
        if (templateId) {
            setSelectedTemplateId(templateId)
            let selectedTemplate = getSenderTemplates().find(template => template.id === templateId);
            props.updateTemplate(selectedTemplate);
        }
    }

    function isCurrentStep(): boolean {
        return props.isActive;
    }

    return (
        <div>
            <div className="card">
                <div className="card-header text-center">
                    <CreateSenderStepCounter {...props}></CreateSenderStepCounter>
                </div>
                {isCurrentStep() && (

                    <div className="card-body">
                        <h4>Sender template</h4>
                        {isFormValid()}

                        <FormGroupRow label="Template" required={true}>
                            <Select
                                value={selectedTemplateId}
                                onChange={selection => {
                                    // @ts-ignore
                                    updateTemplate(selection.value);
                                }}
                                options={[
                                    ...getSenderTemplates().map(item => ({
                                        value: item.id,
                                        label: item.templateName,
                                    })),
                                ]}
                                clearable={false}
                                matchProp="label"
                            />

                        </FormGroupRow>

                        <CreateSenderStepButtons {...props} isValid={isFormValid()}></CreateSenderStepButtons>
                    </div>
                )}
            </div>

        </div>
    );

};
export default CreateSenderStepSelectTemplate;