import React, {Fragment} from 'react';
import SpeosReprintTable from './SpeosReprintTable';

const SpeosReprintRoute = () => {
    return (
        <Fragment>
            <h3 className="mb-5 mt-4">Reprint batches</h3>
            <SpeosReprintTable></SpeosReprintTable>
        </Fragment>
    );
};

export default SpeosReprintRoute;