import React from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import FormGroupRow from "./FormGroupRow";

type Option = {
    value: string;
    label: string;
};

type Props = {
    label: string;
    value: any;
    onChange: (value: any) => void;
    options: Option[];
    description?: any;
    multi?: boolean;
    addEmptyOption?: boolean;
    emptyOptionLabel?: string;
    required?: boolean;
    disabled?: boolean;
    cssClasses?: any;
    placeholder?: string;
    selectAll?: boolean;
};

const FormGroupRowSelect = ({
                                label,
                                value,
                                required,
                                disabled,
                                onChange,
                                options,
                                placeholder,
                                description,
                                multi,
                                addEmptyOption,
                                cssClasses,
                                emptyOptionLabel,
                                selectAll,
                                ...props
                            }: Props) => {
    if (multi && selectAll) {
        const handleSelectAll = (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            onChange(options.map(item => item.value));
        };

        //eslint-disable-next-line
        const selectAllLink = <a href="#" onClick={handleSelectAll}>Select all</a>;
        description = <>{description} {selectAllLink}</>;
    }

    return (
        <FormGroupRow label={label} required={required} description={description} cssClasses={cssClasses}>
            <Select
                {...props}
                multi={multi}
                placeholder={placeholder || 'Select...'}
                value={value}
                disabled={disabled}
                onChange={selection => onChange(multi ? selection.map((item: any) => item.value) : selection.value)}
                options={[...((addEmptyOption || emptyOptionLabel) ? [{
                    value: '',
                    label: (emptyOptionLabel || '(empty)')
                }] : []), ...options]}
                clearable={false}
            />
        </FormGroupRow>
    );
};

export default FormGroupRowSelect;
