import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import OverlayRoute from "../Elements/Overlay/OverlayRoute";
import UserEditRoute from "./UserEditRoute";
import UserPartnerSettingsRoute from "./UserPartnerSettingsRoute";
import UserRolesRoute from "./UserRolesRoute";

const UserRoutes = ({closeOverlay}) => {
    return (
        <Fragment>
            <OverlayRoute exact path="/users/:id/edit" closeOverlay={closeOverlay} render={({match}) => (
                <UserEditRoute match={match} onSubmit={closeOverlay}/>
            )}/>
            <OverlayRoute exact path="/users/:id/edit-partner-settings" closeOverlay={closeOverlay} render={({match}) => (
                <UserPartnerSettingsRoute match={match} onSubmit={closeOverlay}/>
            )}/>
            <OverlayRoute exact path="/users/:id/edit-roles" closeOverlay={closeOverlay} render={({match}) => (
                <UserRolesRoute match={match} onSubmit={closeOverlay}/>
            )}/>
        </Fragment>
    );
};

UserRoutes.propTypes = {
    closeOverlay: PropTypes.func.isRequired,
};

export default UserRoutes;
