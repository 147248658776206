import React, {useEffect, useState} from 'react';
import FormGroupRowCheckbox from '../../../Elements/FormGroupRowCheckbox';
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import {ArrowDropDownOutlined} from "@material-ui/icons";
import CreateSenderStepButtons from "../../wizard/CreateSenderStepButtons";
import CreateSenderStepCounter from "../../wizard/CreateSenderStepCounter";
import {PartnerSetting} from "./partnerSettings";
import FormGroupRowText from "../../../Elements/FormGroupRowText";
import FormGroupRowSelect from "../../../Elements/FormGroupRowSelect";
import FormGroupRowFile from "../../../Elements/FormGroupRowFile";
import {covertToLabel} from "../../CreateSenderHelper";
import {convertStringToArray} from "./RequiredPartnerSettings";

const CreateSenderStepPartnerSettings = (props: any) => {
    const parentSettings: PartnerSetting[] = props.data.parentSettings;
    const [partnerSettings, setPartnerSettings] = useState<PartnerSetting[]>(props.data.partnerSettings || []);
    const [availableChannels, setAvailableChannels] = useState<string[]>([]);

    useEffect(() => {
        function prepareAvailableChannels() {
            let availableChannelsInParent = parentSettings.find(ps => ps.name === 'available.channels')?.value;
            let availableChannelsInPartner = partnerSettings.find(ps => ps.name === 'available.channels')?.value;

            let availableChannels = (availableChannelsInParent as string[])?.length > 0 ? availableChannelsInParent : availableChannelsInPartner;
            if (availableChannels) {
                setAvailableChannels(convertStringToArray(availableChannels as any) as string[]);
            }
        }

        prepareAvailableChannels();
    }, [partnerSettings, parentSettings]);


    function handleFormChange(rowIndex: number, value: any, psName?: string) {

        setPartnerSettings((prevState: PartnerSetting[]) => {
            const updatedSettings = [...prevState];
            updatedSettings[rowIndex] = {...prevState[rowIndex], value: value};
            return updatedSettings;
        });

        if (psName && psName === 'available.channels') {
            setAvailableChannels(value);
        }
    }

    function isParentOverride(ps: PartnerSetting) {
        return parentSettings
            .map(l => l.category + l.name + l.value)
            .find(l => l === (ps.category + ps.name + ps.value)) !== undefined;
    }

    function isFormValid() {
        let result = true;

        partnerSettings.forEach(ps => {
            if (ps.isRequired && (!ps.value || (ps.value as string[]).length === 0))
                result = false;
        });

        return result;
    }

    function updateBody() {
        let sender = props.data.sender;
        sender.logoUrl = partnerSettings.find(ps => ps.name === 'maildelivery.logo.url')?.value;
        props.updateBody({
            partnerSettings: partnerSettings
        });
        props.updateRelevantConfigurations(partnerSettings);
    }

    function renderAddedSettingInput(ps: PartnerSetting, rowIndex: number) {

        if (ps === undefined) {
            return (<></>);
        }

        if (ps.type === 'string') {
            return (
                (ps.name.includes('logo.url') || ps.name.includes('image.url')) ?
                    <div className={`w-75`}>
                        <FormGroupRowText label=""
                                          value={ps.value}
                                          disabled={!ps.isEditable}
                                          required={ps.isRequired}
                                          cssClasses={['mt-3']}
                                          onChange={value => handleFormChange(rowIndex, value)}/>
                        <p>OR</p>
                        <FormGroupRowFile label=""
                                          value={ps.value}
                                          disabled={!ps.isEditable}
                                          required={ps.isRequired}
                                          cssClasses={['mt-3']}
                                          onChange={(value: any) => handleFormChange(rowIndex, value)}/>
                    </div>
                    :
                    <div className={`w-75`}>
                        <FormGroupRowText label=""
                                          value={ps.value}
                                          disabled={!ps.isEditable}
                                          required={ps.isRequired}
                                          cssClasses={['mt-3']}
                                          onChange={value => handleFormChange(rowIndex, value)}/>
                    </div>

            );
        }

        if (ps.type === 'number') {
            return (
                <div className={`w-75`}>
                    <FormGroupRowText label=""
                                      value={ps.value}
                                      disabled={!ps.isEditable}
                                      required={ps.isRequired}
                                      type="number"
                                      cssClasses={['mt-3']}
                                      onChange={value => handleFormChange(rowIndex, value)}/>
                </div>

            );
        }

        if (ps.type === 'boolean') {
            return (
                <FormGroupRowCheckbox label=""
                                      checked={ps.value}
                                      disabled={!ps.isEditable}
                                      required={ps.isRequired}
                                      cssClasses={['mt-3']}
                                      onChange={value => handleFormChange(rowIndex, value)}/>
            );
        }

        if (ps.type === 'multi-list') {
            let availableOptions = (ps.name.includes('channels') && ps.name !== 'available.channels') ? availableChannels : ps.options;
            return (
                <div className={`w-75`}>
                    <FormGroupRowSelect
                        label=""
                        value={ps.value}
                        disabled={!ps.isEditable}
                        onChange={value => handleFormChange(rowIndex, value, ps.name,)}
                        multi={true}
                        cssClasses={['mt-3']}
                        options={availableOptions ? availableOptions?.map((l: string) => {
                            return {label: l, value: l};
                        }) : []}
                    />
                </div>
            );
        }
        return (<div>not supported</div>);
    }

    function renderSettings(name: string, settings: PartnerSetting[], isParentSettings: boolean) {
        return (
            <>
                <Accordion key={name} defaultExpanded={!isParentSettings}>
                    <AccordionSummary expandIcon={<ArrowDropDownOutlined fontSize="large"/>}>
                        <div className='d-flex justify-content-between w-100'>
                            <h5>{name}</h5>
                        </div>
                    </AccordionSummary>

                    <AccordionDetails>

                        <table className="table">
                            <thead>
                            <tr>
                                <th className='w-25'>Name</th>
                                <th className='w-75'>Value</th>
                            </tr>
                            </thead>
                            <tbody>

                            {(settings === undefined || settings.length === 0) &&
                                <tr>
                                    <td className='text-center' colSpan={4}>
                                        No settings found
                                    </td>
                                </tr>
                            }

                            {settings?.map((ps, rowIndex: number) => {
                                return (
                                    <tr key={rowIndex + name}
                                        className={`align-middle  ${!isParentSettings && isParentOverride(ps) ? 'table-warning' : ''}`}>
                                        <td className={`${(ps.isRequired && !isParentSettings) ? 'text-danger' : ''} align-middle`}>{ps.label ? ps.label : covertToLabel(ps.name)}</td>
                                        <td className='align-middle'>
                                            {isParentSettings ? ps.value.toString() : renderAddedSettingInput(ps, rowIndex)}
                                            {(!isParentSettings && isParentOverride(ps)) &&
                                                <span>{`This setting is the same as parent setting.`}</span>
                                            }
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    return (
        <div>
            <div className="card">
                <div className="card-header text-center">
                    <CreateSenderStepCounter {...props}></CreateSenderStepCounter>
                </div>
                <div className="card-body">
                    {renderSettings('Parent settings', parentSettings, true)}
                    {renderSettings('Partner settings', partnerSettings, false)}
                    <CreateSenderStepButtons {...props}
                                             isValid={isFormValid()}
                                             onBack={updateBody}
                                             onNext={updateBody}> </CreateSenderStepButtons>
                </div>
            </div>
        </div>
    );
};
export default CreateSenderStepPartnerSettings;