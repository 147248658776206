import React, {useContext} from 'react';
import AbstractItemsTableWithLoader from "../../../Tables/AbstractItemsTableWithLoader";
import {fetchBillingEvents, formatCurrency, formatNumber, runScript} from "../../../services";
import {AppContext} from "../../../AppContext";
import moment from 'moment';
import {ModalContext} from "../../../Modal/ModalProvider";

export const tableColumns = (hideColumns = []) => {
    const columns = {
        billingSenderFormatted: 'Billing sender',
        billingEventSubtype: 'Type',
        unitsFormatted: {
            name: 'Units',
            style: {
                width: '100px',
            },
        },
        amountFormatted: {
            name: 'Document amount',
            style: {
                width: '170px',
            },
            rowStyle: {
                textAlign: 'right',
            },
        },
        date: {
            name: 'Date',
            style: {
                width: '160px',
            },
        },
    };

    hideColumns.forEach(column => delete columns[column]);

    return columns;
};

export const transformItem = (item, searchParams = {}) => {
    delete searchParams['overlay'];
    delete searchParams['itemsPerPage'];

    return {
        ...item,
        key: item.id,
        date: moment(item.date).format("MMMM YYYY"),
        unitsFormatted: formatNumber(item.units),
        amountFormatted: formatCurrency(item.amount),
        billingSenderFormatted: item.billingSender,
    };
};

const BillingEventsBillableTable = () => {
    const {billingEventTypes, billingEventSubtypes} = useContext(AppContext);
    const {showModal} = useContext(ModalContext);
    
    const namedSearchParameters = {
        'sender.billingSender.name': {
            type: 'text',
            name: 'Billing sender',
            operators: ['contains'],
        },
        billingEventType: {
            type: 'select',
            name: 'Type',
            options: billingEventTypes,
            operators: ['eq'],
        },
        billingEventSubtype: {
            type: 'select',
            name: 'Subtype',
            options: billingEventSubtypes,
            operators: ['eq'],
        },
        date: {
            type: 'date',
            name: 'Date',
            operators: ['eq', 'lt', 'gt', 'between'],
        },
    };

    const fetchFunction = (searchParams) => {
        return fetchBillingEvents(searchParams).then(data => ({
            ...data,
            items: data.items.map(item => transformItem(item, searchParams)),
        }));
    };

    const selectFunction = async (keys) => {
        const response = await runScript('CreateInvoices', {
            billingEventsBucketIds: keys.flat(),
        });

        if (response.status === 200) {
            const data = await response.json();

            showModal({
                type: 'download',
                exportId: data.id,
                triggerRefreshKeyAfterClose: 'billingEvents',
            });
        } else {
            throw new Error(`${response.status}: ${response.statusText}. Contact a site administrator. This error probably won't go away by itself.`);
        }
    };
    
    return (
        <AbstractItemsTableWithLoader
            refreshKey="billingEvents"
            tableColumns={tableColumns()}
            namedSearchParameters={namedSearchParameters}
            fetchFunction={fetchFunction}

            disabledReasonFunction={events => events.unbillableReason}
            selectableFunction={events => !events.unbillableReason}
            selectableButton="Create invoice"

            selectableButtonFunction={selectFunction}
            skipRefreshAfterSelectableButton
        />
    );
};

export default BillingEventsBillableTable;
