import React from 'react';
import PropTypes from 'prop-types';
import {fetchSenderOnboarding, senderOnboardingBuckarooSendMail} from "../services";
import Fetch from "../Elements/Fetch";
import SenderOnboardingMailForm from "./SenderOnboardingMailForm";

const SenderOnboardingMailRoute = ({match, history, onSubmit}) => {
    return (
        <Fetch fetchFunction={fetchSenderOnboarding} fetchFunctionParams={match.params.id}>
            {senderOnboarding => {
                const handleAction = async (language) => {
                    await senderOnboardingBuckarooSendMail(senderOnboarding.id, language);
                    onSubmit();
                };

                return (
                    <SenderOnboardingMailForm
                        senderOnboarding={senderOnboarding}
                        onAction={handleAction}
                    />
                );
            }}
        </Fetch>
    );
};

SenderOnboardingMailRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default SenderOnboardingMailRoute;