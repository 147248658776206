import React, {Fragment} from 'react';
import Field from '../../Elements/Field';
import Table from '../../Elements/Table/Table';
import {Link} from 'react-router-dom';

interface SpeosBatchDetailProps {
    type: 'Archive' | 'Reprint';
    id: number;
    dateCreated: Date;
    paymentPlans: {
        documentId: number,
        archiveStatus: string,
        archiveDateTime: Date,
        emailSentDate: Date,
        emailBouncedDate: Date,
        dateCreated: Date,
        lastUpdated: Date,
    }[];
}

const SpeosBatchDetail = (props: SpeosBatchDetailProps) => {
    return (
        <Fragment>
            <h1 className="mb-3">{props.type} — Detail </h1>
            <div className="row">
                <div className="col">
                    <Field name="ID">{props.id}</Field>
                    <Field name="Created">{props.dateCreated}</Field>
                </div>
            </div>

            <h4 className="mt-4">Payment plans </h4>
            <div className="mt-2">
                <Table
                    name=""
                    description=""
                    columns={{
                        documentId: 'Document ID',
                        archiveStatus: 'Archive status',
                        archiveDateTime: 'Archive date',
                        emailSentDate: 'Email sent date',
                        emailBouncedDate: 'Email bounced date',
                        dateCreated: 'Created',
                        lastUpdated: 'Last updated',
                    }}
                    items={props.paymentPlans.map(l => {
                        return {
                            ...l,
                            documentId: <Link to={"/document?id=" + l.documentId}>{l.documentId}</Link>,
                            key: l.dateCreated
                        };
                    })}
                    extraClassName="table-sm"
                />
            </div>
        </Fragment>
    );
};

export default SpeosBatchDetail;