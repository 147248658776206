import React, {useEffect, useState} from 'react';
import CreateSenderStepButtons from '../wizard/CreateSenderStepButtons';
import CreateSenderStepCounter from '../wizard/CreateSenderStepCounter';
import FormGroupRowText from '../../Elements/FormGroupRowText';
import FormGroupRowCheckbox from '../../Elements/FormGroupRowCheckbox';
import {isValidAccountNumber} from "../validators/ibanValidator";
import {validateVATNumber} from "../validators/vatValidator";
import {getFormattedIban} from "../CreateSenderHelper";

const CreateSenderStepSender = (props: any) => {
    const sender = props.sender;
    const accountSettings = props.accountSettings;
    const [senderContractNumber, setSenderContractNumber] = useState(sender.senderContractNumber || '');
    const [senderName, setSenderName] = useState(sender.senderName || '');
    const [senderVat, setSenderVat] = useState(sender.senderVat || '');
    const [senderShortName, setSenderShortName] = useState(sender.senderShortName || '');
    const [senderIban, setSenderIban] = useState(sender.senderBankAccountNumber || '');
    const [withS3Bucket, setWithS3Bucket] = useState(accountSettings.withS3Bucket);
    const [errorMessages, setErrorMessages] = useState<Map<string, string>>()

    useEffect(()=>{
        if(sender.senderVat) {
            setValidatedVAT(sender.senderVat);
        }
    }, [sender.senderVat])

    function updateBody(): void {
        props.updateBody({
            sender: {
                senderContractNumber: senderContractNumber,
                senderName: senderName,
                senderShortName: senderShortName,
                senderBankAccountNumber: senderIban,
                senderVat: senderVat.replace(/[ .]/g, ''),
                withS3Bucket: withS3Bucket,
                senderType: accountSettings.senderType
            }
        });
    }

    function setValidatedVAT(vat: string) {
        vat = vat.replace(/[ .]/g, '').toUpperCase();
        setErrorMessages((prevMessages) => {
            // @ts-ignore
            const newMessages = new Map(prevMessages);
            newMessages.delete("invalidVAT");
            return newMessages;
        });

        if (vat !== 'N/A' && vat !== 'NVT') {
            let result = validateVATNumber(vat);

            if (!result) {
                // @ts-ignore
                setErrorMessages((prevMessages) => new Map(prevMessages).set("invalidVAT", "Please provide a valid VAT"));
            }
        }

        const formattedVAT = vat.replace(/^([A-Z]{2})(\d{4})(\d{3})(\d{3})$/, '$1 $2.$3.$4');
        setSenderVat(formattedVAT);
    }

    const setValidatedIban = (iban: any) => {
        iban = iban.toUpperCase();
        setErrorMessages((prevMessages) => {
            // @ts-ignore
            const newMessages = new Map(prevMessages);
            newMessages.delete("invalidAccountNumber");
            return newMessages;
        });

        let result = isValidAccountNumber(iban);

        if (!result) {
            // @ts-ignore
            setErrorMessages((prevMessages) => new Map(prevMessages).set("invalidAccountNumber", "Please provide a valid IBAN"));
        }

        setSenderIban(getFormattedIban(iban));
    };

    function setValidatedShortName(value: string) {
        const shortName = value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
        setSenderShortName(shortName);
    }

    function isCurrentStep(): boolean {
        return props.isActive;
    }

    function isFormValid(): boolean {
        return senderContractNumber !== ''
            && senderName !== ''
            && senderShortName !== ''
            && senderIban !== ''
            && senderVat !== ''
            && (!errorMessages || errorMessages?.size === 0)
            && (accountSettings.withS3Bucket ? withS3Bucket : true);
    }

    return (
        <div>
            <div className="card">
                <div className="card-header text-center">
                    <CreateSenderStepCounter {...props}></CreateSenderStepCounter>
                </div>
                {isCurrentStep() && (
                    <div className="card-body">
                        <h4>Sender</h4>
                        <FormGroupRowText label="SCN" value={senderContractNumber} required={true}
                                          onChange={setSenderContractNumber}/>
                        <FormGroupRowText label="Name"
                                          required={true}
                                          value={senderName}
                                          onChange={(value) => {
                                              setSenderName(value);
                                              setValidatedShortName(value);
                                          }}/>
                        <FormGroupRowText label="Short name" required={true} value={senderShortName}
                                          description="No spaces, no uppercase"
                                          onChange={(value) => {
                                              setValidatedShortName(value);
                                          }}/>
                        <>
                            <FormGroupRowText label="IBAN" required={true}
                                              errorMessage={errorMessages?.get('invalidAccountNumber')}
                                              value={senderIban}
                                              onChange={(value) => {
                                                  setValidatedIban(value);
                                              }}/>
                            <FormGroupRowText label="VAT" required={true}
                                              errorMessage={errorMessages?.get('invalidVAT')}
                                              value={senderVat}
                                              onChange={(value) => {
                                                  setValidatedVAT(value);
                                              }}/>
                        </>

                        {accountSettings.withS3Bucket &&
                            <FormGroupRowCheckbox checked={withS3Bucket}
                                                  onChange={setWithS3Bucket}
                                                  required={true}
                                                  label="Create S3 bucket"
                            ></FormGroupRowCheckbox>
                        }

                        <CreateSenderStepButtons {...props}
                                                 isValid={isFormValid()}
                                                 onBack={updateBody}
                                                 onNext={updateBody}> </CreateSenderStepButtons>
                    </div>
                )}
            </div>
        </div>
    );
};
export default CreateSenderStepSender;