import React, {useState} from 'react';
import useSubmittingButton from "../../hooks/useSubmittingButton";
import {retrieveFilenameFromHeaders, runScript, sendFileToBrowser} from "../../services";
import FormGroupRowDate from "../../Elements/FormGroupRowDate";
import {Col, Row} from "reactstrap";

const PaymentPlansReport = () => {
    const [date, setDate] = useState('');
    const {successMessage, errorMessage, loader, SubmittingButton} = useSubmittingButton();

    const handleDownload = async () => {
        const response = await runScript('PaymentPlansReport', {
            date: date,
        });

        if (response.status === 400) {
            const data = await response.json();
            throw new Error(data.message);
        }

        if (response.status !== 200) {
            throw new Error(`${response.status}: ${response.statusText}. Contact a site administrator. This error probably won't go away by itself.`);
        }

        //fallback filename if no filename from server is received
        let filename = retrieveFilenameFromHeaders(response.headers, 'payment-plans.pdf');

        const blob = await response.blob();
        sendFileToBrowser(filename, blob);
    };

    const disabled = !date;

    return (
        <>
            <h4>Payment Plans Report</h4>

            {errorMessage}

            <form style={{maxWidth: 600}}>

                <FormGroupRowDate
                    label="Date"
                    value={date}
                    onChange={setDate}
                    description="Only month and year will be used."
                />

                {successMessage}

                <Row form className="align-items-center">
                    <Col xs="auto">
                        <SubmittingButton onClick={handleDownload} disabled={disabled}>Download PDF</SubmittingButton>
                    </Col>
                    <Col xs="auto">
                        {loader}
                    </Col>
                </Row>
            </form>
        </>
    );
};

export default PaymentPlansReport;