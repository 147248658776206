import React from 'react';
import Fetch from "../Elements/Fetch";
import { fetchDocumentJson} from "../services";
import DocumentJson from "./DocumentJson";

const DocumentJsonRoute = ({match}) => {
    return (
        <div style={{maxWidth: 800}}>
            <h1 className="mb-5">Document json</h1>
            <Fetch fetchFunction={fetchDocumentJson} fetchFunctionParams={match.params.id}>
                {document => <DocumentJson {...document}/>}
            </Fetch>
        </div>
    );
};

export default DocumentJsonRoute;