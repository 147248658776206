import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "reactstrap";
import {addBillingRule, deleteBillingRule, editBillingRule} from "../../../services";
import {withRefresh} from "../../../Elements/Fetch";
import BillingRuleForm from "../BillingRule/BillingRuleForm";

class BillingRuleEditForm extends Component {

    constructor(props) {
        super(props);

        this.handleAction = this.handleAction.bind(this);
    }

    handleAction(action, state) {
        switch (action) {
            case 'submit':
                return editBillingRule(this.props.billingRule.id, state)
                    .then(data => {
                        this.props.triggerRefresh(['billingRules', `billingSender:${data.billingSenderId}`]);
                        this.props.onSubmit();
                        return data;
                    });
            case 'delete':
                return deleteBillingRule(this.props.billingRule.id)
                    .then(data => {
                        this.props.triggerRefresh(['billingRules', `billingSender:${this.props.billingRule.billingSenderId}`]);
                        this.props.onSubmit();
                        return data;
                    });
            case 'clone':
                return addBillingRule(state)
                    .then(data => {
                        this.props.triggerRefresh(['billingRules', `billingSender:${data.billingSenderId}`]);
                        this.props.onSubmit();
                        return data;
                    });
            default:
        }
    }

    render() {

        const actions = [
            {
                action: 'delete',
                displayName: 'Delete',
                confirmMessage: 'Are you sure you want to delete this billing rule? This cannot be undone.',
            },
            {
                action: 'clone',
                displayName: 'Save as copy',
            },
        ];

        return (
            <Fragment>
                <Row>
                    <Col>
                        <h1 className="mb-5">
                            Edit billing rule
                        </h1>
                    </Col>
                </Row>

                <BillingRuleForm
                    action={this.handleAction}
                    actions={actions}
                    billingRule={this.props.billingRule}
                />

            </Fragment>
        );
    }
}

BillingRuleEditForm.propTypes = {
    billingRule: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default withRefresh(BillingRuleEditForm);