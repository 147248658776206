import React from 'react';
import PropTypes from 'prop-types';

const Field = ({children, externalLink, inline, name}) => {
    let value = children;

    if (!value) {
        value = <small className="font-italic text-muted">(empty)</small>;
    }

    if (externalLink) {
        value = <a href={externalLink} target="_blank" rel="noopener noreferrer">{value}</a>;
    }

    if (inline) {
        return (
            <div>
                {name && <span className="font-weight-bold">{name}: </span>}
                <span>{value}</span>
            </div>
        );
    }
    else {
        return (
            <div className="mb-3">
                <div className="font-weight-bold">{name}:</div>
                <div>{value}</div>
            </div>
        );
    }
};

Field.propTypes = {
    inline: PropTypes.bool,
    externalLink: PropTypes.string,
    name: PropTypes.string.isRequired,
    children: PropTypes.any,
};

export default Field;