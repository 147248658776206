import React from 'react';
import useSubmittingButton from '../../hooks/useSubmittingButton';
import {Col} from 'reactstrap';


const CreateSenderStepButtons = (props: any) => {

    const {successMessage, errorMessage, loader, SubmittingButton} = useSubmittingButton();

    function goNext(): void {
        if (props.onNext) {
            props.onNext();
        }
        props.nextStep();
    }

    function goBack(): void {
        if (props.onBack) {
            props.onBack();
        }
        props.previousStep();
    }

    function isLastStep(): boolean {
        return props.currentStep === props.totalSteps;
    }

    function isFirstStep(): boolean {
        return props.currentStep === 1;
    }

    function renderPreviousButton(): JSX.Element {
        if (isFirstStep()) {
            return (<></>);
        }

        return (
            <>
                { !successMessage &&
                    <button className={"btn btn-outline-success mr-2"} onClick={goBack}>
                        Previous
                    </button>
                }
            </>
        );
    }

    function refreshPage(){
        window.location.reload();
    }

    function renderNextButton(): JSX.Element {
        if (isLastStep()) {
            return (
                <>
                    { !successMessage &&
                        <SubmittingButton onClick={() => {
                            if (props.onNext) {
                                props.onNext();
                            }
                            return props.submit();
                        }} disabled={!props.isValid}>Create sender</SubmittingButton>
                    }
                    <Col xs="auto">{loader}</Col>
                    <div className="mt-2">{errorMessage}</div>
                    <div className="mt-2">{successMessage}</div>

                    { successMessage &&
                        <button className={"btn btn-outline-success mr-2"} onClick={refreshPage}>
                            Create new sender
                        </button>
                    }
                </>
            );
        }

        return (
            <>
                <button disabled={!props.isValid}
                        className={"btn btn-success"} onClick={goNext}>Next
                </button>
            </>
        );
    }

    return (
        <div className="mt-2">
            <div className="text-center border-top pt-3">
                {renderPreviousButton()}
                {renderNextButton()}
            </div>
        </div>
    );
};
export default CreateSenderStepButtons;