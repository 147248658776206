import React from "react";
import {Link} from "react-router-dom";

const TaskLog = (props) => {
    function renderBackButton() {
        return <Link to={`/tasks/${props.taskId}`} className='d-flex justify-content-lg-end mt-5 mr-5'>
                    <button className="btn btn-success position-fixed mt-5">Go back</button>
               </Link>;
    }

    return (
        <>
            {renderBackButton()}
            <ul>
                <h1 className="mb-5">{`Logs for taskId: ${props.taskId}`}</h1>
                {Object.entries(props.logs).map(([key, value]) => (
                    <li style={{listStyle: "none"}} key={key}>{value}</li>
                ))}
            </ul>
        </>
    );
};

export default TaskLog;