import React from 'react';
import PropTypes from 'prop-types';

const Pager = ({
                   currentPage = 0,
                   maxPagesToShow,
                   nbPages,
                   nbPagesLimitReached,
                   goPage,
                   goPreviousPage,
                   goNextPage,
               }) => {
    let pagerStart = Math.max(0, Math.floor(currentPage - maxPagesToShow / 2));
    const pages = [...Array(nbPages).keys()].slice(pagerStart, pagerStart + maxPagesToShow);

    return (
        <ul className="pagination pagination-sm justify-content-center">
            {
                currentPage === 0 &&
                <li className="page-item disabled">
                    <button className="page-link">First</button>
                </li>
            }
            {
                currentPage !== 0 &&
                <li className="page-item">
                    <button className="page-link" onClick={() => goPage(0)}>First</button>
                </li>
            }
            {
                currentPage === 0 &&
                <li className="page-item disabled">
                    <button className="page-link">Previous</button>
                </li>
            }
            {
                currentPage !== 0 &&
                <li className="page-item">
                    <button className="page-link" onClick={goPreviousPage}>Previous</button>
                </li>
            }
            {pages.map(val => {
                if (val === currentPage) {
                    return <li key={val} className="page-item active">
                        <button className="page-link" onClick={() => goPage(val)}>{val + 1}</button>
                    </li>;
                } else {
                    return <li key={val} className="page-item">
                        <button className="page-link" onClick={() => goPage(val)}>{val + 1}</button>
                    </li>;
                }
            })}

            {
                (currentPage + 1 === nbPages) &&
                <li className="page-item disabled">
                    <button className="page-link">Next</button>
                </li>
            }
            {
                (currentPage + 1 !== nbPages) &&
                <li className="page-item">
                    <button className="page-link" onClick={goNextPage}>Next</button>
                </li>
            }
            {
                (currentPage + 1 === nbPages) &&
                <li className="page-item disabled">
                    <button className="page-link">Last</button>
                </li>
            }
            {
                (currentPage + 1 !== nbPages) &&
                <li className="page-item">
                    <button className="page-link" onClick={() => goPage(nbPages - 1)}>Last</button>
                </li>
            }
            {
                nbPagesLimitReached && currentPage + 1 === nbPages &&
                <li className="page-item align-self-center d-flex align-items-center ml-2 text-danger">
                    <small className="d-flex align-items-center">
                        <small>There are more pages. Adjust your sort and try again.</small>
                    </small>
                </li>
            }
        </ul>
    );
};

Pager.propTypes = {
    currentPage: PropTypes.number,
    maxPagesToShow: PropTypes.number.isRequired,
    nbPagesLimitReached: PropTypes.bool,
    nbPages: PropTypes.number.isRequired,
    goPage: PropTypes.func.isRequired,
    goPreviousPage: PropTypes.func.isRequired,
    goNextPage: PropTypes.func.isRequired,
};

export default Pager;