import React from 'react';
import {Route, Switch} from "react-router-dom";
import BillingSenderRoute from "./BillingSender/BillingSenderRoute";
import BillingSenderEditRoute from "./BillingSenderEdit/BillingSenderEditRoute";
import BillingSenderAddRoute from "./BillingSenderAdd/BillingSenderAddRoute";
import BillingSendersRoute from "./BillingSenders/BillingSendersRoute";
import BillingContactsAddRoute from "./BillingContactAdd/BillingContactsAddRoute";
import BillingContactsEditRoute from "./BillingContactEdit/BillingContactEditRoute";
const BillingSenderRoutes = () => {
    return (
        <Switch>
            <Route exact path="/billing/senders" component={BillingSendersRoute}/>
            <Route exact path="/billing/senders/add" component={BillingSenderAddRoute}/>
            <Route exact path="/billing/senders/:id" component={BillingSenderRoute}/>
            <Route exact path="/billing/senders/:id/edit" component={BillingSenderEditRoute}/>
            <Route exact path="/billing/senders/:id/contact/add" component={BillingContactsAddRoute}/>
            <Route exact path="/billing/senders/:id/contact/:contactId/edit" component={BillingContactsEditRoute}/>
        </Switch>
    );
};

export default BillingSenderRoutes;