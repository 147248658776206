import React, {useContext} from 'react';
import AbstractItemsTableWithLoader from '../Tables/AbstractItemsTableWithLoader';
import {Link} from "react-router-dom";
import {fetchUsers, startUsersDownload} from '../services';
import {ModalContext} from "../Modal/ModalProvider";

const UsersTable = () => {
    const {showModal} = useContext(ModalContext);

    const fetchFunction = searchParams => {
        return fetchUsers(searchParams).then(data => ({
            ...data,
            items: data.items.map(item => ({
                ...item,
                id: <Link to={"/users/" + item.id}>{item.id}</Link>,
            })),
        }));
    };

    const tableColumns = {
        id: {
            name: 'User Id',
            sort: true
        },
        firstName: {
            name: 'First Name',
            sort: true
        },
        lastName: {
            name: 'Last Name',
            sort: true
        },
        email: {
            name: 'E-mail',
            sort: true
        },
        language: 'Language',
        address: 'Address',
    };

    const namedSearchParameters = {
        id: {
            type: 'text',
            name: 'User Id',
            operators: ['eq', 'lt', 'gt'],
        },
        firstName: {
            type: 'text',
            name: 'First name',
            operators: ['contains'],
        },
        lastName: {
            type: 'text',
            name: 'Last name',
            operators: ['contains'],
        },
        email: {
            type: 'text',
            name: 'E-mail',
            operators: ['contains', 'eq'],
        },
    };

    return (
        <AbstractItemsTableWithLoader
            tableColumns={tableColumns}
            namedSearchParameters={namedSearchParameters}
            fetchFunction={fetchFunction}
            autoWidthColumns={['id', 'firstName', 'lastName', 'language', 'nbDocuments']}
            downloadModalFunc={startUsersDownload}
            downloadModalDomainObjectName="users"
            showModal={showModal}
        />
    );
};

export default UsersTable;
