import React, {Component} from 'react';
import PageVisibility from 'react-page-visibility';
import RavenJS from 'raven-js';

class VersionChecker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            version: '',
            lastVersionCheck: '',
            hasNewVersion: false,
            timer: null,
        };

        this.startChecking = this.startChecking.bind(this);
        this.stopChecking = this.stopChecking.bind(this);
        this.checkVersion = this.checkVersion.bind(this);
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this._getVersion()
            .then(data => {
                this.setState({
                    version: data['files']['main.js'],
                    lastVersionCheck: new Date(),
                });
            })
            .catch(errorMessage => {
                RavenJS.captureException(new Error(errorMessage));
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.lastVersionCheck !== this.state.lastVersionCheck) {
            this.startChecking();
        }
    }

    startChecking() {
        this.setState((prevState, props) => {
            const ms = 60 * 1000;
            const timeout = Math.max(0, ms - (new Date().getTime() - prevState.lastVersionCheck.getTime()));

            return {
                timer: setTimeout(this.checkVersion, timeout),
            };
        });
    }

    stopChecking() {
        if (this.state.timer) {
            clearTimeout(this.state.timer);
            this.setState({
                timer: null,
            });
        }
    }

    checkVersion() {
        this._getVersion()
            .then(data => {
                if (this.state.version === data['files']['main.js']) {
                    this.setState({
                        lastVersionCheck: new Date(),
                    });
                }
                else {
                    this.setState({
                        hasNewVersion: true,
                    });
                }
            })
            .catch(errorMessage => {
                RavenJS.captureException(new Error(errorMessage));
            });
    }

    _getVersion() {
        return fetch('/asset-manifest.json', {
            headers: {
                'pragma': 'no-cache',
                'cache-control': 'cache-control',
            },
        })
            .then(response => response.json());
    }

    handleVisibilityChange(isVisible) {
        if (isVisible && this.state.version && !this.state.hasNewVersion) {
            this.startChecking();
        }
        else {
            this.stopChecking();
        }
    };

    refresh(event) {
        event.preventDefault();
        window.location.reload(true);
    }

    render() {
        let alert = '';
        if (this.state.hasNewVersion) {
            alert = (
                <div className="alert alert-danger">A new version of this web app is available. Click <a href='/' onClick={this.refresh}>here</a> to refresh.</div>
            );
        }

        return <PageVisibility onChange={this.handleVisibilityChange}>{alert}</PageVisibility>;
    }
}

export default VersionChecker;