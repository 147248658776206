import React from 'react';
import {fetchSpeosReprintBatch} from '../../../services';
import Fetch from '../../../Elements/Fetch';
import SpeosBatchDetail from '../../batchDetail/SpeosBatchDetailProps';

const SpeosReprintDetailRoute = (props: any) => {
    return (
        <Fetch
            fetchFunction={fetchSpeosReprintBatch}
            fetchFunctionParams={props.match.params.id}>
            {(reprintBatch: any) => <SpeosBatchDetail {...reprintBatch} type="Reprint"/>}</Fetch>
    );
};

export default SpeosReprintDetailRoute;