import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FetchFromContext from "../../../Elements/FetchFromContext";
import FormGroupRowText from "../../../Elements/FormGroupRowText";
import FormGroupRowDate from "../../../Elements/FormGroupRowDate";
import FormGroupRowSelect from "../../../Elements/FormGroupRowSelect";
import FormWithActions from "../../../Elements/FormWithActions/FormWithActions";
import {validateNumber} from "../../../services";

class BillingRuleForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            billingSenderId: (this.props.billingRule && this.props.billingRule.billingSenderId) ? this.props.billingRule.billingSenderId : '',
            billingEventType: (this.props.billingRule && this.props.billingRule.billingEventType) ? this.props.billingRule.billingEventType : '',
            billingEventSubtype: (this.props.billingRule && this.props.billingRule.billingEventSubtype) ? this.props.billingRule.billingEventSubtype : '',
            absoluteCost: (this.props.billingRule && this.props.billingRule.absoluteCost) ? this.props.billingRule.absoluteCost : '',
            relativeFraction: (this.props.billingRule && this.props.billingRule.relativeFraction) ? this.props.billingRule.relativeFraction : '',
            maxBillableAmount: (this.props.billingRule && this.props.billingRule.maxBillableAmount) ? this.props.billingRule.maxBillableAmount : '',
            activeFrom: (this.props.billingRule && this.props.billingRule.activeFrom) ? new Date(this.props.billingRule.activeFrom).toISOString().slice(0, 10) : '',
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleAction(action) {
        return this.props.action(action, {
            ...this.state,
            absoluteCost: parseFloat(this.state.absoluteCost), //Becomes null if empty which is wanted behavior
            relativeFraction: parseFloat(this.state.relativeFraction), //Becomes null if empty which is wanted behavior
            maxBillableAmount: parseFloat(this.state.maxBillableAmount), //Becomes null if empty which is wanted behavior
        });
    }

    handleChange(attribute) {
        return value => {
            this.setState({
                [attribute]: value,
            });
        };
    }

    render() {
        const disableSubmitReasons = [];

        if (!this.state.billingSenderId) {
            disableSubmitReasons.push('Billing sender is required.');
        }

        if (!this.state.billingEventType === !this.state.billingEventSubtype) { //clever way to do xor
            disableSubmitReasons.push('Either event type or event subtype must be filled in. Not both.');
        }

        if (!this.state.absoluteCost && !this.state.relativeFraction) {
            disableSubmitReasons.push('Absolute cost or relative fraction must be filled in.');
        }

        if (!this.state.activeFrom) {
            disableSubmitReasons.push('Active from is required.');
        }

        return (
            <FormWithActions onAction={this.handleAction} actions={this.props.actions} disableSubmitReason={disableSubmitReasons.join('\n')}>
                <FetchFromContext property="billingSenders" className="form-group">
                    {billingSenders => (
                        <FormGroupRowSelect
                            label="Billing sender"
                            value={this.state.billingSenderId}
                            onChange={value => this.handleChange('billingSenderId')(value)}
                            options={billingSenders.map(billingSender => ({value: billingSender.id, label: billingSender.name}))}
                        />
                    )}
                </FetchFromContext>

                <FetchFromContext property="billingEventTypes" className="form-group">
                    {billingEventTypes => (
                        <FormGroupRowSelect
                            label="Event Type"
                            value={this.state.billingEventType}
                            onChange={value => this.handleChange('billingEventType')(value)}
                            addEmptyOption
                            options={billingEventTypes.map(item => ({value: item, label: item}))}
                        />
                    )}
                </FetchFromContext>

                <FetchFromContext property="billingEventSubtypes" className="form-group">
                    {billingEventSubtypes => (
                        <FormGroupRowSelect
                            label="Event Subtype"
                            value={this.state.billingEventSubtype}
                            onChange={value => this.handleChange('billingEventSubtype')(value)}
                            addEmptyOption
                            options={billingEventSubtypes.map(item => ({value: item, label: item}))}
                        />
                    )}
                </FetchFromContext>

                <FormGroupRowText
                    label="Absolute Cost"
                    description="Fixed amount to be invoiced per billing event (e.g. €0.123)"
                    value={this.state.absoluteCost}
                    onChange={value => this.handleChange('absoluteCost')(validateNumber(value, this.state.absoluteCost, 3))}
                />

                <FormGroupRowText
                    label="Relative Fraction"
                    description="Fraction of the billing event amount to be invoiced (e.g. 50.00%, 0.01%, ...)"
                    value={this.state.relativeFraction}
                    onChange={value => this.handleChange('relativeFraction')(validateNumber(value, this.state.relativeFraction))}
                />

                <FormGroupRowText
                    label="Max amount"
                    description="Maximum amount a billing event can be invoiced."
                    value={this.state.maxBillableAmount}
                    onChange={this.handleChange('maxBillableAmount')}
                />

                <FormGroupRowDate
                    label="Active From"
                    value={this.state.activeFrom}
                    onChange={this.handleChange('activeFrom')}
                    description="Rule is active at 00:00:00 of this date. This must be the first day of the month."
                />
            </FormWithActions>
        );
    }
}

BillingRuleForm.propTypes = {
    action: PropTypes.func.isRequired,
    actions: PropTypes.array,

    billingRule: PropTypes.shape({
        billingSenderId: PropTypes.number.isRequired,
        billingEventType: PropTypes.string,
        billingEventSubtype: PropTypes.string,
        absoluteCost: PropTypes.number,
        relativeFraction: PropTypes.number,
        activeFrom: PropTypes.string.isRequired,
    }),
};

export default BillingRuleForm;
