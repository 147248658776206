import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import OverlayRoute from "../../Elements/Overlay/OverlayRoute";
import BillingRuleAddRoute from "./BillingRuleAdd/BilingRuleAddRoute";
import BillingRuleEditRoute from "./BillingRuleEdit/BillingRuleEditRoute";

const BillingRuleOverlayRoutes = ({closeOverlay}) => {
    return (
        <Fragment>
            <OverlayRoute exact path="/billing/rules/add" closeOverlay={closeOverlay} render={({match}) => (
                <BillingRuleAddRoute match={match} onSubmit={closeOverlay}/>
            )}/>

            <OverlayRoute exact path="/billing/rules/:id/edit" closeOverlay={closeOverlay} render={({match}) => (
                <BillingRuleEditRoute match={match} onSubmit={closeOverlay}/>
            )}/>
        </Fragment>
    );
};

BillingRuleOverlayRoutes.propTypes = {
    closeOverlay: PropTypes.func.isRequired,
};

export default BillingRuleOverlayRoutes;