import React from 'react';
import PropTypes from 'prop-types';
import {editSenderOnboarding, fetchSenderOnboarding} from "../services";
import Fetch, {withRefresh} from "../Elements/Fetch";
import SenderOnboardingEditForm from "./SenderOnboardingEditForm";

const SenderOnboardingEditRoute = ({match, onSubmit, triggerRefresh}) => (
    <Fetch fetchFunction={fetchSenderOnboarding} fetchFunctionParams={match.params.id}>
        {senderOnboarding => {
            const handleSubmit = async (body) => {
                await editSenderOnboarding(senderOnboarding.id, body);
                triggerRefresh(`senderOnboarding:${senderOnboarding.id}`);
                onSubmit();
            };

            return (
                <SenderOnboardingEditForm
                    senderOnboarding={senderOnboarding}
                    onSubmit={handleSubmit}
                />
            );
        }}
    </Fetch>
);

SenderOnboardingEditRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default withRefresh(SenderOnboardingEditRoute);