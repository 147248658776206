import React, {Fragment} from 'react';
import {NavLink, Route} from "react-router-dom";
import InvoiceReportJoos from "./scripts/InvoiceReportJoos";
import TransactionOverviewReport from "./scripts/TransactionOverviewReport";
import PaylinkDecoder from "./scripts/PaylinkDecoder";
import DocumentsOverviewReport from "./scripts/DocumentsOverviewReport";
import Sms from "./scripts/Sms";
import Optin from "./scripts/Optin";
import DocRefCleanup from "./scripts/DocRefCleanup";
import DatylonReport from "./scripts/DatylonReport";
import PaylinkGenerator from "./scripts/PaylinkGenerator";
import CreateUpdateUser from "./scripts/CreateUpdateUser";
import CreatePaylinkSenderAndUpdateScanSender from "./scripts/CreatePaylinkSenderAndUpdateScanSender";
import PaymentPlansReport from "./scripts/PaymentPlansReport";
import BillingCreditsReport from "./scripts/BillingCreditsReport";
import PartnerReport from "./scripts/PartnerReport";
import SenderOneTimeCredentials from "./scripts/SenderOneTimeCredentials";

const Scripts = () => (
    <Fragment>
        <h1 className="mb-5">Scripts</h1>
        <Route exact path="/scripts" render={() => (
            <ol>
                <li><NavLink exact to="/scripts/invoice-report-joos">Invoice report Joos</NavLink></li>
                <li><NavLink exact to="/scripts/transaction-overview-report">Transaction overview report</NavLink></li>
                <li><NavLink exact to="/scripts/documents-overview-report">Documents overview report</NavLink></li>
                <li><NavLink exact to="/scripts/sms">Sms</NavLink></li>
                <li><NavLink exact to="/scripts/optin">Optin</NavLink></li>
                <li><NavLink exact to="/scripts/docref-cleanup">DocRef Cleanup</NavLink></li>
                <li><NavLink exact to="/scripts/datylon-report">Datylon report</NavLink></li>
                <li><NavLink exact to="/scripts/paylink-generator">Paylink generator</NavLink></li>
                <li><NavLink exact to="/scripts/paylink-decoder">Paylink decoder</NavLink></li>
                <li><NavLink exact to="/scripts/create-update-user">Create/update user</NavLink></li>
                <li><NavLink exact to="/scripts/create-paylink-sender-and-update-scansender">Create Paylink Sender And Update ScanSender</NavLink></li>
                <li><NavLink exact to="/scripts/payment-plans-report">Create Payment Plans Report</NavLink></li>
                <li><NavLink exact to="/scripts/billing-credits-report">Create Billing Credits Report</NavLink></li>
                <li><NavLink exact to="/scripts/partner-report">Create Partner Report</NavLink></li>
                <li><NavLink exact to="/scripts/sender-onetime-credentials">Sender one time credentials</NavLink></li>


            </ol>
        )}/>
        <Route path="/scripts/invoice-report-joos" component={InvoiceReportJoos}/>
        <Route path="/scripts/transaction-overview-report" component={TransactionOverviewReport}/>
        <Route path="/scripts/documents-overview-report" component={DocumentsOverviewReport}/>
        <Route path="/scripts/sms" component={Sms}/>
        <Route path="/scripts/optin" component={Optin}/>
        <Route path="/scripts/docref-cleanup" component={DocRefCleanup}/>
        <Route path="/scripts/datylon-report" component={DatylonReport}/>
        <Route path="/scripts/paylink-generator" component={PaylinkGenerator}/>
        <Route path="/scripts/paylink-decoder" component={PaylinkDecoder}/>
        <Route path="/scripts/create-update-user" component={CreateUpdateUser}/>
        <Route path="/scripts/create-paylink-sender-and-update-scansender" component={CreatePaylinkSenderAndUpdateScanSender}/>
        <Route path="/scripts/payment-plans-report" component={PaymentPlansReport}/>
        <Route path="/scripts/billing-credits-report" component={BillingCreditsReport}/>
        <Route path="/scripts/partner-report" component={PartnerReport}/>
        <Route path="/scripts/sender-onetime-credentials" component={SenderOneTimeCredentials}/>

    </Fragment>
);

export default Scripts;
