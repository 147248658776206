import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Fetch, {RefreshContext} from '../../Elements/Fetch';
import {editMaintenance, fetchMaintenance} from '../../services';
import MaintenanceModeForm from "./MaintenanceModeForm";
import {MaintenanceModeType} from "../MaintenanceMode";

type Props = {
    closeOverlay: () => void;
};

const MaintenanceModeEditRoute = ({
                                      closeOverlay,
                                  }: Props) => {
    const {triggerRefresh} = useContext(RefreshContext);

    const handleSubmit = async (maintenanceMode: MaintenanceModeType) => {
        await editMaintenance(maintenanceMode.enabled, maintenanceMode.from, maintenanceMode.until, maintenanceMode.markdownNL, maintenanceMode.markdownFR, maintenanceMode.markdownEN);
        triggerRefresh('maintenanceMode');
        closeOverlay();
    };

    return (
        <div style={{maxWidth: 800}}>
            <Fetch fetchFunction={fetchMaintenance}>
                {(maintenanceMode: MaintenanceModeType) => (
                    <MaintenanceModeForm
                        maintenanceMode={maintenanceMode}
                        onSubmit={handleSubmit}
                    />
                )}
            </Fetch>
        </div>
    );
};

MaintenanceModeEditRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default MaintenanceModeEditRoute;