import React from 'react';
import PropTypes from 'prop-types';
import {fetchSender} from "../../services";
import Fetch from "../../Elements/Fetch";
import PartnerSettingsform from "./PartnerSettingsForm";

const SenderPartnerSettingsRoute = ({match, history, onSubmit}) => (
    <Fetch fetchFunction={fetchSender} fetchFunctionParams={match.params.id}>
        {sender => {

            const handleSubmit = onSubmit || (() => history.push('/senders/' + sender.id));

            return (
                <div style={{maxWidth: 800}}>
                    <PartnerSettingsform
                        id={sender.partnerId}
                        partnerSettings={sender.partnerSettings}
                        refreshKey={`sender:${sender.id}`}
                        onSubmit={handleSubmit}
                    />
                </div>
            );
        }}
    </Fetch>
);

SenderPartnerSettingsRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default SenderPartnerSettingsRoute;
