import React from 'react';
import PropTypes from 'prop-types';
import {fetchSenderForEdit} from "../../services";
import Fetch from "../../Elements/Fetch";
import SenderEditForm from "./SenderEditForm";

const SenderEditRoute = ({match, history, onSubmit}) => (
    <Fetch fetchFunction={fetchSenderForEdit} fetchFunctionParams={match.params.id}>
        {response => {
            const handleSubmit = onSubmit || (() => history.push('/senders/' + response.sender.id));

            return (
                <div style={{maxWidth: 800}}>
                    <SenderEditForm
                        sender={response.sender}
                        countries={response.countries}
                        onSubmit={handleSubmit}
                    />
                </div>
            );
        }}
    </Fetch>
);

SenderEditRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default SenderEditRoute;
