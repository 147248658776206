import React, {Fragment} from 'react';
import {PlusIcon} from 'react-octicons';
import {Row, Col} from 'reactstrap';
import {Link} from 'react-router-dom';
import {downloadBillingResellersUrl, fetchBillingResellers} from "../../../services";
import AbstractItemsTableWithLoader from "../../../Tables/AbstractItemsTableWithLoader";
import LinkToOverlay from "../../../Elements/Overlay/LinkToOverlay";

const BillingResellersRoute = () => {

    const fetchFunction = (searchParams) => {
        return fetchBillingResellers(searchParams).then(data => ({
            ...data,
            items: data.items.map(item => {
                return {
                    ...item,
                    key: item.id,
                    link: <Link to={'/billing/resellers/' + item.id}>{item.name}</Link>,
                };
            }),
        }));
    };

    const tableColumns = {
        link: {
            name: 'Name',
            sort: true,
            sortKey: 'name',
        },
    };

    const namedSearchParameters = {
        name: {
            type: 'text',
            name: 'Billing reseller',
            operators: ['contains'],
        },
    };

    return (
        <Fragment>

            <Row className="form-row">
                <Col xs="auto"><h2 className="mb-5">Billing resellers</h2></Col>
                <Col style={{marginTop: '8px'}}><LinkToOverlay overlay="/billing/resellers/add"><PlusIcon/></LinkToOverlay></Col>
            </Row>

            <AbstractItemsTableWithLoader
                tableColumns={tableColumns}
                namedSearchParameters={namedSearchParameters}
                fetchFunction={fetchFunction}
                refreshKey="billingResellers"
                downloadUrl={downloadBillingResellersUrl()}
            />
        </Fragment>
    );
};

export default BillingResellersRoute;
