import React from 'react';
import {fetchMandate} from '../services';
import Fetch from "../Elements/Fetch";
import Mandate from './Mandate';

const MandateRoute = (props: any) => {
    return (
        <Fetch
            fetchFunction={fetchMandate}
            fetchFunctionParams={props.match.params.id}
            refreshKey={`mandate:${props.match.params.id}`}
        >
            {(mandate: any) => <Mandate {...mandate}/>}

        </Fetch>
    );
};

export default MandateRoute;