import React, {useState} from 'react';
import PropTypes from "prop-types";
import {Col, Row} from "reactstrap";
import FormWithActions from "../../Elements/FormWithActions/FormWithActions";
import FormGroupRowText from "../../Elements/FormGroupRowText";
import FormGroupRowDate from "../../Elements/FormGroupRowDate";
import moment from "moment";
import {editTransaction} from "../../services";
import {withRefresh} from "../../Elements/Fetch";

const TransactionEditForm = ({transaction, onSubmit, triggerRefresh}) => {

    const [communication, setCommunication] = useState(transaction.communication)
    const [communicationStructured, setCommunicationStructured] = useState(transaction.communicationStructured)
    const [scheduled, setScheduled] = useState(moment(transaction.scheduled).format('YYYY-MM-DD'))

    const getCommunicationField = () => {
        if (communicationStructured) {
            return <FormGroupRowText
                label="Communication"
                placeholder="Communication"
                value={communicationStructured}
                onChange={setCommunicationStructured}
            />;
        } else {
            return <FormGroupRowText
                label="Communication"
                placeholder="Communication"
                value={communication}
                onChange={setCommunication}
            />;
        }
    }

    const handleAction = async () => {
        let updatedTransaction = {};
        if (transaction.communicationStructured && communicationStructured.startsWith("+++")) {
            updatedTransaction = {
                ...updatedTransaction,
                communicationStructured
            }
        } else if (communication) {
            updatedTransaction = {
                ...updatedTransaction,
                communication
            }
        } else {
            const {communicationStructured: oldCommStruct, ...rest} = transaction
            updatedTransaction = {
                ...rest,
                communication: communicationStructured
            }
        }
        if (transaction.status === 'SCHEDULED') {
            updatedTransaction = {
                ...updatedTransaction,
                scheduled
            }
        }

        const data = await editTransaction(transaction.internalId, updatedTransaction);
        console.log(data)

        triggerRefresh(`transaction:${transaction.internalId}`)
        onSubmit();
    };

    return <>
        <Row>
            <Col>
                <h1 className="mb-5">Transaction: {transaction.transactionId}</h1>
            </Col>
        </Row>
        <FormWithActions onAction={handleAction}>
            {getCommunicationField()}

            {transaction.status === 'SCHEDULED' && <FormGroupRowDate
                label="Scheduled Date"
                placeholder="Scheduled Date"
                value={scheduled}
                onChange={setScheduled}
                description=""
            />}
        </FormWithActions>

    </>;
};

TransactionEditForm.propTypes = {
    transaction: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default withRefresh(TransactionEditForm);
