import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import OverlayRoute from "../../Elements/Overlay/OverlayRoute";
import InvoiceEditRoute from "./InvoiceEdit/InvoiceEditRoute";

const InvoiceOverlayRoutes = ({closeOverlay}) => {
    return (
        <Fragment>
            <OverlayRoute exact path="/billing/invoices/:id/edit" closeOverlay={closeOverlay} render={({match}) => (
                <InvoiceEditRoute match={match} onSubmit={closeOverlay}/>
            )}/>
        </Fragment>
    );
};

InvoiceOverlayRoutes.propTypes = {
    closeOverlay: PropTypes.func.isRequired,
};

export default InvoiceOverlayRoutes;
