import React from 'react';
import PropTypes from 'prop-types';
import {fetchUser} from "../services";
import Fetch from "../Elements/Fetch";
import UserEditForm from "./UserEditForm";

const UserEditRoute = ({match, history, onSubmit}) => (
    <Fetch fetchFunction={fetchUser} fetchFunctionParams={match.params.id}>
        {user => {

            const handleSubmit = onSubmit || (() => history.push('/users/' + user.id));

            return (
                <div style={{maxWidth: 800}}>
                    <UserEditForm
                        user={user}
                        onSubmit={handleSubmit}
                    />
                </div>
            );
        }}
    </Fetch>
);

UserEditRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default UserEditRoute;