import React from 'react';
import PropTypes from 'prop-types';
import Fetch from "../../../Elements/Fetch";
import {fetchInvoice} from "../../../services";
import InvoiceEditForm from "./InvoiceEditForm";

const InvoiceEditRoute = ({match, history, onSubmit}) => (
    <Fetch fetchFunction={fetchInvoice} fetchFunctionParams={match.params.id}>
        {invoice => {

            const handleSubmit = onSubmit || (() => history.push('/billing/invoices/' + invoice.id));

            return (
                <div style={{maxWidth: 800}}>
                    <InvoiceEditForm
                        invoice={invoice}
                        onSubmit={handleSubmit}
                    />
                </div>
            );
        }}
    </Fetch>
);

InvoiceEditRoute.propTypes = {
    onSubmit: PropTypes.func,
};

export default InvoiceEditRoute;