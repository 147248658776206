import React from 'react';
import Fetch from "../../../Elements/Fetch";
import {fetchInvoice} from "../../../services";
import Invoice from "./Invoice";

const InvoiceRoute = ({match}) => (
    <Fetch fetchFunction={fetchInvoice} fetchFunctionParams={match.params.id} refreshKey={`invoice:${match.params.id}`}>
        {invoice => <Invoice {...invoice}/>}
    </Fetch>
);

export default InvoiceRoute;