import React from 'react';
import PropTypes from 'prop-types';
import FormText from "./FormText";
import FormGroupRow from "./FormGroupRow";

const FormGroupRowText = ({label, required, type = 'text', value, onChange, disabled, description, placeholder, errorMessage, cssClasses}) => (
    <FormGroupRow label={label} required={required} errorMessage={errorMessage} description={description} cssClasses={cssClasses}>
        <FormText
            type={type}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={onChange}
        />
    </FormGroupRow>
);

FormGroupRowText.propTypes = {
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    description: PropTypes.any,
    errorMessage: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    cssClasses: PropTypes.array

};

export default FormGroupRowText;