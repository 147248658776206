import React, {Fragment} from 'react';
import ManageTemplate from "./ManageTemplate";

const AddTemplateRoute = () => (
    <Fragment>
        <h1 className="mb-5">Create sender template</h1>
        <ManageTemplate/>
    </Fragment>
);

export default AddTemplateRoute;