import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {Link, withRouter} from 'react-router-dom';

const LinkToOverlay = ({location, overlay, children}) => {

    const searchParsed = queryString.parse(location.search);
    searchParsed['overlay'] = overlay;

    const to = {
        pathname: location.pathname,
        search: queryString.stringify(searchParsed),
    };

    return (
        <Link to={to}>{children}</Link>
    );
};

LinkToOverlay.propTypes = {
    overlay: PropTypes.string.isRequired,
};

export default withRouter(LinkToOverlay);