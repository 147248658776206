import React, {Fragment} from 'react';
import EmailBlacklist from './EmailBlacklist';

const EmailBlacklistRoute = () => {
    return (
        <Fragment>
            <h1 className="mb-5">Blacklisted emails</h1>
            <EmailBlacklist/>
        </Fragment>
    );
};

export default EmailBlacklistRoute;
