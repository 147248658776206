import React, {Component} from 'react';
import googleChartLoader from '../GoogleChartLoader';
import {Button, ButtonGroup, Row, Col} from 'reactstrap';

class BillingCreditsConsumeHistoryStats extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            groupBy: 'month',
            stacked: false,
            height: this.props.height ? parseInt(this.props.height, 10) : 400,
            billingEventSubTypes: [],
        };

        this.drawDashboard = this.drawDashboard.bind(this);
        this.calculateRows = this.calculateRows.bind(this);
        this._getRows = this._getRows.bind(this);
    }

    componentDidMount() {
        googleChartLoader.init(['corechart', 'controls']);
        this.calculateRows();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.groupBy !== this.state.groupBy) {
            this.calculateRows();
        }

        if (prevState.stacked !== this.state.stacked) {
            this.calculateRows();
        }

        if (JSON.stringify(prevState.rows) !== JSON.stringify(this.state.rows)) {
            googleChartLoader.init().then(() => {
                this.drawDashboard();
            });
        }
    }

    _getRows(billingEventSubTypes, getDateKeyCallback) {
        let rows = [];

        if (this.state.stacked) {
            const groupedResults = this.props.rows.reduce((acc, item) => {
                const date = getDateKeyCallback(item.date);
                if (!acc[date]) {
                    acc[date] = {};
                }

                billingEventSubTypes.forEach(billingEventSubType => {
                    acc[date][billingEventSubType] = (acc[date][billingEventSubType] || 0) + (item.creditsConsumedBySubType[billingEventSubType] || 0);
                });

                return acc;
            }, {});

            rows = Object.keys(groupedResults).sort().map(key => ([
                new Date(key),
                ...billingEventSubTypes.map(billingEventSubType => Math.round(groupedResults[key][billingEventSubType] * 100) / 100),
            ]));
        } else {
            const groupedResults = this.props.rows.reduce((acc, item) => {
                const date = getDateKeyCallback(item.date);
                acc[date] = (acc[date] || 0) + item.creditsConsumed;
                return acc;
            }, {});

            rows = Object.keys(groupedResults).sort().map(key => ([
                new Date(key),
                Math.round(groupedResults[key] * 100) / 100,
            ]));
        }

        return rows;
    }

    calculateRows() {
        let billingEventSubTypes = this.props.rows.reduce((prev, curr) => {
            Object.keys(curr.creditsConsumedBySubType).forEach(billingEventSubType => {
                prev[billingEventSubType] = billingEventSubType;
            });

            return prev;
        }, {});
        billingEventSubTypes = Object.values(billingEventSubTypes).sort();

        switch (this.state.groupBy) {
            case 'day': {
                const rows = this._getRows(billingEventSubTypes, date => date);

                this.setState({
                    rows: rows,
                    billingEventSubTypes: billingEventSubTypes,
                });
                break;
            }
            case 'month': {
                const rows = this._getRows(billingEventSubTypes, date => `${date.slice(0, 7)}-01`);

                this.setState({
                    rows: rows,
                    billingEventSubTypes: billingEventSubTypes,
                });
                break;
            }
            case 'year': {
                const rows = this._getRows(billingEventSubTypes, date => `${date.slice(0, 4)}-01-01`);

                this.setState({
                    rows: rows,
                    billingEventSubTypes: billingEventSubTypes,
                });
                break;
            }
            case 'quarter': {
                const rows = this._getRows(billingEventSubTypes, date => {
                    const monthString = date.slice(5, 7);

                    if (monthString < '04') {
                        return date.slice(0, 4) + '-01-01';
                    } else if (monthString < '07') {
                        return date.slice(0, 4) + '-04-01';
                    } else if (monthString < '10') {
                        return date.slice(0, 4) + '-07-01';
                    } else {
                        return date.slice(0, 4) + '-10-01';
                    }
                });

                this.setState({
                    rows: rows,
                    billingEventSubTypes: billingEventSubTypes,
                });
                break;
            }
            default:

        }

    }

    drawDashboard() {
        const data = new window.google.visualization.DataTable();
        data.addColumn("date", 'Date');
        if (this.state.stacked) {
            this.state.billingEventSubTypes.forEach(billingEventSubType => {
                data.addColumn("number", billingEventSubType);
            });
        } else {
            data.addColumn("number", 'Credits consumed');
        }
        data.addRows(this.state.rows);

        const dashboard = new window.google.visualization.Dashboard(document.getElementById('BillingCreditsConsumeHistoryStatsWrapper'));

        let end = this.state.rows[this.state.rows.length - 1][0];
        let start = new Date(end);
        let format = 'dd/MM/yy';

        switch (this.state.groupBy) {
            case 'month':
                start.setFullYear(start.getFullYear() - 2);
                format = 'MMM y';
                break;
            case 'quarter':
                start.setFullYear(start.getFullYear() - 2);
                format = 'y Q';
                break;
            case 'year':
                start = this.state.rows[0][0]; //assume that all rows can be shown when grouped by year
                format = 'y';
                break;
            default:
                start.setMonth(start.getMonth() - 2);
        }

        const chart = new window.google.visualization.ChartWrapper({
            chartType: 'ColumnChart',
            containerId: 'BillingCreditsConsumeHistoryStats',
            options: {
                legend: {
                    position: 'none',
                },
                height: this.state.height,
                chartArea: {
                    left: 40,
                    right: 0,
                    top: 30,
                    bottom: 30,
                },
                hAxis: {
                    format: format,
                },
                isStacked: true,
            },
        });

        const controls = new window.google.visualization.ControlWrapper({
            controlType: 'ChartRangeFilter',
            containerId: 'BillingCreditsConsumedHistoryStatsControls',
            options: {
                filterColumnLabel: 'Date',
                ui: {
                    chartOptions: {
                        height: 60,
                        chartArea: {
                            left: 40,
                            right: 10,
                            top: 0,
                            bottom: 20,
                        },
                        hAxis: {
                            'textPosition': 'out',
                            format: format,
                        },
                    },
                },
            },
            state: {
                range: {
                    start: start,
                    end: end,
                }
            },
        });

        new window.google.visualization.DateFormat({pattern: format}).format(data, 0);
        dashboard.bind([controls], [chart]);
        dashboard.draw(data);
    }

    render() {
        const height = this.state.height + 60;

        return (
            <div id="BillingCreditsConsumeHistoryStatsWrapper">
                <Row>
                    <Col xs="auto" className="mr-auto"/>
                    <Col xs="auto">
                        <ButtonGroup size="sm">
                            <Button outline active={!this.state.stacked} color="success" onClick={() => this.setState({stacked: false})}>Total</Button>
                            <Button outline active={this.state.stacked} color="success" onClick={() => this.setState({stacked: true})}>Type</Button>
                        </ButtonGroup>
                    </Col>
                    <Col xs="auto">
                        <ButtonGroup size="sm">
                            <Button outline active={this.state.groupBy === 'day'} color="success" onClick={() => this.setState({groupBy: 'day'})}>Day</Button>
                            <Button outline active={this.state.groupBy === 'month'} color="success" onClick={() => this.setState({groupBy: 'month'})}>Month</Button>
                            <Button outline active={this.state.groupBy === 'quarter'} color="success" onClick={() => this.setState({groupBy: 'quarter'})}>Quarter</Button>
                            <Button outline active={this.state.groupBy === 'year'} color="success" onClick={() => this.setState({groupBy: 'year'})}>Year</Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <div style={{height: height + 'px'}}>
                    <div id="BillingCreditsConsumeHistoryStats"/>
                    <div id="BillingCreditsConsumedHistoryStatsControls"/>
                </div>
            </div>
        );

    }
}

export default BillingCreditsConsumeHistoryStats;
