import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import OverlayRoute from "../Elements/Overlay/OverlayRoute";
import SenderOnboardingMailRoute from "./SenderOnboardingMailRoute";
import SenderOnboardingBuckarooOnboardingApiDataRoute from "./SenderOnboardingBuckarooOnboardingApiDataRoute";
import SenderOnboardingEditRoute from "./SenderOnboardingEditRoute";
import SenderOnboardingSendBuckarooOnboardingMerchantRequestRoute from "./SenderOnboardingSendBuckarooOnboardingMerchantRequestRoute";
import SenderOnboardingBuckarooRequestRoute from "./SenderOnboardingBuckarooRequestRoute";
import SenderOnboardingBuckarooAddFileRoute from "./SenderOnboardingBuckarooAddFileRoute";
import SenderOnboardingBuckarooSendFileRoute from "./SenderOnboardingBuckarooSendFileRoute";
import SenderOnboardingCompleteBuckarooOnboardingMerchantRequestRoute
    from "./SenderOnboardingCompleteBuckarooOnboardingMerchantRequestRoute";

const SenderOnboardingOverlayRoutes = ({closeOverlay}) => {
    return (
        <Fragment>
            <OverlayRoute exact path="/senderOnboarding/:id/edit" closeOverlay={closeOverlay} render={({match}) => (
                <SenderOnboardingEditRoute match={match} onSubmit={closeOverlay}/>
            )}/>
            <OverlayRoute exact path="/senderOnboarding/:id/buckaroo-mail" closeOverlay={closeOverlay} render={({match}) => (
                <SenderOnboardingMailRoute match={match} onSubmit={closeOverlay}/>
            )}/>
            <OverlayRoute exact path="/senderOnboarding/:id/send-buckaroo-onboarding-merchant-request" closeOverlay={closeOverlay} render={({match}) => (
                <SenderOnboardingSendBuckarooOnboardingMerchantRequestRoute match={match} onSubmit={closeOverlay}/>
            )}/>
            <OverlayRoute exact path="/senderOnboarding/:id/complete-buckaroo-onboarding-merchant-request" closeOverlay={closeOverlay} render={({match}) => (
                <SenderOnboardingCompleteBuckarooOnboardingMerchantRequestRoute match={match} onSubmit={closeOverlay}/>
            )}/>
            <OverlayRoute exact path="/senderOnboarding/:id/edit-buckaroo-onboarding-api-data" closeOverlay={closeOverlay} render={({match}) => (
                <SenderOnboardingBuckarooOnboardingApiDataRoute match={match} onSubmit={closeOverlay}/>
            )}/>
            <OverlayRoute exact path="/senderOnboarding/:id/add-buckaroo-file" closeOverlay={closeOverlay} render={({match}) => (
                <SenderOnboardingBuckarooAddFileRoute match={match} onSubmit={closeOverlay}/>
            )}/>
            <OverlayRoute exact path="/senderOnboarding/:id/send-buckaroo-file/:documentId" closeOverlay={closeOverlay} render={({match}) => (
                <SenderOnboardingBuckarooSendFileRoute match={match} onSubmit={closeOverlay}/>
            )}/>
            <OverlayRoute exact size="xl" path="/buckaroo-request/:id" closeOverlay={closeOverlay} render={({match}) => (
                <SenderOnboardingBuckarooRequestRoute match={match} onSubmit={closeOverlay}/>
            )}/>
        </Fragment>
    );
};

SenderOnboardingOverlayRoutes.propTypes = {
    closeOverlay: PropTypes.func.isRequired,
};

export default SenderOnboardingOverlayRoutes;
