import React from 'react';
import {fetchDocument} from '../services';
import Document from "./Document";
import Fetch from "../Elements/Fetch";
import queryString from 'query-string';

const DocumentRoute = ({location}) => {
    const searchParsed = queryString.parse(location.search, {decode: false});
    const documentId = searchParsed.id;

    return (
        <Fetch
            fetchFunction={fetchDocument}
            fetchFunctionParams={documentId}
            refreshKey={`document:${documentId}`}
        >
            {document => <Document {...document}/>}
        </Fetch>
    );
};

export default DocumentRoute;