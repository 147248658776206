import React, {useContext, useState} from 'react';
import {runScript} from "../../services";
import FormGroupRowSender from "../../Elements/FormGroupRowSender";
import FormGroupRowDate from "../../Elements/FormGroupRowDate";
import useSubmittingButton from "../../hooks/useSubmittingButton";
import {Col, Row} from "reactstrap";
import FetchFromContext from "../../Elements/FetchFromContext";
import FormGroupRowSelect from "../../Elements/FormGroupRowSelect";
import {ModalContext} from "../../Modal/ModalProvider";

const PartnerReport = () => {
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [parentSenderIds, setParentSenderIds] = useState([]);
    const [chosenReportingIds, setChosenReportingIds] = useState([]);

    const {errorMessage, loader, SubmittingButton} = useSubmittingButton();
    const {showModal} = useContext(ModalContext);

    const handleSubmit = async () => {
        const response = await runScript('PartnerReport', {
            dateFrom: dateFrom,
            dateTo: dateTo,
            parentSenderIds: parentSenderIds,
            reportingIds: chosenReportingIds,
        });

        if (response.status === 200) {
            const data = await response.json();

            showModal({
                type: 'download',
                exportId: data.id,
            });
        }
        else {
            throw new Error(`${response.status}: ${response.statusText}. Contact a site administrator. This error probably won't go away by itself.`);
        }
    };

    const disabled =
        !dateFrom ||
        !dateTo ||
        (parentSenderIds.length === 0 && chosenReportingIds.length === 0);

    return (
        <>
            <h4>Partner Report</h4>

            {errorMessage}

            <form style={{maxWidth: 600}}>
                <FormGroupRowDate
                    label="Date from"
                    value={dateFrom}
                    onChange={setDateFrom}
                    description="This date is included in the export. The entire month will be in the report."
                />

                <FormGroupRowDate
                    label="Date to"
                    value={dateTo}
                    onChange={setDateTo}
                    description="This date is included in the export. The entire month will be in the report."
                />

                <FetchFromContext property="parentSenders">
                    {parentSenders => (
                        <FormGroupRowSender
                            label="Parent senders"
                            value={parentSenderIds}
                            onChange={setParentSenderIds}
                            senders={parentSenders}
                            multi
                            description="Either Parent senders or Reporting IDs is required."
                        />
                    )}
                </FetchFromContext>

                <FetchFromContext property="reportingIds">
                    {reportingIds => (
                        <FormGroupRowSelect
                            label="Reporting IDs"
                            value={chosenReportingIds}
                            onChange={setChosenReportingIds}
                            options={reportingIds.map(reportingId => ({value: reportingId, label: reportingId}))}
                            multi
                            description="Either Parent senders or Reporting IDs is required."
                            selectAll
                        />
                    )}
                </FetchFromContext>

                <Row form className="align-items-center">
                    <Col xs="auto">
                        <SubmittingButton onClick={handleSubmit} disabled={disabled}>Submit</SubmittingButton>
                    </Col>
                    <Col xs="auto">
                        {loader}
                    </Col>
                </Row>
            </form>
        </>
    );
};

export default PartnerReport;
