import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import MtpFileAddRoute from "./MtpFileAddRoute";
import OverlayRoute from "../Elements/Overlay/OverlayRoute";
import MtpFileEditRoute from "./MtpFileEditRoute";

const MtpFileOverlayRoutes = ({closeOverlay}) => {
    return (
        <Fragment>
            <OverlayRoute
                exact
                path="/billing/mtp-files/add"
                closeOverlay={closeOverlay}
                render={() => <MtpFileAddRoute onSubmit={closeOverlay}/>}
            />
            <OverlayRoute
                exact
                path="/billing/mtp-files/:id/edit"
                closeOverlay={closeOverlay}
                render={({match}) => <MtpFileEditRoute match={match} onSubmit={closeOverlay}/>}
            />
        </Fragment>
    );
};

MtpFileOverlayRoutes.propTypes = {
    closeOverlay: PropTypes.func.isRequired,
};

export default MtpFileOverlayRoutes;