import React, {useContext, useEffect, useRef, useState} from 'react';
import styles from "./Modal.module.css";
import innerStyles from "./ModalDownloadContainer.module.css";
import LoadingButton from "../PortalComponents/Button/LoadingButton";
import {cancelExport, exportDownloadUrl, exportStatus, saveExport} from "../services";
import ButtonLink from "../PortalComponents/Button/ButtonLink";
import Textfield from "../PortalComponents/Textfield/Textfield";
import Checkbox from "../PortalComponents/Checkbox/Checkbox";
import Button from "../PortalComponents/Button/Button";
import {RefreshContext} from "../Elements/Fetch";

type Props = {
    startDownloadFunction?: (searchParams: string) => Promise<DownloadResponse>;
    queryString?: string;
    exportId?: number;
    onClose: () => void;
    downloadModalDomainObjectName?: string;
};

const ModalDownloadContainer = ({
                                    startDownloadFunction,
                                    queryString,
                                    exportId,
                                    onClose,
                                    downloadModalDomainObjectName,
                                }: Props) => {
    const [screen, setScreen] = useState<null | 'download' | 'downloading' | 'downloaded' | 'error' | 'cancelled'>(null);
    const [internalExportId, setInternalExportId] = useState<number | undefined>(exportId);

    useEffect(
        () => {
            if (exportId) {
                //load from backend and show downloading or downloaded

                exportStatus(exportId)
                    .then(data => {
                        setSavedReference(data.reference || '');
                        setNewReference(data.reference || '');
                        setMailWhenComplete(data.mailWhenComplete);

                        if (data.status === 'DONE') {
                            setScreen('downloaded');
                        }

                        if (data.status === 'RUNNING' || data.status === 'NEW') {
                            setTotalRows(data.totalRows || 0);
                            setRowsComplete(data.rowsComplete);
                            setScreen('downloading');
                        }

                        if (data.status === 'ERROR') {
                            setScreen('error');
                        }

                        if (data.status === 'CANCELLED') {
                            setScreen('cancelled');
                        }
                    })
                    .catch(() => {
                        setScreen('error');
                    });

            } else {
                //show start download screen
                setScreen('download');
            }
        },
        [exportId]
    );

    //downloading
    const [totalRows, setTotalRows] = useState(0);
    const [rowsComplete, setRowsComplete] = useState(0);
    const intervalRef = useRef<number>();

    useEffect(
        () => {
            if (screen === 'downloading') {
                intervalRef.current = window.setInterval(() => {
                    exportStatus(internalExportId!)
                        .then(data => {
                            setTotalRows(data.totalRows || 0);
                            setRowsComplete(data.rowsComplete);

                            if (data.status === 'DONE') {
                                setScreen('downloaded');
                            }

                            if (data.status === 'ERROR') {
                                setScreen('error');
                            }
                        })
                        .catch(() => {
                            setScreen('error');
                        });
                }, 2000);
            }

            return () => {
                clearInterval(intervalRef.current);
            };
        },
        [screen, internalExportId]
    );

    //reference
    const [savedReference, setSavedReference] = useState<string>('');
    const [newReference, setNewReference] = useState<string>('');
    const {triggerRefresh} = useContext(RefreshContext);

    const handleSaveReference = async () => {
        const data = await saveExport(internalExportId!, newReference, mailWhenComplete);
        triggerRefresh(['exports']);
        setSavedReference(data.reference || '');
    };

    const reference = (
        <div className={innerStyles.referenceWrapper}>
            <div className={innerStyles.referenceTextfieldWrapper}>
                <Textfield
                    value={newReference}
                    setValue={setNewReference}
                    placeholder="Reference (optional)"
                    border
                />
            </div>
            <div className={innerStyles.referenceButtonWrapper}>
                <LoadingButton
                    label="Save"
                    onClick={handleSaveReference}
                    disabled={savedReference === newReference}
                    backgroundColor="white"
                />
            </div>
        </div>
    );

    //notification
    const [mailWhenComplete, setMailWhenComplete] = useState<boolean>(false);

    const handleChangeMailWhenComplete = async () => {
        const data = await saveExport(internalExportId!, savedReference, !mailWhenComplete);
        setMailWhenComplete(data.mailWhenComplete);
    };

    const notification = (
        <div className={innerStyles.notificationWrapper}>
            <div className={innerStyles.notificationCheckboxWrapper} onClick={handleChangeMailWhenComplete}>
                <Checkbox
                    isChecked={mailWhenComplete}
                />
            </div>
            <div className={innerStyles.notificationTextWrapper}>Receive an e-mail once the export is finished</div>
        </div>
    );

    const handleCancel = async () => {
        await cancelExport(internalExportId!);
        triggerRefresh(['exports']);
        onClose();
    };

    switch (screen) {
        case null:
            return (
                <div className={styles.modal}>
                    <div className={innerStyles.loaderWrapper}>
                        <div className={innerStyles.loader}/>
                    </div>
                </div>
            );
        case "download":
            const handleClick = async () => {
                try {
                    const data = await startDownloadFunction!(queryString!);
                    setInternalExportId(data.id);
                    setScreen('downloading');
                } catch (error) {
                    setScreen('error');
                }
            };

            return (
                <div className={styles.modal}>
                    <div className={styles.title}>Are you sure you want to export your {downloadModalDomainObjectName || 'transactions'}?</div>
                    <div className={styles.body}/>
                    <LoadingButton label={`Export ${downloadModalDomainObjectName || 'transactions'}`} onClick={handleClick}/>
                </div>
            );
        case "downloading":
            let width = 5; //Always show some progress

            if (totalRows > 0) {
                width = rowsComplete / totalRows * 100;
            }

            return (
                <div className={styles.modal}>
                    <div className={styles.title}>We’re finishing up your export!</div>
                    {reference}
                    {notification}
                    <div className={styles.body}>You will also find this export on the Exports-page of your application.</div>
                    <div className={styles.progressBarContainer}>
                        <div className={styles.progressBar} style={{width: `${width}%`}}/>
                    </div>
                    <LoadingButton label="Abort export" onClick={handleCancel} backgroundColor="red"/>
                </div>
            );
        case "downloaded":
            return (
                <div className={styles.modal}>
                    <div className={styles.title}>Your export is finished!</div>
                    {reference}
                    <div className={styles.body}>You will also find this export on the Exports-page of your application.</div>
                    <ButtonLink label="Download export" href={exportDownloadUrl(internalExportId!)}/>
                </div>
            );
        case "error":
            return (
                <div className={styles.modal}>
                    <div className={styles.title}>Sorry, something went wrong with your export!</div>
                    <div className={styles.body}>Please try again later or contact us if the problem persists.</div>
                    <Button label="Close" onClick={onClose}/>
                </div>
            );
        case "cancelled":
            return (
                <div className={styles.modal}>
                    <div className={styles.title}>You cancelled this export!</div>
                    <div className={styles.body}>Please try again later.</div>
                    <Button label="Close" onClick={onClose}/>
                </div>
            );
    }

    //Should not happen
    return null;
};

export default ModalDownloadContainer;
