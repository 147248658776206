import React, {useState} from 'react';
import styles from './FileUpload.module.css';
import {DropzoneOptions, useDropzone} from "react-dropzone";
import prettyBytes from 'pretty-bytes';

export interface Props {
    files: File[];
    setFiles: (files: File[]) => void;
    dropzoneOptions?: DropzoneOptions,
}

const FileUpload = ({
                        files,
                        setFiles,
                        dropzoneOptions,
                    }: Props) => {
    const [errors, setErrors] = useState<String[]>([]);

    const onDrop = (acceptedFiles: any, fileRejections: any) => {
        if (acceptedFiles.length > 0) {
            setFiles(acceptedFiles);
            setErrors([]);
            return;
        }

        if (fileRejections.length > 0) {
            setErrors(fileRejections
                .map((file: any) => {
                    switch (file.errors[0].code) {
                        case 'file-invalid-type':
                            return `Supported file types: ${Object.values(dropzoneOptions?.accept!).flat().join(', ')}.`;
                        case 'file-too-large':
                            return `File must be smaller than ${prettyBytes(dropzoneOptions?.maxSize!)}.`;
                        default:
                            return 'An error occurred. Refresh this page or try again later.';
                    }
                })
                .filter((item: any, pos: number, arr: any[]) => {
                    return arr.indexOf(item) === pos;
                })
            );
        }
    };

    const {getRootProps, getInputProps} = useDropzone({
        ...dropzoneOptions,
        onDrop: onDrop,
    });

    return (
        <div className={styles.fileWrapper} {...getRootProps()}>
            <input {...getInputProps()} />
            {files.length > 0 ? (
                <div>
                    {files.map((file, i) => (
                        <div key={i}>
                            <span>{file.name}</span>
                            <span className={styles.bytes}>({prettyBytes(file.size)})</span>
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    {dropzoneOptions?.multiple ? (
                        <span>Drop multiple files here or click to select files</span>
                    ) : (
                        <span>Drop a file here or click to select a file</span>
                    )}
                </>
            )}
            <div className={styles.error}>{errors.map((error, i) => (
                <div key={i}>{error}</div>
            ))}</div>
        </div>
    );
};

export default FileUpload;
