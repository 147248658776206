import React, {Fragment} from 'react';
import Field from '../Elements/Field';
import Table from '../Elements/Table/Table';
import Fetch from '../Elements/Fetch';
import {fetchMandateTransactions} from '../services';
import {Link} from 'react-router-dom';

type Props = {
    id: string;
    mandateId: string;
    firstName: string;
    lastname: string;
    iban: string;
    type: string;
    status: string;
    senderContractNumber: string;
    companyName: string;
    companyNumber: string;
    address: {
        street: string,
        number: string,
        bus: string,
        city: string,
        zipCode: string,
        country: string,
    }
};

const Mandate = (props: Props) => {

    const tableColumns = {
        id: {
            name: 'Transaction Id',
        },
        documentId: {
            name: 'Document Id',
        },
        status: {
            name: 'Status'
        },
    };

    return (
        <Fragment>
            <div className="row">
                <div className="col">
                    <h1 className="mb-5">Mandate: {props.mandateId}</h1>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Field name="SCN">{props.senderContractNumber}</Field>
                    <Field name="Name">{props.firstName} {props.lastname}</Field>
                    <Field name="Company">{props.companyName}</Field>
                    <Field name="Company number">{props.companyNumber}</Field>
                    <Field name="Iban">{props.iban}</Field>
                    <Field name="Type">{props.type}</Field>
                    <Field name="Status">{props.status}</Field>
                    <Field name="Address">
                        <div>{props.address.street} {props.address.number} {props.address.bus}  </div>
                        <div>{props.address.city} {props.address.zipCode}</div>
                        <div>{props.address.country}</div>
                    </Field>
                </div>
            </div>
            <div>
                <Fetch
                    fetchFunction={fetchMandateTransactions}
                    fetchFunctionParams={props.id}
                    refreshKey={`mandate-tx:${props.id}`}
                >
                    {(transactions: any) =>
                        <Field name="Transactions">
                            <Table columns={tableColumns}
                                   items={transactions.map((item: any) => ({
                                       ...item,
                                       documentId: <Link to={`/document?id=${encodeURIComponent(item.documentId)}`}>{item.documentId}</Link>,
                                       id: <Link to={"/transactions/" + item.id}>{item.transactionId}</Link>,
                                   }))}/>
                        </Field>}
                </Fetch>
            </div>
        </Fragment>
    );
};

export default Mandate;