import React from 'react';
import {Route} from "react-router-dom";
import Overlay from "./Overlay";

//TODO switch the state location from Overlay to here so Route received state location so underlaying component have this with withRouter
const OverlayRoute = ({closeOverlay, render, size, ...restOfProps}) => (
    <Route {...restOfProps} children={({match}) => (
        <Overlay
            match={match}
            closeOverlay={closeOverlay}
            render={render}
            size={size}
        />
    )}/>
);

export default OverlayRoute;