import React, {useState} from 'react';
import {runScript, retrieveFilenameFromHeaders, sendFileToBrowser} from "../../services";
import FormGroupRowSelect from "../../Elements/FormGroupRowSelect";
import FetchFromContext from "../../Elements/FetchFromContext";
import FormGroupRowDate from "../../Elements/FormGroupRowDate";
import useSubmittingButton from "../../hooks/useSubmittingButton";
import {Col, Row} from "reactstrap";

const DatylonReport = () => {

    const [billingSenderId, setBillingSenderId] = useState('');
    const [date, setDate] = useState('');
    const {successMessage, errorMessage, loader, SubmittingButton} = useSubmittingButton();

    const handleDownload = async (type, fallbackFilename) => {
        const response = await runScript('DatylonReport', {
            billingSenderId: billingSenderId,
            date: date,
            type: type,
        });

        if (response.status === 400) {
            const data = await response.json();
            throw new Error(data.message);
        }

        if (response.status !== 200) {
            throw new Error(`${response.status}: ${response.statusText}. Contact a site administrator. This error probably won't go away by itself.`);
        }

        //fallback filename if no filename from server is received
        let filename = retrieveFilenameFromHeaders(response.headers, fallbackFilename);

        const blob = await response.blob();
        sendFileToBrowser(filename, blob);
    };

    const handleDownloadXLSX = () => handleDownload('xlsx', `datylon-report_${billingSenderId}.xlsx`);
    const handleDownloadPDF = () => handleDownload('pdf', `datylon-report_${billingSenderId}.pdf`);

    const disabled = !billingSenderId || !date;

    return (
        <>
            <h4>Datylon Report</h4>

            {errorMessage}

            <form style={{maxWidth: 600}}>

                <FetchFromContext property="billingSenders" className="form-group">
                    {billingSenders => (
                        <FormGroupRowSelect
                            label="Billing sender"
                            value={billingSenderId}
                            onChange={setBillingSenderId}
                            options={billingSenders.map(billingSender => ({value: billingSender.id, label: billingSender.name}))}
                        />
                    )}
                </FetchFromContext>

                <FormGroupRowDate
                    label="Date"
                    value={date}
                    onChange={setDate}
                    description="Only month and year will be used."
                />

                {successMessage}

                <Row form className="align-items-center">
                    <Col xs="auto">
                        <SubmittingButton onClick={handleDownloadXLSX} disabled={disabled}>Download XLSX</SubmittingButton>
                    </Col>
                    <Col xs="auto">
                        <SubmittingButton onClick={handleDownloadPDF} disabled={disabled}>Download PDF</SubmittingButton>
                    </Col>
                    <Col xs="auto">
                        {loader}
                    </Col>
                </Row>
            </form>
        </>
    );
};

export default DatylonReport;