import React, {Fragment} from 'react';
import AuditingTable from "./AuditingTable";

const AuditingRoute = () => {
    return (
        <Fragment>
            <h1 className="mb-5">Auditing</h1>
            <AuditingTable/>
        </Fragment>
    );
};

export default AuditingRoute;