import React from "react";

export type BillingSenderType = {
    id: number;
    name: string;
    externalClientId: string;
    billableFrom: string;
    taxRate: string;
    cachedRemainingCredits: number;
    cachedEstimatedEnd: string;
};

type AppContextType = {
    billingSenders: BillingSenderType[];
};

export const AppContext = React.createContext<Partial<AppContextType>>({});
