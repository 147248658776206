import React from 'react';
import PropTypes from 'prop-types';
import FormGroupRow from "./FormGroupRow";
import DropFile from "./DropFile";

const FormGroupRowFile = ({label, required, type = 'file', value, onChange, disabled, description, placeholder, errorMessage, cssClasses}) => (
    <FormGroupRow label={label} required={required} errorMessage={errorMessage} description={description} cssClasses={cssClasses}>
        <DropFile
            onChange={onChange}
            value={value}
            disabled={disabled}
        />
    </FormGroupRow>
);

FormGroupRowFile.propTypes = {
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    description: PropTypes.string,
    errorMessage: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    cssClasses: PropTypes.array
};

export default FormGroupRowFile;