import React from 'react';
import AbstractItemsTableWithLoader from "../Tables/AbstractItemsTableWithLoader";
import {Link} from "react-router-dom";
import {Col, Row} from "reactstrap";
import LinkToOverlay from "../Elements/Overlay/LinkToOverlay";
import {PlusIcon} from "react-octicons";

const GenericOverviewRoute = ({
                                  overviewTitle,
                                  basePath,
                                  fetchFunction,
                                  linkColumn,
                                  addOverlayRoute,
                                  ...props
                              }) => {

    const fetchOverviewFunction = (searchParams) => {
        return fetchFunction(searchParams).then(data => ({
            ...data,
            items: data.items.map(item => {
                const obj = {
                    ...item,
                };

                if (!obj.key) {
                    obj.key = obj.id;
                }

                if (linkColumn) {
                    obj[linkColumn] = <Link to={`/${basePath}/${item.id}`}>{obj[linkColumn]}</Link>;
                }

                return obj;
            }),
        }));
    };

    return (
        <>
            <Row className="form-row mb-5 align-items-center">
                <Col xs="auto"><h1>{overviewTitle}</h1></Col>
                {addOverlayRoute && <Col><LinkToOverlay overlay={addOverlayRoute}><PlusIcon/></LinkToOverlay></Col>}
            </Row>
            <AbstractItemsTableWithLoader
                {...props}
                fetchFunction={fetchOverviewFunction}
            />
        </>
    );
};

export default GenericOverviewRoute;
