import React, {Component, Fragment} from 'react';
import {addMerchant} from '../../services';
import PropTypes from 'prop-types';
import FormGroupRowSelect from "../../Elements/FormGroupRowSelect";
import {Col, Row} from "reactstrap";
import {withRefresh} from "../../Elements/Fetch";
import FormWithActions from "../../Elements/FormWithActions/FormWithActions";

class SenderMerchantAddForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            paymentProviderType: '',
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleAction(action) {
        return addMerchant(this.props.senderId, this.state.paymentProviderType).then(data => {
            this.props.triggerRefresh(`sender:${this.props.senderId}`);
            this.props.onSubmit();
            return data;
        });
    }

    handleChange(value, attribute) {
        this.setState({
            [attribute]: value
        });
    }

    render() {

        const disableSubmitReason = this.state.paymentProviderType === '' ? 'Payment provider must be selected' : '';

        return (
            <Fragment>
                <Row>
                    <Col>
                        <h1 className="mb-5">
                            Add merchant
                        </h1>
                    </Col>
                </Row>
                <FormWithActions onAction={this.handleAction} disableSubmitReason={disableSubmitReason}>

                    <FormGroupRowSelect
                        label="Payment provider"
                        value={this.state.paymentProviderType}
                        onChange={value => this.handleChange(value, 'paymentProviderType')}
                        options={[
                            {value: 'paybox', label: 'PAYBOX'},
                            {value: 'sign2pay', label: 'SIGN2PAY'},
                            {value: 'sipsbc', label: 'SIPS_BC'},
                            {value: 'sipsPaypage', label: 'SIPS_PAYPAGE'},
                            {value: 'sipsPaypageBc', label: 'SIPS_PAYPAGE_BCMC'},
                            {value: 'klarna', label: 'KLARNA'},
                            {value: 'pomPayments', label: 'POM_PAYMENTS'},
                            {value: 'buckaroo', label: 'BUCKAROO'},
                            {value: 'buckarooBcApp', label: 'BUCKAROO_BC_APP'},
                            {value: 'twikey', label: 'TWIKEY'},
                            {value: 'dummy', label: 'DUMMY'},

                        ]}
                    />

                </FormWithActions>
            </Fragment>
        );
    }
}

SenderMerchantAddForm.propTypes = {
    senderId: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default withRefresh(SenderMerchantAddForm);