import React, {Fragment, useState} from 'react';

import Field from '../Elements/Field';
import AbstractItemsTableWithLoader from "../Tables/AbstractItemsTableWithLoader";
import {
    deliveryReportFile,
    downloadDocRefFile,
    fetchTasklog, restartTask,
    unlockTask
} from "../services";
import {Role} from "../Auth/BackOfficeUserRole";
import Can from "../Auth/Can";
import LinkToOverlay from "../Elements/Overlay/LinkToOverlay";
import {Link} from "react-router-dom";
import PomModal from "../Elements/PomModal";

const Task = ({taskId, taskName, start, complete, status, lastUpdate, params}) => {

    const [completed, setCompleted] = useState(complete);
    const [taskIdToRerun, setTaskIdToRerun] = useState('');

    function downloadDocRefFileFunction(taskId) {
        return downloadDocRefFile(taskId);
    }

    function deliveryReportFileFunction(taskId) {
        return deliveryReportFile(taskId);
    }


    function renderSeeLogsButton() {
        return <Link to={`/tasks/${taskId}/logs`}>
            <div className="btn btn-success mt-2 mb-2">See logs</div>
        </Link>;
    }

    const notReportedIdsOverlay = `/not-reported-document-ids/${taskId}`;

    function renderTaskDocumentIdsNotReportedOverlay() {
        return <LinkToOverlay overlay={notReportedIdsOverlay}>
            <div className="btn btn-success mt-2">See document ids not reported</div>
        </LinkToOverlay>;
    }

    const fetchFunction = (searchParams) => {
        return fetchTasklog(taskId, searchParams)
            .then(data => ({
                ...data,
                items: [
                    ...data.items.map((item, i) => ({
                        ...item,
                        key: i,
                    })),
                ],
            }));
    };

    const tableColumns = {

        time: {
            name: 'Time',
            sort: true,
            style: {
                width: '230px',
            },
        },
        severity: {
            name: 'Severity',
            style: {
                width: '100px'
            },
        },
        log: {
            name: 'Log',
        }

    };

    function unlock(taskId) {
        return unlockTask(taskId).then(() => {
            setCompleted('true')
        })
    }

    function renderRestartTaskButton() {
        return (
            <button onClick={(() => setTaskIdToRerun(taskId))} className="btn btn-success mt-2 mb-2">Restart
                task</button>
        );
    }

    const restartTaskFunc = () => {
        if (taskIdToRerun) {
            return restartTask(taskId)
                .then(response => {
                    return renderRestartedTaskLink(response);
                })
        }
    }

    function renderRestartedTaskLink(response) {
        return <Link to={`/tasks/${response.taskId}`}>
            {response.taskId}</Link>;
    }

    const renderRerunModal = () => {
        if (taskIdToRerun) {
            let confirmMessage = `Are you sure you want to rerun task with id: "${taskId}" ?`
            return (
                <Fragment>
                    <PomModal showFeedback={true} onAction={restartTaskFunc}
                              confirmAction={restartTaskFunc} confirmMessage={confirmMessage}
                              isOpen={!['', null, undefined].includes(taskId)}
                              onClose={closeModal} title={'Restart task'} children={[]}></PomModal>
                </Fragment>
            );
        }
    };

    const closeModal = () => {
        setTaskIdToRerun('');
    }

    return (
        <>
            {renderRerunModal()}
            <Fragment>
                <div className="row">
                    <div className="col">
                        <h1 className="mb-5">Task: {taskId}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Field name='Task id'>{taskId}</Field>
                        <Field name='Task name'>{taskName}</Field>
                        <Field name='Status'>{status}</Field>
                        <Field name='Start'>{start}</Field>
                        <Field name='Last update'>{lastUpdate}</Field>
                        <Field name='Complete'>
                            {completed}
                            {completed === 'false' && (
                                <Can roles={[Role.WRITE]}>
                                    <button className="ml-1 btn btn-sm btn-info" onClick={() => unlock(taskId)}>Force
                                        complete
                                    </button>
                                </Can>
                            )}
                        </Field>
                    </div>
                    <div className="col" style={{marginTop: "50px"}}>
                    <span>
                           <Can roles={[Role.READ]}>
                                {renderSeeLogsButton()}
                           </Can>
                        <br/>
                            <Can roles={[Role.WRITE]}>
                                {renderRestartTaskButton()}
                            </Can>

                    </span>
                        <br/>
                        {(taskName === 'docRefBatch') &&
                            <>
                                <a className="btn btn-success" target="_blank" rel="noopener noreferrer"
                                   href={downloadDocRefFileFunction(taskId)}>Download file</a>
                                <br/>
                                <span>
                           <Can roles={[Role.READ]}>
                                {renderTaskDocumentIdsNotReportedOverlay()}
                           </Can>
                        </span>
                                <br/>
                                <a className="btn btn-success" target="_blank" rel="noopener noreferrer"
                                   style={{marginTop: "10px"}}
                                   href={deliveryReportFileFunction(taskId)}>Download delivery Report</a>
                            </>
                        }
                    </div>
                    {params && <div className="col">
                        <div className="font-weight-bold">params:</div>
                        <pre>{JSON.stringify(params, null, 2)}</pre>
                    </div>
                    }
                </div>
                <div className="row">
                    <div className="col">
                        <AbstractItemsTableWithLoader
                            name="TaskLog"
                            itemsPerPage={20}
                            tableColumns={tableColumns}
                            fetchFunction={fetchFunction}
                        />
                    </div>
                </div>
            </Fragment>
        </>
    );
};

export default Task;
