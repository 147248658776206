import React, {Fragment} from 'react';
import Fetch from "../Elements/Fetch";
import {fetchAllRoles, fetchUserRoles} from "../services";
import {Col, Row} from "reactstrap";
import UserRoleForm from "./UserRoleForm";

const UserRolesRoute = ({match, history, onSubmit}) => (
    <Fetch fetchFunction={fetchAllRoles}>
        {roles => {
            const handleSubmit = onSubmit || (() => history.push('/users/' + match.params.id));

            return (
                <Fetch fetchFunction={fetchUserRoles} fetchFunctionParams={match.params.id}>
                    {userRoles => {
                        return (
                            <div style={{maxWidth: 800}}>
                                <Fragment>
                                    <Row>
                                        <Col>
                                            <h1 className="mb-5">
                                                Edit roles
                                            </h1>
                                        </Col>
                                    </Row>

                                    <UserRoleForm
                                        refreshKey={`user:${match.params.id}`}
                                        onSubmit={handleSubmit}
                                        userId={match.params.id}
                                        roles={roles}
                                        selectedRoles={userRoles}/>
                                </Fragment>
                            </div>
                        )
                    }}


                </Fetch>
            );
        }}
    </Fetch>
)

export default UserRolesRoute;