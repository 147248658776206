import React, {useState} from 'react';
import {retrieveFilenameFromHeaders, runScript, sendFileToBrowser} from "../../services";
import useSubmittingButton from "../../hooks/useSubmittingButton";
import {Col, Row} from "reactstrap";

const InvoiceReportJoos = () => {

    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const {errorMessage, loader, SubmittingButton} = useSubmittingButton();

    const handleSubmit = async () => {
        const response = await runScript('InvoiceReportJoos', {
            dateFrom: dateFrom,
            dateTo: dateTo,
        });

        if (response.status === 200) {
            //fallback filename if no filename from server is received
            let filename = retrieveFilenameFromHeaders(response.headers, `invoicing-report_${dateFrom}_${dateTo}.xlsx`);
            const blob = await response.blob();
            sendFileToBrowser(filename, blob);
        }
        else {
            throw new Error(`${response.status}: ${response.statusText}. Contact a site administrator. This error probably won't go away by itself.`);
        }
    };

    return (
        <>
            <h4>Invoice report Joos</h4>

            {errorMessage}

            <form style={{maxWidth: 600}}>
                <div className="form-group row">
                    <label className="col-3 col-form-label">Date from</label>
                    <div className="col">
                        <input type="date" className="form-control" placeholder="Date from" value={dateFrom} onChange={e => setDateFrom(e.target.value)}/>
                        <small className="form-text text-muted">
                            This date is included in the export.
                        </small>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-3 col-form-label">Date to</label>
                    <div className="col">
                        <input type="date" className="form-control" placeholder="Date to" value={dateTo} onChange={e => setDateTo(e.target.value)}/>
                        <small className="form-text text-muted">
                            This date is included in the export.
                        </small>
                    </div>
                </div>

                <Row form className="align-items-center">
                    <Col xs="auto">
                        <SubmittingButton onClick={handleSubmit} disabled={!dateFrom || !dateTo}>Submit</SubmittingButton>
                    </Col>
                    <Col xs="auto">
                        {loader}
                    </Col>
                </Row>
            </form>
        </>
    );
};

export default InvoiceReportJoos;
