import React, {Fragment} from 'react';
import {Nav, NavItem} from 'reactstrap';
import {NavLink} from "react-router-dom";
import BillingSenderRoutes from "./BillingSender/BillingSenderRoutes";
import BillingRuleRoutes from "./BillingRule/BillingRuleRoutes";
import InvoiceRoutes from "./Invoice/InvoiceRoutes";
import BillingCreditsRoutes from "./BillingCredits/BillingCreditsRoutes";
import BillingEventsRoutes from "./BillingEvent/BillingEventsRoutes";
import BillingResellerRoutes from "./BillingReseller/BillingResellerRoutes";
import BillingReportsRoutes from "./BillingReports/BillingReportsRoutes";
import BillingStatusRoutes from "./BillingStatus/BillingStatusRoutes";
import moment from "moment";
import MtpFileRoutes from "../MtpFile/MtpFileRoutes";

const Billing = () => {
    return (
        <Fragment>
            <h1 className="mb-5">Billing</h1>

            <Nav pills horizontal="end">
                <NavItem><NavLink className="nav-link" to="/billing/events">Events</NavLink></NavItem>
                <NavItem><NavLink className="nav-link" to={`/billing/invoices?dateCreated=${moment().startOf('month').format("YYYY-MM-DD")}&dateCreated_op=gt`}>Invoices</NavLink></NavItem>
                <NavItem><NavLink className="nav-link" to="/billing/credits?isFreeTier=false&isFreeTier_op=eq&inactive=false&inactive_op=eq">Credits</NavLink></NavItem>
                <NavItem><NavLink className="nav-link" to="/billing/rules">Rules</NavLink></NavItem>
                <NavItem><NavLink className="nav-link" to="/billing/senders">Billing senders</NavLink></NavItem>
                <NavItem><NavLink className="nav-link" to="/billing/resellers">Billing resellers</NavLink></NavItem>
                <NavItem><NavLink className="nav-link" to="/billing/reports">Reports</NavLink></NavItem>
                <NavItem><NavLink className="nav-link" to="/billing/status">Status</NavLink></NavItem>
                <NavItem><NavLink className="nav-link" to="/billing/mtp-files">MTP Files</NavLink></NavItem>
            </Nav>

            <BillingEventsRoutes/>
            <InvoiceRoutes/>
            <BillingCreditsRoutes/>
            <BillingRuleRoutes/>
            <BillingSenderRoutes/>
            <BillingResellerRoutes/>
            <BillingReportsRoutes/>
            <BillingStatusRoutes/>
            <MtpFileRoutes/>

        </Fragment>
    );
};

export default Billing;
