import React, {Fragment} from 'react';

const FormDate = ({value, onChange, searchKey, tabIndex, type='date'}) => (
    <Fragment>
        <input type={type} className="form-control form-control-sm" tabIndex={tabIndex} value={value}
               onChange={event => onChange(event.target.value, searchKey)}/>
        <div className="small text-muted">
            <small>Format: YYYY-MM-DD {type === 'datetime-local' ? 'HH:MM' : ''}</small>
        </div>
    </Fragment>
);

export default FormDate;