import React, {useEffect, useRef} from 'react';
import {JSONEditor} from "@json-editor/json-editor";
import schema from './schema.json';
import {Button} from "reactstrap";

const JsonEditor = ({title, defaultValue, onSubmit}) => {
    const elementRef = useRef();
    const editorRef = useRef();

    const handleSubmit = () => {
        const value = editorRef.current.getValue();
        onSubmit(value);
    };

    useEffect(
        () => {
            editorRef.current = new JSONEditor(elementRef.current, {
                theme: 'bootstrap4',
                schema: schema,
                form_name_root: title,
                disable_collapse: true,
                disable_edit_json: true,
                disable_properties: true,
                required_by_default: true,
                startval: defaultValue,
            });
        },
        [title, defaultValue]
    );

    return (
        <div>
            <div ref={elementRef}/>
            <Button color="success" onClick={handleSubmit}>Save</Button>
        </div>
    );
};

export default JsonEditor;