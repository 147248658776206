import React from "react";

const TaskDocumentIdsNotReported = (TaskDocumentIdsNotReported) => {
    return (
        <ul>
            <h1 className="mb-5">Task document ids not reported</h1>
            {Object.entries(TaskDocumentIdsNotReported).map(([key, value]) => (
                <li style={{listStyle: "none"}} key={key}><strong>{key}: </strong>
                    {Array.isArray(value) ? value.join(', ') : value}
                </li>
            ))}
        </ul>
    );
}

export default TaskDocumentIdsNotReported;