import React, {Component, Fragment} from 'react';
// @ts-ignore
import {PlusIcon, XIcon} from 'react-octicons';
import {Col, Row} from "reactstrap";
import {withRefresh} from "../../Elements/Fetch";
import FormWithActions from "../../Elements/FormWithActions/FormWithActions";
import classNames from 'classnames';
import {GlobalSetting, GlobalSettingUpdate} from '../model/GlobalSetting.interface';

interface GlobalSettingsAddFormProps {
    onSubmit: (settings: GlobalSetting[]) => Promise<any>;
    settings: GlobalSettingUpdate[];
    triggerRefresh: any;
}

interface SettingsState {
    settings: any[];
}

class SettingsForm extends Component<GlobalSettingsAddFormProps, SettingsState> {

    constructor(props: GlobalSettingsAddFormProps) {
        super(props);
        this.state = {
            settings: props.settings.map((setting: GlobalSetting) => ({...setting, deleted: false} as GlobalSettingUpdate)),
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handlePaste = this.handlePaste.bind(this);
        this.handleDeleteSetting = this.handleDeleteSetting.bind(this);
        this.handleChangeSettings = this.handleChangeSettings.bind(this);
    }

    handleAction() {
        let settings = this.state.settings.map(l => {
            return l as GlobalSetting;
        });

        return this.props.onSubmit(settings).then(() => {
            this.props.triggerRefresh();
            return [];
        });
    }

    handleChangeSettings(value: string, index: number, property: string) {
        this.setState((prevState: SettingsState, props) => ({
            settings: [
                ...prevState.settings.slice(0, index),
                {...prevState.settings[index], [property]: value},
                ...prevState.settings.slice(index + 1),
            ],
        }));
    }

    handlePaste(event: any, index: number) {
        const clipboardData = event.clipboardData.getData('Text').trim();
        if (clipboardData.includes("\t")) {
            event.preventDefault();

            let clipboardLines = [clipboardData];
            if (clipboardData.includes("\n")) {
                clipboardLines = clipboardData.split("\n");
            }
            if (clipboardData.includes("\r")) {
                clipboardLines = clipboardData.split("\r");
            }

            this.setState((prevState, props) => ({
                settings: [
                    ...prevState.settings,
                    ...clipboardLines.map(clipboardLine => {
                        const lineData = clipboardLine.split("\t");
                        return {
                            key: 'NEW',
                            category: lineData.shift().trim(),
                            name: lineData.shift().trim(),
                            value: lineData.join(' ').trim(),
                            deleted: false,
                        };
                    }),
                ],
            }));
        }
    }

    handleAdd() {
        this.setState((prevState: SettingsState, props) => ({
            settings: [...prevState.settings, {
                key: 'NEW',
                category: '',
                name: '',
                value: '',
                deleted: false,
            }],
        }));
    }

    handleDeleteSetting(index: number) {
        this.setState((prevState: SettingsState, props) => {
            if (prevState.settings[index].key === 'NEW') {
                return {
                    settings: [
                        ...prevState.settings.slice(0, index),
                        ...prevState.settings.slice(index + 1),
                    ],
                };
            } else {
                return {
                    settings: [
                        ...prevState.settings.slice(0, index),
                        {...prevState.settings[index], deleted: true},
                        ...prevState.settings.slice(index + 1),
                    ],
                };
            }
        });
    }

    render() {

        let disableSubmitReason = '';
        const settings = this.state.settings.map((setting, index) => {
            if (setting.deleted) {
                return "";
            }

            const hasOtherRowsWithSameSetting = this.state.settings.filter(s => s.category === setting.category && s.name === setting.name).length > 1;

            if (!setting.category || !setting.name || !setting.value) {
                disableSubmitReason = 'Attributes cannot be empty';
            }

            if (hasOtherRowsWithSameSetting) {
                disableSubmitReason = 'Duplicate attributes are not allowed';
            }

            return (
                <div className="form-group form-row" key={index}>
                    <div className="col">
                        <input
                            tabIndex={1}
                            type="text"
                            placeholder="category"
                            className={classNames("form-control", {
                                'is-invalid': !setting.category || hasOtherRowsWithSameSetting,
                            })}
                            value={setting.category}
                            onChange={event => this.handleChangeSettings(event.target.value, index, 'category')}
                            onPaste={(event) => this.handlePaste(event, index)}
                        />
                    </div>
                    <div className="col">
                        <input
                            tabIndex={1}
                            type="text"
                            placeholder="name"
                            className={classNames("form-control", {
                                'is-invalid': !setting.name || hasOtherRowsWithSameSetting,
                            })}
                            value={setting.name}
                            onChange={(event) => this.handleChangeSettings(event.target.value, index, 'name')}
                            onPaste={event => this.handlePaste(event, index)}
                        />
                    </div>
                    <div className="col">
                        <input
                            tabIndex={1}
                            type="text"
                            placeholder="value"
                            className={classNames('form-control', {
                                'is-invalid': !setting.value || hasOtherRowsWithSameSetting,
                            })}
                            value={setting.value}
                            onChange={event => this.handleChangeSettings(event.target.value, index, 'value')}
                            onPaste={(event) => this.handlePaste(event, index)}
                        />
                    </div>
                    <div className="col-1 col-form-label">
                        <button type="button" className="btn btn-link btn-sm"
                                onClick={() => this.handleDeleteSetting(index)}>
                            <XIcon/>
                        </button>
                    </div>
                </div>
            );
        });

        return (
            <Fragment>
                <Row>
                    <Col>
                        <h1 className="mb-5">
                            Settings
                        </h1>
                    </Col>
                </Row>
                <FormWithActions onAction={this.handleAction} disableSubmitReason={disableSubmitReason}>

                    {settings}
                    <div className="form-group form-row">
                        <div className="col">
                            <button type="button" className="btn btn-link btn-sm" onClick={this.handleAdd}>
                                <PlusIcon/>
                            </button>
                        </div>
                    </div>

                </FormWithActions>
            </Fragment>
        );
    }
}

export default withRefresh(SettingsForm);
