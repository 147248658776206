import React from 'react';
import {fetchUser} from "../services";
import Fetch from "../Elements/Fetch";
import User from "./User";

const UserRoute = ({match}) => (
    <Fetch fetchFunction={fetchUser} fetchFunctionParams={match.params.id} refreshKey={`user:${match.params.id}`}>
        {user => <User {...user}/>}
    </Fetch>
);

export default UserRoute;