import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from "reactstrap";
import {addBillingCredit} from "../../../services";
import {withRefresh} from "../../../Elements/Fetch";
import FormGroupRowDate from "../../../Elements/FormGroupRowDate";
import FormGroupRowText from "../../../Elements/FormGroupRowText";
import FormWithActions from "../../../Elements/FormWithActions/FormWithActions";
import moment from 'moment';

class BillingCreditsAddForm extends Component {

    constructor(props) {
        super(props);

        const initialExpirationDate = moment().add(13, 'months');
        if (initialExpirationDate.date() !== 1) {
            initialExpirationDate.add(1, 'month').startOf('month');
        }

        this.state = {
            purchasedCredits: '',
            purchaseAmount: '',
            purchasedDate: moment().format('YYYY-MM-DD'),
            expirationDate: initialExpirationDate.format('YYYY-MM-DD'),
            comment: '',
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleAction(action) {
        switch (action) {
            case 'submit':
                return addBillingCredit({
                    ...this.state,
                    billingSenderId: this.props.billingSenderId,
                })
                    .then(data => {
                        this.props.triggerRefresh(['billingCreditsList', `billingCredits:${this.props.billingSenderId}`]);
                        this.props.onSubmit();
                        return data;
                    });
            default:

        }
    }

    handleChange(attribute) {
        return value => {
            this.setState({
                [attribute]: value,
            });
        };
    }

    render() {
        const disableSubmitReason = (!this.state.purchasedCredits || !this.state.purchaseAmount || !this.state.purchasedDate || !this.state.expirationDate) ? 'Fill in all required fields' : '';

        return (
            <Fragment>
                <Row>
                    <Col>
                        <h1 className="mb-5">
                            Add credits
                        </h1>
                    </Col>
                </Row>

                <FormWithActions onAction={this.handleAction} disableSubmitReason={disableSubmitReason}>

                    <FormGroupRowText
                        label="Credits"
                        value={this.state.purchasedCredits}
                        onChange={this.handleChange('purchasedCredits')}
                    />

                    <FormGroupRowText
                        label="Purchase amount"
                        value={this.state.purchaseAmount}
                        onChange={this.handleChange('purchaseAmount')}
                    />

                    <FormGroupRowDate
                        label="Purchase date"
                        value={this.state.purchasedDate}
                        onChange={this.handleChange('purchasedDate')}
                    />

                    <FormGroupRowDate
                        label="Expiration date"
                        value={this.state.expirationDate}
                        onChange={this.handleChange('expirationDate')}
                        description="Credits will expire at 00:00:00 of this date. This must be the first day of the month."
                    />

                    <FormGroupRowText label="Comment" value={this.state.comment} onChange={this.handleChange('comment')}/>

                </FormWithActions>
            </Fragment>
        );
    }
}

BillingCreditsAddForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default withRefresh(BillingCreditsAddForm);
