import React from 'react';
import PropTypes from 'prop-types';
import './DropFile.css';
import {useRef, useState} from "react";
import {uploadLogoToS3} from "../services";

const DropFileInput = ({value, onChange, disabled}) => {
    const defaultLogo = 'https://s3.eu-central-1.amazonaws.com/assets.api.letspom.eu/senderlogos/upload-logo.webp-d4bf9bd4-53fb-4a8a-9c0e-38775c12ff3d';
    const defaultLogoInDisabledMode = 'https://s3.eu-central-1.amazonaws.com/assets.api.letspom.eu/senderlogos/red-cross-mark-icon-3d-render-png.webp-413df99d-de77-41dc-9b8c-36828bdc62b0'
    let incomingSrc = value;

    const wrapperRef = useRef(null);
    const [src, setSrc] = useState(disabled ? defaultLogoInDisabledMode : defaultLogo);
    const [errorMessage, setErrorMessage] = useState('');
    const onDragEnter = () => wrapperRef.current.classList.add('dragover');

    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    const onFileDrop = (e) => {
        const logo = e.target.files[0];
        if (logo) {
            const formData = new FormData();
            formData.append('logo', logo);

            uploadLogoToS3(formData).then(response => {
                if (!response.url) {
                    setErrorMessage(response.message);
                    return;
                }

                setSrc(response.url);
                onChange(response.url);
            })
                .catch(errorMessage => {
                    setErrorMessage(errorMessage)
                })
        }
    }
    return (
        <>
            <div
                ref={wrapperRef}
                className={`drop-file-input ${disabled ? 'disabled' : ''}`}
                onDragEnter={disabled ? '' : onDragEnter}
                onDragLeave={disabled ? '' : onDragLeave}
                onDrop={disabled ? '' : onDrop}
            >
                <div className="drop-file-input_label">
                    <img
                        src={incomingSrc || src}
                        alt="Logo not found"/>
                    <p>Drag & Drop your logo here</p>
                </div>
                <input disabled={disabled} multiple={false} type="file" onChange={onFileDrop}/>
            </div>
            {errorMessage &&
                <div className="alert alert-danger alert-dismissible">
                    {errorMessage}
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span onClick={() => setErrorMessage('')}
                                  aria-hidden="true">&times;</span></button>
                </div>
            }
        </>
    );
}

DropFileInput.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired
}

export default DropFileInput;