import React, {Fragment} from 'react';
import TransactionsTable from './TransactionsTable';

const TransactionsRoute = () => (
    <Fragment>
        <h1 className="mb-5">Transactions</h1>
        <TransactionsTable itemsPerPage={20}/>
    </Fragment>
);

export default TransactionsRoute;