import React from 'react';
import {Route, Switch} from "react-router-dom";
import InvoiceRoute from "./Invoice/InvoiceRoute";
import InvoiceEditRoute from "./InvoiceEdit/InvoiceEditRoute";
import InvoicesRoute from "./Invoices/InvoicesRoute";

const InvoiceRoutes = () => {
    return (
        <Switch>
            <Route exact path="/billing/invoices" component={InvoicesRoute}/>
            <Route exact path="/billing/invoices/:id" component={InvoiceRoute}/>
            <Route exact path="/billing/invoices/:id/edit" component={InvoiceEditRoute}/>
        </Switch>
    );
};

export default InvoiceRoutes;
