import React, {Fragment} from 'react';
import InvoicesTable from "./InvoicesTable";

const InvoicesRoute = () => {
    return (
        <Fragment>
            <h1 className="mb-5">Invoices</h1>
            <InvoicesTable/>
        </Fragment>
    );
};

export default InvoicesRoute;