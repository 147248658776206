import React, {Fragment} from 'react';
import DocRefsTable from './DocRefsTable';

const DocRefsRoute = () => (
    <Fragment>
        <h1 className="mb-5">DocRefs</h1>
        <DocRefsTable/>
    </Fragment>
);

export default DocRefsRoute;