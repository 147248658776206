import React, {Fragment, useContext} from 'react';

import Field from '../../../Elements/Field';
import Table from '../../../Elements/Table/Table';

import {formatCurrency, formatNumber, startInvoiceDownload} from '../../../services';
import {Row, Col, Button} from 'reactstrap';
import {PencilIcon} from 'react-octicons';
import LinkToOverlay from "../../../Elements/Overlay/LinkToOverlay";
import moment from "moment";
import {ModalContext} from "../../../Modal/ModalProvider";

const Invoice = ({id, billingSender, reference, dateCreated, items, locked, pllayUser, externalReadableId, totalAmount}) => {
    const {showModal} = useContext(ModalContext);

    const itemColumns = {
        description: 'Type',
        unitsFormatted: 'Units',
        amountFormatted: {
            name: 'Document amount',
            style: {
                width: '165px',
            },
            rowStyle: {
                textAlign: 'right',
            },
        },
        billAmountFormatted: {
            name: 'Billed',
            style: {
                width: '120px',
            },
            rowStyle: {
                textAlign: 'right',
            },
        },
        date: {
            name: 'Date',
            style: {
                width: '160px',
            },
        },
    };

    const itemRows = items.map(item => {
        return {
            ...item,
            unitsFormatted: formatNumber(item.units),
            amountFormatted: formatCurrency(item.amount),
            billAmountFormatted: formatCurrency(item.billAmount, 2, 3),
            date: moment(item.date).format("MMMM YYYY"),
        };
    });

    const handleDownloadClick = () => {
        showModal({
            type: 'download',
            startDownloadFunction: () => startInvoiceDownload(id),
            downloadModalDomainObjectName: 'invoice',
        });
    };

    return (
        <Fragment>

            <Row className="form-row">
                <Col xs="auto"><h2 className="mb-5">Invoice</h2></Col>
                <Col style={{marginTop: '5px'}}><LinkToOverlay overlay={`/billing/invoices/${id}/edit`}><PencilIcon/></LinkToOverlay></Col>
            </Row>

            <Field name='Billing sender'>{billingSender.name}</Field>
            <Field name='Date created'>{new Date(dateCreated).toLocaleString()}</Field>
            <Field name='Reference'>{reference}</Field>
            <Field name='Locked'>{locked ? 'Locked' : ''}</Field>
            <Field name='Created by'>{pllayUser}</Field>
            <Field name='Eenvoudig Factureren invoice number'>{externalReadableId}</Field>

            <Table name='Items' columns={itemColumns} items={itemRows}/>

            <Field name='Total'>{formatCurrency(totalAmount)}</Field>

            <Button color="success" onClick={handleDownloadClick}>Download</Button>

        </Fragment>
    );
};

export default Invoice;
